import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Field, Form } from "formik";
import * as yup from "yup";
import { CardBody, CardHeader, Col, Row } from "react-bootstrap";
import { request } from "../../../utils/Request";
import InputAttachment from "../../../components/Shared/InputAttachment";
import { InputSingleSelectField } from "../../../components/Shared/InputSingleSelectField";
import { Card } from "primereact/card";
import { get, isEmpty } from "lodash";
import { Toast } from "primereact/toast";
import { InputField } from "../../../components/Shared/InputField";
import SupplierNotificationSettingProfile from "./SupplierNotificationSettingProfile";
import { Button } from "primereact/button";
import DriverNotificationSettingProfile from "./DriverNotificationSettingProfile";
import CompanySettingProfile from "./CompanySettingProfile";
import CvsProfile from "./CvsProfile";
import PanelSettingProfile from "./PanelSettingProfile";
import AlpProfile from "./AlpProfile";
import Auth from "../../../utils/Auth";
import Tooltip from "../../../components/Common/Display/Tooltip";

type TInputForm = {
    id?: null | any,
    first_name?: string,
    email: string,
    last_name: string | null,
    phone: string | null,
    password: string | null,
    password_confirmation: string | null,
    profile?: any,
    is_two_factor_authentication: boolean,
    timezone: string | null,
    date_format: string | null,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        first_name: yup.string().required(t('the field is required.', { field: t('first name') })).nullable(),
        last_name: yup.string().notOneOf([yup.ref('firstName')]).nullable(),
        email: yup.string().email(t('the email must be a valid email address.')).required(t('the field is required.', { field: t('email') })).nullable(),
        phone: yup.string().nullable().matches(/^$|((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, t('the field value is not valid', { field: t('phone') })),
        password: yup.string().when('id', {
            is: null,
            then: yup.string().required(t('the field is required.', { field: t('password') })).nullable(),
            otherwise: yup.string().notRequired().nullable(),
        }),
        password_confirmation: yup.string().when('id', {
            is: null,
            then: yup.string().required(t('the field is required.', { field: t('password confirmation') })).oneOf([yup.ref('password'), null], t('the field confirmation does not match.', { field: t('password') })).nullable(),
            otherwise: yup.string().notRequired().nullable(),
        }),
        profile: yup.object().nullable(),
        is_two_factor_authentication: yup.boolean(),
        timezone: yup.object().nullable(),
        date_format: yup.object().nullable(),
    });
};

const DEFAULT_FORM_STATE = {
    id: null,
    first_name: null,
    email: null,
    last_name: null,
    phone: null,
    password: null,
    password_confirmation: null,
    profile: null,
    is_two_factor_authentication: false,
    timezone: null,
    date_format: null
};

const DEFAULT_DROPDOWNS = {
    countries: [],
    timezones: [],
    clients: [],
    dateFormats: []
};

const DEFAULT_SETTING_FORM_STATE = {
    logo: null,
    logo_text: null,
};

function Index(props: any) {
    const { t } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<any>(DEFAULT_FORM_STATE);
    const [dropdowns, setDropdowns] = useState<any>(DEFAULT_DROPDOWNS);
    const [user, setUser] = useState<any>(null);
    const [isAlpConnected, setIsAlpConnected] = useState(false);
    const [settings, setSettings] = useState<any>(DEFAULT_SETTING_FORM_STATE);
    const toast = useRef(null);
    const auth = new Auth();

    const toastify = (message: any, color: string = 'info', summary: any = null) => {
        if ( toast && toast.current ) {
            // @ts-ignore
            toast.current.show({ severity: color, summary: summary, detail: message });
        }
    };

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: 'PATCH',
                url: 'auth/profile',
                data: payloads,
            });

            const { data } = response.data;
             auth.setUser(data);

            if ( !isEmpty(data.alp_user) ) {
                setIsAlpConnected(data.alp_user);
            }
            window.location.reload();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    const getDropdowns = async () => {
        try {
            const response = await request({
                url: 'auth/dropdowns',
            });
            const { data } = response.data;

            setDropdowns({
                countries: data.countries,
                timezones: data.timezones,
                clients: data.clients,
                dateFormats: data.date_formats
            });
        } catch (e) {
            setDropdowns(DEFAULT_DROPDOWNS);
        }
    };

    const getProfile = async () => {
        try {
            const response = await request({
                url: 'auth/user',
            });

            const { data } = response.data;

            setUser(data);
            const formData = {
                id: data.id,
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                phone: data.phone,
                is_two_factor_authentication: data.is_two_factor_authentication > 0,
                profile: data.profile?.id,
                timezone: data.timezone,
                date_format: data.date_format
            };

            if ( !isEmpty(data.alp_user) ) {
                setIsAlpConnected(data.alp_user);
            }

            setInitFormState(formData);
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    const getSetting = async () => {
        try {
            const response = await request({
                url: `/auth/setting`
            });

            const { data } = response.data;
            setSettings(data);
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        async function init() {
            await getProfile();
            await getDropdowns();
            await getSetting();
        }

        init();
    }, []);

    return (
        <>
            <div>
                <Toast ref={ toast } />
                <Row>
                    <Col sm={ 12 } md={ 8 }>
                        <Card className={ 'mb-3' }>
                            <CardHeader>
                                <h4 className={ "m-0" }>{ t('user') } { t('profile') }</h4>
                                <h6 className={ "m-1" }>
                                    <Tooltip tooltip={t('identification number')}>
                                        { get(auth.getUser(), ['identification_number']) }
                                    </Tooltip>
                                </h6>
                            </CardHeader>
                            <CardBody className={ "mt-3" }>
                                <Formik
                                    innerRef={ formRef }
                                    enableReinitialize={ true }
                                    initialValues={ initFormState }
                                    validationSchema={ validationSchema(t) }
                                    onSubmit={ onSubmit }
                                >
                                    { (props: FormikProps<any>) => {
                                        return (
                                            <Form onSubmit={ props.handleSubmit } autoComplete={ "off" }>
                                                <Row>
                                                    <Col sm={ 12 } md={ 6 }>
                                                        <Field component={ InputField }
                                                               name="first_name"
                                                               label={ t("first name") }
                                                               placeholder={ t("enter") + ' ' + t("first name").toLowerCase() }
                                                               isRequired
                                                               autoFocus />
                                                    </Col>
                                                    <Col sm={ 12 } md={ 6 }>
                                                        <Field component={ InputField }
                                                               name="last_name"
                                                               label={ t("last name") }
                                                               placeholder={ t("enter") + ' ' + t("last name").toLowerCase() }
                                                        />
                                                    </Col>
                                                    <Col sm={ 12 } md={ 6 }>
                                                        <Field component={ InputField }
                                                               name="email"
                                                               label={ t("email") }
                                                               autoComplete={ "off" }
                                                               placeholder={ t("enter") + ' ' + t("email").toLowerCase() }
                                                               isRequired
                                                        />
                                                    </Col>
                                                    <Col sm={ 12 } md={ 6 }>
                                                        <Field component={ InputField }
                                                               name="phone"
                                                               label={ t("phone") }
                                                               placeholder={ t("enter") + ' ' + t("phone").toLowerCase() }
                                                        />
                                                    </Col>
                                                    <Col sm={ 12 } md={ 6 }>
                                                        <Field component={ InputField }
                                                               name="password"
                                                               type={ "password" }
                                                               label={ t("password") }
                                                               placeholder={ t("enter") + ' ' + t("password").toLowerCase() }
                                                               isRequired={ !props.values.id }
                                                        />
                                                    </Col>
                                                    <Col sm={ 12 } md={ 6 }>
                                                        <Field component={ InputField }
                                                               name="password_confirmation"
                                                               type={ "password" }
                                                               label={ t("password confirmation") }
                                                               placeholder={ t("enter confirm password") }
                                                               isRequired={ !props.values.id }
                                                        />
                                                    </Col>
                                                    <Col sm={ 12 } md={ 6 }>
                                                        <Field component={ InputSingleSelectField }
                                                               name="timezone"
                                                               options={ dropdowns?.timezones }
                                                               label={ t('timezone') }
                                                        />
                                                    </Col>
                                                    <Col sm={ 12 } md={ 6 }>
                                                        <Field component={ InputSingleSelectField }
                                                               name="date_format"
                                                               options={ dropdowns?.dateFormats }
                                                               label={ t('date format') }
                                                        />
                                                    </Col>
                                                    {/*<Col sm={ 12 } md={ 6 }>*/ }
                                                    {/*    <Field component={ InputSwitch }*/ }
                                                    {/*           name="is_two_factor_authentication"*/ }
                                                    {/*           label={ t("enable two factor login") }*/ }
                                                    {/*           needBoolean*/ }
                                                    {/*    />*/ }
                                                    {/*</Col>*/ }
                                                    <Col sm={ 12 } md={ 6 }>
                                                        <Field component={ InputAttachment }
                                                               name="profile"
                                                               label={ t("profile pic") }
                                                               needBoolean
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={ 12 }>
                                                        <Button type="submit" size={ 'small' }
                                                                title={ t("to data save into database") }
                                                                className="btn btn-success waves-effect btn-label waves-light mt-3">
                                                            <i className="fas fa-save label-icon me-2" /> { t("let's save") }
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        );
                                    }
                                    }
                                </Formik>
                            </CardBody>
                        </Card>

                        {
                            ( user && user.level == 8 && settings ) &&
                            <CompanySettingProfile user={ user } settings={ settings } dropdowns={ dropdowns }
                                                   toastify={ toastify } />
                        }
                    </Col>
                    {
                        ( user && user.level == 8 ) &&
                        <Col sm={ 12 } md={ 4 }>
                            <AlpProfile connected={ isAlpConnected } user={ user } toastify={ toastify } />
                            <CvsProfile user={ user } toastify={ toastify } />
                            { settings &&
                            <PanelSettingProfile user={ user } settings={ settings } toastify={ toastify } /> }
                        </Col>
                    }

                    {
                        ( user && user.level == 7 ) &&
                        <Col sm={ 12 } md={ 6 } className={ 'mt-3' }>
                            <DriverNotificationSettingProfile toastify={ toastify } user={ user } />
                        </Col>
                    }

                    {
                        ( user && user.level == 8 ) &&
                        <Col sm={ 12 } md={ 6 } className={ 'mt-3' }>
                            <SupplierNotificationSettingProfile toastify={ toastify } user={ user } />
                        </Col>
                    }

                </Row>
            </div>
        </>
    );
}

Index.propTypes =
    {
        t: PropTypes.any,
    }
;

export default withTranslation()(Index);
