import { MenuModel } from "../../../types";

export function DriverNav(props: any) {
    const { t, auth } = props;

    const model: MenuModel[] = [
        {
            label: t("dashboard"),
            icon: "pi pi-home",
            key: 1,
            items: [
                {
                    label: t("dashboard"),
                    icon: "pi pi-fw pi-home",
                    to: "/dashboard",
                    key: 2,
                },
                {
                    label: t("loads"),
                    icon: "pi pi-fw pi-table",
                    to: "/loads",
                    key: 3,
                },
            ],
        },
    ];

    return model;
}
