import React from 'react';
import { ErrorMessage } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { DynamicObject } from "../../helpers/commonTypes";

interface IInputFieldProps {
    label?: string,
    withoutLabel?: boolean,
    field: any,
    form?: any,
    icon?: any,
    isRequired?: boolean
    formGroupClass?: any,
    inputGroupIconClass?: any,
    inputClass?: any,
    placeholder?: any,
    disabled?: boolean,
    invalid?: boolean,
    onBlur?: any,
    formText?: any,
    inputRef: any,
    config?: DynamicObject
}

export class InputRichTextEditor extends React.Component<IInputFieldProps, {}> {
    static defaultProps = {
        isRequired: false,
        config: {
            toolbar: {
                items: ['bold', 'heading', 'fontColor', 'numberedList', 'bulletedList', 'insertTable', 'horizontalLine', 'sourceEditing', 'removeFormat']
            },
            removePlugins: ['Title'],
            htmlSupport: {
                allow: [{
                    attributes: true,
                }]
            }
        }
    };

    constructor(props: IInputFieldProps) {
        super(props);
    }

    render() {
        const {
            field,
            form,
            formGroupClass,
            withoutLabel,
            isRequired,
            formText,
            config
        } = this.props;
        const { errors } = form;

        return (
            <div className="p-fluid">
                <div className={ `field ${ formGroupClass }` }>
                    { !withoutLabel
                        && <label htmlFor={ field.id ?? field.name }>
                            { this.props.label }
                            { isRequired && <strong style={ { color: 'var(--red-500)' } }> *</strong> }
                      </label>
                    }

                    <>
                        <CKEditor
                            editor={ Editor }
                            config={ config }
                            data={ field.value }
                            onChange={ (event: any, editor: any) => {
                                const data = editor.getData();
                                form.setFieldValue(field.name, data);
                            } }
                        />
                        { formText && <small>{ formText }</small> }
                        <ErrorMessage name={ field.name } component="small"
                                      className="p-error" />
                    </>
                </div>
            </div>
        );
    }
}
