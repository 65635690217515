import React from "react";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { request } from "../../../utils/Request";
import { Card, CardBody, CardHeader, Form, CardFooter } from "react-bootstrap";
import { FormikProps, Formik, Field } from "formik";
import PropTypes from "prop-types";
import { InputField } from "../../../components/Shared/InputField";
import { Button } from "primereact/button";

type TInputCvsForm = {
    cvs_auth_token: null | any,
}

const DEFAULT_FORM_STATE = {
    cvs_auth_token: null,
};

interface IProps {
    user?: any,
    t?: any,
    toastify?: any
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        cvs_auth_token: yup.string().required(t('the field is required.', { field: t('auth token') })).nullable(),
    });
};

function CvsProfile(props: IProps) {
    const { user, t, toastify } = props;
    const [initFormState, setInitFormState] = useState<any>(DEFAULT_FORM_STATE);

    const onSubmit = async (payloads: TInputCvsForm, { setErrors }: any) => {
        try {
            const response = await request({
                method: 'POST',
                url: `/suppliers/cvs`,
                data: payloads,
            });

            toastify(t("record updated", { item: t('token') }), "success");
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    const onEdit = async (): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/cvs`
            });

            const { data } = response.data;
            
            setInitFormState({
                cvs_auth_token: data.cvs_auth_token
            });
        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        onEdit();
    }, []);

    return (
        <Formik
            enableReinitialize={ true }
            initialValues={ initFormState }
            onSubmit={ onSubmit }
            validationSchema={ validationSchema(t) }
        >
            { (props: FormikProps<TInputCvsForm>) => {
                return (
                    <Form onSubmit={ props.handleSubmit }>
                        <Card>
                            <CardHeader className={ "bg-body-tertiary" }>
                                <h4 className="card-title">{ t('CVS') } { t('Access') }</h4>
                            </CardHeader>
                            <CardBody>
                                <Field component={ InputField }
                                       name="cvs_auth_token"
                                       label={ t("cvs auth token") }
                                       placeholder={ t("enter") + ' ' + t("cvs auth token").toLowerCase() }
                                       isRequired />
                            </CardBody>
                            <CardFooter>
                                <Button type="submit" size={ 'small' }
                                        title={ t("to data save into database") }
                                        className="btn btn-success waves-effect btn-label waves-light">
                                    <i
                                        className="fas fa-save label-icon me-2" /> { t("let's save") }
                                </Button>
                            </CardFooter>
                        </Card>
                    </Form>
                );
            } }
        </Formik>
    );
}

CvsProfile.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(CvsProfile);
