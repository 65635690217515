import React, { useRef } from "react";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { request } from "../../../utils/Request";
import { Card, CardBody, CardHeader, Form, CardFooter, Col, Row } from "react-bootstrap";
import { FormikProps, Formik, Field } from "formik";
import { find, get, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { InputSwitchField } from "../../../components/Shared/InputSwitchField";
import { Button } from "primereact/button";

type TInputForm = {
    when_load_added: any,
    when_load_updated: any,
    when_load_deleted: any,
}

const DEFAULT_FORM_STATE = {
    when_load_added: false,
    when_load_updated: false,
    when_load_deleted: false,
};

interface IProps {
    user?: any,
    t?: any,
    toastify: any;
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        when_load_added: yup.boolean(),
        when_load_updated: yup.boolean(),
        when_load_deleted: yup.boolean(),
    });
};

function SupplierNotificationSettingProfile(props: IProps) {
    const { user, t, toastify } = props;
    const [initFormState, setInitFormState] = useState<any>({ ...DEFAULT_FORM_STATE });
    const formRef: any = useRef();
    const toast = useRef(null);


    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: 'PATCH',
                url: `/auth/notification/setting`,
                data: {
                    ...payloads
                },
            });

            toastify(t("record updated", { item: t('settings') }), "success");
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    const getSetting = async () => {
        try {
            const response = await request({
                url: `/auth/notification/setting`
            });

            const { data } = response.data;

            setInitFormState({
                when_load_added: ( find(data, {
                    type_of_notification: "when_load_added",
                    group_of_notification: "Email"
                }) || {} ).enable || false,
                when_load_updated: ( find(data, {
                    type_of_notification: "when_load_updated",
                    group_of_notification: "Email"
                }) || {} ).enable || false,
                when_load_deleted: ( find(data, {
                    type_of_notification: "when_load_deleted",
                    group_of_notification: "Email"
                }) || {} ).enable || false,
            });
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    // @ts-ignore
    useEffect(() => {
        async function init() {
            await getSetting();
        }

        init();
    }, []);

    return (
        <Formik
            innerRef={ formRef }
            enableReinitialize={ true }
            initialValues={ initFormState }
            onSubmit={ onSubmit }
            validationSchema={ validationSchema(t) }
        >
            { (props: FormikProps<TInputForm>) => {
                return (
                    <Form onSubmit={ props.handleSubmit }>
                        <Card>
                            <CardHeader className={ "bg-body-tertiary" }>
                                <h4 className="card-title">{ t('notification settings') }</h4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={ 12 } md={ 6 }>
                                        <Field component={ InputSwitchField }
                                               name="when_load_added"
                                               label={ t("email me when load added") }
                                        />
                                    </Col>
                                    <Col sm={ 12 } md={ 6 }>
                                        <Field component={ InputSwitchField }
                                               name="when_load_updated"
                                               label={ t("email me when load updated") }
                                        />
                                    </Col>
                                    <Col sm={ 12 } md={ 6 }>
                                        <Field component={ InputSwitchField }
                                               name="when_load_deleted"
                                               label={ t("email me when load deleted") }
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Button type="submit" size={ 'small' }
                                        title={ t("to data save into database") }
                                        className="btn btn-success waves-effect btn-label waves-light">
                                    <i className="fas fa-save label-icon me-2" /> { t("let's save") }
                                </Button>
                            </CardFooter>
                        </Card>
                    </Form>
                );
            } }
        </Formik>
    );
}

SupplierNotificationSettingProfile.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(SupplierNotificationSettingProfile);
