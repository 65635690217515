import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Field, Form } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Col, Row } from "react-bootstrap";
import { InputField } from "../../../../components/Shared/InputField";
import { Sidebar } from "primereact/sidebar";
import { get, has, includes, trim, find } from "lodash";
import { Button } from "primereact/button";
import { InputSingleSelectField } from "../../../../components/Shared/InputSingleSelectField";
import { InputChip } from "../../../../components/Shared/InputChip";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    dropdowns: any;
    params: any;
    toastify: any;
    onClose?: any;
}

type TInputForm = {
    invoice_recipient_type: any,
    invoice_service_fee: any,
    invoice_bank_account_number: any,
    invoice_oib: any,
    invoice_vat_number: any,
    invoice_currency: any,
    invoice_payment_method: any,
    invoice_payment_condition: any,
    invoice_email_addresses: any,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        invoice_recipient_type: yup.string().required(t('the field is required.', { field: t('recipient type') })).nullable(),
        invoice_service_fee: yup.number().min(0).max(100).nullable(),
        invoice_bank_account_number: yup.string().nullable(),
        invoice_oib: yup.string().required(t('the field is required.', { field: t('oib') })).nullable(),
        invoice_vat_number: yup.string().required(t('the field is required.', { field: t('vat number') })).nullable(),
        invoice_currency: yup.string().required(t('the field is required.', { field: t('currency') })).nullable(),
        invoice_payment_method: yup.string().required(t('the field is required.', { field: t('payment method') })).nullable(),
        invoice_payment_condition: yup.string().required(t('the field is required.', { field: t('payment condition') })).nullable(),
        invoice_email_addresses: yup.array().nullable(), //.min(1, t('the field is required.', { field: t('email') }))
    });
};

const DEFAULT_FORM_STATE = {
    invoice_recipient_type: null,
    invoice_service_fee: null,
    invoice_bank_account_number: null,
    invoice_oib: null,
    invoice_vat_number: null,
    invoice_currency: "EUR",
    invoice_payment_method: null,
    invoice_payment_condition: null,
    invoice_email_addresses: [],
};

function ClientInvoiceSetting(props: IFormProps) {
    const { t, toastify, params, dropdowns } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);
    const [operationId, setOperationId] = useState<any>(null);
    // const [searchParams] = useSearchParams();

    const onClose = () => {
        setPanelState(false);
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm();
        }

        if ( props.onClose ) {
            props.onClose();
        }
    };

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: 'PATCH',
                url: `/suppliers/clients/${ operationId }`,
                data: {
                    ...payloads,
                    invoice_email_addresses: payloads.invoice_email_addresses.map((item: any) => item).join(','),
                    type: 'invoice-settings'
                },
            });

            toastify(t("record updated", { item: t('settings') }), "success");

            resetForm();
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");

            }
        }
    };


    const onEditInvoiceSetting = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/clients/${ id }`
            });

            const { data } = response.data;

            const settings = data.invoice_settings;
            const email = find(settings, { title: 'invoice_email_addresses' })?.value ?? [];

            const formData: TInputForm = {
                ...data,
                invoice_recipient_type: find(settings, { title: 'invoice_recipient_type' })?.value ?? null,
                invoice_service_fee: find(settings, { title: 'invoice_service_fee' })?.value ?? 0,
                invoice_bank_account_number: find(settings, { title: 'invoice_bank_account_number' })?.value ?? null,
                invoice_oib: find(settings, { title: 'invoice_oib' })?.value ?? null,
                invoice_vat_number: find(settings, { title: 'invoice_vat_number' })?.value ?? null,
                invoice_currency: find(settings, { title: 'invoice_currency' })?.value ?? "EUR",
                invoice_payment_method: find(settings, { title: 'invoice_payment_method' })?.value ?? null,
                invoice_payment_condition: find(settings, { title: 'invoice_payment_condition' })?.value ?? null,
                invoice_email_addresses: email.length ? email.split(',') : [],
            };

            setInitFormState(formData);
            setOperationId(id);
            setPanelState(true);
        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        if ( includes(['invoice-settings'], get(params, ['operation'])) ) {
            onEditInvoiceSetting(params.operationId);
        }
    }, [params]);

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('invoice settings') + ' ' + t('action') }</h5>
                            <p>{ t("using this form you can add or update the record") }</p>
                        </div>
                    }
                    visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } } closeOnEscape={ false }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                        validationSchema={ validationSchema(t) }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4" }>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSingleSelectField }
                                                   name="invoice_recipient_type"
                                                   options={ dropdowns?.recipientTypes }
                                                   label={ t('recipient type') }
                                                   placeholder={ t('pick') +' '+t('recipient type').toLowerCase() }
                                                   isRequired autoFocus={ true }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="invoice_service_fee"
                                                   label={ t("service fee") + '(%)' }
                                                   placeholder={ "Ex. 25" } />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="invoice_bank_account_number"
                                                   label={ t("bank account number") }
                                                   placeholder={ "Ex. 3215888070908" } />
                                        </Col>
                                        <Col sm={ 12 } md={ 3 }>
                                            <Field component={ InputField }
                                                   name="invoice_oib"
                                                   label={ t("oib") }
                                                   placeholder={ "Ex. 8809998" }
                                                   isRequired />
                                        </Col>
                                        <Col sm={ 12 } md={ 3 }>
                                            <Field component={ InputField }
                                                   name="invoice_vat_number"
                                                   label={ t("vat number") }
                                                   placeholder={ "Ex. 8809998" }
                                                   isRequired />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSingleSelectField }
                                                   name="invoice_currency"
                                                   options={ dropdowns?.currencies }
                                                   label={ t('currency') }
                                                   placeholder={ t('pick') +' '+t('currency').toLowerCase() }
                                                   isRequired
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 3 }>
                                            <Field component={ InputSingleSelectField }
                                                   name="invoice_payment_method"
                                                   options={ dropdowns?.paymentMethods }
                                                   label={ t('payment method') }
                                                   placeholder={ t('pick') +' '+t('payment method').toLowerCase() }
                                                   isRequired
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 3 }>
                                            <Field component={ InputSingleSelectField }
                                                   name="invoice_payment_condition"
                                                   options={ dropdowns?.paymentConditions }
                                                   label={ t('payment condition') }
                                                   placeholder={ t('pick') +' '+t('payment condition').toLowerCase() }
                                                   isRequired
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputChip }
                                                   isEmail={ true }
                                                   name={ 'invoice_email_addresses' }
                                                   label={ t('email addresses') }
                                                   placeHolder={ t("type something and press tab...") }
                                                   formText={ t("any invalid emails may not be processed") }
                                                   rounded>
                                            </Field>
                                        </Col>
                                    </Row>
                                    <div className="filter-card-footer"
                                         style={ { width: '785px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-save me-2" />
                                            { t("let's save") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting } onClick={onClose}
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("cancel") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

ClientInvoiceSetting.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(ClientInvoiceSetting);
