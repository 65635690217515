import React, { useEffect, useRef, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import LocationButton from "./partials/LocationButton";
import L from "leaflet";
import { request } from "../../../utils/Request";
import { isEmpty, get, uniq } from "lodash";
import { utcDateToLocalDate } from "../../../utils/Helper";
import { Dialog } from "primereact/dialog";

const MapController = () => {
    const { t } = useTranslation();
    const [markers, setMarkers] = useState<any>([]);
    const greenIcon = L.icon({
        iconUrl: "/images/markers/green-truck.png", iconSize: L.point(34, 34),
    });
    const redIcon = L.icon({ iconUrl: "/images/markers/red-truck.png", iconSize: L.point(34, 34), });
    const blueIcon = L.icon({ iconUrl: "/images/markers/blue-truck.png", iconSize: L.point(34, 34), });

    const getVehicleMoment = async () => {
        try {
            const response = await request({
                url: '/suppliers/t/vehicles/map',
            });

            const { data } = response.data;
            const shallowMarkers = data.filter((item: any) => !isEmpty(item.moment_log));
            setMarkers(shallowMarkers);
        } catch (e) {

        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            getVehicleMoment();
        }, 15000); // Adjust the interval time as needed

        return () => clearInterval(intervalId);
    }, []);

    return <div>
        { markers && markers.map((item: any, index: number) => {
            return <Marker
                key={ index }
                position={ [get(item, ['moment_log', 'latitude']), get(item, ['moment_log', 'longitude'])] }
                icon={ ( item.color === "green" ? greenIcon : (
                    item.color === "red" ? redIcon : (
                        item.color === "blue" ? blueIcon : redIcon
                    )
                ) ) }>
                <Popup>
                    <div className="container p-0">
                        <div className="flex">
                            <div className="d-flex bd-highlight">
                                <div className="p-2 flex-grow-1 bd-highlight">
                                    <b>{ get(item, ['truck_plate_number']) }</b></div>
                                <div className="p-2 bd-highlight">
                                    <i className="fas fa-user" style={ { marginRight: '5px' } }></i>
                                    { get(item, ['moment_log', 'driver_name']) }
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="d-flex bd-highlight">
                                <div className="p-2 flex-grow-1 bd-highlight">
                                    <i className="fas fa-clock" style={ { marginRight: '5px' } }></i>
                                    { utcDateToLocalDate(get(item, ['moment_log', 'created_at'])) }
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="d-flex bd-highlight">
                                <div className="p-2 flex-grow-1 bd-highlight" style={ { color: 'green' } }>
                                    <i className="fas fa-map-marker-alt" style={ { marginRight: '5px' } }></i>
                                    { get(item, ['moment_log', 'location']) } - { get(item, ['moment_log', 'country']) }
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="d-flex bd-highlight">
                                <div className="p-2 flex-grow-1 bd-highlight">
                                    <b>Lat</b>:{ get(item, ['moment_log', 'latitude']) }</div>
                                <div className="p-2 bd-highlight"><b>Lag</b>:{ get(item, ['moment_log', 'longitude']) }
                                </div>
                            </div>
                        </div>
                        { !isEmpty(get(item, ['load_number'])) &&
                            <div className="flex">
                                <div className="p-2 flex-grow-1 bd-highlight">
                                    <b>{ t("load") }</b>: { get(item, ['load_number']).map((item: any, i: number) => `${i > 0 ? ',' : ''} ${item}`) }
                                </div>
                            </div>
                        }
                        { !isEmpty(get(item, ['driver_name'])) &&
                            <div className="flex">
                                <div className="p-2 flex-grow-1 bd-highlight">
                                    <b>{ t("load driver") }</b>: { uniq(get(item, ['driver_name'])).filter((item) => !isEmpty(item)).map((item: any, i: number) => `${i > 0 ? ',' : ''} ${item}`) }
                                </div>
                            </div>
                        }
                    </div>
                </Popup>
            </Marker>;
        }) }
    </div>;
};

const TransportVehicleMap = (props: any) => {
    const { t } = props;
    const toast = useRef(null);
    const [height, setHeight] = useState<any>(800);
    const [legendModal, setLegendModal] = useState<any>(false);
    const updateDimensions = () => {
        const height = window.innerWidth >= 992 ? window.innerHeight : 400;
        setHeight(height);
    };

    useEffect(() => {
        async function init() {
            window.addEventListener("resize", updateDimensions);

            return () => {
                window.removeEventListener("resize", updateDimensions);
            };
        }

        init();
    }, []);

    return (
        <div className={ "module-container" }>
            <div className="layout-content">
                <Dialog header={ t('legends') }
                        visible={ legendModal } style={ { width: '50vw' } }
                        onHide={ () => setLegendModal(false) }
                        breakpoints={ { '960px': '85vw', '641px': '100vw' } }>
                    <div>
                        <p className={ "m-0 fs-5" }>
                            <img
                                height={15}
                                src={'/images/markers/green-truck.png'}
                            /> { t('green - parked (announced)') }
                        </p>
                        <p className={ "m-0 fs-5" }>
                            <img
                                height={15}
                                src={'/images/markers/blue-truck.png'}
                            /> { t('blue - in transit') }
                        </p>
                        <p className={ "m-0 fs-5" }>
                            <img
                                height={15}
                                src={'/images/markers/red-truck.png'}
                            /> { t('red - last log time is older then 3 hours (no signal last 3 hours)') }
                        </p>
                    </div>
                </Dialog>


                <MapContainer
                    // @ts-ignore
                    fullscreenControl={ true }
                    style={ { height: height, width: "100%" } }
                    center={ [51.505, -0.09] } zoom={ 5 } scrollWheelZoom={ true }
                >
                    <MapController />
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <LocationButton />
                </MapContainer>

                <a className="cursor-pointer" onClick={() => setLegendModal(true)}>
                    <i className="fas fa-info-circle text-blue-400" />
                </a>
            </div>
        </div>
    );
};

// TransportVehicleMap.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(TransportVehicleMap);
