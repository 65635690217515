import React from "react";
import { Navigate } from "react-router-dom";
import Auth from "../../utils/Auth";

const Authmiddleware = (props: any) => {
    const auth = new Auth();
    if ( !auth.getUser() ) {
        return (
            <Navigate to={ { pathname: "/login" } } />
        );
    }
    return <React.Fragment>{ props.children }</React.Fragment>;
};

export default Authmiddleware;
