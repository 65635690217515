import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import * as yup from "yup";
import { CardHeader, Col, Row } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { isEmpty } from "lodash";
import HasAccess from "../../../../utils/HasAccess";
import { request } from "../../../../utils/Request";
import { InputField } from "../../../../components/Shared/InputField";
import { InputSingleSelectField } from "../../../../components/Shared/InputSingleSelectField";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

interface IQuickLocationFormProps {
    t?: any | undefined;
    selected?: any;
    toastify?: any;
    onClose?: any;
    onStore: any;
    onUpdate: any;
    allowAdd: boolean;
    allowEdit: boolean;
    classForAddButton?: string
    classForUpdateButton?: string
    labelForAddButton?: string
    labelForUpdateButton?: string
    dropdowns?: any;
    fetchDropdowns: boolean,
}

type TInputQuickLocationModalForm = {
    id: null | any,
    address: null | any,
    street_line_1: null | any,
    street_line_2: null | any,
    landmark: null | any,
    zip: null | any,
    country: null | any,
    city: null | any,
    is_disable: null | any,
    latitude: null | any,
    longitude: null | any,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        address: yup.string().trim(t('the field is invalid.')).max(100).nullable(), // required(t('the field is required.', { field: t('address') })).
        city: yup.string().trim(t('the field is invalid.')).required(t('the field is required.', { field: t('city') })).max(100).nullable(),
        street_line_1: yup.string().trim(t('the field is invalid.')).max(100).nullable(), // .required(t('the field is required.', { field: t('location name') }))
        zip: yup.string().trim(t('the field is invalid.')).required(t('the field is required.', { field: t('zip') })).max(50).nullable(),
        landmark: yup.string().trim(t('the field is invalid.')).max(50).nullable(), // required(t('the field is required.', { field: t('landmark') }))
        street_line_2: yup.string().trim(t('the field is invalid.')).nullable().max(100).nullable(),
        country: yup.string().required(t('the field is required.', { field: t('country') })).nullable(),
    });
};

const DEFAULT_FORM_STATE = {
    id: null,
    address: null,
    street_line_1: null,
    street_line_2: null,
    landmark: null,
    zip: null,
    country: null,
    city: null,
    is_disable: false,
    latitude: null,
    longitude: null,
};

const DEFAULT_DROPDOWNS = {
    countries: []
};

// @ts-ignore
function QuickLocationModalForm(props: IQuickLocationFormProps) {
    const { t, toastify } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputQuickLocationModalForm>(DEFAULT_FORM_STATE);
    const [modal, setModal] = useState(false);
    const [isLoaded, setLoading] = useState<number>(1);
    const [entity, setEntity] = useState<any>(null);
    const [dropdowns, setDropdowns] = useState<any>(DEFAULT_DROPDOWNS);

    const toggle = (value: boolean = false, operation = 'store') => {
        setLoading(-1);
        if ( !value ) {
            if ( !isEmpty(props.onClose) ) {
                props.onClose();
                setInitFormState(initFormState);
                return;
            }
        }

        if ( props.fetchDropdowns ) {
            request({
                url: '/suppliers/locations/create',
                params: {
                    type: 'list'
                }
            })
            .then((response: any) => {
                const { data } = response.data;
                setDropdowns({ countries: data.countries ?? [] });
            })
            .catch(() => setDropdowns(DEFAULT_DROPDOWNS))
            .finally(() => {
                if ( operation === 'update' ) {
                    setModal(value);
                    onEdit();
                } else {
                    setModal(value);
                    setLoading(1);
                    const formDate = { ...DEFAULT_FORM_STATE };
                    setInitFormState(formDate);
                }
            });
            return;
        } else {
            setModal(value);
            setLoading(1);
            setDropdowns(props.dropdowns);
            setTimeout(() => {
                const formDate = { ...initFormState };
                setInitFormState(formDate);
            }, 500);
        }
    };

    const onSubmit = async (payloads: TInputQuickLocationModalForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: ( !payloads.id ? 'POST' : 'PATCH' ),
                url: ( !payloads.id ? `/suppliers/locations` : `/suppliers/locations/${ payloads.id }` ),
                data: { ...payloads, isQuick: true },
            });

            if ( payloads.id ) {
                if ( props.onUpdate ) {
                    props.onUpdate(response.data.data);
                }

                toastify(t("record updated", { item: t('location') }), "success");
            } else {
                if ( props.onStore ) {
                    props.onStore(response.data.data);
                }

                toastify(t("record added", { item: t('location') }), "info");
            }

            resetForm();
            toggle(false);
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    const onEdit = async () => {
        if ( !isEmpty(props.selected) ) {
            setLoading(-1);
            try {
                const response = await request({
                    url: `/suppliers/locations/${ props.selected }`
                });

                const { data } = response.data;
                setEntity(data);
                const formData: TInputQuickLocationModalForm = { ...data };
                setInitFormState(formData);
            } catch (error: any) {
                toastify(t("server error"), "error");
            } finally {
                setLoading(1);
            }
        }
    };

    useEffect(() => {
        setInitFormState(DEFAULT_FORM_STATE);
    }, []);

    return (
        <>
            <Formik
                innerRef={ formRef }
                enableReinitialize={ true }
                initialValues={ initFormState }
                onSubmit={ onSubmit }
                validationSchema={ validationSchema(t) }
            >
                { (formikProps: FormikProps<TInputQuickLocationModalForm>) => {
                    return (
                        <>
                            <span>
                                    <HasAccess hasAnyAccess={ ['locationsstore'] }>
                                        <a className={ props.classForAddButton ?? "" }
                                           onClick={ () => toggle(true, 'store') }
                                        >{ props.labelForAddButton ?? t('add') }</a>
                                    </HasAccess>
                                {
                                    ( props.selected && !isEmpty(props.selected) ) &&
                                    <HasAccess hasAnyAccess={ ['locationsupdate'] }>
                                        <a
                                          className={ props.classForUpdateButton ?? "" }
                                          onClick={ () => toggle(true, 'update') }>
                                            { props.labelForUpdateButton ?? t('update') }
                                        </a>
                                    </HasAccess>
                                }
                            </span>
                            <Dialog visible={ modal } onHide={ () => toggle(false) }
                                    header={<>
                                        { t('location') } { t('action panel') }
                                    </>}
                                    style={{ width: '50vw' }} breakpoints={{ '960px': '85vw', '641px': '100vw' }}>
                                <Form onSubmit={ formikProps.handleSubmit }>
                                    <Row>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="street_line_1"
                                                   label={ t("location name") }
                                                   placeholder={ t("enter") + ' ' + t("location name").toLowerCase() }
                                                   />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="street_line_2"
                                                   label={ t("street name") }
                                                   placeholder={ t("enter") + ' ' + t("street name").toLowerCase() } />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="city"
                                                   label={ t("city") }
                                                   placeholder={ t("enter") + ' ' + t("city").toLowerCase() }
                                                   isRequired
                                                   autoFocus={true} />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="zip"
                                                   label={ t("zip") }
                                                   placeholder={ t("enter") + ' ' + t("zip").toLowerCase() }
                                                   isRequired />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSingleSelectField }
                                                   name="country"
                                                   options={ dropdowns?.countries }
                                                   label={ t('country') }
                                                   placeholder={ t("pick") + ' ' + t("country").toLowerCase() }
                                                   isRequired
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="landmark"
                                                   label={ t("landmark") }
                                                   placeholder={ t("enter") + ' ' + t("landmark").toLowerCase() }/>
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="address"
                                                   label={ t("address") }
                                                   placeholder={ t("enter") + ' ' + t("address").toLowerCase() } />
                                        </Col>
                                    </Row>
                                    <div className={ "text-end" }>
                                        { ( isLoaded < 0 ) && "Loading..." }
                                        {
                                            ( isLoaded > 0 ) && <>

                                              <Button type={ "button" } disabled={ formikProps.isSubmitting }
                                                      tooltip={ t("to data save into database") }
                                                      size={ "small" } outlined
                                                      tooltipOptions={ { position: 'top' } }
                                                      onClick={ formikProps.submitForm }
                                              >
                                                  <i className="pi pi-save me-2" />
                                                  { t("let's save") }
                                              </Button>
                                              <Button type={ "button" } disabled={ formikProps.isSubmitting }
                                                      onClick={ () => formikProps.resetForm() }
                                                      tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                      size={ "small" } severity={ "warning" } outlined
                                                      tooltipOptions={ { position: 'top' } }
                                              >
                                                  <i className="pi pi-refresh me-2" />
                                                  { t("reset") }
                                              </Button>
                                              <Button type={ "button" } disabled={ formikProps.isSubmitting }
                                                      tooltip={ t("operation cancel") } className={ "ms-2" }
                                                      size={ "small" } severity={ "danger" } outlined
                                                      tooltipOptions={ { position: 'top' } }
                                                      onClick={ () => toggle(false) }
                                              >
                                                  <i className="pi pi-arrow-left me-2" />
                                                  { t("cancel") }
                                              </Button>
                                            </>
                                        }
                                    </div>

                                </Form>
                            </Dialog>
                        </>
                    );
                } }
            </Formik>
        </>
    );
}

QuickLocationModalForm.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(QuickLocationModalForm);
