import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Field, Form } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Col, Row } from "react-bootstrap";
import { InputField } from "../../../../components/Shared/InputField";
import { Sidebar } from "primereact/sidebar";
import { get, has, includes, trim, isEmpty } from "lodash";
import { Button } from "primereact/button";
import { InputChip } from "../../../../components/Shared/InputChip";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    dropdowns: any;
    params: any;
    toastify: any;
    onClose?: any;
}

type TInputForm = {
    id: string | null;
    send_to: any,
    send_from: any,
    subject: any,
    cc_emails: any,
    bcc_emails: any,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        send_to: yup.string().required(t('the field is required.', { field: t('send to') })).email(t('the email must be a valid email address.')).nullable(),
        send_from: yup.string().required(t('the field is required.', { field: t('send from') })).email(t('the email must be a valid email address.')).nullable(),
        subject: yup.string().nullable(),
        bcc_emails: yup.array().nullable(),
        cc_emails: yup.array().nullable(),
    });
};

const DEFAULT_FORM_STATE = {
    id: null,
    send_to: null,
    send_from: null,
    subject: null,
    cc_emails: [],
    bcc_emails: [],
};

function EmailResend(props: IFormProps) {
    const { t, toastify, params } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);
    // const [searchParams] = useSearchParams();

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: 'PATCH',
                url: `/suppliers/email/logs/${ payloads.id }`,
                data: {
                    ...payloads,
                    cc_emails: !isEmpty(payloads.cc_emails) ? payloads.cc_emails.join(',') : null,
                    bcc_emails: !isEmpty(payloads.bcc_emails) ? payloads.bcc_emails.join(',') : null,
                },
            });

            toastify(t("resend", { item: t('email') }), "success");

            resetForm();
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");

            }
        }
    };

    const onReset = () => {
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm();
        }
    };

    const onClose = () => {
        setPanelState(false);
        onReset();

        if ( props.onClose ) {
            props.onClose();
        }
    };

    const getDetail = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/email/logs/${ id }`
            });

            const { data } = response.data;
            const formData: TInputForm = { ...data };

            formData.send_to = data.sent_to;
            formData.send_from = data.sent_from;
            formData.subject = data.subject;
            formData.cc_emails = !isEmpty(data.cc_emails) ? data.cc_emails.split(',') : [];
            formData.bcc_emails = !isEmpty(data.bcc_emails) ? data.bcc_emails.split(',') : [];

            setInitFormState(formData);

            setPanelState(true);
        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        // setInitFormState(DEFAULT_FORM_STATE);
        if ( includes(['resend'], get(params, ['operation'])) ) {
            getDetail(params.operationId);
        }
    }, [params]);

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('brand') + ' ' + t('action') }</h5>
                            <p>{ t("using this form you can add or update the record") }</p>
                        </div>
                    }
                    visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                        validationSchema={ validationSchema(t) }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4" }>
                                        <Col sm={ 12 }>
                                            <Field component={ InputField }
                                                   name="send_to"
                                                   label={ t("to email") }
                                                   rounded
                                                   isRequired />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field component={ InputField }
                                                   name="send_from"
                                                   label={ t("from email") }
                                                   rounded
                                                   isRequired />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field component={ InputField }
                                                   name="subject"
                                                   label={ t("subject") }
                                                   rounded
                                                   isRequired />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field component={ InputChip }
                                                   isEmail={ true }
                                                   name={ 'cc_emails' }
                                                   placeHolder={ t("type something and press tab...") }
                                                   formText={ t("any invalid emails may not be processed") }
                                                   label={ t('cc email') }
                                                   rounded
                                                   isRequired>
                                            </Field>
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field component={ InputChip }
                                                   isEmail={ true }
                                                   name={ 'bcc_emails' }
                                                   label={ t('bcc email') }
                                                   placeHolder={ t("type something and press tab...") }
                                                   formText={ t("any invalid emails may not be processed") }
                                                   rounded
                                                   isRequired>
                                            </Field>
                                        </Col>
                                    </Row>
                                    <div className="filter-card-footer"
                                         style={ { width: '785px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-save me-2" />
                                            { t("let's save") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ onReset }
                                                tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                size={ "small" } severity={ "warning" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-refresh me-2" />
                                            { t("reset") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("cancel") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

EmailResend.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(EmailResend);
