import { get, isEmpty, trim } from "lodash";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";
import { client } from "./Request";
import React from "react";
import { split, tail } from "lodash";
import Auth from "./Auth";
import * as store from "store";

export const POINT_DECIMAL = 2;
export const STORE_POINT_DECIMAL = 3;
export const LOCALSTORAGE_PREFIX = process.env.REACT_APP_STORAGE_PREFIX || 'AlcolmCALP';

/**
 * to format date
 *
 * @param date
 * @param format
 */
export const formatDate = (date: any, format = 'DD-MM-YYYY') => ( date ? moment(date).format(format) : '' );

/**
 * to convert utc date to local time date
 *
 * @param date
 * @param toFormat
 * @param fromFormat
 */
export function utcDateToLocalDate(date: any, toFormat = 'DD-MM-YYYY hh:mm A', fromFormat = 'YYYY-MM-DD HH:mm:ss') {
    if ( !date ) {
        return null;
    }

    const auth = new Auth();
    const user = auth.getUser();
    const utcDate = moment.utc(date, fromFormat);

    if ( !isEmpty(get(user, ['timezone'])) ) {
        try {
            return utcDate.clone().tz(get(user, ['timezone'])).format(toFormat);
        } catch (e: any) {

        }
    }

    return utcDate.clone().tz(moment.tz.guess()).format(toFormat);
}

/**
 * to convert locate date to utc
 *
 * @param date
 * @param fromFormat
 * @param toFormat
 */
export function dateToUtcDate(date: any, fromFormat = 'YYYY-MM-DD[T]HH:mm:ss', toFormat = 'YYYY-MM-DD[T]HH:mm:ss') {
    if ( !date ) {
        return null;
    }

    return moment(date, fromFormat).clone().tz(moment.tz.guess()).utc().format(toFormat);
}

export function getDataGridLoadOption(loadOptions: any, dataGrid: any = null) {
    const _dataGrid = ( dataGrid ) ? dataGrid() : {};

    const params: any = {
        search: ( loadOptions.filter ) ? get(loadOptions.filter, ['0', 'filterValue']) : '',
    };

    if ( loadOptions.take ) {
        params.page = ( ( _dataGrid && _dataGrid.current ) ? ( _dataGrid.current._instance.pageIndex() + 1 ) : 1 );
        params.per_page = ( ( _dataGrid && _dataGrid.current ) ? _dataGrid.current._instance.pageSize() : 20 );
    }

    if ( !isEmpty(loadOptions.sort) ) {
        params.sort_field = get(loadOptions, ['sort', '0', 'selector']);
        params.sort_order = get(loadOptions, ['sort', '0', 'desc']) === true ? 'desc' : 'asc';
    }

    if ( typeof params.search === 'object' ) {
        params.filters = params.search;
        delete params.search;
    }

    return params;
}

/**
 * For Drawer
 */
export const maskMotion: any = {
    motionAppear: true,
    motionName: 'mask-motion',
    onAppearEnd: console.warn,
};

export const motion: any = (placement: any) => ( {
    motionAppear: true,
    motionName: `panel-motion-${ placement }`,
} );

export const motionProps: Partial<any> = {
    maskMotion,
    motion,
};

export function random(length: number, randomStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz_') {
    let result = '';
    const characters = randomStr;
    const charactersLength = characters.length;
    let counter = 0;

    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }

    return result;
}

export function handleSyncRequestLoader() {
    const preloader: any = document.getElementById('preloader');
    let counter = 0;

    const handleView = () => {
        if ( preloader ) {
            preloader.style.visibility = "visible";
            if ( counter <= 0 ) {
                preloader.style.visibility = "hidden";
            }
        }
    };

    client.interceptors.request.use(
        (config: any) => {
            if ( config.method !== "get" && ( config.data && config.data.ignore_request > 0 ) ) {
                return config;
            } else if ( config.method === "get" && ( config.params && config.params.ignore_request > 0 ) ) {
                return config;
            }

            counter++;
            handleView();
            return config;
        },
        (error: any) => {
            counter--;
            handleView();
            return Promise.reject(error);
        }
    );

    client.interceptors.response.use(
        (data: any) => {
            counter--;
            handleView();
            return Promise.resolve(data);
        },
        (error: any) => {
            counter--;
            handleView();
            return Promise.reject(error);
        }
    );
}

/**
 * Like php number_format
 *
 * @param number
 * @param decimals
 * @param dec_point
 * @param thousands_sep
 */
export function numberFormat(number: any, decimals: any, dec_point: any, thousands_sep: any) {
    // Strip all characters but numerical ones.
    number = ( number + '' ).replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = ( typeof thousands_sep === 'undefined' ) ? ',' : thousands_sep,
        dec = ( typeof dec_point === 'undefined' ) ? '.' : dec_point,
        s: any = '',
        toFixedFix: any = function (n: any, prec: any) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = ( prec ? toFixedFix(n, prec) : '' + Math.round(n) ).split('.');
    if ( s[0].length > 3 ) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ( ( s[1] || '' ).length < prec ) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export const passwordRegex = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

export function getTypeOfPrice(type: string | number | null) {
    if ( type == 1 ) {
        return "fixed";
    }

    if ( type == 3 ) {
        return "per km";
    }

    return "per load";
}

export function getColors() {
    return [
        "#E0EEE0", "#E0FFFF", "#FFFACD", "#FFEFD5", "#F5FFFA", "#FFEBCD", "#F0F8FF", "#FAEBD7", "#FFE4E1", "#F0F8FF",
        "#4682B4", "#B0E0E6", "#ADD8E6", "#87CEEB", "#87CEFA", "#00FA9A", "#20B2AA", "#7FFFD4", "#00FFFF", "#E0FFFF",
        "#00FA9A", "#20B2AA", "#7FFFD4", "#00FFFF", "#E0FFFF", "#AFEEEE", "#F0FFFF", "#F5FFFA", "#00CED1", "#40E0D0",
        "#FFE4E1", "#FFE4C4", "#FFDEAD", "#FFDAB9", "#EEE8AA", "#E0EEE0", "#B0E0E6", "#ADD8E6", "#87CEEB", "#87CEFA",
        "#F5F5DC", "#FFE4C4", "#F0FFF0", "#FAFAD2", "#E6E6FA", "#98FB98", "#DDA0DD", "#B0E0E6", "#FFD700", "#00FA9A",
        "#F0FFF0", "#FFDAB9", "#90EE90", "#E6CFFA", "#FDF5E6", "#FAF0E6", "#FFF5EE", "#FFE4B5", "#E0F8E6", "#FFF8DC",
        "#FFE4E1", "#FFE4C4", "#FFDEAD", "#FFDAB9", "#EEE8AA", "#E0EEE0", "#B0E0E6", "#ADD8E6", "#87CEEB", "#87CEFA",
        "#5F9EA0", "#FFB6C1",  "#B0C4DE", "#ADD8E6", "#B0E0E6", "#87CEEB", "#87CEFA", "#40E0D0", "#7FFFD4", "#00CED1",
        "#F0FFFF", "#E0FFFF", "#AFEEEE", "#00FFFF", "#F5FFFA", "#FFE4E1", "#FFE4C4", "#FFDEAD", "#FFDAB9", "#EEE8AA",
        "#AFEEEE", "#F0FFFF", "#F5FFFA", "#00CED1", "#40E0D0", "#5F9EA0", "#4682B4", "#B0C4DE", "#ADD8E6", "#B0E0E6",
        "#87CEEB", "#87CEFA", "#40E0D0", "#7FFFD4", "#00CED1", "#F0FFFF", "#E0FFFF", "#AFEEEE", "#00FFFF", "#F5FFFA",
        "#FFE4E1", "#FFE4C4", "#FFDEAD", "#FFDAB9", "#EEE8AA", "#E0EEE0", "#B0E0E6", "#ADD8E6", "#87CEEB", "#87CEFA",
        "#00FA9A", "#20B2AA", "#7FFFD4", "#00FFFF", "#E0FFFF", "#AFEEEE", "#F0FFFF", "#F5FFFA", "#00CED1", "#40E0D0",
        "#5F9EA0", "#4682B4", "#B0C4DE", "#ADD8E6", "#B0E0E6", "#87CEEB", "#87CEFA", "#40E0D0", "#7FFFD4", "#00CED1",
        "#F0FFFF", "#E0FFFF", "#AFEEEE", "#00FFFF", "#F5FFFA", "#FFE4E1", "#FFE4C4", "#FFDEAD", "#FFDAB9", "#EEE8AA",
        "#E0EEE0", "#B0E0E6", "#ADD8E6", "#87CEEB", "#87CEFA", "#00FA9A", "#20B2AA", "#7FFFD4", "#00FFFF", "#E0FFFF",
        "#AFEEEE", "#F0FFFF", "#F5FFFA", "#00CED1", "#40E0D0", "#5F9EA0", "#4682B4", "#B0C4DE", "#ADD8E6", "#B0E0E6",
        "#87CEEB", "#87CEFA", "#40E0D0", "#7FFFD4", "#00CED1", "#F0FFFF", "#E0FFFF", "#AFEEEE", "#00FFFF", "#F5FFFA",
        "#00FA9A", "#20B2AA", "#7FFFD4", "#00FFFF", "#E0FFFF", "#AFEEEE", "#F0FFFF", "#F5FFFA", "#00CED1", "#40E0D0",
        "#5F9EA0", "#4682B4", "#B0C4DE", "#ADD8E6", "#B0E0E6", "#87CEEB", "#87CEFA", "#40E0D0", "#7FFFD4", "#00CED1",
        "#F0FFFF", "#E0FFFF", "#AFEEEE", "#00FFFF", "#F5FFFA", "#FFE4E1", "#FFE4C4", "#FFDEAD", "#FFDAB9", "#EEE8AA",
        "#E0EEE0", "#B0E0E6", "#ADD8E6", "#87CEEB", "#87CEFA", "#00FA9A", "#20B2AA", "#7FFFD4", "#00FFFF", "#E0FFFF",
        "#AFEEEE", "#F0FFFF", "#F5FFFA", "#00CED1", "#40E0D0", "#5F9EA0", "#4682B4", "#B0C4DE", "#ADD8E6", "#B0E0E6",
        "#87CEEB", "#87CEFA", "#40E0D0", "#7FFFD4", "#00CED1", "#F0FFFF", "#E0FFFF", "#AFEEEE", "#00FFFF", "#F5FFFA",
        "#FFE4E1", "#FFE4C4", "#FFDEAD", "#FFDAB9", "#EEE8AA", "#E0EEE0", "#B0E0E6", "#ADD8E6", "#87CEEB", "#87CEFA",
        "#00FA9A", "#20B2AA", "#7FFFD4", "#00FFFF", "#E0FFFF", "#AFEEEE", "#F0FFFF",
    ]
}

export function customSplitter(haystack: string, splitter: string = '___', unique: boolean = true): any[] {
    const result: any[] = [];
    const uniqueValues = new Set();

    for (let i = 0; i < haystack.length; i++) {
        const slug = haystack[i];
        const tokens = split(slug, splitter);
        const objectId = tokens[0];

        tail(tokens).forEach(value => {
            if ( unique ) {
                if ( !uniqueValues.has(value) ) {
                    uniqueValues.add(value);
                    result.push({ id: objectId, value });
                }
            } else {
                result.push({ id: objectId, value });
            }
        });
    }

    return result;
}

/**
 * example data:
 * { id: 'eb802496-6390-4abc-89b4-19327849bd47', value: 'Boris Trifković' },
 * { id: 'eb802496-6390-4abc-89b4-19327849bd47', value: 'Alen Sabo' },
 * { id: 'eb802496-6390-4abc-89b4-19327849bd47', value: 'Adnan Čamdžić' },
 * { id: 'afbf2aeb-2db7-4d22-8e8a-20d95b681262', value: 'Boris Trifković' },
 * { id: 'afbf2aeb-2db7-4d22-8e8a-20d95b681262', value: 'Alen Sabo' },
 * { id: 'afbf2aeb-2db7-4d22-8e8a-20d95b681262', value: 'Adnan Čamdžić' }
 *
 * @param data
 */
export function groupIdsByValue(data: any) {
    const groupedData: any = {};

    data.forEach((item: any) => {
        const { id, value } = item;
        if ( !groupedData[value] ) {
            groupedData[value] = [];
        }
        groupedData[value].push(id);
    });

    const result = [];
    for (const value in groupedData) {
        result.push({ ids: groupedData[value], value: value });
    }

    return result;
}

export function giveUniqueColoredString(inputString: string) {
    let uniqueColors: string[] = [];
    let newString = "";

    // Split the string by "||"
    const items = inputString.split("||");

    // Loop through each item
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const subStrings = item.split("___");
        if (!uniqueColors.includes(subStrings[1])) {
            newString += `${subStrings[0]}___${subStrings[1]}||`;
            uniqueColors.push(subStrings[1]);
        }
    }

    return trim(newString, "||");
}

export function setLastUpdate(id: string, module: string = 'SpotLoad'): void {
    store.set(`${ LOCALSTORAGE_PREFIX }.update`, {
        module, id
    });
}

export function getLastUpdate() {
    return store.get(`${ LOCALSTORAGE_PREFIX }.update`);
}

export function setToStorage(key: string|number, value: any): void {
    sessionStorage.setItem(`${ LOCALSTORAGE_PREFIX }.${key}`, value);
}

export function getFromStorage(key: string|number) {
    return sessionStorage.getItem(`${ LOCALSTORAGE_PREFIX }.${key}`);
}

export function getUserTimezone() {
    const auth = new Auth();
    const user = auth.getUser();

    if ( !isEmpty(get(user, ['timezone'])) ) {
        return get(user, ['timezone']);
    }

    return moment.tz.guess();
}

export function serializeObject(obj: any, parentKey = '') {
    const serialized: any = {};

    // Iterate over the keys of the object
    Object.keys(obj).forEach(key => {
        // Construct the current key
        const currentKey = parentKey ? `${parentKey}[${key}]` : key;

        // Check if the value is an object
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            // If it's an object, recursively serialize it
            const nestedSerialized = serializeObject(obj[key], currentKey);
            Object.assign(serialized, nestedSerialized);
        } else {
            // If it's not an object, assign the value to the current key
            serialized[currentKey] = obj[key];
        }
    });

    return serialized;
}

export function getOptionLabelForTransportVehicle(option: any) {
    return (
        <div className="react-select__option">
            { !isEmpty(option.active_journey) &&
            <span className="yellow-indicator"
                  title={ `${ option.active_journey.load_number } | ${ option.active_journey.objectable_type }` } /> }
            { isEmpty(option.active_journey) && <span className="green-indicator" /> }
            <span className={ "ps-1" }>
                { option.type && `${ option.type } | ` }
                { option.truck_plate_number }
                | { option.trailer_plate_number }
                {
                    option.truck_owner
                    && <span className="ms-2">{ `( ${ option.truck_owner.company_name } )` }</span>
                }
                {
                    option.mode_of_transport
                    && <span className="ms-2 text-uppercase">{ `( ${ option.mode_of_transport } )` }</span>
                }
            </span>
        </div>
    );
}


export function getLanguage(lang: string) {
    if ( lang === "eng" ) {
        return "English";
    }

    if ( lang === "gr" ) {
        return "German";
    }

    if ( lang === "it" ) {
        return "Italian";
    }

    if ( lang === "rs" ) {
        return "Russian";
    }

    if ( lang === "sp" ) {
        return "Spanish";
    }

    if ( lang === "sl" ) {
        return "Slovenian";
    }

    return "English";
}
