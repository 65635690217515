import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Field, Form } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Col, Row } from "react-bootstrap";
import { InputField } from "../../../../components/Shared/InputField";
import { Sidebar } from "primereact/sidebar";
import { get, includes } from "lodash";
import { Button } from "primereact/button";
import { InputSingleSelectField } from "../../../../components/Shared/InputSingleSelectField";
import InputDateTime from "../../../../components/Shared/InputDateTime";
import { InputSwitchField } from "../../../../components/Shared/InputSwitchField";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    dropdowns: any;
    params: any;
    toastify: any;
    onClose?: any;
    operationCancel?: any;
}

type TInputForm = {
    id?: null | any,
    first_name: null | any,
    last_name: null | any,
    email: null | any,
    passport_number: null | any,
    password: null | any,
    password_confirmation: null | any,
    phone: null | any,
    is_active: null | any,
    documents: object[] | null,
    is_two_factor_authentication: null | any,
    is_suspended: null | any,
    from_suspended_at?: null | any,
    to_suspended_at?: null | any,
    legal_entity_id: null | any,
    has_sub_contractor: boolean,
    sub_contractor_id: string | null,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        passport_number: yup.string().nullable(),
        first_name: yup.string().required(t('the field is required.', { field: t('first name') })).nullable(),
        last_name: yup.string().notOneOf([yup.ref('firstName')]).nullable(),
        phone: yup.string().nullable().matches(/^$|((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, t('the field value is not valid', { field: t('phone') })),
        is_active: yup.boolean().nullable(),
        password: yup.string().nullable(),
        password_confirmation: yup.string().nullable().oneOf([yup.ref('password'), null], t('the field confirmation does not match.', { field: t('password') })),
        documents: yup.array().nullable(),
        is_two_factor_authentication: yup.boolean().nullable(),
        is_suspended: yup.boolean().nullable(),
        legal_entity_id: yup.string().nullable(),
        from_suspended_at: yup.date(t('the field value is not valid', { field: t("from suspend date/time") })).nullable()
        .when("is_suspended", {
            is: true,
            then: yup.date().required(t('the field is required.', { field: t('from ') }))
        }),
        to_suspended_at: yup.date(t('the field value is not valid', { field: t("to suspend date/time") })).nullable()
        .when("is_suspended", {
            is: true,
            then: yup.date().required(t('the field is required.', { field: t('to suspend date/time') }))
        })
        .min(yup.ref('from_suspended_at'), t('the field must be a date after', {
            field: t("to suspend date/time"),
            fieldAfter: t("from suspend date/time")
        })),
        email: yup.string().email(t('the email must be a valid email address.')).nullable(),
        has_sub_contractor: yup.boolean(),
        sub_contractor_id: yup.string(t('the field value is not valid', { field: t("sub contractor") })).nullable()
        .when("has_sub_contractor", {
            is: true,
            then: yup.string().required(t('the field is required.', { field: "sub contractor" })).nullable()
        }),
    });
};

const DEFAULT_FORM_STATE = {
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    passport_number: null,
    password: null,
    password_confirmation: null,
    phone: null,
    is_active: false,
    documents: [],
    is_two_factor_authentication: false,
    is_suspended: null,
    from_suspended_at: null,
    to_suspended_at: null,
    legal_entity_id: null,
    has_sub_contractor: false,
    sub_contractor_id: null,
};

function DriverForm(props: IFormProps) {
    const { t, toastify, params, dropdowns, operationCancel } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);
    const [entity, setEntity] = useState<any>({});

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: ( !payloads.id ? 'POST' : 'PATCH' ),
                url: ( !payloads.id ? `/suppliers/drivers` : `/suppliers/drivers/${ payloads.id }` ),
                data: {
                    ...payloads,
                },
            });

            if ( payloads.id ) {
                toastify(t("record updated", { item: t('driver') }), "success");
            } else {
                toastify(t("record added", { item: t('driver') }), "info");
            }

            resetForm();
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");

            }
        }
    };

    const onReset = () => {
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm();
        }
    };

    const onClose = () => {
        setPanelState(false);
        setEntity(null);
        onReset();

        if ( props.onClose ) {
            props.onClose();
        }
    };

    const onEdit = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/drivers/${ id }`
            });

            const { data } = response.data;
            const formData: TInputForm = { ...data, sub_contractor_id: data.sub_contractor?.id, };
            formData.is_suspended = data.is_suspended > 0;
            formData.is_active = data.is_active;
            formData.legal_entity_id = get(data, ['legal_entity', 'id']);
            setInitFormState(formData);
            setEntity(data);
            setPanelState(true);
        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        // setInitFormState(DEFAULT_FORM_STATE);

        if ( includes(['add'], get(params, ['operation'])) ) {
            setPanelState(true);
        }

        if ( includes(['edit'], get(params, ['operation'])) ) {
            onEdit(params.operationId);
        }
    }, [params]);

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('driver') + ' ' + t('action') }</h5>
                            <p>{ t("using this form you can add or update the record") }</p>
                        </div>
                    }
                    visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } } closeOnEscape={ false }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                        validationSchema={ validationSchema(t) }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4 md:mb-7 sm:mb-7" }>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="first_name"
                                                   label={ t("first name") }
                                                   placeholder={ t("enter") + ' ' + t("first name").toLowerCase() }
                                                   isRequired
                                                   autoFocus />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="last_name"
                                                   label={ t("last name") }
                                                   placeholder={ t("enter") + ' ' + t("last name").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="email"
                                                   label={ t("email") }
                                                   placeholder={ t("enter") + ' ' + t("email").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="passport_number"
                                                   label={ t("passport number") }
                                                   placeholder={ t("enter") + ' ' + t("passport number").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="password"
                                                   type={ "password" }
                                                   label={ t("password") }
                                                   placeholder={ t("enter") + ' ' + t("password").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="password_confirmation"
                                                   type={ "password" }
                                                   label={ t("password confirmation") }
                                                   placeholder={ t("enter confirm password") }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="phone"
                                                   label={ t("phone") }
                                                   placeholder={ t("enter") + ' ' + t("phone").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field
                                                component={ InputSingleSelectField }
                                                name={ 'legal_entity_id' }
                                                options={ dropdowns.legalEntities }
                                                label={ t('legal entity') }
                                                labelField="name"
                                                valueField="id"
                                                isClearable
                                            />
                                        </Col>
                                        {
                                            ( !entity || !entity.is_system ) &&
                                          <Col sm={ 12 } md={ 6 }>
                                              <Field component={ InputSwitchField }
                                                     name="is_active"
                                                     label={ t("status") }
                                                     onLabel={ t("active") }
                                                     offLabel={ t("inactive") }
                                                     labelClass={ "w-25" }
                                                     needBoolean
                                              />
                                          </Col>
                                        }
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSwitchField }
                                                   name="is_two_factor_authentication"
                                                   label={ t("enable two factor login") }
                                                   needBoolean
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSwitchField }
                                                   name="is_suspended"
                                                   label={ t("want to suspend login?") }
                                                   needBoolean
                                            />
                                        </Col>

                                        {
                                            props.values.is_suspended &&
                                          <>
                                              <Col sm={ 12 } md={ 6 }>
                                                  <Field component={ InputDateTime }
                                                         name="from_suspended_at"
                                                         type={ "datetime" }
                                                         label={ t("from suspend date/time") }
                                                         isRequired
                                                  />
                                              </Col>
                                              <Col sm={ 12 } md={ 6 }>
                                                  <Field component={ InputDateTime }
                                                         name="to_suspended_at"
                                                         type={ "datetime" }
                                                         label={ t("to suspend date/time") }
                                                         isRequired
                                                  />
                                              </Col>
                                          </>
                                        }

                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSwitchField }
                                                   name="has_sub_contractor"
                                                   label={ t("has sub contractor?") }
                                            />
                                        </Col>
                                        {
                                            props.values.has_sub_contractor &&
                                          <Col sm={ 12 } md={ 6 }>
                                              <Field component={ InputSingleSelectField }
                                                     name="sub_contractor_id"
                                                     options={ dropdowns?.subContractors }
                                                     label={ t('subcontractor(transport company)') }
                                                     labelField="label"
                                                     valueField="id"
                                                     isClearable
                                                     isRequired
                                              />
                                          </Col>
                                        }
                                    </Row>
                                    <div className="filter-card-footer"
                                         style={ { width: '785px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-save me-2" />
                                            { t("let's save") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ onReset }
                                                tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                size={ "small" } severity={ "warning" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-refresh me-2" />
                                            { t("reset") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                                onClick={ () => operationCancel(null, null) }
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("cancel") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

DriverForm.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(DriverForm);
