import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useEffect, useState } from "react";
import { CardBody, Col, Row } from "react-bootstrap";
import { find, get, includes, isEmpty } from "lodash";
import { request } from "../../../../utils/Request";
import { utcDateToLocalDate } from "../../../../utils/Helper";
import { Card } from "primereact/card";
import Tooltip from "../../../../components/Common/Display/Tooltip";
import Auth from "../../../../utils/Auth";

const ClientDetail = (props: any) => {
    const { t, toastify, params } = props;
    const [entity, setEntity] = useState<any>(null);
    const auth = new Auth();

    const getDetail = async (id: string) => {
        try {
            const response = await request({
                url: `/suppliers/clients/${ id }`
            });

            const { data } = response.data;
            setEntity(data);
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        if ( includes(['detail'], get(params, ['operation'])) ) {
            getDetail(params.operationId);
        }
    }, [params]);

    return (
        <div className={ "p-4 bg-white" }>
            {
                entity &&
              <>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('name') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ entity.name }</div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('main location') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity.main_location, ['label']) }</div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('email') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity, ['email']) }</div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('phone') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ entity.phone }</div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('legal entity') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity.legal_entity, ['company_name']) }</div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('recipient type') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_recipient_type'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('service fee') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_service_fee'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('bank account number') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_bank_account_number'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('oib') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_oib'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('vat number') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_vat_number'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('currency') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_currency'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('payment method') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end text-capitalize">
                              {
                                  !isEmpty(
                                      find(props.dropdowns.paymentMethods, {
                                          id: get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_payment_method'), [0, 'value'])
                                      })
                                  )
                                      ? get(find(props.dropdowns.paymentMethods, {
                                          id: get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_payment_method'), [0, 'value'])
                                      }), ['label'])
                                      : get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_payment_method'), [0, 'value'])
                              }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('payment condition') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_payment_condition'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('email addresses') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_email_addresses'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('pre payment positive') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end text-green-500">
                              {entity.pre_payment_positive}
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('pre payment negative') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end text-red-500">
                              {entity.pre_payment_negative}
                          </div>
                      </Col>
                  </Row>

                  <Card>
                      <CardBody>
                          <Row>
                              <Col sm={ 12 }>
                                  <h6>{ t("pre payments") }</h6>
                                  <hr />
                                  <table className={ "fl-table" }>
                                      <thead>
                                          <tr>
                                              <th>#</th>
                                              <th>{ t('creator') }</th>
                                              <th>{ t('invoice') }</th>
                                              <th>{ t('payment date') }</th>
                                              <th>{ t('payment method') }</th>
                                              <th>{ t('price') }</th>
                                              <th>{ t('status') }</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          { entity.pre_payments.map((item: any, index: number) => {
                                              return (
                                                  <React.Fragment key={ index }>
                                                      <tr>
                                                          <td>{ index + 1 }</td>
                                                          <td>{ get(item, ['author', 'name']) }</td>
                                                          <td>
                                                              {
                                                                  item.invoice &&
                                                                <>
                                                                    {
                                                                        item.invoice.status === 'draft'
                                                                        &&
                                                                      <Tooltip tooltip={ t("draft pdf") }>
                                                                          <a
                                                                            title={ t('draft pdf') }
                                                                            className={ "cursor-pointer ms-2" }
                                                                              /*@ts-ignore*/
                                                                            href={ `${ process.env.REACT_APP_API_URL }/w/invoice/document/${ item.invoice.id }?status=draft&types=pdf&uToken=` + auth.getUser().id }
                                                                            target={ "_blank" }
                                                                            rel="noopener noreferrer"
                                                                          >
                                                                              { item.invoice.status === "draft" && get(item.invoice, ['draft_invoice_number']) }
                                                                          </a>
                                                                      </Tooltip>
                                                                    }
                                                                    {
                                                                        item.invoice.status === 'final'
                                                                        &&
                                                                      <Tooltip
                                                                        tooltip={ t("final pdf") }
                                                                        position={ "left" }>
                                                                          <a
                                                                            className={ "cursor-pointer ms-2" }
                                                                              /*@ts-ignore*/
                                                                            href={ `${ process.env.REACT_APP_API_URL }/w/invoice/document/${ item.invoice.id }?status=final&types=pdf&uToken=` + auth.getUser().id }
                                                                            target={ "_blank" }
                                                                            rel="noopener noreferrer"
                                                                          >
                                                                              { item.invoice.status === "final" && get(item.invoice, ['final_invoice_number']) }
                                                                          </a>
                                                                      </Tooltip>
                                                                    }
                                                                </>
                                                              }
                                                          </td>
                                                          <td>{ utcDateToLocalDate(item.payment_date) }</td>
                                                          <td className={"text-capitalize"}>
                                                              {
                                                                  !isEmpty(
                                                                      find(props.dropdowns.paymentMethods, {
                                                                          id: item.payment_method
                                                                      })
                                                                  )
                                                                      ? get(find(props.dropdowns.paymentMethods, {
                                                                          id: item.payment_method
                                                                      }), ['label'])
                                                                      : item.payment_method
                                                              }
                                                          </td>
                                                          <td>{ item.price }</td>
                                                          <td>{ t(item.status) }</td>
                                                      </tr>
                                                      <tr>
                                                          <td colSpan={ 7 } className={ "text-left" }>
                                                              { t('comments') }: { item.comments }
                                                          </td>
                                                      </tr>
                                                  </React.Fragment>
                                              );
                                          }) }
                                      </tbody>
                                      { isEmpty(entity.pre_payments) &&
                                          <tfoot>
                                            <tr>
                                                <td colSpan={5}>
                                                    { t("no data available") }
                                                </td>
                                            </tr>
                                        </tfoot>
                                      }
                                  </table>
                              </Col>
                          </Row>
                      </CardBody>
                  </Card>
              </>
            }
        </div>
    );
};

ClientDetail.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(ClientDetail));
