import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Field, Form } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Col, Row } from "react-bootstrap";
import { InputField } from "../../../../components/Shared/InputField";
import { Sidebar } from "primereact/sidebar";
import { get, has, includes, trim } from "lodash";
import { Button } from "primereact/button";
import { ColorPicker } from "primereact/colorpicker";
import { InputSwitchField } from "../../../../components/Shared/InputSwitchField";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    dropdowns: any;
    params: any;
    toastify: any;
    onClose?: any;
    operationCancel?: any;
}

type TInputForm = {
    id?: any,
    email?: string | null,
    company_name?: string | null,
    is_default?: string | any,
    is_disable?: string | any,
    phone?: null,
    color?: string | null,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        is_active: yup.boolean(),
        email: yup.string().trim(t('the field is invalid.')).email(t('the email must be a valid email address.')).required(t('the field is required.', { field: t('email') })).nullable(),
        company_name: yup.string().trim(t('the field is invalid.')).required(t('the field is required.', { field: t('company name') })).nullable(),
        phone: yup.string().nullable().matches(/^$|((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, t('the field value is not valid', { field: t('phone') })),
        color: yup.string().trim(t('the field is invalid.')).required(t('the field is required.', { field: t('color') })).nullable(),
    });
};

const DEFAULT_FORM_STATE = {
    id: null,
    email: null,
    company_name: null,
    is_default: null,
    is_disable: null,
    phone: null,
    color: '000000'
};

function LegalEntityForm(props: IFormProps) {
    const { t, toastify, params, dropdowns, operationCancel } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);
    const [color, setColor] = useState('000000');
    // const [searchParams] = useSearchParams();

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: ( !payloads.id ? 'POST' : 'PATCH' ),
                url: ( !payloads.id ? `/suppliers/legal/entities` : `/suppliers/legal/entities/${ payloads.id }` ),
                data: {
                    ...payloads,
                    color: `#${ payloads.color }`
                },
            });

            if ( payloads.id ) {
                toastify(t("record updated", { item: t('legal entity') }), "success");
            } else {
                toastify(t("record added", { item: t('legal entity') }), "info");
            }

            resetForm();
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");

            }
        }
    };

    const onReset = () => {
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm();
        }
    };

    const onClose = () => {
        setPanelState(false);
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm();
        }

        if ( props.onClose ) {
            props.onClose(
                !includes(['edit'], get(params, ['operation']))
            );
        }
    };

    const onEdit = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/legal/entities/${ id }`
            });

            const { data } = response.data;
            const formData: TInputForm = { ...data };
            formData.is_default = data.is_default > 0;
            formData.is_disable = data.is_disable > 0;
            formData.color = trim(data.color, '#');
            setColor(formData.color);
            setInitFormState(formData);
            setPanelState(true);
        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };

    const onChangeColor = (e: any) => {
        formRef.current.setFieldValue('color', e.target.value);
        setColor(e.target.value);
    };

    useEffect(() => {
        if ( includes(['add'], get(params, ['operation'])) ) {
            setPanelState(true);
        }

        if ( includes(['edit'], get(params, ['operation'])) ) {
            onEdit(params.operationId);
        }
    }, [params]);

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('legal entity') + ' ' + t('action') }</h5>
                            <p>{ t("using this form you can add or update the record") }</p>
                        </div>
                    }
                    visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } } closeOnEscape={ false }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                        validationSchema={ validationSchema(t) }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4" }>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="company_name"
                                                   label={ t("company name") }
                                                   placeholder={ t("enter") + ' ' + t("company name").toLowerCase() }
                                                   isRequired
                                                   autoFocus />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="email"
                                                   label={ t("email") }
                                                   placeholder={ t("enter") + ' ' + t("email").toLowerCase() }
                                                   isRequired />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="phone"
                                                   label={ t("phone") }
                                                   placeholder={ t("enter") + ' ' + t("phone").toLowerCase() } />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Row>
                                                <Col sm={ 10 } className={ "pe-0" }>
                                                    <Field component={ InputField }
                                                           name="color"
                                                           label={ t("color") }
                                                           inputClass={ "right-border-radius-erash" }
                                                           placeholder={ t("pick color") }
                                                           disabled={ true } />
                                                </Col>
                                                <Col sm={ 2 } className={ "ps-0" }>
                                                    <div className={ "p-fluid" }>
                                                        <div className={ "field adjust-height" }>
                                                            <label>&nbsp;</label>
                                                            <ColorPicker format="hex" value={ color }
                                                                         onChange={ onChangeColor } />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSwitchField }
                                                   name="is_default"
                                                   label={ t("is default?") }
                                                   trueLabel={ t("yes") }
                                                   falseLabel={ t("no") }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSwitchField }
                                                   name="is_disable"
                                                   label={ t("want to deactive?") }
                                                   trueLabel={ t("yes") }
                                                   falseLabel={ t("no") }
                                            />
                                        </Col>
                                    </Row>
                                    <div className="filter-card-footer"
                                         style={ { width: '785px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-save me-2" />
                                            { t("let's save") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                size={ "small" } severity={ "warning" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                                onClick={ onReset }
                                        >
                                            <i className="pi pi-refresh me-2" />
                                            { t("reset") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                                onClick={ () => onClose() }
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("cancel") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

LegalEntityForm.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(LegalEntityForm);
