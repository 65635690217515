import { withTranslation } from "react-i18next";
import WithRouter from "../../../../../components/Common/WithRouter";
import React, { useRef, useState } from "react";
import { request } from "../../../../../utils/Request";
import { ConfirmPopup } from 'primereact/confirmpopup';
import StateLessGrid from "../../../../../components/Shared/StateLessGrid";
import { Button } from "primereact/button";
import { Col, Row } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../../../../components/Shared/InputField";
import AlpSpotLoadFilter from "./AlpSpotLoadFilter";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { isEmpty, uniqBy, get } from "lodash";
import { utcDateToLocalDate } from "../../../../../utils/Helper";
import AlpSpotLoadStatus from "../../../alpSpotLoads/partials/AlpSpotLoadStatus";
import Location from "../../../../../components/Common/Display/Location";
import { Badge } from "primereact/badge";
import Tooltip from "../../../../../components/Common/Display/Tooltip";
import HasAccess from "../../../../../utils/HasAccess";
import AlpSpotLoadUpdatePrice from "../../../alpSpotLoads/partials/AlpSpotLoadUpdatePrice";
import AlpSpotLoadDetail from "../../../alpSpotLoads/partials/AlpSpotLoadDetail";
import { css } from "@emotion/css";
import { ButtonGroup } from "primereact/buttongroup";
import VinWithType from "../../../../../components/Common/Display/VinWithType";

const AlpSpotLoadList = (props: any) => {
    const { t, moduleState, toastify, dropdowns } = props;
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [operation, setOperation] = useState<any>(searchParams.get('operation') ?? null);
    const [operationId, setOperationId] = useState<any>(searchParams.get('operationId') ?? null);
    const [filterPanel, setFilterPanel] = useState<boolean>(false);
    const [loadId, setLoadId] = useState<any>(null);
    const [total, setTotal] = useState(0);

    const [initSearchFormState, setInitSearchFormState] = useState<any>({
        search: moduleState.search ?? null
    });
    const tableRef: any = useRef();

    const getUniqueLocationList = (originalArray: any, string: any) => {
        const locations = originalArray.map((item: any) => item[string]);

        return uniqBy(locations, (e: any) => e.id);
    };

    const columns = [
        {
            field: 'load_number',
            title: t("load") + ' #',
            sorting: true,
        },
        {
            field: 'loading',
            title: t("loading"),
            sorting: false,
            render: ({ alp_spot_load_orders }: any) => getUniqueLocationList(alp_spot_load_orders, 'from_location')
            .map((item: any, index: number) => <Location key={ index } item={ item } titled={ 1 } />)
        },
        {
            field: 'unload',
            title: t("unloading"),
            sorting: false,
            render: ({ alp_spot_load_orders }: any) => getUniqueLocationList(alp_spot_load_orders, 'to_location')
            .map((item: any, index: number) => <Location key={ index } item={ item } titled={ 1 } />)
        },
        {
            field: 'cmr',
            title: t('cmr'),
            sorting: true,
        },
        {
            field: 'legal_entity',
            title: t('legal entity')
        },
        {
            field: 'vin_number',
            title: t('vin(s)'),
            render: ({ alp_spot_load_orders }: any) => alp_spot_load_orders
            .map(({ order }: any) => get(order, ['vin_number']))
            .map((item: any, index: number) => (
                <div key={ index }>
                    <VinWithType vinNumber={ item } />
                </div>
            ))
        },
        {
            field: 'model',
            title: t('model(s)'),
            sorting: false,
            render: ({ alp_spot_load_orders }: any) => alp_spot_load_orders
            .map(({ order }: any, index: number) => (
                <div key={ index }>
                    { get(order, ['brand_model', 'title']) } - { get(order, ['brand_model', 'version_code']) }
                </div>
            ))
        },
        {
            field: 'qty',
            title: t('qty'),
            sorting: true,
        },
        {
            field: 'driver_name',
            title: t('driver'),
            sorting: true,
        },
        {
            field: 'truck_plate_number',
            title: t('truck'),
            render: ({ truck_plate_number, trailer_plate_number, sub_contractor_name }: any) => {
                return (
                    <>
                        <span>{ !isEmpty(truck_plate_number) ? truck_plate_number : "" }</span>
                        <span>{ !isEmpty(trailer_plate_number) ? ` | ${ trailer_plate_number }` : "" }</span>
                        <div>
                            { !isEmpty(sub_contractor_name) ? sub_contractor_name : "" }
                        </div>
                    </>
                );
            }
        },
        {
            field: 'status',
            title: t('status'),
            render: ({ status, gate_out_confirmed_at, alp_spot_load_orders, invoiced_at }: any) => (
                <span className={ "text-uppercase" }>
                    <AlpSpotLoadStatus status={ status } textable={ true } />
                    {
                        (
                            ( alp_spot_load_orders.filter((item: any) => !isEmpty(item.from_supplier_compound)).length > 0 )
                            && status !== 'announced'
                            && isEmpty(gate_out_confirmed_at)
                        ) &&
                        <div className={ "mt-1" }>
                            <Badge severity={ 'danger' } value={ t('gate out: pending') } />
                        </div>
                    }
                    { !isEmpty(invoiced_at) &&
                    <div className={ "mt-1" }>
                        <span className={ "text-dark" }>
                            { t('invoiced') }
                        </span>
                    </div>
                    }
                </span>
            )
        },
        {
            field: 'created_at',
            title: t('announcement date'),
            sorting: true,
            render: ({ created_at }: any) => {
                return (
                    <>
                        { ( created_at ) && <span>{ utcDateToLocalDate(created_at) }</span> }
                    </>
                );
            },
        },
        {
            field: 'actual_loading_time',
            title: t('loading date'),
            sorting: true,
            render: ({ actual_loading_time }: any) => {
                return (
                    <>
                        { ( actual_loading_time ) && <span>{ utcDateToLocalDate(actual_loading_time) }</span> }
                    </>
                );
            },
        },
        {
            field: 'invoiced_at',
            title: t('invocied at'),
            sorting: true,
            render: ({ invoiced_at }: any) => {
                return (
                    <>
                        { ( invoiced_at ) && <span>{ utcDateToLocalDate(invoiced_at) }</span> }
                    </>
                );
            },
        },
        {
            field: 'invoice_number',
            title: t('invoice number'),
            render: ({ invoice_number }: any) => {
                return invoice_number ?? "";
            },
        },
        {
            field: 'action',
            title: t('action'),
            sorting: false,
            align: 'left',
            render: (_row: any) => {
                return (
                    <>
                        <HasAccess hasAllAccess={ ['alpspotloadsupdate'] }>
                            <Tooltip
                                tooltip={ t('edit item') }>
                                <a onClick={ () => setLoadId(_row.id) }>
                                    <i className="fas fa-dollar-sign" />
                                </a>
                            </Tooltip>
                        </HasAccess>
                        <HasAccess hasAnyAccess={ ['alpspotloadsview'] }>
                            <Tooltip
                                tooltip={ t('detail of item') }>
                                <a onClick={ () => onOperationClick('detail', _row.id) } className={ 'ms-2' }>
                                    <i className="fas fa-eye" />
                                </a>
                            </Tooltip>
                        </HasAccess>
                    </>
                );
            }
        },
    ];

    const onOperationClick = (operationToBePerformed: any = 'add', id: any = null) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('operation', operationToBePerformed);
        searchParams.set('operationId', id);

        if ( isEmpty(operationToBePerformed) ) {
            searchParams.delete('operation');
        }

        if ( isEmpty(id) ) {
            searchParams.delete('operationId');
        }

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        }, { replace: true });
        setOperationId(id);
        setOperation(operationToBePerformed);
    };

    const onReset = (reset: any = true) => {
        const mStates = { ...moduleState };

        if ( reset ) {
            mStates.filters = [];
            mStates.page = 1;
            mStates.search = null;
        }

        props.setQuery(mStates);
    };

    const onCloseOperation = (reset: any = true) => {
        // make sure back_to and back_with are not blank and redirect with all parameters
        if ( !isEmpty(searchParams.get('back_to'))) {
            // @ts-ignore
            // parameters will have r as a prefix so remove it first
            props.router.navigate(`/${searchParams.get('back_to')}`);
            return;
        }

        onOperationClick(null, null);
        onReset(reset);
    };

    const onSearch = ({ search }: any) => {
        props.setQuery({
            ...moduleState,
            search: search
        });
    };

    const onFilterClick = () => {
        setFilterPanel(true);
    };

    const onSyncList = async () => {
        try {
            const response = await request({
                url: `/suppliers/alp/spot/loads/sync/list`,
                method: 'POST',
            });

            onReset();
        } catch (e: any) {
            if ( e.status === 422 ) {
                return toastify(e.data.message, "error");
            }

            toastify(t("server error"), "error");
        }
    };

    const setRowClass = (row: any) => {
        const color = get(row, ['legal_entity_color']);

        return css`
            background-color: ${color};
        `;
    }

    const focusOnSearch = () => {
        const searchInput: HTMLInputElement | null = document.querySelector('input[name="search"]');
        if ( searchInput ) {
            searchInput.focus();
        }
    };

    return (
        <div className={ "block-viewer" }>
            <div className="block-section">
                <div className="block-header">
                    <span className="block-title">
                        <h5 className={ "mb-0" }>{ t('EF') + ' ' + t('spot load') } ({total})</h5>
                    </span>
                    <div className="block-actions">
                        {
                            operation == 'detail' &&
                            <div>
                                <Button type={ "button" }
                                        tooltip={ t("operation cancel") } className={ "ms-2" }
                                        size={ "small" } severity={ "warning" }
                                        tooltipOptions={ { position: 'top' } } onClick={ () => onCloseOperation(false) }>
                                    <i className="pi pi-arrow-left me-2" /> { t("cancel") }

                                </Button>
                            </div>
                        }
                    </div>
                </div>
                <div className="block-content">
                    {
                        operation !== 'detail' &&
                        <Row className={ "p-2" }>
                            <Col
                              sm={ 12 } md={ 6 } className={ "mb-2" }>
                                <Formik
                                  enableReinitialize={ true }
                                  initialValues={ initSearchFormState }
                                  onSubmit={ onSearch }
                                >
                                    { (props: FormikProps<any>) => {
                                        return (
                                            <Form onSubmit={ props.handleSubmit }>
                                                <Col sm={ 12 } md={ 8 }>
                                                    <Field component={ InputField } withoutLabel={ true }
                                                           autoFocus={ true }
                                                           name="search"
                                                           formGroupClass={ "mb-0" }
                                                           placeholder={ t("search") } />
                                                </Col>
                                            </Form>
                                        );
                                    } }
                                </Formik>
                            </Col>

                            <Col
                              sm={ 12 } md={ 6 } className={ "text-right mb-2" }>
                                <ButtonGroup>
                                    <Button onClick={ onSyncList } icon="pi pi-sync" size={ "small" } outlined
                                            tooltip={ t('add') }
                                            tooltipOptions={ { position: 'top' } } />
                                    <Button icon="pi pi-filter-fill" size={ "small" } outlined tooltip={ t('filter') }
                                            onClick={ onFilterClick }
                                            tooltipOptions={ { position: 'top' } } />
                                    <Button onClick={ onReset } icon="pi pi-refresh" size={ "small" } outlined
                                            tooltip={ t('reset') }
                                            tooltipOptions={ { position: 'top' } } />
                                </ButtonGroup>
                            </Col>

                            <Col sm={ 12 }>
                                <StateLessGrid
                                  size={ "normal" }
                                  url={ '/suppliers/alp/spot/loads/archived/list?1=1' }
                                  name={ "AlpSpotLoadList" }
                                  columns={ columns }
                                  moduleState={ moduleState }
                                  setQuery={ props.setQuery }
                                  ref={ tableRef }
                                  itemSize={ 45 }
                                  setTotal={ setTotal }
                                  setRowClass={ setRowClass }
                                />
                            </Col>
                        </Row>
                    }

                    {
                        ( operation === 'detail' && !isEmpty(operationId) ) &&
                        <>
                            <AlpSpotLoadDetail
                              setQuery={ props.setQuery }
                              toastify={ props.toastify }
                              dropdowns={ props.dropdowns }
                              params={ { operation, operationId } }
                              onClose={ onCloseOperation }
                            />
                        </>
                    }

                    <AlpSpotLoadUpdatePrice
                        toastify={ toastify }
                        dropdowns={ [] }
                        id={ loadId }
                        onClose={ () => {
                            setLoadId(null);
                            onReset();
                        } }
                    />

                    <AlpSpotLoadFilter
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        visibility={ filterPanel }
                        onClose={ () => {
                            setFilterPanel(false);
                            focusOnSearch();
                        } }
                    />

                    <ConfirmPopup />
                </div>
            </div>
        </div>
    );
};

AlpSpotLoadList.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(AlpSpotLoadList));
