import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { get, includes } from "lodash";
import { request } from "../../../../utils/Request";

const ModelDetail = (props: any) => {
    const { t, toastify, params } = props;
    const [entity, setEntity] = useState<any>(null);

    const getDetail = async (id: string) => {
        try {
            const response = await request({
                url: `/suppliers/models/${ id }`
            });

            const { data } = response.data;
            setEntity(data);
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        if ( includes(['detail'], get(params, ['operation'])) ) {
            getDetail(params.operationId);
        }
    }, [params]);

    return (
        <div className={ "p-4 bg-white" }>
            {
                entity &&
              <>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('brand') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ entity.brand.title }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('model name') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ entity.title	 }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('version code') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ entity.version_code }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('height') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ entity.height }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('width') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ entity.width }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('length') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ entity.length }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('description') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ entity.description }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('belongs to') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ entity.belongs_to }</div>
                      </Col>
                  </Row>
              </>
            }
        </div>
    );
};

ModelDetail.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(ModelDetail));
