import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { get, includes } from "lodash";
import { request } from "../../../../utils/Request";

const RouterDetail = (props: any) => {
    const { t, toastify, params } = props;
    const [entity, setEntity] = useState<any>(null);

    const getDetail = async (id: string) => {
        try {
            const response = await request({
                url: `/suppliers/routes/${ id }`
            });

            const { data } = response.data;
            setEntity(data);
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        if ( includes(['detail'], get(params, ['operation'])) ) {
            getDetail(params.operationId);
        }
    }, [params]);

    return (
        <div className={ "p-4 bg-white" }>
            {
                entity &&
              <>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('title') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ entity.title }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('loading location') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity.from_location, ['label']) }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('unloading location') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity.to_location, ['label']) }</div>
                      </Col>
                  </Row>
              </>
            }
        </div>
    );
};

RouterDetail.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(RouterDetail));
