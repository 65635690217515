import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Form, Field } from "formik";
import { Col, Row } from "react-bootstrap";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { get, map, pickBy } from "lodash";
import { InputCheckboxField } from "../../../../components/Shared/InputCheckboxField";
import * as XLSX from "xlsx";
import qs from "qs";
import { request } from "../../../../utils/Request";
import { utcDateToLocalDate } from "../../../../utils/Helper";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    dropdowns: any;
    visibility: any;
    toastify: any;
    onClose?: any;
}

type TInputForm = {
    consider_all_pages: boolean,
    title: boolean,
    brand_title: boolean,
    version_type: boolean,
    description: boolean,
    belongs_to: boolean
};

const DEFAULT_FORM_STATE = {
    consider_all_pages: true,
    title: true,
    brand_title: true,
    version_type: true,
    description: true,
    belongs_to: true,
};

// @ts-ignore
function ModelExcel(props: IFormProps) {
    const { t, visibility } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<any>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);

    const onSubmit = async (payloads: TInputForm): Promise<void> => {
        const _params: any = props.moduleState;
        const _payloads: any = payloads;

        if ( payloads.consider_all_pages ) {
            delete _params.page;
            delete _params.per_page;
        }

        try {
            const response = await request({
                url: `/suppliers/models`,
                params: _params,
                paramsSerializer: {
                    serialize: (params: any) => qs.stringify(params)
                },
            });

            delete _payloads.consider_all_pages;
            const columns = pickBy(_payloads, (item: boolean) => ( item ));
            const title: any = getTitles();
            const data: any = [];
            map(response.data.data, (value: any) => {
                const row: any = {
                    title: value.title,
                    brand_title: value.brand?.title,
                    version_type: value.version_code,
                    description: value.description,
                    belongs_to: value.belongs_to
                };

                const _row: any = {};
                map(columns, (_, key: string) => ( _row[key] = row[key] ));
                const arrayOfRow = Object.keys(_row).map((key) => _row[key]);
                data.push(arrayOfRow);
            });

            const _title = map(columns, (_, key: string) => ( title[key] = title[key] ));
            const excel = XLSX.utils.book_new();
            const sheet = XLSX.utils.aoa_to_sheet([_title, ...data]);
            XLSX.utils.book_append_sheet(excel, sheet, t('models'));
            XLSX.writeFile(excel, "models.xlsx");
        } catch (e: any) {
            props.toastify(t("server error"), "error");
        }
    };

    const onClose = () => {
        setPanelState(false);

        if ( props.onClose ) {
            props.onClose();
        }
    };

    const getTitles = () => {
        return {
            title: t('title'),
            brand_title: t('brand'),
            version_type: t('version code'),
            description: t('description'),
            belongs_to: t('belongs to'),
        };
    };

    useEffect(() => {
        if ( visibility ) {
            setPanelState(visibility);
            setInitFormState({ ...DEFAULT_FORM_STATE, ...props.moduleState.filters ?? [] });
        }
    }, [visibility]);

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t("export") } { t("models") }</h5>
                            <p>
                                { t("select columns to be exported.") }
                            </p>
                        </div>
                    }
                    visible={ panelState } position="left" onHide={ onClose } blockScroll={ true }
                    style={ { width: '400px' } } closeOnEscape={ false }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4 md:mb-7 sm:mb-7" }>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="consider_all_pages"
                                                component={ InputCheckboxField }
                                                label={ t("consider all pages") }
                                            />
                                        </Col>

                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="title"
                                                component={ InputCheckboxField }
                                                label={ t('title') }
                                            />
                                        </Col>

                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="brand_title"
                                                component={ InputCheckboxField }
                                                label={ t('brand') }
                                            />
                                        </Col>

                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="version_type"
                                                component={ InputCheckboxField }
                                                label={ t('version code') }
                                            />
                                        </Col>

                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="description"
                                                component={ InputCheckboxField }
                                                label={ t('description') }
                                            />
                                        </Col>

                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="belongs_to"
                                                component={ InputCheckboxField }
                                                label={ t('belongs to') }
                                            />
                                        </Col>
                                    </Row>
                                    <div className="filter filter-card-footer"
                                         style={ { width: '385px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("apply filter") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-filter me-2" />
                                            { t("filter") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ onClose }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("close") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

ModelExcel.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(ModelExcel);
