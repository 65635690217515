import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import withRouter from "../../components/Common/WithRouter";
import alcolmLogo from "../../assets/images/alcolm-logo.png";
import backgroundImage from "../../assets/images/auth-bg.jpg";
import config from "../../config";
import { Col, Container, Row } from "react-bootstrap";
import * as Yup from "yup";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../components/Shared/InputField";
import { request } from "../../utils/Request";
import { Message } from 'primereact/message';
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

interface FORGOT_PASSWORD_FORM_STATE_TYPE {
    email: string | null,
}

const DEFAULT_LOGIN_FORM_STATE: FORGOT_PASSWORD_FORM_STATE_TYPE = {
    email: null,
};

const validationSchema = (t: any) => Yup.object().shape({
    email: Yup.string().required(t("The field is required.", { field: "Email" })).email(t("The email must be a valid email address.")).nullable(),
});

const Logout = (props: any) => {
    const dispatch = useDispatch();
    const formRef: any = useRef();
    const [initFormState, _] = useState<FORGOT_PASSWORD_FORM_STATE_TYPE>(DEFAULT_LOGIN_FORM_STATE);
    const { t } = props;
    const toast = useRef(null);

    const toastify = (message: any, color: string = 'info', summary: any = null) => {
        if ( toast && toast.current ) {
            // @ts-ignore
            toast.current.show({ severity: color, summary: summary, detail: message });
        }
    };

    document.title = `${ t("Logout") } | ${ config.appNameForTitle }`;


    const onSubmit = async (values: FORGOT_PASSWORD_FORM_STATE_TYPE, { setErrors }: any): Promise<void> => {
        try {
            const response = await request({
                method: 'POST',
                url: `/forgot/password`,
                data: values,
            });

            const { data } = response;

            toastify(t("we have sent you a link!", { item: t('user') }), "info");

        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            }
        }
    };

    useEffect(() => {
    }, [dispatch]);

    return (
        <React.Fragment>
            {
                <>
                    <Toast ref={ toast } />
                    <div className="auth-page">
                        <Container fluid className="p-0">
                            <Row className="g-0">
                                <Col lg={ 4 } md={ 5 } className="col-xxl-4">
                                    <div className="px-5 min-h-screen flex align-items-center">
                                        <div
                                            className="w-full border-1 surface-border surface-card border-round p-4 px-4 md:px-7 z-1">
                                            <div className="mb-0 mb-md-0 text-center">
                                                <Link to="/dashboard" className="d-block auth-logo">
                                                    <img
                                                        src={ `${ process.env.REACT_APP_API_URL }/logo/alcolm-logo-blue.png` }
                                                        height="80"
                                                        alt="Alcolm"
                                                    />{ " " }
                                                </Link>
                                            </div>

                                            <div className="mt-3">
                                                <div className="text-900 text-xl font-bold  text-center">
                                                    <h5>{ t("reset password!") }</h5>
                                                </div>
                                                <div className="text-600 font-medium text-center">
                                                    { t('reset password with') } { config.appName }
                                                </div>

                                                <Message className={ 'mt-4' } severity="info"
                                                         text={ t('enter your email and instructions will be sent to you') + '!' } />

                                                <div className={ "mt-4 pb-4" }>
                                                    <Formik
                                                        innerRef={ formRef }
                                                        enableReinitialize={ true }
                                                        initialValues={ initFormState }
                                                        validationSchema={ validationSchema(t) }
                                                        onSubmit={ onSubmit }
                                                    >
                                                        { (props: FormikProps<any>) => {
                                                            return (
                                                                <Form onSubmit={ props.handleSubmit }>
                                                                    <Field component={ InputField }
                                                                           name="email"
                                                                           label={ t("Email") }
                                                                           isRequired="true"
                                                                           placeholder={ t("Enter email address") }
                                                                           autoComplete="off"
                                                                           autoFocus />

                                                                    <div className={ "mt-3" }>
                                                                        <Button label={ t("reset") }
                                                                                type={ "submit" }
                                                                                disabled={ props.isSubmitting }
                                                                                className={ "w-full" }
                                                                                size={ "small" }
                                                                                loading={ props.isSubmitting }
                                                                        />
                                                                    </div>
                                                                </Form>
                                                            );
                                                        } }
                                                    </Formik>
                                                </div>


                                                <div className="mt-5 text-center">
                                                    <p className="text-muted mb-0">
                                                        { t("remember it ?") } { " " }
                                                        <Link
                                                            to="/login"
                                                            className="text-primary fw-semibold"
                                                        >
                                                            { " " }
                                                            { t("sign in") }{ " " }
                                                        </Link>{ " " }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <div className="col-xxl-8 col-lg-8 col-md-7"
                                     style={ {
                                         backgroundImage: `url(${ backgroundImage })`,
                                         backgroundSize: "cover",
                                         backgroundPosition: 'center -50px',
                                         backgroundColor: '#f7f7f7',
                                         backgroundRepeat: "no-repeat"
                                     } }>
                                    &nbsp;
                                </div>
                            </Row>
                        </Container>
                    </div>
                </>
            }

        </React.Fragment>
    );
};

export default withTranslation()(withRouter(Logout));

Logout.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};
