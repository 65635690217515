import React from 'react';
import { ErrorMessage } from "formik";
import { InputSwitch } from "primereact/inputswitch";
import { has } from "lodash";

interface IInputSwitch {
    label?: string,
    withoutLabel?: boolean,
    field: any,
    form?: any,
    icon?: any,
    isRequired?: boolean
    formGroupClass?: any,
    disabled?: boolean,
    invalid?: boolean,
    formText?: any,
    needBoolean: boolean,
    trueValue?: any,
    id?: string,
    trueLabel?: any,
    falseLabel?: any,
    onChange?: any
}

export class InputSwitchField extends React.Component<IInputSwitch, {}> {
    static defaultProps = {
        needBoolean: true,
        trueValue: true,
        formGroupClass: ""
    };

    handleOnChange = (flag: boolean) => {
        const {
            field,
            form
        } = this.props;

        const value = ( flag ) ? 1 : 0;
        form.setFieldValue(field.name, ( this.props.needBoolean ) ? flag : value);
        if ( this.props.onChange ) {
            this.props.onChange(( this.props.needBoolean ) ? flag : value);
        }
    };

    render() {
        const {
            field,
            withoutLabel,
            formGroupClass,
            id,
            form,
            ...props
        } = this.props;
        const { errors } = form;

        return (
            <div className="p-fluid">
                <div className={ `field ${ formGroupClass }` }>
                    { !withoutLabel
                        && <label>
                            { props.label }
                        </label>
                    }

                    <div>
                        <span style={ { width: '50px', paddingRight: "5px" } }>
                            <InputSwitch
                                className={ `${ has(errors, field.name) ? 'p-invalid' : '' }` }
                                checked={ ( field.value == true || field.value == props.trueValue ) }
                                onChange={ (e: any) => this.handleOnChange(e.value) }
                                disabled={props.disabled ?? false}/>
                        </span>
                        <span style={ { position: "relative", top: "-8px", left: "5px" } }>
                            { ( field.value == props.trueValue && props.trueLabel ) && <>{ props.trueLabel }</> }
                            { ( field.value != props.trueValue && props.falseLabel ) && <>{ props.falseLabel }</> }
                        </span>
                    </div>

                    <ErrorMessage name={ field.name } component="small"
                                  className="p-error" />
                </div>
            </div>
        );
    }
}
