import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { get, includes } from "lodash";
import { request } from "../../../../utils/Request";
import { utcDateToLocalDate } from "../../../../utils/Helper";

const UserDetail = (props: any) => {
    const { t, toastify, params } = props;
    const [entity, setEntity] = useState<any>(null);

    const getDetail = async (id: string) => {
        try {
            const response = await request({
                url: `/suppliers/email/logs/${ id }`
            });

            const { data } = response.data;
            setEntity(data);
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        if ( includes(['detail'], get(params, ['operation'])) ) {
            getDetail(params.operationId);
        }
    }, [params]);

    return (
        <div className={ "p-4" }>
            {
                entity &&
                <>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('subject') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">{ entity.subject }</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('sent to') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">{ entity.sent_to }</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('sent from') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">{ entity.sent_from }</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('cc') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">{ entity.cc }</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('bcc') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">{ entity.bcc }</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('process') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">{ entity.process }</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('sent at') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">{ entity.sent_at ? utcDateToLocalDate(entity.sent_at) : '' }</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('resend at') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div
                              className="text-end">{ entity.resend_at ? utcDateToLocalDate(entity.resend_at) : '' }</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('read at') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">{ entity.read_at ? utcDateToLocalDate(entity.read_at) : '' }</div>
                        </Col>
                    </Row>
                    <Row className={ "mt-2" }>
                        <Col sm="12">
                            <h3>
                                <span className="text-capitalize">{ t('content') }</span>
                                <hr />
                            </h3>
                            <div className={ "overflow-scroll" }>
                                <div dangerouslySetInnerHTML={ { __html: entity.content } } />
                            </div>
                        </Col>
                    </Row>
                </>
            }
        </div>

    );
};

UserDetail.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(UserDetail));
