import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useState } from "react";
import { includes, isEmpty } from "lodash";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SpotLoadForm from "./SpotLoadForm";
import SpotLoadDetail from "./SpotLoadDetail";

const SpotLoadList = (props: any) => {
    const { t, moduleState, toastify } = props;
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [operation, setOperation] = useState<any>(searchParams.get('operation') ?? null);
    const [operationId, setOperationId] = useState<any>(searchParams.get('operationId') ?? null);

    const onOperationClick = (operationToBePerformed: any = 'add', id: any = null) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('operation', operationToBePerformed);
        searchParams.set('operationId', id);

        if ( isEmpty(operationToBePerformed) ) {
            searchParams.delete('operation');
        }

        if ( isEmpty(id) ) {
            searchParams.delete('operationId');
        }

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        }, { replace: true });
        setOperationId(id);
        setOperation(operationToBePerformed);
    };

    const onCloseOperation = () => {
        onOperationClick(null, null);
        onReset();
    };

    const onReset = () => {
        props.setQuery({
            ...moduleState,
            filters: [],
            page: 1,
            search: null
        });
    };

    return (
        <div className={ "block-viewer" }>
            <div className="block-section">
                {
                    (!includes(['add', 'edit'], operation)) &&
                  <>
                      <div className={ `${ operation === 'detail' ? 'd-none' : '' }` }>
                          <div className="block-header">
                                <span className="block-title">
                                    <h5 className={ "mb-0" }>{ t('spot loads') }</h5>
                                </span>
                              <div className="block-actions">
                              </div>
                          </div>
                          <div className={ `block-content` }>

                          </div>
                      </div>

                  </>
                }

                {
                    (operation === 'detail' && !isEmpty(operationId)) &&
                    <>
                        <SpotLoadDetail
                            setQuery={ props.setQuery }
                            toastify={ props.toastify }
                            dropdowns={ props.dropdowns }
                            params={ { operation, operationId } }
                            onClose={ onCloseOperation }
                        />
                    </>
                }

                {
                    (includes(['add', 'edit'], operation)) &&
                    <SpotLoadForm
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        params={ { operation, operationId } }
                        onClose={ onCloseOperation }
                        operationCancel={ () => onOperationClick(null, null) }
                      />
                }
            </div>

        </div>
    );
};

SpotLoadList.propTypes =
    {
        // t: PropTypes.any,
    }
;

export default withTranslation()(WithRouter(SpotLoadList));

