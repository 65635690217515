import React, { useEffect, useRef, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import { Col, Row } from "react-bootstrap";
import { Field, FieldArray, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../../../components/Shared/InputField";
import { Button } from "primereact/button";
import * as yup from "yup";
import { find, get, isEmpty } from "lodash";
import { request } from "../../../../utils/Request";
import Location from "../../../../components/Common/Display/Location";

interface IAddMissingRoutesProps {
    visible: any;
    dropdowns?: any | undefined;
    toastify: any;
    routes: any;
    orders: any;
    onClose: any;
}

// @ts-ignore
function AddMissingRoutes(props: IAddMissingRoutesProps) {
    const { dropdowns, toastify, routes, orders } = props;
    const { t } = useTranslation();
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<any>({routes: []});
    const [open, setOpen] = useState<boolean>(false);

    const validationSchema = (t: Function): any => {
        return yup.object().shape({
            routes: yup.array()
            .of(yup.object({
                from_location_id: yup.string().required(t('the field is required.', { field: t('from location') })).nullable(),
                to_location_id: yup.string().required(t('the field is required.', { field: t('to location') })).nullable(),
                title: yup.string().trim(t('the field is invalid.')).required(t('the field is required.', { field: t('title') })).nullable(),
            }))
            .min(1, t('the field is required.', { field: t('route') }))
            .required(t('the field is required.', { field: t('route') })),
        });
    }

    const onSubmit = async () => {
        const base: any = get(formRef.current, ['values']);

        try {
            const response = await request({
                method: 'POST',
                url: `/suppliers/routes/store/multiple`,
                data: {
                    routes: base.routes
                },
            });

            toastify(t("new route/s added"), "success");
            onClose(response.data.data);
        } catch (e: any) {
            if ( e.status === 422 ) {
                toastify(t("invalid data"), "error");
            } else {
                toastify(t("server error"), "error");
            }
        }
    }

    const onClose = (data: any) => {
        formRef.current.resetForm();
        setOpen(false);
        props.onClose(data, props.orders);
    }

    // @ts-ignore
    useEffect(() => {
        if ( props.visible ) {
            setOpen(true);
            // const base: any = get(formRef.current, ['values']);
            const _initFormState = {
                routes: routes.map((route: any) => {
                    return {
                        title: find(props.dropdowns.locations, { id: route.from_location_id }).city + ' - ' + find(props.dropdowns.locations, { id: route.to_location_id }).city,
                        from_location_id: route.from_location_id,
                        to_location_id: route.to_location_id,
                    };
                })
            }

            setInitFormState(_initFormState);
        }

    }, [props.visible]);

    return (
        <>
            <div>
                <Dialog header={ <>{ t("add missing routes") }</> } visible={open} style={{ width: '85vw' }}
                        onHide={() => onClose([])}
                        breakpoints={{ '960px': '100vw', '641px': '100vw' }}>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                        validationSchema={ validationSchema(t) }
                    >
                        { (formikProps: FormikProps<any>) => {
                            const { values } = formikProps;

                            return (
                                <Form onSubmit={ formikProps.handleSubmit }>
                                    <Row>
                                        <Col sm={12}>
                                            <table className={ "fl-table mb-3" }>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>{ t('title') }*</th>
                                                        <th>{ t('from location') }</th>
                                                        <th>{ t('to location') }</th>
                                                    </tr>
                                                </thead>
                                                <FieldArray
                                                    name="routes"
                                                    render={ (arrayHelpers: any) => (
                                                        <tbody>
                                                            {
                                                                !isEmpty(values.routes) && values.routes.map((route: any, index: number) => (
                                                                    <tr key={ index }>
                                                                        <td data-label={ '#' }>{ index + 1 }</td>
                                                                        <td data-label={ t('title') }>
                                                                            <Field component={ InputField }
                                                                                   name={ `routes.${ index }.title` }
                                                                                   placeholder={ t("enter") + ' ' + t("title").toLowerCase() } />
                                                                        </td>
                                                                        <td data-label={ t('from location') } style={{width: '200px', whiteSpace: "break-spaces"}}>
                                                                            <Location
                                                                                item={ find(dropdowns.locations, { id: route.from_location_id }) }
                                                                                titled={ 0 } />
                                                                        </td>
                                                                        <td data-label={ t('to location') } style={{width: '200px', whiteSpace: "break-spaces"}}>
                                                                            <Location
                                                                                item={ find(dropdowns.locations, { id: route.to_location_id }) }
                                                                                titled={ 0 } />
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    ) }
                                                />
                                            </table>
                                        </Col>
                                    </Row>
                                    <div className={ "text-end" }>
                                        <Button type={ "button" } disabled={ formikProps.isSubmitting }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                                onClick={ formikProps.submitForm }
                                        >
                                            <i className="pi pi-save me-2" />
                                            { t("let's save") }
                                        </Button>
                                        <Button type={ "button" } disabled={ formikProps.isSubmitting }
                                                onClick={ () => formikProps.resetForm() }
                                                tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                size={ "small" } severity={ "warning" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-refresh me-2" />
                                            { t("reset") }
                                        </Button>
                                        <Button type={ "button" } disabled={ formikProps.isSubmitting }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                                onClick={ () => onClose([]) }
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("cancel") }
                                        </Button>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </Dialog>
            </div>
        </>
    );
}

export default withTranslation()(AddMissingRoutes);
