import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import UrlHelper from "js-url-helper";
import qs from "qs";
import { Toast } from "primereact/toast";
import { Route, Routes } from "react-router-dom";
import SpotLoadList from "./partials/SpotLoadList";

const url = new UrlHelper();

const SpotLoad = (props: any) => {
    const { t } = props;
    const toast = useRef(null);
    const [moduleState, setModuleState] = useState<any>({
        page: 1,
        per_page: 20,
        search: null,
        initialized: false,
    });

    useEffect(() => {
        document.title = t("spot loads");
        const prevUrl = qs.parse(url.getSearchParam().prev_url ?? "");

        setModuleState({
            page: !isEmpty(prevUrl.page) ? url.getSearchParam().page : 1,
            per_page: !isEmpty(prevUrl.per_page) ? url.getSearchParam().per_page : 20,
            search: !isEmpty(prevUrl.search) ? url.getSearchParam().search : null,
            initialized: true,
        });
    }, []);

    const toastify = (message: any, color: string = 'info', summary: any = null) => {
        if ( toast && toast.current ) {
            // @ts-ignore
            toast.current.show({ severity: color, summary: summary, detail: message });
        }
    };

    return (
        <div className={ "module-container" }>
            <Toast ref={ toast } />
            <div className="layout-content">
                { ( moduleState && moduleState.initialized ) ?
                    <React.Fragment>
                        <Routes>
                            <Route path={ "/" } element={
                                <SpotLoadList
                                    moduleState={ moduleState }
                                    toastify={ toastify }
                                    setQuery={ setModuleState }
                                /> } />
                        </Routes>
                    </React.Fragment>
                    : <h6>{ t('loading') }</h6> }
            </div>
        </div>
    );
};

// SpotSpotLoad.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(SpotLoad);
