import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import UrlHelper from "js-url-helper";
import WebhookSentList from "./partials/SentList";
import { Route, Routes } from "react-router-dom";
import { Toast } from "primereact/toast";
import { getFromStorage, setToStorage } from "../../../../utils/Helper";
import qs from "qs";
import { isEmpty } from "lodash";

const url = new UrlHelper();

const WebhookSent = (props: any) => {
    const { t } = props;
    const toast = useRef(null);
    const [dropdowns, setDropdowns] = useState<any>(null);
    const [moduleState, setModuleState] = useState<any>({
        page: 1,
        per_page: 20,
        search: null,
        initialized: false,
        sort_field: null,
        sort_order: null,
    });

    useEffect(() => {
        document.title = t("webhooks");

        const prevUrl = qs.parse(url.getSearchParam().prev_url ?? "");
        const fromStorage: any = getFromStorage('supplierWebhookSentModuleState');
        const defaultState = {
            page: !isEmpty(prevUrl.page) ? url.getSearchParam().page : 1,
            per_page: !isEmpty(prevUrl.per_page) ? url.getSearchParam().per_page : 20,
            search: !isEmpty(prevUrl.search) ? url.getSearchParam().search : null,
            initialized: true,
            sort_field: null,
            sort_order: null,
            ...(!isEmpty(fromStorage) ? JSON.parse(fromStorage) : {})
            // filters: !isEmpty(prevUrl.filters) ? url.getSearchParam().filters : qs.stringify({
            //     statuses: ['produced']
            // }),
        }

        setModuleState(defaultState);
        setToStorage('supplierWebhookSentModuleState', JSON.stringify(defaultState));

    }, []);

    const toastify = (message: any, color: string = 'info', summary: any = null) => {
        if ( toast && toast.current ) {
            // @ts-ignore
            toast.current.show({ severity: color, summary: summary, detail: message });
        }
    };

    const setQuery = (value: any) => {
        setToStorage('supplierWebhookSentModuleState', JSON.stringify(value));
        setModuleState(value);
    }

    return (
        <div className={ "module-container" }>
            <Toast ref={ toast } />
            <div className="layout-content">
                { ( moduleState && moduleState.initialized ) ?
                    <React.Fragment>
                        <Routes>
                            <Route path={ "/" } element={
                                <WebhookSentList
                                    moduleState={ moduleState }
                                    dropdowns={ dropdowns }
                                    toastify={ toastify }
                                    setQuery={ setQuery }
                                /> } />
                        </Routes>
                    </React.Fragment>
                    : <h6>{ t('loading') }</h6> }
            </div>
        </div>
    );
};

// WebhookSent.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(WebhookSent);
