import React, { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../../contexts/LayoutContext";
import { Link } from "react-router-dom";
import { MenuProvider } from "../../contexts/MenuProvider";
import AppMenu from "./AppMenu";
import { find, isEmpty } from "lodash";
import { request } from "../../utils/Request";

const AppSidebar = () => {
    const { setLayoutState } = useContext(LayoutContext);
    const [logo, setLogo] = useState<any>();
    const [logoText, setLogoText] = useState<any>();
    const [logoResponsive, setLogoResponsive] = useState<any>();

    const anchor = () => {
        setLayoutState((prevLayoutState: any) => ( {
            ...prevLayoutState,
            anchored: !prevLayoutState.anchored,
        } ));
    };

    const onGetSetting = async (): Promise<void> => {
        try {
            const response = await request({
                url: `/auth/setting`,
                params: {
                    settings: [
                        'logo', 'logo_text', 'mobile_logo'
                    ]
                }
            });

            const { data } = response.data;

            if ( !isEmpty(find(data, { title: 'logo' })) ) {
                setLogo(find(data, { title: 'logo' }).value);
            }

            if ( !isEmpty(find(data, { title: 'logo_text' })) ) {
                setLogoText(find(data, { title: 'logo_text' }).value);
            }

            if ( !isEmpty(find(data, { title: 'mobile_logo' })) ) {
                setLogoResponsive(find(data, { title: 'mobile_logo' }).value);
            }

        } catch (error: any) {

        }
    };

    useEffect(() => {
        onGetSetting();
        //
        // const interval = setInterval(() => {
        //     setCurrentTime(moment());
        // }, 1000);
        // return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div className="sidebar-header">
                <Link to={ "/" } className="app-logo">
                    <span className="app-logo-normal">
                        {
                            !isEmpty(logo)
                            &&
                          <img src={ `${ process.env.REACT_APP_DOCUMENT_URL }/${ logo }` }
                               alt="Logo" width={ 80 } />
                        }
                        { isEmpty(logo) &&
                          <img src={ `${ process.env.REACT_APP_API_URL }/logo/alcolm-logo-dark.png` } alt="Logo"
                               width={ 80 } /> }
                    </span>
                    <span className="app-logo-small">
                        {
                            !isEmpty(logoResponsive)
                            &&
                          <img src={ `${ process.env.REACT_APP_DOCUMENT_URL }/${ logoResponsive }` }
                               alt="Logo" width={ 80 } />
                        }
                        { isEmpty(logoResponsive) &&
                          <img src={ `${ process.env.REACT_APP_API_URL }/logo/alcolm-logo-dark.png` } alt="Logo"
                               width={ 80 } /> }
                    </span>
                </Link>
                <button
                    className="layout-sidebar-anchor p-link z-2 mb-2"
                    type="button"
                    onClick={ anchor }
                ></button>
            </div>

            <div className="layout-menu-container">
                <MenuProvider>
                    <AppMenu />
                </MenuProvider>
            </div>
        </>
    );
};

export default AppSidebar;
