import React, { useState } from "react";
import { toLower, capitalize, isUndefined } from 'lodash';
import { useTranslation } from "react-i18next";
import { Dialog } from 'primereact/dialog';
import Tooltip from "./Tooltip";
import LazyImage from "../../Shared/LazyImage";

interface IProps {
    item: any,
    titled: any,
    lazyLoading?: any,
    displayLocationName?: any,
}

export function getLocation(item: any) {
    if ( !item ) {
        return '';
    }

    let label: any = '';

    if ( item.address ) {
        label += item.address + ', ';
    }

    if ( item.street_line_1 ) {
        label += item.street_line_1 + ', ';
    }

    if ( item.street_line_2 ) {
        label += item.street_line_2 + ', ';
    }

    if ( item.zip ) {
        label += item.zip + ', ';
    }

    if ( item.city ) {
        label += item.city + ', ';
    }

    if ( item.country ) {
        label += capitalize(item.country);
    }

    return label;
}

function LocationDialog(props: any) {
    const { label, item, source, lazyLoading } = props;
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const handleClickOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        //
        setTimeout(() => ( document.body.style.overflow = 'unset' ), 500);
    };

    return (
        <span>
            <Dialog
                header={ <>{ t('location') } { t('detail') }</> }
                visible={ open }
                onHide={ handleClose }
            >
              <div>
                  <div className="row">
                    <div className="col-sm-6 text-capitalize">{ t('address') }({ t('name') }):</div>
                    <div className="col-sm-6 text-right text-lowercase"> { item.address } </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 text-capitalize">{ t('landmark') }({ t('node') }):</div>
                    <div className="col-sm-6 text-right text-lowercase"> { item.landmark } </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 text-capitalize">{ t('location name') }:</div>
                    <div className="col-sm-6 text-right text-lowercase"> { item.street_line_1 } </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 text-capitalize">{ t('street name') }:</div>
                    <div className="col-sm-6 text-right text-lowercase"> { item.street_line_2 } </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 text-capitalize">{ t('zip') }:</div>
                    <div className="col-sm-6 text-right text-lowercase"> { item.zip } </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 text-capitalize">{ t('city') }:</div>
                    <div className="col-sm-6 text-right text-capitalize"> { item.city } </div>
                  </div>
                  <div className="row ">
                    <div className="col-sm-6">{ t('country') }:</div>
                    <div className="col-sm-6 text-right text-capitalize"> { item.country } </div>
                  </div>
                </div>
            </Dialog>
            <Tooltip tooltip={ label ?? 'N/A' } position={ "top" }>
                <span onClick={ handleClickOpen }
                      className={ "cursor-pointer" }>
                      {
                          source
                          &&
                        <span
                          style={ { position: "relative", top: "3px" } }>
                              <LazyImage
                                src={ source }
                                className="float-left m-r-5"
                                width={ 14 }
                                lazyLoading={ lazyLoading }
                                alt={ item.country.toLowerCase() }
                              />
                        </span>
                      }
                    <span className={ "ps-1 text-capitalize" }>
                            { `${ item.city }` }
                        <span
                            className={ "ps-1" }>{
                            ( props.displayLocationName > 0 && item.street_line_1 )
                                ? `${ item.street_line_1 }`
                                : "" }
                        </span>
                    </span>
                </span>
            </Tooltip>
        </span>
    );
}

function Location(props: IProps) {
    const { item, titled, lazyLoading } = props;

    if ( !item ) {
        return ( <span> </span> );
    }

    const source = item.country_sort ? `/images/flags/${ toLower(item.country_sort) }.png` : null;
    const label = getLocation(item);

    return (
        <>
            {
                titled ?
                    <LocationDialog
                        source={ source }
                        item={ item }
                        label={ label }
                        displayLocationName={ props.displayLocationName }
                        lazyLoading={ isUndefined(lazyLoading) ? true : lazyLoading }
                    />
                    :
                    <span>
                {
                    item.label
                        ? item.label
                        : label
                }
              </span>
            }
        </>
    );
}

export default Location;
