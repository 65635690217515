import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Field, Form } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Col, Row } from "react-bootstrap";
import { InputField } from "../../../../components/Shared/InputField";
import { Sidebar } from "primereact/sidebar";
import { get, includes } from "lodash";
import { Button } from "primereact/button";
import { InputSelectField } from "../../../../components/Shared/InputSelectField";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    dropdowns: any;
    params: any;
    toastify: any;
    onClose?: any;
    operationCancel?: any;
}

type TInputForm = {
    id: null | any,
    identification_number: null | any,
    brands: Array<any> | any,
    models: Array<any> | any,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        identification_number: yup.string().trim(t('the field is invalid.')).required(t('the field is required.', { field: t('title') })).nullable(),
        brands: yup.array().required(t('the field is required.', { field: t('brands') })).nullable(),
        models: yup.array().required(t('the field is required.', { field: t('models') })).nullable(),
    });
};

const DEFAULT_FORM_STATE = {
    id: null,
    identification_number: null,
    brands: [],
    models: [],
};

function PriceForm(props: IFormProps) {
    const { t, toastify, params, dropdowns, operationCancel } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);
    const [entity, setEntity] = useState<any>({});

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: ( !payloads.id ? 'POST' : 'PATCH' ),
                url: ( !payloads.id ? `/suppliers/prices` : `/suppliers/prices/${ payloads.id }` ),
                data: {
                    ...payloads,
                    loading_factors: !payloads.id ? 0 : entity.loading_factors,
                    step: 'price-create'
                },
            });

            if ( payloads.id ) {
                toastify(t("record updated", { item: t('price') }), "success");
            } else {
                toastify(t("record added", { item: t('price') }), "info");
            }

            resetForm();
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");

            }
        }
    };

    const onReset = () => {
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm(
                !includes(['edit'], get(params, ['operation']))
            );
        }
    };

    const onClose = () => {
        setPanelState(false);
        onReset();

        if ( props.onClose ) {
            props.onClose();
        }
    };

    const onEdit = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/prices/${ id }`
            });

            const { data } = response.data;

            const formData: TInputForm = {
                id: data.id,
                identification_number: data.identification_number,
                brands: data.brands.map((item: any) => item.id),
                models: data.brand_models.map((item: any) => item.id),
            };

            setInitFormState(formData);
            setPanelState(true);
            setEntity(data);
        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        // setInitFormState(DEFAULT_FORM_STATE);
        setEntity(null);

        if ( includes(['add'], get(params, ['operation'])) ) {
            setPanelState(true);
        }

        if ( includes(['edit'], get(params, ['operation'])) ) {
            onEdit(params.operationId);
        }
    }, [params]);

    const onChangeBrands = (e: any) => {
        if ( formRef.current && dropdowns.models && dropdowns.brands) {
            const values = formRef.current.values;
            const newModels: any = [];

            dropdowns.models.map(function (item: any) {
                if ( includes(values.models, item.id) && includes(e.value, item.brand_id) ) {
                    newModels.push(item.id);
                }
            });

            formRef.current.setFieldValue('models', newModels);
        }
    }
    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('price') + ' ' + t('action') }</h5>
                            <p>{ t("using this form you can add or update the record") }</p>
                        </div>
                    }
                    visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } } closeOnEscape={ false }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                        validationSchema={ validationSchema(t) }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4 md:mb-7 sm:mb-7" }>
                                        <Col sm={ 12 }>
                                            <Field component={ InputField }
                                                   name="identification_number"
                                                   label={ t("title") }
                                                   placeholder={ t("enter") + ' ' + t("title").toLowerCase() }
                                                   isRequired
                                                   autoFocus />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field component={ InputSelectField }
                                                   options={ dropdowns.brands }
                                                   name="brands"
                                                   placeholder={ t("select") + ' ' + t("brands") }
                                                   onChange={onChangeBrands}
                                                   label={ t('brands') } showClear />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field component={ InputSelectField }
                                                   options={ dropdowns.models.filter(({ brand_id }: any) => includes(props.values.brands, brand_id)) }
                                                   name="models"
                                                   placeholder={ t("select") + ' ' + t("models") }
                                                   label={ t('models') } showClear />
                                        </Col>
                                    </Row>
                                    <div className="filter-card-footer"
                                         style={ { width: '785px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-save me-2" />
                                            { t("let's save") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ onReset }
                                                tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                size={ "small" } severity={ "warning" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-refresh me-2" />
                                            { t("reset") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                                onClick={() => operationCancel(null, null)}
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("cancel") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

PriceForm.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(PriceForm);
