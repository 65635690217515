import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import UrlHelper from "js-url-helper";
import qs from "qs";
import { Route, Routes } from "react-router-dom";
import { Toast } from "primereact/toast";
import { getFromStorage, setToStorage } from "../../../../utils/Helper";
import EmailSettingForm from "./partials/EmailSettingForm";

const EmailSettingIndex = (props: any) => {
    const { t } = props;
    const toast = useRef(null);
    const [moduleState, setModuleState] = useState<any>({
        initialized: false,
    });

    useEffect(() => {
        document.title = t("email settings");

        async function init() {
            const fromStorage: any = getFromStorage('supplierEmailSettingsModuleState');
            const defaultState = {
                initialized: true,
                ...(!isEmpty(fromStorage) ? JSON.parse(fromStorage) : {})
            }

            setModuleState(defaultState);
            setToStorage('supplierEmailSettingsModuleState', JSON.stringify(defaultState));
        }

        init();
    }, []);

    const toastify = (message: any, color: string = 'info', summary: any = null) => {
        if ( toast && toast.current ) {
            // @ts-ignore
            toast.current.show({ severity: color, summary: summary, detail: message });
        }
    };

    const setQuery = (value: any) => {
        setToStorage('supplierEmailSettingsModuleState', JSON.stringify(value));
        setModuleState(value);
    }

    return (
        <div className={ "module-container" }>
            <Toast ref={ toast } />
            <div className="layout-content">
                { ( moduleState && moduleState.initialized ) ?
                    <React.Fragment>
                        <Routes>
                            <Route path={ "/" } element={
                                <EmailSettingForm
                                    toastify={toastify}
                                    setQuery={setQuery}
                                />
                            } />
                        </Routes>
                    </React.Fragment>
                    : <h6>{ t('loading') }</h6> }
            </div>
        </div>
    );
};

// Route.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(EmailSettingIndex);
