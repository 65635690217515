import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { FormikProps, Formik, Form, Field } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Col, Row } from "react-bootstrap";
import { Sidebar } from "primereact/sidebar";
import { find, findIndex, get, isEmpty, last, sortBy } from "lodash";
import { Button } from "primereact/button";
import { random } from "../../../../utils/Helper";
import { InputSingleSelectField } from "../../../../components/Shared/InputSingleSelectField";
import { InputSwitchField } from "../../../../components/Shared/InputSwitchField";
import Location from "../../../../components/Common/Display/Location";
import { Tag } from "primereact/tag";
import { Divider } from "primereact/divider";

interface IFormProps {
    t?: any | undefined;
    onClose: any;
    visible: any;
    toastify: any;
    dropdowns: any;
}

type TInputForm = {
    token: any,
    from_location_id: any,
    is_active: any,
    to_location_id: any,
    status: any,
    is_final_journey: any
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        status: yup.string().nullable(),
        is_active: yup.boolean().nullable(),
        from_location_id: yup.string().required(t('the field is required.', { field: t('from location') })).nullable(),
        to_location_id: yup.string().required(t('the field is required.', { field: t('to location') })).nullable(),
        is_final_journey: yup.boolean().nullable()
    });
};

const DEFAULT_FORM_STATE = {
    token: random(5),
    from_location_id: null,
    is_active: false,
    to_location_id: null,
    status: 'pending',
    is_final_journey: false
};

function UpdateAllJourney(props: IFormProps) {
    const { t, dropdowns, toastify } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);
    const [entity, setEntity] = useState<any>(null);
    const [journeys, setJourneys] = useState<any>([]);

    const onClose = () => {
        setPanelState(false);

        if ( props.onClose ) {
            props.onClose();
        }
    };

    const onSave = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        let _journeys: any = [...journeys];
        let existingJourney = find(_journeys, { token: payloads.token });

        if ( payloads.is_active ) {
            _journeys = _journeys.map((item: any) => ( { ...item, is_active: false } ));
        }

        if ( !isEmpty(existingJourney) ) {
            const index = findIndex(_journeys, { token: payloads.token });
            existingJourney = payloads;
            _journeys[index] = existingJourney;
        } else {
            _journeys.push({
                ...payloads,
                token: random(5),
            });
        }

        const record = find(journeys, {
            from_location_id: payloads.from_location_id,
            to_location_id: payloads.to_location_id,
            status: 'pending',
        });

        if ( record && record.token != payloads.token ) {
            setErrors({ from_location_id: [t("the record is duplicate.")] });
            return;
        }

        if ( _journeys.filter((item: any) => item.is_active).length <= 0 && !payloads.is_active ) {
            setErrors({ from_location_id: [t("minimum one journey should be active")] });
            return;
        }

        setJourneys(_journeys);
        resetForm();
        setInitFormState(DEFAULT_FORM_STATE);
    };

    const onDelete = (id: string) => {
        if ( find(journeys, { token: id, is_active: false }) ) {
            const trashIndex = findIndex(journeys, {token: id, status: 'pending'});
            const _journeys = journeys.filter((_: any, index: number) => (index != trashIndex));
            setJourneys(_journeys);
        }
    };

    const onEdit = (token: any) => {
        const currentDoc: any = find(journeys, { token: token });

        if ( currentDoc ) {
            setInitFormState(currentDoc);
        } else {
            toastify(t("no data"), "error");
        }
    };

    const onReset = (props: any) => {
        props.resetForm();
        setInitFormState(DEFAULT_FORM_STATE);
    };

    const onSubmit = async () => {
        try {
            const response = await request({
                method: 'POST',
                url: `/suppliers/orders/journeys`,
                data: {
                    id: entity.id,
                    journeys: journeys,
                }
            });

            toastify(t("record updated", { item: t('order') }), "success");
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                toastify(t("Minimum 1 order journey required."), "error");
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    const onDetail = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/orders/${ id }`
            });

            const { data }: any = response.data;
            setEntity(data);
            const mostRecent = last(sortBy(data.order_journeys, [function(o: any) { return o.created_at; }]));

            setInitFormState({
                ...DEFAULT_FORM_STATE,
                from_location_id: get(mostRecent, ['to_location', 'id'])
            });

            setJourneys(
                data.order_journeys.map((item: any) =>
                ( {
                    ...item,
                    from_location_id: item.from_location.id,
                    to_location_id: item.to_location.id,
                    is_active: (data.current_journey && data.current_journey.token === item.token)
                } )
            ));
            setPanelState(true);
        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        if ( !isEmpty(props.visible) ) {
            onDetail(props.visible);
        }
    }, [props.visible]);

    return (
        <>
            <div className={ "md:mb-7 sm:mb-7" }>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('update') } { t('order journey') }{ entity && ` #${ entity.order_cd }` }</h5>
                            <p>{ t("using this form you can add or update the record") }</p>
                        </div>
                    }
                    visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSave }
                        validationSchema={ validationSchema(t) }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4" }>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field
                                                component={ InputSingleSelectField }
                                                name="from_location_id"
                                                options={ dropdowns.locations }
                                                label={ t('from location') }
                                                disabled={ props.values.status === 'completed' }
                                                placeholder={ t('pick') + ' ' + t('loading location') }
                                                panelClassName={"max-width-300px hide-p-toggler"}
                                                isRequired
                                                autoFocus
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field
                                                component={ InputSingleSelectField }
                                                name="to_location_id"
                                                options={ dropdowns.locations }
                                                disabled={ props.values.status === 'completed' }
                                                label={ t('to location') }
                                                placeholder={ t('pick') + ' ' + t('unloading location') }
                                                panelClassName={"max-width-300px hide-p-toggler"}
                                                isRequired
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 3 }>
                                            <Field component={ InputSwitchField }
                                                   name="is_active"
                                                   label={ t("make this journey active") }
                                                   trueLabel={ t("yes") }
                                                   falseLabel={ t("no") }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 3 }>
                                            <Field component={ InputSwitchField }
                                                   name="is_final_journey"
                                                   label={ t("is final journey") }
                                                   trueLabel={ t("yes") }
                                                   falseLabel={ t("no") }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 2 }>
                                            <Button type="submit" severity={ undefined } size={ "small" } outlined
                                                    title={ t("save") } className={ "d-block w-full mt-1" }>
                                                <i className="fas fa-plus" />
                                            </Button>
                                        </Col>
                                        <Col sm={ 12 } md={ 2 }>
                                            <Button type="button" severity={ "danger" } size={ "small" } outlined
                                                    title={ t("reset") } className={ "d-block w-full mt-1" }
                                                    onClick={ () => props.resetForm() }>
                                                <i className="fas fa-redo-alt" />
                                            </Button>
                                        </Col>

                                        <Col sm={ 12 }>
                                            <Divider align={ "left" }>
                                                <div className="inline-flex align-items-center">
                                                    <b>{ t("order journeys") }</b>
                                                </div>
                                            </Divider>
                                        </Col>
                                        <Col sm={ 12 }>
                                            <table className={ "fl-table" }>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>{ t("from location") }</th>
                                                        <th>{ t("to location") }</th>
                                                        <th>{ t("status") }</th>
                                                        <th>
                                                            { t("is final journey") }
                                                            <div>
                                                                <small>{ t("order's final destination") }</small>
                                                            </div>
                                                        </th>
                                                        <th className={ "text-end" }>{ t('action') }</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        journeys && journeys.map((item: any, index: any) => {
                                                            return (
                                                                <tr key={ index }
                                                                    className={ `${ item.is_active ? 'table-info' : '' }` }>
                                                                    <td>{ index + 1 }</td>
                                                                    <td className={ "text-left" }>
                                                                        <Location
                                                                            item={ find(dropdowns.locations, { id: item.from_location_id }) }
                                                                            titled={ 1 } />
                                                                    </td>
                                                                    <td className={ "text-left" }>
                                                                        <Location
                                                                            item={ find(dropdowns.locations, { id: item.to_location_id }) }
                                                                            titled={ 1 } />
                                                                    </td>
                                                                    <td>
                                                                        { item.status === "pending" &&
                                                                          <Tag value={ t("pending") }
                                                                               severity={ "danger" } /> }
                                                                        { item.status === "completed" &&
                                                                          <Tag value={ t("complete") }
                                                                               severity={ "success" } /> }
                                                                    </td>
                                                                    <td className={ "text-uppercase" }>
                                                                        { item.is_final_journey ? <Tag value={ t("yes") }
                                                                                                       severity={ "success" } /> :
                                                                            <Tag value={ t("no") }
                                                                                 severity={ "danger" } /> }
                                                                    </td>
                                                                    <td className={ "text-end" }>
                                                                      <a
                                                                        onClick={ () => onEdit(item.token) }
                                                                        className={ `cursor-pointer me-2` }
                                                                        target={ "_blank" }>
                                                                          <i className="fas fa-edit" />
                                                                      </a>
                                                                      {
                                                                          item && !item.is_active
                                                                          &&
                                                                        <>
                                                                            <a
                                                                              onClick={ () => onDelete(item.token) }
                                                                              className={ `cursor-pointer` }
                                                                              target={ "_blank" }
                                                                              rel="noopener noreferrer">
                                                                                <i
                                                                                  className="fas fa-trash-alt" />
                                                                            </a>
                                                                        </>
                                                                      }
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    {
                                                        isEmpty(journeys)
                                                        &&
                                                      <tr>
                                                          <td colSpan={ 7 }>{ t("no data") }</td>
                                                      </tr>
                                                    }
                                                </tfoot>
                                            </table>
                                        </Col>
                                    </Row>
                                    <div className="filter-card-footer"
                                         style={ { width: '785px' } }>
                                        <Button type={ "button" } onClick={ onSubmit }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-save me-2" />
                                            { t("let's save") }
                                        </Button>
                                        <Button type={ "button" }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                                onClick={ onClose }
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("cancel") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

//     UpdateAllJourney.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(UpdateAllJourney);
