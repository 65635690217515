import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Form, Field } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Col, Row } from "react-bootstrap";
import { Sidebar } from "primereact/sidebar";
import { filter, find, findIndex, get, has, isEmpty } from "lodash";
import { Button } from "primereact/button";
import { formatDate, random } from "../../../../utils/Helper";
import moment from "moment-timezone";
import { InputSingleSelectField } from "../../../../components/Shared/InputSingleSelectField";
import InputDateTime from "../../../../components/Shared/InputDateTime";
import { InputField } from "../../../../components/Shared/InputField";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Tooltip from "../../../../components/Common/Display/Tooltip";
import { InputText } from "primereact/inputtext";

interface IFormProps {
    id: any;
    t?: any | undefined;
    dropdowns: any;
    toastify: any;
    onClose?: any;
}

type TInputPricePeriod = {
    id: any,
    token: any,
    type_of_price: any,
    mode_of_transport: any,
    route_id: any
    price_ftl: any
    price_spl: any
    start_date: any
    end_date: any
};

type TInputForm = {
    loading_factors: any,
    period: TInputPricePeriod | any
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        loading_factors: yup.number(t('the field should be numeric.', { field: t("loading factors") })).required(t('the field is required.', { field: t('loading factors') })).nullable(),
        period: yup.object({
            route_id: yup.string().required(t('the field is required.', { field: t('route') })).nullable(),
            type_of_price: yup.string().required(t('the field is required.', { field: t('price type') })).nullable(),
            mode_of_transport: yup.string().required(t('the field is required.', { field: t('mode of transport') })).nullable(),
            price_ftl: yup.number(t('the field should be numeric.', { field: t("price ftl") })).required(t('the field is required.', { field: t('price ftl') })).nullable(),
            price_spl: yup.number(t('the field should be numeric.', { field: t("price spl") })).required(t('the field is required.', { field: t('price spl') })).nullable(),
            price_unit: yup.number(t('the field should be numeric.', { field: t("price unit") })).required(t('the field is required.', { field: t('price unit') })).nullable(),
            start_date: yup.date(t('the field is not valid', { field: t("start date") }))
            .required(t('the field is required.', { field: t('start date') })).nullable(),
            end_date: yup
            .date(t('the field is not valid', { field: t("end date") })).nullable()
            .required(t('the field is required.', { field: t('end date') }))
            .min(yup.ref('start_date'), t('the field must be a date after', {
                field: t("end date"),
                fieldAfter: t("start date")
            })),
        })
    });
};

const DEFAULT_FORM_STATE = {
    loading_factors: 1,
    period: {
        id: null,
        token: random(5),
        type_of_price: 1,
        mode_of_transport: null,
        route_id: null,
        price_ftl: 0,
        price_unit: 0,
        price_spl: 0,
        start_date: null,
        end_date: null,
    }
};

function PriceForm(props: IFormProps) {
    const { t, toastify, dropdowns } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);
    const [entity, setEntity] = useState<any>({});
    const [periods, setPeriods] = useState<any>([]);

    const onSubmit = async () => {
        const base: any = get(formRef.current, ['values']);

        try {
            const response = await request({
                method: 'PATCH',
                url: `/suppliers/prices/${ entity.id }`,
                data: {
                    loading_factors: base.loading_factors,
                    price_routes: periods,
                    type: "price-period"
                },
            });

            toastify(t("record updated", { item: t('price') }), "success");
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                if (has(e.data, 'errors.price_routes')) {
                    toastify(t("Minimum 1 price-period required."), "error");
                }

                if (has(e.data, 'errors.loading_factors')) {
                    toastify(e.data.errors.loading_factors[0], "error");
                }
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    const onSave = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        const _periods: any = [...periods];
        let existingRoute = find(_periods, { token: payloads.period.token });

        if ( !isEmpty(existingRoute) ) {
            const index = findIndex(_periods, { token: payloads.period.token });
            existingRoute = {
                ...payloads.period,
                from_db: (entity?.price_periods.filter((item: any) => item.token == payloads.period.token).length > 0),
                route: find(dropdowns.routes, { id: payloads.period.route_id })
            };
            _periods[index] = existingRoute;
        } else {
            _periods.push({
                ...payloads.period,
                token: random(5),
                route: find(dropdowns.routes, { id: payloads.period.route_id })
            });
        }

        const record = find(periods, {
            route_id: payloads.period.route_id,
            mode_of_transport: payloads.period.mode_of_transport,
            type_of_price: payloads.period.type_of_price,
        });

        if ( record
            && record.token != payloads.period.token
            && ( filter(periods, ( ({ start_date, end_date }) => moment(periods.start_date).isBetween(start_date, end_date) ))
                || filter(periods, ( ({ start_date, end_date }) => moment(periods.end_date).isBetween(start_date, end_date) )) )
        ) {
            setErrors({ period: {route_id: [t("the record is duplicate.")] }});

            return;
        }

        setPeriods(_periods);
        setEntity({
            ...entity,
            loading_factors: payloads.loading_factors
        });
        resetForm();
        setInitFormState({ ...DEFAULT_FORM_STATE, loading_factors: payloads.loading_factors });
    };

    const onReset = (props: any) => {
        props.resetForm();
        props.setValues({
            ...DEFAULT_FORM_STATE,
            loading_factors: initFormState.loading_factors
        });
    };

    const onClose = () => {
        setPanelState(false);

        if ( props.onClose ) {
            props.onClose();
        }
    };

    const onEdit = (token: any) => {
        const currentDoc: any = find(periods, { token: token });
        if ( currentDoc ) {
            setInitFormState({
                loading_factors: initFormState.loading_factors,
                period: {
                    ...currentDoc,
                    start_date: currentDoc.start_date,
                    end_date: currentDoc.end_date,
                    route_id: currentDoc.route.id,
                }
            });
        } else {
            toastify(t("no data"), "error");
        }
    };

    const onChangeLoadingFactors = (value: any, formProps: any) => {
        formProps.setFieldValue('loading_factors', value);
        setUnitPrice(formProps);
    }

    const setUnitPrice = (props: any) => {
        const { values } = props;
        const { loading_factors } = values;
        if ( loading_factors > 0 && values.period.price_ftl > 0 ) {
            let ftl = parseFloat(values.period.price_ftl);
            let _loading_factors = parseFloat(loading_factors);
            // @ts-ignore
            props.setFieldValue('period.price_unit', parseFloat(ftl / _loading_factors).toFixed(2));
        }
    };

    const onDetail = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/prices/${ id }`
            });

            const { data } = response.data;
            setPeriods(data.price_periods.map((item: any) => ( { ...item, route_id: item.route.id, from_db: true } )));
            setInitFormState({ ...DEFAULT_FORM_STATE, loading_factors: data.loading_factors });
            setPanelState(true);
            setEntity(data);
        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };

    const onDelete = (id: string) => {
        const _periods = periods.filter((item: any) => item.token !== id);
        setPeriods(_periods);
    };

    useEffect(() => {
        if ( !isEmpty(props.id) ) {
            onDetail(props.id);
        }
    }, [props.id]);

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('price period') + ' ' + t('action') }</h5>
                            <p>{ t("using this form you can add or update the record") }</p>
                        </div>
                    }
                    visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSave }
                        validationSchema={ validationSchema(t) }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4 md:mb-7 sm:mb-7" }>
                                        <Col sm={ 12 }>
                                            <Row>
                                                <Col sm={ 12 }>
                                                    <div className="p-fluid">
                                                        <div className={ "field" }>
                                                            <label htmlFor={ 'loading_factor' }>{ t('loading factors') }</label>
                                                            <InputText
                                                                { ...props }
                                                                name={ 'loading_factors' }
                                                                id={ 'loading_factors' }
                                                                onChange={ (event: any) => onChangeLoadingFactors(event.target.value, props) }
                                                                value={ props.values.loading_factors ?? 0 }
                                                                className={ `${ has(props.errors, 'loading_factors') ? 'p-invalid' : '' } p-inputtext-sm` }
                                                                onFocus={ ($event: any) => $event.target.select() }
                                                            />
                                                            { (props.errors && props.errors.loading_factors) && <div
                                                              className="p-error">
                                                                {/*@ts-ignore*/}
                                                                { props.errors.loading_factors }
                                                            </div> }
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm={ 12 } md={ 3 }>
                                                <Field component={ InputSingleSelectField }
                                                           name="period.route_id"
                                                           options={ dropdowns.routes }
                                                           label={ t('route') }
                                                           placeholder={ t("pick") + ' ' + t("route") }
                                                           panelClassName={ "hide-p-toggler" }
                                                           isClearable
                                                           isRequired
                                                    />
                                                </Col>
                                                <Col sm={ 12 } md={ 3 }>
                                                    <Field component={ InputDateTime }
                                                           name="period.start_date"
                                                           type={ "date" }
                                                           label={ t("start date") }
                                                           displayDateFormat={ "dd.MM.yyyy" }
                                                           placeholder={ t("pick a date") }
                                                           isRequired
                                                    />
                                                </Col>
                                                <Col sm={ 12 } md={ 3 }>
                                                    <Field component={ InputDateTime }
                                                           name="period.end_date"
                                                           type={ "date" }
                                                           label={ t("end date") }
                                                           displayDateFormat={ "dd.MM.yyyy" }
                                                           placeholder={ t("pick a date") }
                                                           isRequired
                                                    />
                                                </Col>
                                                <Col sm={ 12 } md={ 3 }>
                                                    <Field component={ InputSingleSelectField }
                                                           name="period.type_of_price"
                                                           options={ dropdowns.priceTypes.map((item: any) => ({...item, isDisabled: (item.id === 2)})) }
                                                           label={ t('price type') }
                                                           disabled={ props.values.period.from_db }
                                                           panelClassName={ "hide-p-toggler" }
                                                           isClearable
                                                           isRequired
                                                    />
                                                </Col>
                                                <Col sm={ 12 } md={ 3 }>
                                                    <Field component={ InputSingleSelectField }
                                                           name="period.mode_of_transport"
                                                           options={ dropdowns.transportModes }
                                                           label={ t('transport mode') }
                                                           panelClassName={ "hide-p-toggler" }
                                                           placeholder={ t("pick") + ' ' + t("transport mode") }
                                                           isClearable
                                                           rounded
                                                    />
                                                </Col>
                                                <Col sm={ 12 } md={ 3 }>
                                                    <Field component={ InputField }
                                                           type={ "number" }
                                                           name="period.price_ftl"
                                                           label={ t("price ftl") }
                                                           placeholder={ t("enter") + ' ' + t("price ftl").toLowerCase() }
                                                           onBlur={ () => setUnitPrice(props) }
                                                           isRequired />
                                                </Col>
                                                <Col sm={ 12 } md={ 3 }>
                                                    <Field component={ InputField }
                                                           type={ "number" }
                                                           name="period.price_spl"
                                                           label={ t("price spl") }
                                                           placeholder={ t("enter") + ' ' + t("price spl").toLowerCase() }
                                                           isRequired />
                                                </Col>
                                                <Col sm={ 12 } md={ 3 }>
                                                    {
                                                        (props.values.period && props.values.period.type_of_price <= 1) &&
                                                      <Field component={ InputField }
                                                             name="period.price_unit"
                                                             label={ t("price unit") }
                                                             disabled={ true }
                                                      />
                                                    }
                                                </Col>
                                                <Col sm={ 12 } md={ 2 }>
                                                    <Button type={ "submit" } severity={"success"}
                                                            tooltip={ t("to data save into database") }
                                                            size={ "small" } outlined className={"w-full"}
                                                            tooltipOptions={ { position: 'bottom' } }
                                                    >
                                                        <i className="fas fa-plus-square me-1" />
                                                        {t('save')}
                                                    </Button>
                                                </Col>
                                                <Col sm={ 12 } md={ 2 }>
                                                    <Button type={ "button" }  severity={"danger"}
                                                            tooltip={ t("reset") }
                                                            size={ "small" } outlined className={"w-full"}
                                                            onClick={ () => onReset(props) }
                                                            tooltipOptions={ { position: 'bottom' } }
                                                    >
                                                        <i className="fas fa-sync-alt me-1"></i>
                                                        {t('reset')}
                                                    </Button>
                                                </Col>
                                                <Col sm={ 12 } className={"mt-1"}>
                                                    <DataTable value={ periods } editMode="row" dataKey="id">
                                                        <Column field="route"
                                                                header={ t('route') }
                                                                body={ (rowData: any) => {
                                                                    return (
                                                                        <React.Fragment>
                                                                            { rowData.route?.title }
                                                                            <div>
                                                                                <small>
                                                                                    <Tooltip tooltip={ t('price type') }
                                                                                             position={ 'top' }>
                                                                                        { get(find(dropdowns.priceTypes, { id: rowData.type_of_price }), ['label']) }
                                                                                    </Tooltip>
                                                                                </small>
                                                                                { " " }
                                                                                <small>
                                                                                    <Tooltip
                                                                                        tooltip={ t('mode of transport') }
                                                                                        position={ 'top' }>
                                                                                        { get(find(dropdowns.transportModes, { id: rowData.mode_of_transport }), ['label']) }
                                                                                    </Tooltip>
                                                                                </small>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                } }
                                                        />
                                                        <Column field="price_ftl" header={ t('price ftl') }/>
                                                        <Column field="price_spl" header={ t('price spl') }/>
                                                        <Column field="price_unit" header={ t('price unit') }
                                                                body={ (rowData: any) => {
                                                                    return (
                                                                        rowData.type_of_price === 1 ? rowData.price_unit : ""
                                                                    )
                                                                } }/>
                                                        <Column field="date" header={ t('date') }
                                                                body={ (rowData: any) => {
                                                                    return (
                                                                        <>
                                                                            <div>{ formatDate(rowData.start_date, 'DD.MM.YYYY') }</div>
                                                                            <div>{ formatDate(rowData.end_date, 'DD.MM.YYYY') }</div>
                                                                        </>
                                                                    )
                                                                } } />
                                                        <Column field="action" header={ t('action') }
                                                                body={ (rowData: any) => {
                                                                    return (
                                                                        <div>
                                                                            <a
                                                                                onClick={ () => onEdit(rowData.token) } className={"me-1 cursor-pointer"}>
                                                                                <i className="fas fa-edit" />
                                                                            </a>
                                                                            <a
                                                                                onClick={ () => onDelete(rowData.token) } className={"cursor-pointer"}>
                                                                                <i className="fas fa-trash-alt" />
                                                                            </a>
                                                                        </div>
                                                                    )
                                                                } } />
                                                    </DataTable>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <div className="filter-card-footer"
                                         style={ { width: '785px' } }>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined onClick={ onSubmit }
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-save me-2" />
                                            { t("let's save") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                                onClick={() => onClose()}
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("cancel") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

PriceForm.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(PriceForm);
