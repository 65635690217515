import React, { useRef } from "react";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { request } from "../../../utils/Request";
import { Card, CardBody, CardHeader, Form, CardFooter, Col, Row } from "react-bootstrap";
import { FormikProps, Formik, Field } from "formik";
import { find } from "lodash";
import PropTypes from "prop-types";
import InputAttachment from "../../../components/Shared/InputAttachment";
import { InputField } from "../../../components/Shared/InputField";
import { InputSingleSelectField } from "../../../components/Shared/InputSingleSelectField";
import { Button } from "primereact/button";

type TInputForm = {
    invoice_address_country: any,
    invoice_address_city: any,
    invoice_address_street: any,
    invoice_address_street_no: any,
    invoice_address_zip: any,
    invoice_company_logo: any,
    invoice_company_name: any,
    invoice_mbs: any,
    invoice_oib: any,
    invoice_phone: any,
    invoice_email: any,
    invoice_registration_number: any,
    invoice_vat: any,
    invoice_vat_number: any,
    emil_frey_client_id: any,
}

const DEFAULT_FORM_STATE = {
    invoice_address_country: null,
    invoice_address_city: null,
    invoice_address_street: null,
    invoice_address_street_no: null,
    invoice_address_zip: null,
    invoice_company_logo: null,
    invoice_company_name: null,
    invoice_mbs: null,
    invoice_oib: null,
    invoice_phone: null,
    invoice_email: null,
    invoice_registration_number: null,
    invoice_vat: null,
    invoice_vat_number: null,
};

interface IProps {
    user?: any,
    settings: any,
    dropdowns: any,
    t?: any,
    toastify?: any
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        invoice_company_logo: yup.string().required(t('the field is required.', { field: t('company logo') })).nullable(),
        invoice_company_name: yup.string().required(t('the field is required.', { field: t('company name') })).nullable(),
        invoice_address_country: yup.string().required(t('the field is required.', { field: t('country') })).nullable(),
        invoice_address_city: yup.string().required(t('the field is required.', { field: t('city') })).nullable(),
        invoice_address_street: yup.string().required(t('the field is required.', { field: t('street') })).nullable(),
        invoice_address_street_no: yup.string().required(t('the field is required.', { field: t('street no') })).nullable(),
        invoice_address_zip: yup.string().required(t('the field is required.', { field: t('zip') })).nullable(),
        invoice_mbs: yup.string().required(t('the field is required.', { field: t('mbs') })).nullable(),
        invoice_oib: yup.string().required(t('the field is required.', { field: t('oib') })).nullable(),
        invoice_phone: yup.string().required(t('the field is required.', { field: t('phone') })).nullable(),
        invoice_email: yup.string().required(t('the field is required.', { field: t('email') })).nullable(),
        invoice_registration_number: yup.string().required(t('the field is required.', { field: t('registration number') })).nullable(),
        invoice_vat: yup.string().required(t('the field is required.', { field: t('vat') })).nullable(),
        invoice_vat_number: yup.string().required(t('the field is required.', { field: t('vat number') })).nullable(),
        emil_frey_client_id: yup.string().required(t('the field is required.', { field: t('emil frey client') })).nullable(),
    });
};

function CompanySettingProfile(props: IProps) {
    const { user, t, settings, dropdowns, toastify } = props;
    const [initFormState, setInitFormState] = useState<any>({ ...DEFAULT_FORM_STATE, ...settings });
    const formRef: any = useRef();

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: 'PATCH',
                url: `/auth/setting`,
                data: {
                    ...payloads,
                    type: 'company'
                },
            });

            toastify(t("record updated", { item: t('settings') }), "success");
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    useEffect(() => {
        setInitFormState({
            invoice_company_logo: find(settings, { title: 'invoice_company_logo' })?.value ?? null,
            invoice_company_name: find(settings, { title: 'invoice_company_name' })?.value ?? null,
            invoice_address_country: find(settings, { title: 'invoice_address_country' })?.value ?? null,
            invoice_address_city: find(settings, { title: 'invoice_address_city' })?.value ?? null,
            invoice_address_street: find(settings, { title: 'invoice_address_street' })?.value ?? null,
            invoice_address_street_no: find(settings, { title: 'invoice_address_street_no' })?.value ?? null,
            invoice_address_zip: find(settings, { title: 'invoice_address_zip' })?.value ?? null,
            invoice_mbs: find(settings, { title: 'invoice_mbs' })?.value ?? null,
            invoice_oib: find(settings, { title: 'invoice_oib' })?.value ?? null,
            invoice_phone: find(settings, { title: 'invoice_phone' })?.value ?? null,
            invoice_email: find(settings, { title: 'invoice_email' })?.value ?? null,
            invoice_registration_number: find(settings, { title: 'invoice_registration_number' })?.value ?? null,
            invoice_vat: find(settings, { title: 'invoice_vat' })?.value ?? null,
            invoice_vat_number: find(settings, { title: 'invoice_vat_number' })?.value ?? null,
            emil_frey_client_id: find(settings, { title: 'emil_frey_client_id' })?.value ?? null,
        });
    }, [settings]);

    return (
        <Formik
            innerRef={ formRef }
            enableReinitialize={ true }
            initialValues={ initFormState }
            onSubmit={ onSubmit }
            validationSchema={ validationSchema(t) }
        >
            { (props: FormikProps<TInputForm>) => {
                return (
                    <Form onSubmit={ props.handleSubmit }>
                        <Card>
                            <CardHeader className={ "bg-body-tertiary" }>
                                <h4 className="card-title">{ t('company settings') }</h4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={ 12 } md={ 6 }>
                                        <Field component={ InputField }
                                               name="invoice_company_name"
                                               label={ t("company name") }
                                               placeholder={ "Ex. AG Logistics" }
                                               isRequired />
                                    </Col>
                                    <Col sm={ 12 } md={ 6 }>
                                        <Field component={ InputAttachment }
                                               name="invoice_company_logo"
                                               label={ t("company logo") }
                                               needBoolean
                                               isRequired
                                        />
                                    </Col>
                                    <Col sm={ 12 } md={ 6 }>
                                        <Field component={ InputSingleSelectField }
                                               options={ dropdowns?.countries }
                                               name="invoice_address_country"
                                               placeholder={ t("select") + ' ' + t("country") }
                                               label={ t('country') }
                                               isRequired
                                        />
                                    </Col>
                                    <Col sm={ 12 } md={ 6 }>
                                        <Field component={ InputField }
                                               name="invoice_address_city"
                                               label={ t("city") }
                                               placeholder={ "Ex. Zurich" }
                                               isRequired />
                                    </Col>
                                    <Col sm={ 12 } md={ 6 }>
                                        <Field component={ InputField }
                                               name="invoice_address_street"
                                               label={ t("street") }
                                               placeholder={ "Ex. Studacker strasse 33" }
                                               isRequired />
                                    </Col>
                                    <Col sm={ 12 } md={ 3 }>
                                        <Field component={ InputField }
                                               name="invoice_address_street_no"
                                               label={ t("street no") }
                                               placeholder={ "Ex.  33" }
                                               isRequired />
                                    </Col>
                                    <Col sm={ 12 } md={ 3 }>
                                        <Field component={ InputField }
                                               name="invoice_address_zip"
                                               label={ t("zip") }
                                               placeholder={ "Ex. 3098" }
                                               isRequired />
                                    </Col>
                                    <Col sm={ 12 } md={ 6 }>
                                        <Field component={ InputField }
                                               name="invoice_email"
                                               label={ t("email") }
                                               placeholder={ "Ex. info@example.com" }
                                               isRequired />
                                    </Col>
                                    <Col sm={ 12 } md={ 3 }>
                                        <Field component={ InputField }
                                               name="invoice_mbs"
                                               label={ t("mbs") }
                                               placeholder={ "Ex. 080000888" }
                                               isRequired />
                                    </Col>
                                    <Col sm={ 12 } md={ 3 }>
                                        <Field component={ InputField }
                                               name="invoice_oib"
                                               label={ t("oib") }
                                               placeholder={ "Ex. 66688889999" }
                                               isRequired />
                                    </Col>
                                    <Col sm={ 12 } md={ 6 }>
                                        <Field component={ InputField }
                                               name="invoice_phone"
                                               label={ t("phone") }
                                               placeholder={ "Ex. +38512398766" }
                                               isRequired />
                                    </Col>
                                    <Col sm={ 12 } md={ 6 }>
                                        <Field component={ InputField }
                                               name="invoice_registration_number"
                                               label={ t("registration number") }
                                               placeholder={ "Ex. HRSR.000980677" }
                                               isRequired />
                                    </Col>
                                    <Col sm={ 12 } md={ 6 }>
                                        <Field component={ InputField }
                                               name="invoice_vat"
                                               label={ t("vat rate") }
                                               placeholder={ "Ex. 25" }
                                               isRequired />
                                    </Col>
                                    <Col sm={ 12 } md={ 6 }>
                                        <Field component={ InputField }
                                               name="invoice_vat_number"
                                               label={ t("vat number") }
                                               placeholder={ "Ex. HR88785030111" }
                                               isRequired />
                                    </Col>
                                    <Col sm={ 12 } md={ 6 }>
                                        <Field component={ InputSingleSelectField }
                                               options={ dropdowns?.clients }
                                               name="emil_frey_client_id"
                                               placeholder={ t("select") + ' ' + t("client") }
                                               label={ t('emil frey client') }
                                               formText={ t('will update all loads') }
                                               isRequired
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Button type="submit" size={ 'small' }
                                        title={ t("to data save into database") }
                                        className="btn btn-success waves-effect btn-label waves-light">
                                    <i className="fas fa-save label-icon me-1" /> { t("let's save") }
                                </Button>
                            </CardFooter>
                        </Card>
                    </Form>
                );
            } }
        </Formik>
    );
}

CompanySettingProfile.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(CompanySettingProfile);
