import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Form, Field } from "formik";
import { Col, Row } from "react-bootstrap";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { map, pickBy } from "lodash";
import { InputCheckboxField } from "../../../../components/Shared/InputCheckboxField";
import * as XLSX from "xlsx";
import qs from "qs";
import { request } from "../../../../utils/Request";
import { formatDate } from "../../../../utils/Helper";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    dropdowns: any;
    visibility: any;
    toastify: any;
    onClose?: any;
}

type TInputForm = {
    consider_all_pages: boolean,
    title: boolean,
    loading_factors: boolean,
    brands: boolean,
    models: boolean,
    price_periods: boolean
};

const DEFAULT_FORM_STATE = {
    consider_all_pages: true,
    title: true,
    loading_factors: true,
    brands: true,
    models: true,
    price_periods: true
};

// @ts-ignore
function PriceExcel(props: IFormProps) {
    const { t, visibility, dropdowns } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<any>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);

    const onSubmit = async (payloads: TInputForm): Promise<void> => {
        const _params: any = props.moduleState;
        const _payloads: any = payloads;

        if ( payloads.consider_all_pages ) {
            delete _params.page;
            delete _params.per_page;
        }

        _params.excel = 1;

        try {
            const response = await request({
                url: `/suppliers/prices`,
                params: _params,
                paramsSerializer: {
                    serialize: (params: any) => qs.stringify(params)
                },
            });

            delete _payloads.consider_all_pages;
            const columns = pickBy(_payloads, (item: boolean) => ( item ));
            const title: any = getTitles();
            const data: any = [];
            map(response.data.data, (value: any) => {
                const row: any = {
                    title: value.identification_number,
                    loading_factors: value.loading_factors,
                    brands: (value.brands ? value.brands.map((item: any) => item.title).join(", ") : ""),
                    models: value.brand_models ? value.brand_models.map((item: any) => `${item.title} - ${item.version_code}`).join(", ") : "",
                    price_periods: value.price_periods
                        ? value.price_periods.map(
                            (item: any, index: number) => ` ${index + 1}. ${item.route.title} || ${item.price_ftl} || ${item.price_spl} || ${item.price_unit} || ${formatDate(item.start_date)} || ${formatDate(item.end_date)} `
                        ).join("\n")
                        : ""
                };

                const _row: any = {};
                map(columns, (_, key: string) => ( _row[key] = row[key] ));
                const arrayOfRow = Object.keys(_row).map((key) => _row[key]);
                data.push(arrayOfRow);
            });

            const _title = map(columns, (_, key: string) => ( title[key] = title[key] ));
            const excel = XLSX.utils.book_new();
            const sheet = XLSX.utils.aoa_to_sheet([_title, ...data]);
            XLSX.utils.book_append_sheet(excel, sheet, t('prices'));
            XLSX.writeFile(excel, "prices.xlsx");
        } catch (e: any) {
            console.log(e);
            props.toastify(t("server error"), "error");
        }
    };

    const onClose = () => {
        setPanelState(false);

        if ( props.onClose ) {
            props.onClose();
        }
    };

    const getTitles = () => {
        return {
            identification_number: t('title'),
            brands: t('brands'),
            models: t('models'),
            loading_factors: t('loading factors'),
            price_periods: t('price periods') + `# ${ t('route') } || ${ t('price ftl') } || ${ t('price spl') } || ${ t('price unit') } || ${ t('start date') } || ${ t('end date') }`,
        };
    };

    useEffect(() => {
        if ( visibility ) {
            setPanelState(visibility);
            setInitFormState({ ...DEFAULT_FORM_STATE, ...props.moduleState.filters ?? [] });
        }
    }, [visibility]);


    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t("export") } { t("locations") }</h5>
                            <p>
                                { t("select columns to be exported.") }
                            </p>
                        </div>
                    }
                    visible={ panelState } position="left" onHide={ onClose } blockScroll={ true }
                    style={ { width: '400px' } } closeOnEscape={ false }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4 md:mb-7 sm:mb-7" }>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="consider_all_pages"
                                                component={ InputCheckboxField }
                                                label={ t("consider all pages") }
                                            />
                                        </Col>

                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="title"
                                                component={ InputCheckboxField }
                                                label={ t("title") }
                                            />
                                        </Col>

                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="brands"
                                                component={ InputCheckboxField }
                                                label={ t("brands") }
                                            />
                                        </Col>

                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="models"
                                                component={ InputCheckboxField }
                                                label={ t("models") }
                                            />
                                        </Col>

                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="loading_factors"
                                                component={ InputCheckboxField }
                                                label={ t("loading factors") }
                                            />
                                        </Col>

                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="price_periods"
                                                component={ InputCheckboxField }
                                                label={ t("price periods") }
                                            />
                                        </Col>
                                    </Row>
                                    <div className="filter filter-card-footer"
                                         style={ { width: '385px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("apply filter") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-filter me-2" />
                                            { t("filter") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ onClose }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("close") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

PriceExcel.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(PriceExcel);
