import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useRef, useState } from "react";
import { request } from "../../../../utils/Request";
import Tooltip from "../../../../components/Common/Display/Tooltip";
import HasAccess from "../../../../utils/HasAccess";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import StateLessGrid from "../../../../components/Shared/StateLessGrid";
import { Button } from "primereact/button";
import { Col, Row } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../../../components/Shared/InputField";
import TransportVehicleForm from "./TransportVehicleForm";
import TransportVehicleFilter from "./TransportVehicleFilter";
import { Badge } from "primereact/badge";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { includes, isEmpty } from "lodash";
import { utcDateToLocalDate } from "../../../../utils/Helper";
import TransportVehicleDetail from "./TransportVehicleDetail";
import { ButtonGroup } from "primereact/buttongroup";
import TransportVehicleExcel from "./TransportVehicleExcel";

const TransportVehicleList = (props: any) => {
    const { t, moduleState, toastify } = props;
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [operation, setOperation] = useState<any>(searchParams.get('operation') ?? null);
    const [operationId, setOperationId] = useState<any>(searchParams.get('operationId') ?? null);
    const [filterPanel, setFilterPanel] = useState<boolean>(false);
    const [excelPanel, setExcelPanel] = useState<boolean>(false);
    const [initSearchFormState, setInitSearchFormState] = useState<any>({
        search: moduleState.search ?? null
    });
    const tableRef: any = useRef();
    const [total, setTotal] = useState(0);

    const onDelete = async (payloads: any): Promise<void> => {
        try {
            await request({
                url: `suppliers/transport/vehicles/${ payloads.id }`,
                method: 'DELETE',
                data: { _method: 'DELETE' }
            });

            toastify(t("record deleted", { item: t('driver') }), "info");
            onReset();
        } catch (e: any) {
            if(e.status === 422) {
                return toastify(e.data.message, "error");
            }

            toastify(t("server error"), "error");
        }
    };

    const columns = [
        {
            field: 'type',
            title: t('type'),
            sorting: true,
            render: (_row: any) => ( <span className={ "text-capitalize" }>{ _row.type }</span> )
        },
        { field: 'mode_of_transport', title: t('mode of transport'), sorting: true },
        { field: 'truck_plate_number', title: t('truck plate number'), sorting: true },
        { field: 'trailer_plate_number', title: t('trailer plate number'), sorting: true },
        {
            field: 'connect_to_cvs', title: t('cvs status'), sorting: true,
            render: (_row: any) => (
                _row.connect_to_cvs ?
                    <span className={ "text-capitalize" }>{ t('connected') }</span>
                    : <span className={ "text-capitalize" }>{ t('not connected') }</span>
            )
        },
        {
            field: 'is_active', title: t('status'), sorting: true, render: (_row: any) => (
                _row.is_active ?
                    <Badge severity="success" value={ t('active') } />
                    : <Badge severity="danger" value={ t('inactive') } />
            )
        },
        { field: 'capacity', sorting: true, title: t('truck capacity') + ' ( ' + t('in kilograms') + ')' },
        { field: 'euro_norm', sorting: true, title: t('euro norm') },
        {
            field: 'legal_entity',
            title: t('legal entity'),
            render: (_row: any) => _row.truck_owner?.company_name,
            sorting: true,
            sortField: 'truck_owner_id'
        },
        { field: 'trailer_capacity', sorting: true, title: t('trailer capacity') + ' ( ' + t('in kilograms') + ')' },
        {
            field: 'has_sub_contractor', title: t('has sub contractor?'), render: (_row: any) => (
                _row.has_sub_contractor ?
                    <Badge severity="success" value={ t('yes') } />
                    : <Badge severity="danger" value={ t('no') } />
            )
        },
        {
            field: 'sub_contractor',
            title: t('sub contractor'),
            render: (_row: any) => _row.sub_contractor?.name,
            sorting: true,
            sortField: 'sub_contractor_id'
        },
        {
            field: 'action',
            title: t('action'),
            sorting: false,
            render: (_row: any) => {
                return (
                    <>
                        <HasAccess hasAllAccess={ ['transportvehiclesupdate'] }>
                            <Tooltip
                                tooltip={ t('edit item') }>
                                <a onClick={ () => onOperationClick('edit', _row.id) }>
                                    <i className="fas fa-edit" />
                                </a>
                            </Tooltip>
                        </HasAccess>
                        <HasAccess hasAllAccess={ ['transportvehiclesdestroy'] }>
                            <Tooltip
                                tooltip={ t('delete item') }>
                                <a
                                    title={ t('delete item') }
                                    className={ "cursor-pointer ms-2 btn btn-soft-danger waves-effect waves-light btn-xs" }
                                    onClick={ (event: any) => confirmPopup({
                                        target: event.currentTarget,
                                        message: t('do you want to delete this record?'),
                                        icon: 'pi pi-info-circle',
                                        // @ts-ignorer
                                        defaultFocus: 'reject',
                                        acceptClassName: 'p-button-danger',
                                        accept: () => onDelete(_row),
                                        reject: () => {
                                        },
                                    }) }>
                                    <i className="fas fa-trash-alt" />
                                </a>
                            </Tooltip>
                        </HasAccess>
                        <HasAccess hasAllAccess={ ['transportvehiclesupdate'] }>
                            <Tooltip tooltip={ t('detail of item') }>
                                <a
                                    className={ "cursor-pointer ms-2" }
                                    onClick={ () => onOperationClick('detail', _row.id) }>
                                    <i className="fas fa-eye" />
                                </a>
                            </Tooltip>
                        </HasAccess>
                    </>
                );
            }
        },
    ];

    const DetailRow = ({ data }: any) => {
        return (
            <>
                <div>
                    <strong className={ 'text-capitalize' }>{ t('brand') }:</strong> { data.brand }
                </div>
                <div>
                    <strong
                        className={ 'text-capitalize' }>{ t('year of production') }:</strong> { data.year_of_production }
                </div>
                <div>
                    <strong
                        className={ 'text-capitalize' }>{ t('vehicle engine type') }:</strong> { data.vehicle_engine_type }
                </div>
                <div>
                    <strong className={ 'text-capitalize' }>{ t('speed limit') }:</strong> { data.speed_limit }
                </div>
                <div>
                    <strong className={ 'text-capitalize' }>{ t('fuel consumption') }:</strong> { data.fuel_consumption }
                </div>
                <div>
                    <strong className={ 'text-capitalize' }>{ t('tank') }:</strong> { data.tank }
                </div>
                <div>
                    <strong className={ 'text-capitalize' }>{ t('cvs key') }:</strong> { data.vehicle_ext_key }
                </div>
                <div>
                    <strong
                        className={ 'text-capitalize' }>{ t('cvs logged at') }:</strong> { data.cvs_logged_at ? utcDateToLocalDate(data.cvs_logged_at) : "" }
                </div>
                <div>
                    <strong className={ 'text-capitalize' }>{ t('legal entity') }:</strong> { data.legal_entity?.name }
                </div>
                <div>
                    <strong
                        className={ 'text-capitalize' }>{ t('truck capacity') + ' (' + t("in kilograms") + ')' }:</strong> { data.capacity }
                </div>
                <div>
                    <strong
                        className={ 'text-capitalize' }>{ t('trailer capacity') + ' (' + t("in kilograms") + ')' }:</strong> { data.trailer_capacity }
                </div>
                <div>
                    <strong
                        className={ 'text-capitalize' }>{ t('max load capacity') }:</strong> { data.max_load_capacity }
                </div>
                <div>
                    <strong className={ 'text-capitalize' }>{ t('number of axles') }:</strong> { data.number_of_axles }
                </div>
            </>
        );
    };

    const onOperationClick = (operationToBePerformed: any = 'add', id: any = null) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('operation', operationToBePerformed);
        searchParams.set('operationId', id);

        if ( isEmpty(operationToBePerformed) ) {
            searchParams.delete('operation');
        }

        if ( isEmpty(id) ) {
            searchParams.delete('operationId');
        }

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        }, { replace: true });
        setOperationId(id);
        setOperation(operationToBePerformed);
    };

    const onCloseOperation = (reset: any = true) => {
        onOperationClick(null, null);
        onReset(reset);
        focusOnSearch();
    };

    const onReset = (reset: any = true) => {
        const mStates = { ...moduleState };

        if ( reset ) {
            mStates.filters = [];
            mStates.page = 1;
            mStates.search = null;
        }

        props.setQuery(mStates);
    };

    const onSearch = ({ search }: any) => {
        props.setQuery({
            ...moduleState,
            search: search
        });
    };

    const onFilterClick = () => {
        setFilterPanel(true);
    };

    const onExcelClick = () => {
        setExcelPanel(true);
    };

    const focusOnSearch = () => {
        const searchInput: HTMLInputElement | null = document.querySelector('input[name="search"]');
        if ( searchInput ) {
            searchInput.focus();
        }
    };

    return (
        <div className={ "block-viewer" }>
            <div className="block-section">
                <div className="block-header">
                    <span className="block-title">
                        <h5 className={ "mb-0" }>{ t('transport vehicles') } ({total})</h5>
                    </span>
                    <div className="block-actions">
                        {
                            operation == 'detail' &&
                          <div>
                              <Button type={ "button" }
                                      tooltip={ t("operation cancel") } className={ "ms-2" }
                                      size={ "small" } severity={ "warning" }
                                      tooltipOptions={ { position: 'top' } } onClick={ () => onCloseOperation(false) }>
                                  <i className="pi pi-arrow-left me-2" /> { t("cancel") }
                              </Button>
                          </div>
                        }
                    </div>
                </div>
                <div className="block-content">
                       <Row className={ `p-2 ${operation === 'detail' ? 'position-minus' : ''}` }>
                          <Col
                            sm={ 12 } md={ 6 } className={ "mb-2" }>
                              <Formik
                                enableReinitialize={ true }
                                initialValues={ initSearchFormState }
                                onSubmit={ onSearch }
                              >
                                  { (props: FormikProps<any>) => {
                                      return (
                                          <Form onSubmit={ props.handleSubmit }>
                                              <Col sm={ 12 } md={ 8 }>
                                                  <Field component={ InputField } withoutLabel={ true }
                                                         autoFocus={ true }
                                                         name="search"
                                                         formGroupClass={ "mb-0" }
                                                         placeholder={ t("search") } />
                                              </Col>
                                          </Form>
                                      );
                                  } }
                              </Formik>
                          </Col>

                          <Col
                            sm={ 12 } md={ 6 } className={ "text-right mb-2" }>
                                <ButtonGroup>
                                    <Button size={ "small" } outlined tooltip={ t('add item') }
                                            tooltipOptions={ { position: 'top' } }
                                            onClick={ () => onOperationClick('add') }>
                                        <i className="pi pi-plus" />
                                        <span className={ "ms-1" }>{ t('add') }</span>
                                    </Button>
                                    <Button icon="pi pi-filter-fill" size={ "small" } outlined tooltip={ t('filter') }
                                            onClick={ onFilterClick }
                                            tooltipOptions={ { position: 'top' } } />
                                    <Button icon="pi pi-file-excel" size={ "small" } outlined tooltip={ t('excel') }
                                            onClick={ onExcelClick }
                                            tooltipOptions={ { position: 'top' } } />
                                    <Button onClick={ onReset } icon="pi pi-refresh" size={ "small" } outlined
                                            tooltip={ t('reset') }
                                            tooltipOptions={ { position: 'top' } } />
                                </ButtonGroup>
                          </Col>

                          <Col sm={ 12 }>
                              <StateLessGrid
                                size={ "normal" }
                                url={ '/suppliers/transport/vehicles?1=1' }
                                name={ "transportVehicleList" }
                                columns={ columns }
                                moduleState={ moduleState }
                                setQuery={ props.setQuery }
                                ref={ tableRef }
                                detailRow={ (data: any) => <DetailRow data={ data } /> }
                                itemSize={ 45 }
                                setTotal={ setTotal }
                              />
                          </Col>
                      </Row>

                    {
                        (operation === 'detail' && !isEmpty(operationId)) &&
                        <>
                            <TransportVehicleDetail
                              setQuery={ props.setQuery }
                              toastify={ props.toastify }
                              dropdowns={ props.dropdowns }
                              params={ { operation, operationId } }
                              onClose={ onCloseOperation }
                            />
                        </>
                    }

                    {
                        (includes(['add', 'edit'], operation)) &&
                        <TransportVehicleForm
                          moduleState={ moduleState }
                          setQuery={ props.setQuery }
                          toastify={ props.toastify }
                          dropdowns={ props.dropdowns }
                          params={ { operation, operationId } }
                          operationCancel={ onOperationClick }
                          onClose={ onCloseOperation }
                        />
                    }

                    <TransportVehicleFilter
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        visibility={ filterPanel }
                        onClose={ () => {
                            setFilterPanel(false);
                            focusOnSearch();
                        } }
                    />

                    <TransportVehicleExcel
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        visibility={ excelPanel }
                        onClose={ () => {
                            setExcelPanel(false);
                            focusOnSearch();
                        } }
                    />

                    <ConfirmPopup />
                </div>
            </div>
        </div>
    );
};

TransportVehicleList.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(TransportVehicleList));
