import React from "react";
import Login from "../pages/Authentication/Login";
import Dashboard from "../pages/Dashboard";
import { Navigate } from "react-router-dom";

//supplier

//Authentication pages
import Brand from "../pages/Suppliers/brands";
import LegalEntity from "../pages/Suppliers/legalEntities";
// import Login from "src/pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPassword from "../pages/Authentication/ForgetPassword";
import User from "../pages/Suppliers/users";
import Role from "../pages/Suppliers/roles";
import Model from "../pages/Suppliers/models";
import Location from "../pages/Suppliers/locations";
import TransportVehicle from "../pages/Suppliers/transportVehicles";
// @ts-ignore
import TransportVehicleMap from "../pages/Suppliers/transportVehicles/Map";
import Driver from "../pages/Suppliers/drivers";
import Sent from "../pages/Suppliers/webhooks/sent";
import Received from "../pages/Suppliers/webhooks/received";
// import Order from "../pages/Suppliers/orders";
// import OrderDraft from "../pages/Suppliers/orderDrafts";
import SpotLoad from "../pages/Suppliers/spotLoads";
// import AlpInboundLoad from "../pages/Suppliers/alpInboundLoads";
// import AlpOutboundLoad from "../pages/Suppliers/alpOutboundLoads";
// import AlpSpotLoad from "../pages/Suppliers/alpSpotLoads";
import ArchivedAlpSpotLoad from "../pages/Suppliers/archived/alpSpotLoads";
// import ArchivedAlpInboundLoad from "../pages/Suppliers/archived/alpInboundLoads";
// import ArchivedAlpOutboundLoad from "../pages/Suppliers/archived/alpOutboundLoads";
import Profile from "../pages/Suppliers/profile";
// import Account from "../pages/Admin/accounts";
import Route from "../pages/Suppliers/routes";
import PrePayment from "../pages/Suppliers/prePayments";
// import Price from "../pages/Suppliers/prices";
import Client from "../pages/Suppliers/clients";
import SubContractor from "../pages/Suppliers/subContractors";
import ArchivedSpotLoad from "../pages/Suppliers/archived/spotLoads";
import ArchivedOrder from "../pages/Suppliers/archived/orders";
import ArchivedInvoice from "../pages/Suppliers/archived/invoices";
import Invoice from "../pages/Suppliers/invoices";
import ResetPassword from "../pages/Authentication/ResetPassword";
import Contract from "../pages/Suppliers/contracts";
import Price from "../pages/Suppliers/prices";
import EmailSetting from "../pages/Suppliers/settings/emailSettings";
import TransportVehicleMomentLog from "../pages/Suppliers/transportVehicleMomentLogs";
import PantheonTransferLog from "../pages/Suppliers/pantheonTransferLogs";
import EmailLog from "../pages/Suppliers/emailLogs";
import SupplierLoad from "../pages/Suppliers/loads";
import ManagementDashboard from "../pages/Suppliers/ManagementDashboard";
import DiscountCode from "../pages/Suppliers/discountCodes";
import CvsApiLog from "../pages/Suppliers/cvsApiLogs";
import Account from "../pages/Admin/accounts";
import Order from "../pages/Suppliers/orders";
import PaymentMethod from "../pages/Suppliers/paymentMethods";

import DriverLoad from "../pages/Drivers/loads";

interface RouteProps {
    path: string;
    component: any;
    exact?: boolean;
}

const adminRoutes: Array<RouteProps> = [
    { path: "/accounts/*", component: <Account /> },
    { path: "/profile", component: <Profile /> },
];

const supplierRoutes: Array<RouteProps> = [
    //dashboard
    { path: "/dashboard", component: <Dashboard /> },
    { path: "/management/dashboard", component: <ManagementDashboard /> },
    //
    // //users
    { path: "/users/*", component: <User /> },
    { path: "/roles/*", component: <Role /> },
    { path: "/brands/*", component: <Brand /> },
    { path: "/models/*", component: <Model /> },
    { path: "/locations/*", component: <Location /> },
    { path: "/transport/vehicles/*", component: <TransportVehicle /> },
    { path: "/drivers/*", component: <Driver /> },
    { path: "/sent/webhooks/logs/*", component: <Sent /> },
    { path: "/received/webhooks/logs/*", component: <Received /> },
    { path: "/orders", component: <Order /> },
    { path: "/temporary", component: <>Loading...</> },
    { path: "/spot/loads/*", component: <SpotLoad /> },
    { path: "/routes/*", component: <Route /> },
    { path: "/profile", component: <Profile /> },
    { path: "/clients/*", component: <Client /> },
    { path: "/loads/*", component: <SupplierLoad /> },
    { path: "/sub/contractors/*", component: <SubContractor /> },
    { path: "/legal/entities/*", component: <LegalEntity /> },
    { path: "/transport/vehicles/map", component: <TransportVehicleMap /> },
    { path: "/archived/spot/loads", component: <ArchivedSpotLoad /> },

    { path: "/archived/invoices/*", component: <ArchivedInvoice /> },
    { path: "/archived/alp/spot/loads/*", component: <ArchivedAlpSpotLoad /> },
    { path: "/archived/orders/*", component: <ArchivedOrder /> },
    { path: "/prices/*", component: <Price /> },
    { path: "/contracts/*", component: <Contract /> },
    { path: "/invoices/*", component: <Invoice /> },
    { path: "/settings/email/settings", component: <EmailSetting /> },
    { path: "/transport/vehicles/moment/logs/*", component: <TransportVehicleMomentLog /> },
    { path: "/pantheon/transfer/logs", component: <PantheonTransferLog /> },
    { path: "/email/logs/*", component: <EmailLog /> },
    { path: "/discount/codes/*", component: <DiscountCode /> },
    { path: "/cvs/api/logs/*", component: <CvsApiLog /> },
    { path: "/pre/payments/*", component: <PrePayment /> },
    { path: "/settings/payment/methods/*", component: <PaymentMethod /> },
    // // this route should be at the end of all other routes
    { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
];

const authRoutes: Array<RouteProps> = [
//     //Authentication pages
    { path: "/", component: <Login /> },
    { path: "/login", component: <Login /> },
    { path: "/logout", component: <Logout /> },
    { path: "/forget/password", component: <ForgetPassword /> },
    { path: "/reset/password/*", component: <ResetPassword /> },
];

const driverRoutes: Array<RouteProps> = [
    { path: "/loads/*", component: <DriverLoad /> },
    { path: "/profile", component: <Profile /> },
];

const defaultRoutes: Array<RouteProps> = [
    { path: "/dashboard", component: <Dashboard /> },
    { path: "*", component: ( <h1>404</h1> ) },
];

export { supplierRoutes, adminRoutes, authRoutes, driverRoutes, defaultRoutes };
