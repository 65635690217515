import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { request } from "../../../utils/Request";
import UrlHelper from "js-url-helper";
import LegalEntityList from "./partials/LegalEntityList";
import qs from "qs";
import { Route, Routes } from "react-router-dom";
import { Toast } from "primereact/toast";
import { getFromStorage, setToStorage } from "../../../utils/Helper";

const DEFAULT_DROPDOWNS = {
    legalEntities: [],
    statuses: []
};

const url = new UrlHelper();

const LegalEntity = (props: any) => {
    const { t } = props;
    const toast = useRef(null);
    const [dropdowns, setDropdowns] = useState<any>(null);
    const [moduleState, setModuleState] = useState<any>({
        page: 1,
        per_page: 20,
        search: null,
        initialized: false,
        sort_field: null,
        sort_order: null,
    });

    const getDropdowns = async () => {
        try {
            const response = await request({
                url: '/suppliers/legal/entities/create',
                params: {
                    type: 'list',
                }
            });
            const { data } = response.data;
            setDropdowns({
                legalEntities: data.legalEntities,
                statuses: [{ id: false, label: t('active') }, { id: true, label: t('deactive') }],
            });
        } catch (e) {
            setDropdowns(DEFAULT_DROPDOWNS);
        } finally {
            const prevUrl = qs.parse(url.getSearchParam().prev_url ?? "");
            const fromStorage: any = getFromStorage('supplierLegalEntityModuleState');
            const defaultState = {
                page: !isEmpty(prevUrl.page) ? url.getSearchParam().page : 1,
                per_page: !isEmpty(prevUrl.per_page) ? url.getSearchParam().per_page : 20,
                search: !isEmpty(prevUrl.search) ? url.getSearchParam().search : null,
                initialized: true,
                sort_field: null,
                sort_order: null,
                ...(!isEmpty(fromStorage) ? JSON.parse(fromStorage) : {})
                // filters: !isEmpty(prevUrl.filters) ? url.getSearchParam().filters : qs.stringify({
                //     statuses: ['produced']
                // }),
            }

            setModuleState(defaultState);
            setToStorage('supplierLegalEntityModuleState', JSON.stringify(defaultState));
        }
    };

    useEffect(() => {
        document.title = t("legal entities");

        async function init() {
            await getDropdowns();
        }

        init();
    }, []);

    const toastify = (message: any, color: string = 'info', summary: any = null) => {
        if ( toast && toast.current ) {
            // @ts-ignore
            toast.current.show({ severity: color, summary: summary, detail: message });
        }
    };

    const setQuery = (value: any) => {
        setToStorage('supplierLegalEntityModuleState', JSON.stringify(value));
        setModuleState(value);
    }

    return (
        <div className={ "module-container" }>
            <Toast ref={ toast } />
            <div className="layout-content">
                { ( moduleState && moduleState.initialized ) ?
                    <React.Fragment>
                        <Routes>
                            <Route path={ "/" } element={
                                <LegalEntityList
                                    moduleState={ moduleState }
                                    dropdowns={ dropdowns }
                                    toastify={ toastify }
                                    setQuery={ setModuleState }
                                /> } />
                        </Routes>
                    </React.Fragment>
                    : <h6>{ t('loading') }</h6> }
            </div>
        </div>
    );
};

// LegalEntity.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(LegalEntity);
