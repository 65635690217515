import React, { useEffect, useRef, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { request } from "../../../../utils/Request";
import { setLastUpdate, utcDateToLocalDate } from "../../../../utils/Helper";
import { Field, Form, Formik, FormikProps } from "formik";
import * as yup from "yup";
import { groupBy, map, isEmpty, filter, includes } from 'lodash';
import moment from "moment";
import { Sidebar } from "primereact/sidebar";
import { InputField } from "../../../../components/Shared/InputField";
import { CardBody, CardHeader, Col, Row } from "react-bootstrap";
import { InputTextAreaField } from "../../../../components/Shared/InputTextAreaField";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import InputDateTime from "../../../../components/Shared/InputDateTime";
import { Divider } from "primereact/divider";
import SpotLoadStatus from "../../spotLoads/partials/SpotLoadStatus";
import { ButtonGroup } from "primereact/buttongroup";

interface IFormProps {
    t?: any | undefined;
    item: any;
    onClose: any;
    toastify: any;
}

type TInputForm = {
    id?: null | any,
    cmr?: null | string,
    comments?: null | string,
    load_orders?: any,
};

const DEFAULT_FORM_STATE: TInputForm = {
    id: null,
    cmr: null,
    comments: null,
    load_orders: [],
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({});
};

function SpotLoadStatusUpdate(props: IFormProps) {
    const { item, toastify } = props;
    const { t } = useTranslation();
    const formRef: any = useRef();
    const [entity, setEntity] = useState<any>(null);
    const [initFormState, setInitFormState] = useState<any>({ ...DEFAULT_FORM_STATE });
    const [panelState, setPanelState] = useState<boolean>(false);

    const onClose = () => {
        setPanelState(false);

        if ( props.onClose ) {
            props.onClose();
        }
    };

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        const values: any = payloads;

        try {
            const response = await request({
                method: 'POST',
                url: `/suppliers/spot/loads/order/update/status`,
                data: {
                    ...values,
                    id: item.id
                },
            });

            toastify(t("record updated", { item: t('spot load') }), "info");
            setLastUpdate(payloads.id, 'SpotLoad');
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    const getDetail = async (id: string) => {
        try {
            const response = await request({
                url: `/suppliers/spot/loads/${ id }`
            });

            const { data } = response.data;
            setEntity(data);

            const formData: TInputForm = {
                id: data.id,
                cmr: data.cmr ?? null,
                load_orders: [],
            };

            const tokens = groupBy(data.load_orders, (item: any) => item.group_token);

            map(tokens, (item: any) => {
                const status = item[0].status;
                const route = item[0].route;

                if ( status !== "pending" ) {
                    formData.load_orders.push({
                        is_checked: ( status !== "delivered" ), // if not delivered then true either false
                        status: status,
                        id: item[0].group_token,
                        prev_status: status,
                        operation_date: !isEmpty(item[0].in_transit_at)
                            ? utcDateToLocalDate(item[0].in_transit_at, 'YYYY-MM-DD HH:mm:ss')
                            : moment().format('YYYY-MM-DD HH:mm:ss'),
                        route: ( route || {} ).title,
                        vin_numbers: map(item, (i: any) => ( i.order || {} ).vin_number)
                    });
                }
            });

            if ( !isEmpty(formData.load_orders) ) {
                setInitFormState(formData);
                setPanelState(true);
            }
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        setEntity(null);
        if ( !isEmpty(props.item) ) {
            getDetail(props.item.id);
        }

        if ( panelState && isEmpty(props.item) ) {
            setPanelState(false);
        }
    }, [props.item]);

    return (
        <>
            <div>
                {
                    entity &&
                  <>

                      <Sidebar
                        header={
                            <div className="bg-body-tertiary card-header">
                                <h5 className={ "card-title mt-0" }>{ t('update') } { t('order') }{ entity && ` #${ entity.load_number }` }</h5>
                                <p>{ t("using this form you can add or update the record") }</p>
                            </div>
                        }
                        visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                        style={ { width: '800px' } } closeOnEscape={false}
                      >
                          <div style={ {
                              height: '3px',
                              background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                          } }></div>

                          <Formik
                            innerRef={ formRef }
                            enableReinitialize={ true }
                            initialValues={ initFormState }
                            onSubmit={ onSubmit }
                            validationSchema={ validationSchema(t) }
                          >
                              { (props: FormikProps<TInputForm>) => {
                                  const { values, errors }: any = props;

                                  return (
                                      <Form onSubmit={ props.handleSubmit }>
                                          <Row className={ "mt-4 mb-7" }>
                                              {
                                                  !filter(values.load_orders, (item: any) => item.status == 'announced').length &&
                                                <Col md={ 12 } sm={ 4 } lg={ 4 }>
                                                    <Field component={ InputField }
                                                           name="cmr"  autoFocus={true}
                                                           label={ t("cmr") }
                                                           placeholder={ t("enter") + ' ' + t("cmr").toLowerCase() }
                                                    />
                                                </Col>
                                              }

                                              <Col md={ 12 } sm={ 8 } lg={ 8 }>
                                                  <Field component={ InputTextAreaField }
                                                         name="comments"
                                                         label={ t('comments') }
                                                         placeholder={ t("enter") + ' ' + t("narration").toLowerCase() }
                                                  />
                                              </Col>
                                              {
                                                  ( values && values.load_orders ) &&
                                                  values.load_orders.map((row: any, index: number) => {
                                                      return (
                                                          <Col sm={ 12 } key={ index }>
                                                              <Card
                                                                  className={ "p-3 border-black-alpha-40 border-1 content-p-0 mt-2" }>
                                                                  <CardHeader className={ "p-0" }>
                                                                      <Row>
                                                                          <Col sm={ 12 } lg={ 6 }>
                                                                              <h5 className={ "mb-1" }>{ row.route }</h5>
                                                                              { ( row.prev_status !== "delivered" ) && <>
                                                                                  <span
                                                                                    className={ "me-1" }>{ t("updateable") }</span>
                                                                                  <span style={ {
                                                                                      position: "relative",
                                                                                      top: "2px"
                                                                                  } }>
                                                                                      <Field
                                                                                        type="checkbox"
                                                                                        name={ `load_orders.${ index }.is_checked` }
                                                                                        className={ "btn-xs me-2 pt-1 checkbox-position position-relative" } />
                                                                                  </span>
                                                                                  { values.load_orders[index].is_checked && t('yes') }
                                                                                  { !values.load_orders[index].is_checked && t('no') }
                                                                              </> }
                                                                          </Col>
                                                                          <Col sm={ 12 } lg={ 6 }
                                                                               className={ "text-end" }>
                                                                              <SpotLoadStatus
                                                                                  status={ row.prev_status } />
                                                                          </Col>
                                                                      </Row>
                                                                  </CardHeader>
                                                                  <Divider className={ "m-3" } />
                                                                  <CardBody>
                                                                      <Row className={ "mt-1" }>
                                                                          <Col lg={ 4 }>
                                                                              <h6 className={ "m-0 pb-1" }>{ t('vin(s)') }</h6>
                                                                              {
                                                                                  row.vin_numbers.map((item: any, index: number) => (
                                                                                      <div
                                                                                          key={ index }>{ item }</div> ))
                                                                              }
                                                                          </Col>
                                                                          {
                                                                              row.prev_status !== "delivered"
                                                                              && <>
                                                                                <Col lg={ 4 }>
                                                                                    <h6
                                                                                      className={ "m-0 pb-1" }>{ t('status') }</h6>
                                                                                    <div>
                                                                                        {
                                                                                            ( row.status == 'announced' || row.prev_status == 'announced' ) &&
                                                                                          <ButtonGroup>
                                                                                              <Button
                                                                                                className={ "p-button-x-sm" }
                                                                                                type={ "button" }
                                                                                                size="small"
                                                                                                onClick={ () => props.setFieldValue(`load_orders.${ index }.status`, "announced") }
                                                                                                outlined={ row.status !== "announced" }
                                                                                              >
                                                                                                  { t("announced") }
                                                                                              </Button>
                                                                                              <Button
                                                                                                severity={ "warning" }
                                                                                                className={ "p-button-x-sm" }
                                                                                                type={ "button" }
                                                                                                size="small"
                                                                                                onClick={ () => props.setFieldValue(`load_orders.${ index }.status`, "in-transit") }
                                                                                                outlined={ row.status !== "in-transit" }
                                                                                              >
                                                                                                  { t("in transit") }
                                                                                              </Button>
                                                                                          </ButtonGroup>
                                                                                        }
                                                                                        {
                                                                                            ( ( includes(['in-transit'], row.status)
                                                                                                    && !includes(['announced'], row.prev_status) )
                                                                                                || includes(['in-transit'], row.prev_status) ) &&
                                                                                          <ButtonGroup>
                                                                                              <Button
                                                                                                severity={ "warning" }
                                                                                                className={ "p-button-x-sm" }
                                                                                                type={ "button" }
                                                                                                size="small"
                                                                                                onClick={ () => props.setFieldValue(`load_orders.${ index }.status`, "in-transit") }
                                                                                                outlined={ row.status !== "in-transit" }
                                                                                              >
                                                                                                  { t("in transit") }
                                                                                              </Button>
                                                                                              <Button
                                                                                                className={ "p-button-x-sm" }
                                                                                                severity={ "success" }
                                                                                                size="small"
                                                                                                type={ "button" }
                                                                                                onClick={ () => props.setFieldValue(`load_orders.${ index }.status`, "delivered") }
                                                                                                outlined={ row.status !== "delivered" }
                                                                                              >
                                                                                                  { t("delivered") }
                                                                                              </Button>
                                                                                          </ButtonGroup>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                                  {
                                                                                      row.status !== 'announced' &&
                                                                                    <Col lg={ 3 }>
                                                                                        <h6
                                                                                          className={ "m-0 pb-1" }>{ t("operation date") }</h6>
                                                                                        <Field
                                                                                          withoutLabel={ true }
                                                                                          placeholder={ t("pick a date") }
                                                                                          component={ InputDateTime }
                                                                                          name={ `load_orders.${ index }.operation_date` }
                                                                                          type={ "datetime" }
                                                                                          formGroupClass={ "mb-0 pb-0" }
                                                                                          // maxDate={ moment().tz(moment.tz.guess()).toDate() }
                                                                                          enableTimeStartOfDay
                                                                                        />
                                                                                    </Col>
                                                                                  }
                                                                            </>
                                                                          }
                                                                      </Row>
                                                                  </CardBody>
                                                              </Card>
                                                          </Col>
                                                      );
                                                  })
                                              }
                                          </Row>
                                          <div className="filter-card-footer"
                                               style={ { width: '785px' } }>
                                              <Button tooltip={ t("to data save into database") }
                                                      size={ "small" } outlined
                                                      tooltipOptions={ { position: 'top' } }
                                                      disabled={props.isSubmitting}
                                              >
                                                  <i className="pi pi-save me-2" />
                                                  { t("let's save") }
                                              </Button>
                                              <Button type={ "button" }
                                                      tooltip={ t("operation cancel") } className={ "ms-2" }
                                                      size={ "small" } severity={ "danger" } outlined
                                                      tooltipOptions={ { position: 'top' } }
                                                      onClick={ onClose }
                                                      disabled={props.isSubmitting}
                                              >
                                                  <i className="pi pi-arrow-left me-2" />
                                                  { t("cancel") }
                                              </Button>
                                          </div>
                                      </Form>
                                  );
                              } }
                          </Formik>
                      </Sidebar>
                  </>
                }
            </div>
        </>
    );
}

SpotLoadStatusUpdate.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(SpotLoadStatusUpdate);
