import React, { useContext, useEffect, useRef, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import PropTypes from "prop-types";
import { useTranslation, withTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { utcDateToLocalDate } from "../../utils/Helper";
import Auth from "../../utils/Auth";
import moment from "moment/moment";
import { get, isEmpty, trim } from "lodash";
import { request } from "../../utils/Request";
import { LayoutContext } from "../../contexts/LayoutContext";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";


interface IProps {
    onClose?: any;
    visible: any;
}

const ChatWindow = (props: IProps) => {
    const { t } = useTranslation();
    const { layoutConfig } = useContext(LayoutContext);
    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [messages, setMessages] = useState<any>([{
        ownerId: null,
        text: t("greetings! how may i assist you?"),
        createdAt: utcDateToLocalDate(moment())
    }]);

    const [defaultUserId, setDefaultUserId] = useState<any>(null);
    const [textContent, setTextContent] = useState<any>("");
    const chatWindow = useRef<HTMLDivElement>(null);
    const auth: any = new Auth();

    // const defaultUserId =

    const onClose = () => {
        if ( props.onClose ) {
            props.onClose();
            setVisible(false);
        }
    };

    useEffect(() => {
        if ( props.visible ) {
            setVisible(true);
            setDefaultUserId(auth.getUser().id);
        }
    }, [props.visible]);

    const onSubmit = () => {
        if ( isEmpty(trim(textContent)) ) {
            return;
        } else {
            let message = {
                text: textContent,
                ownerId: defaultUserId,
                createdAt: utcDateToLocalDate(moment()),
            };
            setMessages((prevMessages: any) => [...prevMessages, { ...message }]);
            setTextContent("");

            setTimeout(() => {
                if ( chatWindow.current ) {
                    chatWindow.current.scrollTo({
                        top: chatWindow.current.scrollHeight,
                        behavior: 'smooth'
                    });
                }
            }, 1000);

            if ( loading ) {
                return false;
            }

            setLoading(true);
            request({
                method: 'POST',
                url: 'suppliers/chat/bot',
                data: {
                    vin: message.text
                },
            })
            .then((response: any) => {
                setMessages((prevMessages: any) => [
                    ...prevMessages,
                    {
                        text: response.data.data,
                        ownerId: null,
                        createdAt: utcDateToLocalDate(moment()),
                    }
                ]);
            })
            .catch((error: any) => {
                if ( error.status === 422 ) {
                    setMessages((prevMessages: any) => [
                        ...prevMessages,
                        {
                            text: t('incorrect input'),
                            ownerId: null,
                            createdAt: utcDateToLocalDate(moment()),
                        }
                    ]);
                }
            })
            .finally(() => {
                setLoading(false);
                setTimeout(() => {
                    if ( chatWindow.current ) {
                        chatWindow.current.scrollTo({
                            top: chatWindow.current.scrollHeight,
                            behavior: 'smooth'
                        });
                    }
                }, 1000);
            });
        }
    };

    const handleInputTextKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if ( e.key === "Enter" ) {
            onSubmit();
        }
    };

    return (
        <>
            <Dialog visible={ visible } onHide={ onClose } keepInViewport={true}
                    className={ "chat-window" } draggable={true} modal={false}
                    closeOnEscape={ false } position={"bottom-right"} resizable={true}
                     header={ <>
                         <div className="flex align-items-center">
                             <div className="relative flex align-items-center mr-3">
                                 <img
                                     src={ `/images/logo/alcolm-logo-dark.png` }
                                     alt={ "Alcolm" }
                                     height={ "50px" }
                                 />
                             </div>
                         </div>

                         <Divider className={"mb-0"}/>
                     </> }>
                <div className="flex flex-column h-full">
                    <div style={{position: 'absolute', bottom: '-2px', right: '2px'}}>
                        <i className="pi pi-arrow-down-right"></i>
                    </div>
                    <div
                        ref={ chatWindow }
                        className="p-0 md:px-4 lg:px-6 lg:py-4 mt-2 overflow-y-auto"
                        style={ { height: '40vh' } }
                    >
                        { messages.map((message: any, i: number) => {
                            return (
                                <div key={ i }>
                                    { message.ownerId === defaultUserId ? (
                                        <div className="grid grid-nogutter mb-2">
                                            <div className="col text-right">
                                                <p className="text-900 font-semibold mb-1">
                                                    { get(auth.getUser(), ['name']) }
                                                </p>
                                                <span
                                                    className="inline-block text-left font-medium border-1 surface-border bg-primary-100 text-primary-900 p-2 white-space-normal border-round text-sm"
                                                    style={ {
                                                        wordBreak: "break-word",
                                                        maxWidth: "80%",
                                                    } }
                                                >
                                                    <div dangerouslySetInnerHTML={ { __html: message.text } } />
                                                </span>
                                                <p className="text-700 mt-1 text-sm">
                                                    { utcDateToLocalDate(message.createdAt) }{ " " }
                                                    <i className="pi pi-check ml-2 text-green-400"></i>
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="grid grid-nogutter mb-2">
                                            <div className="col">
                                                <p className="text-900 font-semibold mb-1">
                                                    Alcolm Bot
                                                </p>
                                                <span
                                                    className="text-700 inline-block font-medium border-1 surface-border p-2 white-space-normal border-round text-sm"
                                                    style={ {
                                                        wordBreak: "break-word",
                                                        maxWidth: "80%",
                                                    } }
                                                >
                                                <div dangerouslySetInnerHTML={ { __html: message.text } } />
                                            </span>
                                                <p className="text-700 mt-1 text-sm">
                                                    { utcDateToLocalDate(message.createdAt) }
                                                    <i className="pi pi-check ml-2 text-green-400"></i>
                                                </p>
                                            </div>
                                        </div>
                                    ) }
                                </div>
                            );
                        }) }
                    </div>
                    <div
                        className="md:p-5 sm:p-5 pb-2 flex sm:flex-row align-items-center mt-auto gap-3 border-top-1 surface-border">
                        <InputText
                            id="message"
                            type="text"
                            placeholder={t('feel free to type your inquiry here')}
                            className="flex-1 w-full sm:w-auto sm:block border-round"
                            value={ textContent }
                            onChange={ (e) => setTextContent(e.target.value) }
                            onKeyDown={ handleInputTextKeyDown }
                            disabled={ loading }
                        />
                        <div className="flex sm:block sm:mt-0 gap-3">
                            <Button
                                onClick={onSubmit}
                                label={t("send")}
                                icon="pi pi-send"
                                className="w-full sm:w-auto"
                            ></Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

ChatWindow.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(ChatWindow);
