import React from 'react';
import { isUndefined } from "lodash";

const LazyImage = ({ src, alt, className, width, height, lazyLoading  }: any) => {
    const [imageSrc, setImageSrc] = React.useState<any>(null);
    const imgRef: any = React.useRef();

    React.useEffect(() => {
        if(lazyLoading !== false && !isUndefined(lazyLoading)) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if ( entry.isIntersecting ) {
                        setImageSrc(src);
                        observer.unobserve(imgRef.current);
                    }
                });
            });

            observer.observe(imgRef.current);

            return () => {
                if ( imgRef.current ) {
                    observer.unobserve(imgRef.current);
                }
            };
        }
    }, [src]);

    return ((lazyLoading !== false && !isUndefined(lazyLoading)) ? <img
        ref={ imgRef }
        src={ imageSrc }
        alt={ alt }
        loading="lazy"
        className={ className }
        width={width}
        height={height}
    /> : <img
        src={ src }
        alt={ alt }
        className={ className }
        width={width}
        height={height} />);
};

export default LazyImage;
