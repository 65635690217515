import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Form, Field } from "formik";
import { Col, Row } from "react-bootstrap";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { capitalize, find, get, includes, indexOf, isEmpty, map, pickBy, sortBy, uniq } from "lodash";
import { InputCheckboxField } from "../../../../components/Shared/InputCheckboxField";
import * as XLSX from "xlsx";
import qs from "qs";
import { request } from "../../../../utils/Request";
import { getOrderStatus } from "../../../../components/Common/Display/OrderStatus";
import Location, { getLocation } from "../../../../components/Common/Display/Location";
import { customSplitter, giveUniqueColoredString, groupIdsByValue, utcDateToLocalDate } from "../../../../utils/Helper";
import { CircleComponent, ColumnRenderForDriver, getColor } from "./Column";
import AlpSpotLoadStatus from "../../alpSpotLoads/partials/AlpSpotLoadStatus";
import { Badge } from "primereact/badge";
import SpotLoadStatus from "../../spotLoads/partials/SpotLoadStatus";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    dropdowns: any;
    visibility: any;
    toastify: any;
    onClose?: any;
}

type TInputForm = {
    consider_all_pages: boolean,
    load_number: boolean,
    driver: boolean,
    cmr: boolean,
    legal_entity: boolean,
    ldg_nr: boolean,
    client: boolean,
    model: boolean,
    transport_vehicle: boolean,
    location: boolean,
    vins: boolean,
    qty: boolean,
    status: boolean,
    invoiced_at: boolean,
    invoice: boolean,
    loading: boolean,
    unloading: boolean,
};

const DEFAULT_FORM_STATE = {
    consider_all_pages: true,
    load_number: true,
    driver: true,
    cmr: true,
    legal_entity: true,
    ldg_nr: true,
    client: true,
    model: true,
    transport_vehicle: true,
    location: true,
    vins: true,
    qty: true,
    status: true,
    invoiced_at: true,
    invoice: true,
    loading: true,
    unloading: true,
};

// @ts-ignore
function LoadExcel(props: IFormProps) {
    const { t, visibility, dropdowns } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<any>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);

    const driverRender = (value: any) => {
        const idsOrder = uniq(value.seq.split("||"));

        return groupIdsByValue(
            sortBy(
                customSplitter(
                    ( get(value, ['driver_name']) || "" )
                    .split("||"),
                    '___',
                    false
                )
                , (item: any) => indexOf(idsOrder, item.id)
            )
        )
        .map((i: any)=> (!isEmpty(i.value) ? i.value : ""))
        .join("\n");
    }

    const legalEntityRender = (props: any) => {
        const { row, dropdowns }: any = props;
        const idsOrder = uniq(row.seq.split("||"));

        if ( row.type === "AlpSpotLoad" ) {
            const fromLocation = find(dropdowns.legalEntities, { id: get(row, ['legal_entity']) });

            return get(fromLocation, ['label']);
        }

        return sortBy(
            // split record by ||
            customSplitter(
                (get(row, ['legal_entity']) || "")
                .split("||")
            )
            , (item: any) => indexOf(idsOrder, item.id))
            .map((i: any, index: number) => {
                const locations = i.value.split("~~~");
                const fromLocation = find(dropdowns.legalEntities, {id: get(locations, [1])})

                return get(fromLocation, ['label']);
            })
            .join("\n");
    }

    const clientRender = (props: any) => {
        const { row, dropdowns }: any = props;
        const idsOrder = uniq(row.seq.split("||"));

        if ( row.type === "AlpSpotLoad" ) {
            const clients = get(( get(row, ['client_name']) || "" ).split("||"), ['0']);
            const client = get(clients.split("___"), [1]);

            return get(find(dropdowns.clients, { id: client }), ['name']);
        }

        return groupIdsByValue(
            sortBy(
                customSplitter(
                    ( get(row, ['client_name']) || "" )
                    .split("||"),
                    '___',
                    false
                )
                , (item: any) => indexOf(idsOrder, item.id))
        )
        .map((i: any)=> (!isEmpty(i.value) ? i.value : ""))
        .join("\n");
    };

    const brandModelRender = (props: any) => {
        const { row, dropdowns }: any = props;
        const idsOrder = uniq(row.seq.split("||"));

        if ( row.type === "AlpSpotLoad" ) {
            return groupIdsByValue(
                sortBy(
                    customSplitter(
                        ( get(row, ['brand_model']) || "" )
                        .split("||"),
                        '___',
                        false
                    )
                    , (item: any) => indexOf(idsOrder, item.id))
            )
            .map((i: any) => {
                const model = find(dropdowns.models, { id: i.value });
                if ( isEmpty(i.value) || isEmpty(model) ) {
                    return '';
                }

                return get(model, ['label']);
            });
        }

        return groupIdsByValue(
            sortBy(
                customSplitter(
                    ( get(row, ['vin_number']) || "" )
                    .split("||"),
                    '___',
                    false
                )
                , (item: any) => indexOf(idsOrder, item.id))
        )
        .map((i: any, index: number) => {
            if ( isEmpty(i.value) ) {
                return '';
            }

            const data = find(groupIdsByValue(
                customSplitter(
                    ( get(row, ['brand_model']) || "" )
                    .split("||"),
                    '___',
                    false
                )
            ), (item) => includes(item.ids, i.value));

            const model = find(dropdowns.models, { id: get(data, ['value']) });

            return get(model, ['label']);
        })
        .join("\n");
    }

    const transportVehicleRender = (props: any) => {
        const { row }: any = props;
        const idsOrder = uniq(row.seq.split("||"));
        return groupIdsByValue(
            sortBy(
                customSplitter(
                    ( get(row, ['transport_vehicle']) || "" )
                    .split("||"),
                    '___',
                    false
                )
                , (item: any) => indexOf(idsOrder, item.id))
        )
        .map((i: any, index: number) => {
            if ( isEmpty(i.value) ) {
                return '';
            }

            return i.value.replaceAll("~~~", " | ");
        }) ;
    }

    const locationRender = (props: any) => {
        const { row, dropdowns }: any = props;
        const idsOrder = uniq(row.seq.split("||"));

        return sortBy(
            // split record by ||
            customSplitter(
                ( get(row, ['locations']) || "" )
                .split("||")
            )
            , (item: any) => indexOf(idsOrder, item.id))
        .map((i: any) => {
            // split from and to location by ~~~
            const locations = i.value.split("~~~");
            const fromLocation = find(dropdowns.locations, { id: get(locations, [0]) });
            const toLocation = find(dropdowns.locations, { id: get(locations, [1]) });
            return `${fromLocation.label} - ${toLocation.label}`;
        });
    }

    const vinRender = (props: any) => {
        const { row }: any = props;
        const idsOrder = uniq(row.seq.split("||"));

        return groupIdsByValue(
            sortBy(
                customSplitter(
                    ( get(row, ['vin_number']) || "" )
                    .split("||"),
                    '___',
                    false
                )
                , (item: any) => indexOf(idsOrder, item.id))
        )
        .map((i: any)=> (!isEmpty(i.value) ? i.value : ""))
        .join("\n");
    }

    const statusRender = (props: any) => {
        const { row }: any = props;

        if ( row.type === "AlpSpotLoad" ) {
            return (row.status === "accepted" || row.status === "confirmed" ? 'confirmed' : row.status);
        }

        if ( row.type === "SpotLoad" ) {
            const idsOrder = uniq(row.seq.split("||"));

            return  groupIdsByValue(
                sortBy(
                    customSplitter(
                        ( get(row, ['item_status']) || "" )
                        .split("||"),
                        '___',
                        false
                    )
                    , (item: any) => indexOf(idsOrder, item.id))
            )
            .map((i: any)=> (!isEmpty(i.value) ? i.value : ""))
            .join("\n");
        }

        return "";
    }

    const invoicedAtRender = (props: any)  => {
        const { row }: any = props;

        if ( row.type === "AlpSpotLoad" ) {
            return utcDateToLocalDate(row.invoiced_at);
        }

        const idsOrder = uniq(row.seq.split("||"));

        return groupIdsByValue(
            sortBy(
                customSplitter(
                    ( get(row, ['invoiced_at']) || "" )
                    .split("||"),
                    '___',
                    false
                )
                , (item: any) => indexOf(idsOrder, item.id))
        )
        .map((i: any) => (!isEmpty(i.value) ? utcDateToLocalDate(i.value) : ""))
        .join("\n");
    }

    const invoiceNumberRender = (props: any)  => {
        const { row }: any = props;

        if ( row.type === "AlpSpotLoad" ) {
            return row.invoice_number;
        }

        if ( row.type === "SpotLoad" ) {
            const idsOrder = uniq(row.seq.split("||"));

            return groupIdsByValue(
                sortBy(
                    customSplitter(
                        ( get(row, ['invoice_number']) || "" )
                        .split("||"),
                        '___',
                        false
                    )
                    , (item: any) => indexOf(idsOrder, item.id))
            )
            .map((i: any) => (!isEmpty(i.value) ? i.value : ""))
            .join("\n");
        }

        return "";
    }

    const inTransitAtRender = (props: any) => {
        const { row }: any = props;

        if ( row.type === "AlpSpotLoad" ) {
            return utcDateToLocalDate(row.actual_loading_time);
        }

        const idsOrder = uniq(row.seq.split("||"));

        return sortBy(
            customSplitter(
                ( get(row, ['in_transit_at']) || "" )
                .split("||")
            )
        , (item: any) => indexOf(idsOrder, item.id))
        .map((i: any, index: number) => (!isEmpty(i.value) ? utcDateToLocalDate(i.value) : ""))
        .join("\n");
    }

    const deliveredAtRender = (props: any) => {
        const { row }: any = props;

        if ( row.type === "AlpSpotLoad" ) {
            return utcDateToLocalDate(row.actual_unloading_time);
        }

        const idsOrder = uniq(row.seq.split("||"));

        return sortBy(
            customSplitter(
                ( get(row, ['delivered_at']) || "" )
                .split("||")
            )
            , (item: any) => indexOf(idsOrder, item.id))
        .map((i: any, index: number) => ( !isEmpty(i.value) ? utcDateToLocalDate(i.value) : "" ))
        .join("\n");
    }

    const onSubmit = async (payloads: TInputForm): Promise<void> => {
        const _params: any = props.moduleState;
        const _payloads: any = payloads;
        _params.excel = 1;

        if ( payloads.consider_all_pages ) {
            delete _params.page;
            delete _params.per_page;
        }

        try {
            const response = await request({
                url: `/suppliers/loads`,
                params: _params,
                paramsSerializer: {
                    serialize: (params: any) => qs.stringify(params)
                },
            });

            delete _payloads.consider_all_pages;
            const columns = pickBy(_payloads, (item: boolean) => ( item ));
            const title: any = getTitles();
            const data: any = [];
            map(response.data.data, (value: any) => {
                const { load_number, load_type } = value;

                const row: any = {
                    load_number: `${load_number} \n
                        ${(load_type === 'compound' ? t("deliver to: compound") :  load_type === 'dealer' ? t("deliver to: dealer") : "")} 
                    `,
                    driver: driverRender(value),
                    cmr: value.cmr,
                    legal_entity: legalEntityRender({ row: value, dropdowns }),
                    ldg_nr: customSplitter(( get(value, ['ldg_nr']) || "" ).split("||")).map((i: any) => i.value).join(', '),
                    client: clientRender({ row: value, dropdowns }),
                    model: brandModelRender({ row: value, dropdowns }),
                    transport_vehicle: transportVehicleRender({ row: value, dropdowns }),
                    location: locationRender({ row: value, dropdowns }),
                    vinRender: vinRender({ row: value, dropdowns }),
                    qty: value.qty,
                    status: statusRender({ row: value, dropdowns }),
                    invoiced_at: invoicedAtRender({ row: value, dropdowns }),
                    invoice_number: invoiceNumberRender({ row: value, dropdowns }),
                    loading: inTransitAtRender({ row: value, dropdowns }),
                    unloading: deliveredAtRender({ row: value, dropdowns })
                };

                const _row: any = {};
                map(columns, (_, key: string) => ( _row[key] = row[key] ));
                const arrayOfRow = Object.keys(_row).map((key) => _row[key]);
                data.push(arrayOfRow);
            });

            const _title = map(columns, (_, key: string) => ( title[key] = title[key] ));
            const excel = XLSX.utils.book_new();
            const sheet = XLSX.utils.aoa_to_sheet([_title, ...data]);
            XLSX.utils.book_append_sheet(excel, sheet, t('loads'));
            XLSX.writeFile(excel, "loads.xlsx");
        } catch (e: any) {
            props.toastify(t("server error"), "error");
        }
    };

    const onReset = () => {
        const base: any = formRef.current;
        if ( base ) {
            base.resetForm();
            setInitFormState({
                ...DEFAULT_FORM_STATE
            });
        }

        props.setQuery({
            ...props.moduleState,
        });
    };

    const onClose = () => {
        setPanelState(false);

        if ( props.onClose ) {
            props.onClose();
        }
    };

    const getTitles = () => {
        return {
            load_number: t('load number'),
            driver: t('driver'),
            cmr: t('cmr'),
            legal_entity: t('legal entity'),
            ldg_nr: t('ldg nr'),
            client: t('client'),
            brand: t('brand'),
            model: t('model'),
            transport_vehicle: t('transport vehicle'),
            location: t('location'),
            vins: t('vins'),
            qty: t('qty'),
            status: t('status'),
            invoiced_at: t('invoiced at'),
            invoice: t('invoice'),
            loading: t('loading'),
            unloading: t('unloading'),
        };
    };

    useEffect(() => {
        if ( visibility ) {
            setPanelState(visibility);
            setInitFormState({ ...DEFAULT_FORM_STATE, ...props.moduleState.filters ?? [] });
        }
    }, [visibility]);


    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t("export") } { t("loads") }</h5>
                            <p>
                                { t("select columns to be exported.") }
                            </p>
                        </div>
                    }
                    visible={ panelState } position="left" onHide={ onClose } blockScroll={ true }
                    style={ { width: '400px' } } closeOnEscape={ false }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4 md:mb-7 sm:mb-7" }>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="consider_all_pages"
                                                component={ InputCheckboxField }
                                                label={ t("consider all pages") }
                                            />
                                        </Col>

                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="load_number"
                                                component={ InputCheckboxField }
                                                label={ t("load number") }
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="driver"
                                                component={ InputCheckboxField }
                                                label={ t("driver") }
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="cmr"
                                                component={ InputCheckboxField }
                                                label={ t("cmr") }
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="legal_entity"
                                                component={ InputCheckboxField }
                                                label={ t("legal entity") }
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="ldg_nr"
                                                component={ InputCheckboxField }
                                                label={ t("ldg nr") }
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="client"
                                                component={ InputCheckboxField }
                                                label={ t("client") }
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="model"
                                                component={ InputCheckboxField }
                                                label={ t("model(s)") }
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="transport_vehicle"
                                                component={ InputCheckboxField }
                                                label={ t("transport vehicle") }
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="location"
                                                component={ InputCheckboxField }
                                                label={ t("location") }
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="vins"
                                                component={ InputCheckboxField }
                                                label={ t("vin(s)") }
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="qty"
                                                component={ InputCheckboxField }
                                                label={ t("qty") }
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="status"
                                                component={ InputCheckboxField }
                                                label={ t("status") }
                                            />
                                        </Col>

                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="invoiced_at"
                                                component={ InputCheckboxField }
                                                label={ t("invoiced at") }
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="invoice"
                                                component={ InputCheckboxField }
                                                label={ t("invoice") }
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="loading"
                                                component={ InputCheckboxField }
                                                label={ t("loading") }
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="unloading"
                                                component={ InputCheckboxField }
                                                label={ t("unloading") }
                                            />
                                        </Col>
                                    </Row>
                                    <div className="filter filter-card-footer"
                                         style={ { width: '385px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("apply filter") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-filter me-2" />
                                            { t("filter") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ onReset }
                                                tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                size={ "small" } severity={ "warning" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-refresh me-2" />
                                            { t("reset") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ onClose }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("close") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

LoadExcel.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(LoadExcel);
