import React, { useEffect } from "react";

//import Breadcrumbs
import config from "../../config";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import WithRouter from "../../components/Common/WithRouter";
import Auth from "../../utils/Auth";
import { useNavigate } from "react-router-dom";
import SupplierDashboard from './Supplier';
import DriverDashboard from "./Driver";
import { get } from "lodash";

const Dashboard = (props: any) => {
    const { t } = props;
    const auth = new Auth();
    const navigate = useNavigate();
    document.title = `${ t("dashboard") } | ${ config.appNameForTitle }`;

    useEffect(() => {
        if ( auth.getToken() === "undefined" || !auth.getUser() ) {
            navigate('/login');
        }
    }, []);

    return (
        <div className="page-content">
            {
                ( auth && !auth.hasRole('SystemAdmin') && auth.hasAnyAccess(['operationaldashboardview']) )
                && <SupplierDashboard />
            }

            {
                ( auth && auth.hasRole('SystemDriver') ) &&
                <DriverDashboard />
            }

            {
                ( auth && get(auth.getUser(), ['level']) == 20) &&
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-0 font-size-18">{ t("welcome") }</h4>
                    </div>
                </div>
            }
        </div>
    );
};

Dashboard.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(WithRouter(Dashboard));
