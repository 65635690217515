import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Field, Form } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Col, Row } from "react-bootstrap";
import { InputField } from "../../../../components/Shared/InputField";
import { Sidebar } from "primereact/sidebar";
import { get, includes } from "lodash";
import { Button } from "primereact/button";
import InputDateTime from "../../../../components/Shared/InputDateTime";
import { InputSingleSelectField } from "../../../../components/Shared/InputSingleSelectField";
import { InputChip } from "../../../../components/Shared/InputChip";
import { InputQuillEditor } from "../../../../components/Shared/InputQuillEditor";
import moment from "moment-timezone";
import { random } from "../../../../utils/Helper";
import ContractDocument from "./ContractDocument";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    dropdowns: any;
    params: any;
    toastify: any;
    onClose?: any;
    operationCancel?: any;
}

type TInputForm = {
    id: null | any,
    objectable_id: null | any,
    objectable_type: null | any,
    email: null | any,
    additional_notes: null | any,
    title: null | any,
    start_date: null | any,
    end_date: null | any,
    reminder_at: null | any,
    documents: any,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        title: yup.string().required(t('the field is required.', { field: t('title') })).nullable(),
        objectable_id: yup.string().required(t('the field is required.', { field: '' })).nullable(),
        start_date: yup.string().required(t('the field is required.', { field: t('start date') })).nullable(),
        end_date: yup.string().required(t('the field is required.', { field: t('end date') })).nullable(),
        reminder_at: yup.string().required(t('the field is required.', { field: t('reminder at') })).nullable(),
        email: yup.array().min(1, t('the field is required.', { field: t('email') })).nullable(),
    });
};

const DEFAULT_FORM_STATE = {
    id: null,
    objectable_id: null,
    objectable_type: "SubContractor",
    email: [],
    additional_notes: null,
    title: null,
    start_date: moment().format('YYYY-MM-DD HH:mm:ss'),
    end_date: null,
    reminder_at: null,
    documents: [],
};

function ContractForm(props: IFormProps) {
    const { t, toastify, params, dropdowns, operationCancel } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);
    const [entity, setEntity] = useState<any>({});

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: ( !payloads.id ? 'POST' : 'PATCH' ),
                url: ( !payloads.id ? `/suppliers/contracts` : `/suppliers/contracts/${ payloads.id }` ),
                data: {
                    ...payloads,
                },
            });

            if ( payloads.id ) {
                toastify(t("record updated", { item: t('contract') }), "success");
            } else {
                toastify(t("record added", { item: t('contract') }), "info");
            }

            resetForm();
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");

            }
        }
    };

    const onReset = () => {
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm(
                !includes(['edit'], get(params, ['operation']))
            );
        }
    };

    const onClose = () => {
        setPanelState(false);
        onReset();

        if ( props.onClose ) {
            props.onClose();
        }
    };

    const onEdit = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/contracts/${ id }`
            });

            const { data } = response.data;

            const formData: TInputForm = {
                id: data.id,
                objectable_id: get(data, ['sub_contractor', 'id']),
                objectable_type: "SubContractor",
                title: data.title,
                email: data.email.split(',') ?? [],
                additional_notes: get(data, ['additional_notes']),
                start_date: data.start_date,
                end_date: data.end_date,
                reminder_at: data.reminder_at,
                documents: data.documents ? data.documents.map((item: any) => ( {
                    ...item,
                    document: item.id,
                    token: random(10)
                } )) : []
            };
            setInitFormState(formData);
            setPanelState(true);
            setEntity(data);
        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };

    const onUpdateDocument = (payloads: any) => {
        const base: any = get(formRef.current, ['values']);
        setInitFormState({ ...base, documents: payloads });
    };

    useEffect(() => {
        // setInitFormState(DEFAULT_FORM_STATE);
        setEntity(null);

        if ( includes(['add'], get(params, ['operation'])) ) {
            setPanelState(true);
        }

        if ( includes(['edit'], get(params, ['operation'])) ) {
            onEdit(params.operationId);
        }
    }, [params]);

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('contract') + ' ' + t('action') }</h5>
                            <p>{ t("using this form you can add or update the record") }</p>
                        </div>
                    }
                    visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } } closeOnEscape={ false }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                        validationSchema={ validationSchema(t) }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            const { values, errors, setFieldValue }: any = props;

                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4 md:mb-7 sm:mb-7" }>
                                        <Col sm={ 12 }>
                                            <Field component={ InputField }
                                                   name="title"
                                                   label={ t("title") }
                                                   placeholder={ t("enter") + ' ' + t("title").toLowerCase() }
                                                   isRequired
                                                   autoFocus />
                                        </Col>
                                        <Col sm={ 6 }>
                                            <Field
                                                placeholder={ t("pick a date") }
                                                component={ InputDateTime }
                                                name={ `start_date` }
                                                type={ "date" }
                                                label={ t("start date") }
                                                isRequired
                                                isClearable
                                            />
                                        </Col>
                                        <Col sm={ 6 }>
                                            <Field
                                                placeholder={ t("pick a date") }
                                                component={ InputDateTime }
                                                name={ `end_date` }
                                                type={ "date" }
                                                label={ t("end date") }
                                                isRequired
                                                isClearable
                                            />
                                        </Col>
                                        <Col sm={ 6 }>
                                            <Field
                                                component={ InputSingleSelectField }
                                                name={ 'objectable_id' }
                                                panelClassName={ "hide-p-toggler" }
                                                options={ dropdowns.subContractors }
                                                label={ t('sub contractor') }
                                                placeholder={ t("pick") + ' ' + t("sub contractor") }
                                                isRequired
                                            />
                                        </Col>
                                        <Col sm={ 6 }>
                                            <Field
                                                placeholder={ t("pick a date") }
                                                component={ InputDateTime }
                                                name={ `reminder_at` }
                                                type={ "date" }
                                                label={ t("Reminder date") }
                                                isRequired
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field component={ InputChip }
                                                   isEmail={ true }
                                                   name={ 'email' }
                                                   placeholder={ t("type something and press tab...") }
                                                   formText={ t("any invalid emails may not be processed") }
                                                   label={ t('email addresses') + ' (' + t('reminder recipient') + ') ' }
                                                   rounded
                                                   isRequired>
                                            </Field>
                                        </Col>
                                        <Col sm={ 12 } className={"mt-2"}>
                                            <Field
                                                name="additional_notes"
                                                component={ InputQuillEditor }
                                                label={ t("additional notes") }
                                                height={ 100 }
                                            />
                                        </Col>
                                        <Col sm={ 12 } className={"mt-3"}>
                                            <ContractDocument
                                                toastify={ toastify }
                                                onUpdate={ onUpdateDocument }
                                                documents={ values.documents }
                                            />
                                        </Col>
                                    </Row>
                                    <div className="filter-card-footer"
                                         style={ { width: '785px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-save me-2" />
                                            { t("let's save") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ onReset }
                                                tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                size={ "small" } severity={ "warning" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-refresh me-2" />
                                            { t("reset") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                                onClick={() => operationCancel(null, null)}
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("cancel") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

ContractForm.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(ContractForm);
