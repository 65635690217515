import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { get, includes } from "lodash";
import { request } from "../../../../utils/Request";

const TransportVehicleDetail = (props: any) => {
    const { t, toastify, params } = props;
    const [entity, setEntity] = useState<any>(null);

    const getDetail = async (id: string) => {
        try {
            const response = await request({
                url: `/suppliers/transport/vehicles/${ id }`
            });

            const { data } = response.data;
            setEntity(data);
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        if ( includes(['detail'], get(params, ['operation'])) ) {
            getDetail(params.operationId);
        }
    }, [params]);

    return (
        <div className={ "p-4 bg-white" }>
            {
                entity &&
              <>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('mode of transport') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ entity.mode_of_transport }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('type') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ entity.type }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('legal entity') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity, ['truck_owner', 'company_name']) }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('truck plate number') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity, ['truck_plate_number']) }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('sub contractor') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity, ['sub_contractor', 'name']) }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span
                                className="text-capitalize">{ t("truck capacity") + ' (' + t("in kilograms") + ')' }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity, ['capacity']) }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span
                                className="text-capitalize">{ t("trailer capacity") + ' (' + t("in kilograms") + ')' }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity, ['trailer_capacity']) }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t("max load capacity") }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity, ['max_load_capacity']) }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t("number of axles") }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity, ['number_of_axles']) }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t("year of production") }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity, ['year_of_production']) }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t("brand") }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity, ['brand']) }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t("euro norm") }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity, ['euro_norm']) }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t("model") }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity, ['model']) }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t("vehicle engine type") }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity, ['vehicle_engine_type']) }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t("fuel consumption") }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity, ['fuel_consumption']) }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t("cvs key") }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity, ['vehicle_ext_key']) }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t("status") }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity, ['is_active']) ? t("yes") : t("no") }</div>
                      </Col>
                  </Row>
              </>
            }
        </div>
    );
};

TransportVehicleDetail.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(TransportVehicleDetail));
