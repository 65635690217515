import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Col, Row } from "react-bootstrap";
import { request } from "../../../../utils/Request";
import { isEmpty, map, uniq, get, find } from "lodash";
import moment from "moment/moment";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputSelectField } from "../../../../components/Shared/InputSelectField";
import InputDateTime from "../../../../components/Shared/InputDateTime";
import { Divider } from "primereact/divider";
import { dateToUtcDate, random, utcDateToLocalDate } from "../../../../utils/Helper";
import { InputField } from "../../../../components/Shared/InputField";
import VinWithType from "../../../../components/Common/Display/VinWithType";

interface IAllInvoiceableListProps {
    t?: any | undefined;
    visibility: boolean;
    toastify: any;
    onClose: any;
}

const DEFAULT_FILTER_FORM_STATE = {
    clients: [],
    vinNumbers: null,
    toInvoiceableDate: null,
    fromInvoiceableDate: null,
};

const DEFAULT_DROPDOWNS = {
    clients: [],
    vinNumbers: null,
    routes: [],
};

// @ts-ignore
function AllInvoiceableList(props: IAllInvoiceableListProps) {
    const formRef: any = useRef();
    const { t, toastify } = props;
    const [isPanelActive, setPanelActive] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [dropdowns, setDropdowns] = useState<any>({ ...DEFAULT_DROPDOWNS });
    const [isOpen, setIsOpen] = useState(false);
    const toggleFilter = () => setIsOpen(!isOpen);
    const [filterFormState, setInitFilterFormState] = useState<any>({
        ...DEFAULT_FILTER_FORM_STATE,
        fromInvoiceableDate: moment().startOf('month'),
        toInvoiceableDate: moment().endOf('month'),
    });

    const onCloseDrawer = () => {
        setPanelActive(false);
        props.onClose();
    };

    const onSelectionChange = ({ value }: any) => {
        setSelectedRow(value);
    };

    const getDropdowns = async () => {
        try {
            const response = await request({
                url: '/suppliers/invoices/create',
                params: {
                    type: 'ready',
                    sub_type: 'suppliers'
                }
            });

            const { data } = response.data;

            setDropdowns({
                clients: data.clients,
                routes: data.routes,
                loadNumbers: data.loadNumbers.map((load: any) => ({key: load.guid, id: load.guid, label: load.load_number }))
            });

            await getList();
        } catch (e) {
            setDropdowns(DEFAULT_DROPDOWNS);
        }
    };

    const getList = async () => {
        const base: any = get(formRef.current, ['values']);
        setData([]);

        request({
            url: `/suppliers/invoices/ready/to/invoice`,
            params: {
                filters: {
                    ...base,
                    fromInvoiceableDate: base.fromInvoiceableDate
                        ? dateToUtcDate(moment(base.fromInvoiceableDate).startOf('month'), 'YYYY-MM-DD[T]HH:mm:ss', 'YYYY-MM-DD HH:mm:ss')
                        : null,

                    toInvoiceableDate: base.toInvoiceableDate
                        ? dateToUtcDate(moment(base.toInvoiceableDate).endOf('month'), 'YYYY-MM-DD[T]HH:mm:ss', 'YYYY-MM-DD HH:mm:ss')
                        : null,
                },
            }
        })
        .then((response: any) => {
            if ( !isEmpty(response.data.data) ) {

                const grouped: any[] = [];
                map(response.data.data, (item: any) => {
                    // client id , load id, ((orderid * load order group token) ~ (orderid * load order group token))
                    const group_token_vins = item.orders.map((order: any) => `${order.id}*${order.group_token}`).join("~")

                    grouped.push({
                        group_token: ( item.load_type === "SpotLoad" )
                            ? `${ item.client_id },${ item.load_id },${ group_token_vins }`
                            : `${item.load_id}, ${item.load_id}, ${item.load_id}*${item.load_id}`
                        ,
                        invoiceable_at: item.invoiceable_at,
                        group_type: item.load_type,
                        id: `${ item.client_id },${ item.load_id },${ group_token_vins }`,
                        price_type: item.price_type,
                        load_type: item.load_type,
                        route: item.route_detailed,
                        route_invoice_title: item.route_invoice_title,
                        legal_entity_title: item.legal_entity_title,
                        legal_entity_id: item.legal_entity_id,
                        client: item.client,
                        load_number: item.load_number,
                        client_id: item.client_id,
                        total: item.total,
                        orders: item.orders,
                        routes: item.routes,
                        from_addresses: uniq(item.from_locations.map((location: any) => `${location.street_line_1} ${location.zip} ${location.city} ${location.sort_name}`)),
                        to_addresses: uniq(item.to_locations.map((location: any) => `${location.street_line_1} ${location.zip} ${location.city} ${location.sort_name}`)),
                        ldg_nr: uniq(item.orders.map((order: any) => order.ldg_nr)),
                        vin_number: uniq(item.orders.map((order: any) => order.vin_number)),
                        brand: uniq(item.orders.map((order: any) => order.brand)).join(", "),
                        model: uniq(item.orders.map((order: any) => order.model_title)).join(", "),
                    });
                });

                setData(grouped);
                setTimeout(() => setSelectedRow([]), 700);
            }
        })
        .catch((e: any) => {
            toastify(t("server error"), "error");
        });
    }

    const onClose = () => {
        setPanelActive(false);
        props.onClose();
    };

    const onSubmit = async () => {
        const invoices: any[] = [];
        map(selectedRow, (invoiceable: any) => {
            const invoice_setting = get(find(dropdowns.clients, {id: invoiceable.client_id}), ['invoice_settings']);
            const emails = get(find(invoice_setting, {title: 'invoice_email_addresses'}), ['value']) ?? "";
            const type = get(find(invoice_setting, {title: 'invoice_recipient_type'}), ['value']) ?? "eu";
            const service_fee = get(find(invoice_setting, {title: 'invoice_service_fee'}), ['value']) ?? 0;
            const price = Number(invoiceable.total).toFixed(2);
            const serviceTotal = ( service_fee > 0 ) ? Number(( Number(invoiceable.total) * Number(service_fee) ) / 100).toFixed(2) : 0;
            const vatTotal = type === "ino" ? Number(( ( Number(invoiceable.total) + Number(serviceTotal) ) * 25 ) / 100).toFixed(2) : 0;
            const grandTotal = ( Number(price) + Number(serviceTotal) + Number(vatTotal) ).toFixed(2);
            const lgd_numbers: any = uniq(invoiceable.orders.map((order: any) => order.ldg_nr)).join(', ');
            const vin_numbers: any = uniq(invoiceable.orders.map((order: any) => order.vin_number)).join(', ');
            let title: string = "";

            if ( !isEmpty(lgd_numbers) ) {
                title = `${ t("ldg nr") } ${ lgd_numbers } \n`;
            }

            title = `${title}${ invoiceable.load_number }# ${ invoiceable.route_invoice_title } \n ${vin_numbers}`;
            const row = {
                id: null,
                status: 'draft',
                receiver_id: invoiceable.client_id,
                recipient_type: type,
                currency: 'EUR',
                service_fee: service_fee,
                email: emails ? emails.split(',') : [],
                footer_description: null,
                header_description: null,
                finalize_date: moment().format('YYYY-MM-DD HH:mm:ss'),
                legal_entity_id: invoiceable.legal_entity_id,
                discount_code_id: null,
                bank_account_number: null,
                is_vatable: type === "ino",
                pre_payment_total: 0,
                pre_payments: invoiceable.pre_payments,
                items: [{
                    id: null,
                    token: random(15),
                    title: title,
                    price: Number(invoiceable.total),
                    qty: Number(1),
                    is_active_service_fee: (service_fee > 0),
                    total: Number(grandTotal),
                    vat: type === "ino" ? 25 : 0,
                    discount: 0,
                    discount_percentage: 0,
                    vat_total: vatTotal,
                    service_fee_amount: serviceTotal,
                    description: null,
                    group_type: invoiceable.load_type,
                    group_token: invoiceable.group_token,
                    price_type: invoiceable.load_type,
                    load_type: invoiceable.load_type,
                    discount_type: 'type_value',
                    is_vatable: type === "ino",
                    ldg_nr: lgd_numbers,
                }]
            };

            invoices.push(row);
        });

        if ( invoices.length <= 0 ) {
            toastify(t("minimum item(s) required."), "error");
            return;
        }

        try {
            const response = await request({
                url: `suppliers/spot/loads/store/invoice`,
                method: 'POST',
                data: {
                    _method: "POST",
                    invoiceable: invoices,
                }
            });

            toastify(t("invoice(s) added"), "success");
            setTimeout(() => setSelectedRow([]), 700);
            setPanelActive(false);
        } catch (e: any) {
            if ( e.status === 510 ) {
                const { data } = e.data;
                let summary: any = '';

                map(data, (item: any) => {
                    summary += t("error while generating a invoice for") + `: ${ item.client_name } ` + '\n';
                });

                toastify(t("server error"), "error", summary);
            } else {
                toastify(t("server error"), "error",);
            }

        }
    };

    useEffect(() => {
        if ( props.visibility ) {
            setData([]);
            setPanelActive(true);
            setTimeout(() => setSelectedRow(null), 700)
        }

    }, [props]);

    // @ts-ignore
    useEffect(() => {
        if ( isPanelActive ) {
            setData([]);
            getDropdowns();
        }

    }, [isPanelActive]);

    const onFilterReset = (props: any) => {
        props.resetForm();
        setInitFilterFormState({
            ...DEFAULT_FILTER_FORM_STATE,
            fromInvoiceableDate: moment().startOf('month'),
            toInvoiceableDate: moment().endOf('month'),
        });
    }

    return (
        <>
            <div className={ "border" }>
                <Sidebar
                    visible={ isPanelActive } onHide={ onCloseDrawer } blockScroll={ true }
                    fullScreen
                >
                    <Card className={ "border-0 p-0" }>
                        <CardHeader className={"mb-2"}>
                            <Row>
                                <Col sm={ 12 } md={ 6 } lg={ 6 }>
                                    <h5 className={ "card-title mt-0" }>{ t('generate draft invoice(s)') }</h5>
                                    <p>{ t("create invoice(s) for selected load(s)") }</p>
                                </Col>
                                <Col sm={ 12 } md={ 6 } lg={ 6 } className={ "text-end" }>
                                <Button type="button" severity={undefined} outlined size={"small"}
                                        onClick={ onSubmit } className="me-2">
                                        <i className="fas fa-save me-1" /> { t("generate draft") }
                                    </Button>
                                    <Button type="button" severity={"danger"} outlined size={"small"}
                                            onClick={ onClose }
                                            title={ t("operation cancel") }
                                            className="me-2">
                                        <i className="fas fa-angle-left me-1" /> { t("cancel") }
                                    </Button>
                                    <Button type="button" severity={"info"} outlined size={"small"}
                                            onClick={ toggleFilter  }
                                            title={ t("filter") }>
                                        <i className="fas fa-filter me-1" /> { t("filter") }
                                    </Button>
                                </Col>
                            </Row>
                            <Row className={ `${!isOpen ? 'd-none' : ''}` }>
                                <Divider className={"mt-2"} />

                                <Formik
                                    enableReinitialize={ true }
                                    initialValues={ filterFormState }
                                    onSubmit={ getList }
                                    innerRef={ formRef }
                                >
                                    { (props: FormikProps<any>) => {

                                        return (
                                            <Form onSubmit={ props.handleSubmit }>
                                                <Row>
                                                    <Col sm={12} md={4} lg={3}>
                                                        <Field component={ InputSelectField }
                                                               name="routes"
                                                               options={ dropdowns.routes }
                                                               label={ t('routes') }
                                                               showClear
                                                        />
                                                    </Col>
                                                    <Col sm={12} md={4} lg={3}>
                                                        <Field component={ InputSelectField }
                                                               name="clients"
                                                               options={ dropdowns.clients }
                                                               label={ t('clients') }
                                                               showClear
                                                        />
                                                    </Col>
                                                    <Col sm={12} md={4} lg={3}>
                                                        <Field component={ InputField }
                                                               name="vinNumbers"
                                                               label={ t("vin number") }
                                                               placeholder={ t("enter") + ' ' + t("vin number").toLowerCase() }
                                                               formText={ t("a series of vin number values, each separated by a space") }
                                                               autoFocus={true}
                                                        />
                                                    </Col>
                                                    <Col sm={12} md={4} lg={3}>
                                                        <Field component={ InputSelectField }
                                                               name="loadNumbers"
                                                               options={ dropdowns.loadNumbers }
                                                               label={ t('load number(s)') }
                                                               showClear
                                                        />
                                                    </Col>
                                                    <Col sm={12} md={4} lg={3}>
                                                        <Field component={ InputDateTime }
                                                               name="fromInvoiceableDate"
                                                               type={ "datetime" }
                                                               label={ t("from invoiceable date") }
                                                               isClearable={ true }
                                                               enableTimeStartOfDay
                                                               rounded
                                                        />
                                                    </Col>
                                                    <Col sm={12} md={4} lg={3}>
                                                        <Field component={ InputDateTime }
                                                               name="toInvoiceableDate"
                                                               type={ "datetime" }
                                                               label={ t("to invoiceable date") }
                                                               isClearable={ true }
                                                               enableTimeEndOfDay
                                                               rounded
                                                        />
                                                    </Col>
                                                    <Col sm={12} md={4} lg={3} className={"md:mt-4 sm:mt-0"}>
                                                        <Button type="submit" severity={undefined} size={"small"} outlined>
                                                            <i className="fas fa-save me-1" /> { t('filter') }
                                                        </Button>
                                                        <Button type="button" severity={"danger"} size={"small"}
                                                                onClick={ () => onFilterReset(props) } outlined
                                                                className="ms-2">
                                                            <i className="fas fa-cut me-1" /> { t('reset') }
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        );
                                    } }
                                </Formik>
                            </Row>
                        </CardHeader>
                        <Divider />
                        <CardBody style={{border: '1px solid var(surface-50)'}}>
                            {
                                isEmpty(data) && <span>{ t('no data') }</span>
                            }
                            {
                                !isEmpty(data) &&
                              <>
                                  <DataTable
                                    value={ data } dataKey="id"
                                    reorderableColumns size={"small"}
                                    selectionMode="multiple"
                                    selection={ selectedRow }
                                    onSelectionChange={ onSelectionChange }
                                    dragSelection
                                    resizableColumns showGridlines stateStorage="session" stateKey="dt-invoiceable-list"
                                     scrollable scrollHeight="750px" virtualScrollerOptions={{ itemSize: 50 }}>
                                      <Column selectionMode="multiple" headerStyle={{ width: '2rem' }} />
                                      <Column field="load_number" header={t('load number')}/>
                                      <Column field="total" header={t('total')} />
                                      <Column field="ldg_nr" header={t('ldg nr')}
                                              body={ (row: any) => row.orders.map((item: any) => (<div>{item.ldg_nr}</div>)) }/>
                                      <Column field="client" header={t('client')} />
                                      <Column field="legal_entity_title" header={t('legal entity')} />
                                      <Column field="from_addresses" header={t('from address')}
                                              body={ (row: any) => row.from_addresses.map((item: any) => (<div>{item}</div>)) } />
                                      <Column field="to_addresses" header={t('to address')}
                                              body={ (row: any) => row.to_addresses.map((item: any) => (<div>{item}</div>)) } />
                                      <Column field="route" header={t('route')} body={(data: any) => (<span dangerouslySetInnerHTML={ { __html: data.route }}></span>)} />
                                      <Column field="vin_number" header={t('vin(s)')}
                                              body={ (row: any) => row.orders.map((item: any) => (<div>
                                                  <VinWithType vinNumber={ item.vin_number } />
                                              </div>)) } />
                                      <Column field="brand" header={t('brand')} />
                                      <Column field="model" header={t('model')} />
                                      <Column field="invoiceable_at" header={t('invoiceable at')} body={
                                          (data: any) => (<span>{utcDateToLocalDate(data.invoiceable_at)}</span>)
                                      }/>
                                  </DataTable>
                              </>
                            }
                        </CardBody>
                    </Card>
                </Sidebar>
            </div>
        </>
    );
}

export default withTranslation()(AllInvoiceableList);
