import React from "react";
import { isEmpty } from "lodash";
import Auth from "./Auth";

interface IHasAccessProps {
    hasAccess?: string,
    hasAnyAccess?: any,
    hasAllAccess?: any,
    hasRole?: any,
    children?: any,
}

function HasAccess(props: IHasAccessProps) {
    const { hasAllAccess, hasAnyAccess } = props;
    const user = new Auth();

    // @ts-ignore
    if ( hasAllAccess && !isEmpty(hasAllAccess) && user.hasAllAccess(hasAllAccess) ) {
        return (
            <>
                { props.children }
            </>
        );
    }

    // @ts-ignore
    if ( hasAnyAccess && !isEmpty(hasAnyAccess) && user.hasAnyAccess(hasAnyAccess) ) {
        return (
            <>
                { props.children }
            </>
        );
    }

    // @ts-ignore
    if ( !hasAnyAccess && !hasAllAccess ) {
        return (
            <>
                { props.children }
            </>
        );
    }

    return (
        <></>
    );
}

export default HasAccess;
