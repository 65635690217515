import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { get, includes } from "lodash";
import { request } from "../../../../utils/Request";
import { Badge } from "primereact/badge";

const UserDetail = (props: any) => {
    const { t, toastify, params } = props;
    const [entity, setEntity] = useState<any>(null);

    const getDetail = async (id: string) => {
        try {
            const response = await request({
                url: `/suppliers/cvs/api/logs/${ id }`
            });

            const { data } = response.data;
            setEntity(data);
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    const getStatus = (entity: any) => {
        const { status } = JSON.parse(entity.response);
        return status;
    };

    useEffect(() => {
        if ( includes(['detail'], get(params, ['operation'])) ) {
            getDetail(params.operationId);
        }
    }, [params]);

    return (
        <div className={ "p-4" }>
            {
                entity &&
                <>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('status') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">
                                {
                                    'OK' == getStatus(entity) ?
                                        <Badge severity={ 'success' } className={ 'ms-2' } value={ t('complete') } /> :
                                        <Badge severity={ 'danger' } className={ 'ms-2' } value={ t('failed') } />
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('api url') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">{ entity.api_url }</div>
                        </Col>
                    </Row>
                    <Row className={ 'my-2' }>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('request') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">{ entity.request }</div>
                        </Col>
                    </Row>
                    <Row className={ 'my-2' }>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('response') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">{ entity.response }</div>
                        </Col>
                    </Row>
                </>
            }
        </div>

    );
};

UserDetail.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(UserDetail));
