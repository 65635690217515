import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Form, Field } from "formik";
import { Col, Row } from "react-bootstrap";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { InputSelectField } from "../../../../../components/Shared/InputSelectField";
import InputDateTime from "../../../../../components/Shared/InputDateTime";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    dropdowns: any;
    visibility: any;
    toastify: any;
    onClose?: any;
}

type TInputForm = {
    fromFinalizeDate: null,
    toFinalizeDate: null,
    toDate: null,
    fromDate: null,
    clients: [],
    statuses: [],
    legalEntities: []
};

const DEFAULT_FORM_STATE = {
    fromFinalizeDate: null,
    toFinalizeDate: null,
    toDate: null,
    fromDate: null,
    clients: [],
    statuses: [],
    legalEntities: []
};

// @ts-ignore
function InvoiceFilter(props: IFormProps) {
    const { t, visibility, dropdowns } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<any>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);

    const onSubmit = async (payloads: any) => {
        props.setQuery({
            ...props.moduleState,
            filters: payloads
        });
    };

    const onReset = () => {
        const base: any = formRef.current;
        if ( base ) {
            base.resetForm();
        }

        props.setQuery({
            ...props.moduleState,
            filters: []
        });
    };

    const onClose = () => {
        setPanelState(false);

        if ( props.onClose ) {
            props.onClose();
        }
    };

    useEffect(() => {
        if ( visibility ) {
            setPanelState(visibility);
            setInitFormState({ ...DEFAULT_FORM_STATE, ...props.moduleState.filters ?? [] });
        }
    }, [visibility]);

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('advance filters') }</h5>
                            <p>{ t("using filters you can archive better result.") }</p>
                        </div>
                    }
                    visible={ panelState } position="left" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } } closeOnEscape={ false }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4 md:mb-7 sm:mb-7" }>
                                        <Col sm={12} md={6}>
                                            <Field component={ InputSelectField }
                                                   name="legal_entities"
                                                   options={ dropdowns?.legalEntities }
                                                   label={ t('legal entities') }
                                                   formGroupClass={ "pb-0 mb-0" }
                                                   showClear
                                                   autoFocus={true}
                                            />
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Field component={ InputSelectField }
                                                   name="clients"
                                                   optionLabel="name"
                                                   options={ dropdowns.clients }
                                                   formGroupClass={ "pb-0 mb-0" }
                                                   label={ t('clients') }
                                            />
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Field component={ InputSelectField }
                                                   name="paymentMethods"
                                                   options={ dropdowns?.paymentMethods }
                                                   placeholder={ t("select") + ' ' + t("payment mode") }
                                                   formGroupClass={ "pb-0 mb-0" }
                                                   label={ t('payment mode') }
                                            />
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Field component={ InputDateTime }
                                                   name="fromFinalizeDate"
                                                   type={ "datetime" }
                                                   label={ t("from finalize date") + ' >=' }
                                                   enableTimeStartOfDay
                                            />
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Field component={ InputDateTime }
                                                   name="toFinalizeDate"
                                                   type={ "datetime" }
                                                   label={ t("to finalize date") + ' <=' }
                                                   enableTimeStartOfDay
                                            />
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Field component={ InputDateTime }
                                                   name="fromDate"
                                                   type={ "datetime" }
                                                   label={ t("from date") }
                                                   isClearable={ true }
                                                   enableTimeStartOfDay
                                            />
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Field component={ InputDateTime }
                                                   name="toDate"
                                                   type={ "datetime" }
                                                   label={ t("to date") }
                                                   isClearable={ true }
                                                   enableTimeEndOfDay
                                            />
                                        </Col>
                                    </Row>
                                    <div className="filter filter-card-footer"
                                         style={ { width: '785px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-filter me-2" />
                                            { t("filter") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ onReset }
                                                tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                size={ "small" } severity={ "warning" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-refresh me-2" />
                                            { t("reset") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ onClose }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("close") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

InvoiceFilter.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(InvoiceFilter);
