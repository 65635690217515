import React from 'react';
import { ErrorMessage } from "formik";
import { has, isEmpty } from "lodash";
import { FieldLabel } from "./FieldLabel";
import { Chips } from 'primereact/chips';
import { FormGroup } from 'react-bootstrap';
import { uniqBy } from 'lodash';
import { DynamicObject } from "../../../../admin/src/helpers/commonTypes";
import * as yup from "yup";

interface IInputChipProps {
    label?: string,
    field: any,
    form?: any,
    rounded?: boolean,
    isRequired?: boolean,
    isClearable: boolean,
    formGroupClass?: any,
    labelField?: any,
    getOptionLabel?: any,
    onSelect?: any,
    placeHolder?: any,
    isMulti?: boolean,
    formText?: string,
    disabled: boolean,
    valueField?: string,
    options?: any,
    isEmail?: boolean,
    size?: string,
}

interface IInputChipState {
    isLoading: boolean,
    isClearable: boolean,
    valueField: string,
    createdOptions: any,
}

let emailSchema = yup.string().email();

export class InputChip extends React.Component<IInputChipProps, IInputChipState> {
    static defaultProps = {
        labelField: 'label',
        disabled: false,
        isClearable: true,
        isEmail: false,
        placeHolder: "Type and hit Enter",
    };

    constructor(props: IInputChipProps) {
        super(props);
        this.state = {
            valueField: 'value',
            isLoading: false,
            isClearable: props.isClearable,
            createdOptions: [],
        };
    }

    onChange = (value: any) => {
        const { field, form } = this.props;
        const options = uniqBy(value, (item: any) => item);
        const _options: any = [];
        const self = this;

        if ( isEmpty(value) ) {
            form.setFieldValue(field.name, []);
            return;
        }

        if ( self.props.isEmail ) {
            options.map(function (option: DynamicObject) {
                emailSchema
                .isValid(option)
                .then((valid: boolean) => {
                    if ( valid ) {
                        _options.push(option);
                    }
                })
                .then(() => {
                    // @ts-ignore
                    self.setState({ createdOptions: _options });
                    form.setFieldValue(field.name, _options);
                });
            });
        } else {
            // @ts-ignore
            self.setState({ createdOptions: _options });
            form.setFieldValue(field.name, _options);
        }
    };

    value = () => {
        const {
            field,
        } = this.props;

        return field.value;
    };

    render() {
        const {
            field,
            formGroupClass,
            form,
            rounded,
            size,
            ...props
        } = this.props;
        const { errors } = form;

        return (
            <FormGroup className={ formGroupClass }>
                <FieldLabel { ...props } />
                <div className={"custom-field"}>
                    <Chips
                        id={ field.name }
                        value={ field.value }
                        onChange={ (e: any) => this.onChange(e.value) }
                        className={ `
                            ${ has(errors, field.name) ? 'p-invalid' : '' } 
                            react-select-container ${ size === "small" ? 'small-select' : '' }
                            w-full
                        ` }
                        placeholder={ this.props.placeHolder ?? "" }
                        disabled={ props.disabled }
                    />
                </div>
                { props.formText && <small>{ props.formText }</small> }
                <ErrorMessage name={ field.name } component="small" className="p-error d-block" />
            </FormGroup>
        );
    }
}
