import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Field, Form } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Col, Row } from "react-bootstrap";
import { InputField } from "../../../../components/Shared/InputField";
import { Sidebar } from "primereact/sidebar";
import { get, includes, isEmpty } from "lodash";
import { Button } from "primereact/button";
import { InputSwitchField } from "../../../../components/Shared/InputSwitchField";
import InputDateTime from "../../../../components/Shared/InputDateTime";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    dropdowns: any;
    params: any;
    toastify: any;
    onClose?: any;
    operationCancel?: any;
}

type TInputForm = {
    id: any,
    first_name: any,
    last_name: any,
    email: any,
    phone: any,
    password: any,
    password_confirmation: any,
    is_active: any,
    is_suspended: boolean;
    identification_number: any,
    from_suspended_at: Date | string | null;
    to_suspended_at: Date | string | null;
    suspend_reason: any,
    documents: any,
    webhooks: any,
    is_session_timeout_active: any,
    timeout_value: any
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        email: yup.string().email(t('the email must be a valid email address.')).required(t('the field is required.', { field: t('email') })).nullable(),
        first_name: yup.string().required(t('the field is required.', { field: t('first name') })).nullable(),

        identification_number: yup.string().required(t('the field is required.', { field: t('identification number') })).nullable(),
        last_name: yup.string().nullable().notOneOf([yup.ref('first_name')]),
        phone: yup.string().nullable().matches(/^$|((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, t('the field value is not valid', { field: t('phone') })),
        is_active: yup.boolean(),
        is_suspended: yup.boolean(),
        documents: yup.array().nullable(),
        webhooks: yup.array().nullable(),
        password: yup.string().when('id', {
            is: null,
            then: yup.string().required(t('the field is required.', { field: t('password') })).nullable(),
            otherwise: yup.string().notRequired().nullable(),
        }),
        password_confirmation: yup.string().when('id', {
            is: null,
            then: yup.string().required(t('the field is required.', { field: t('password confirmation') })).oneOf([yup.ref('password'), null], t('the field confirmation does not match.', { field: t('password') })).nullable(),
            otherwise: yup.string().notRequired().nullable(),
        }),
        suspend_reason: yup.string().when('is_suspended', {
            is: true,
            then: yup.string().required(t('the field is required.', { field: t('suspend reason') })).nullable(),
            otherwise: yup.string().notRequired().nullable(),
        }),
        from_suspended_at: yup.string().when('is_suspended', {
            is: true,
            then: yup.string().required(t('the field is required.', { field: t('from suspend date/time') })).nullable(),
            otherwise: yup.string().notRequired().nullable(),
        }),
        to_suspended_at: yup.string().nullable(),
    });
};

const DEFAULT_FORM_STATE = {
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    password: null,
    password_confirmation: null,
    identification_number: null,
    documents: null,
    webhooks: null,
    phone: null,
    is_active: false,
    is_suspended: false,
    from_suspended_at: null,
    to_suspended_at: null,
    suspend_reason: null,
    timeout_value: '15',
    is_session_timeout_active: null
};

function AccountForm(props: IFormProps) {
    const { t, toastify, params, dropdowns, operationCancel } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: ( !payloads.id ? 'POST' : 'PATCH' ),
                url: ( !payloads.id ? `/admin/suppliers` : `/admin/suppliers/${ payloads.id }` ),
                data: {
                    ...payloads,
                    from_suspended_at: payloads.is_suspended ? payloads.from_suspended_at : null,
                    to_suspended_at: payloads.is_suspended ? payloads.to_suspended_at : null,
                },
            });

            if ( payloads.id ) {
                toastify(t("record updated", { item: t('account') }), "success");
            } else {
                toastify(t("record added", { item: t('account') }), "info");
            }

            resetForm();
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    const onReset = () => {
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm();
        }
    };

    const onClose = () => {
        setPanelState(false);
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm();
        }

        if ( props.onClose ) {
            props.onClose();
        }
    };

    const onEdit = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/admin/suppliers/${ id }`
            });

            const { data } = response.data;
            delete data.documents;
            const formData: TInputForm = { ...data };
            if ( !isEmpty(data.from_suspended_at) ) {
                formData.is_suspended = true;
            }
            setInitFormState(formData);
            setPanelState(true);
        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        if ( includes(['add'], get(params, ['operation'])) ) {
            setPanelState(true);
        }

        if ( includes(['edit'], get(params, ['operation'])) ) {
            onEdit(params.operationId);
        }
    }, [params]);

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('account') + ' ' + t('action') }</h5>
                            <p>{ t("using this form you can add or update the record") }</p>
                        </div>
                    }
                    visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                        validationSchema={ validationSchema(t) }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4 md:mb-7 sm:mb-7" }>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="first_name"
                                                   label={ t("first name") }
                                                   placeholder={ t("enter") + ' ' + t("first name").toLowerCase() }
                                                   isRequired
                                                   autoFocus />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="last_name"
                                                   label={ t("last name") }
                                                   placeholder={ t("enter") + ' ' + t("last name").toLowerCase() }
                                                />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="identification_number"
                                                   label={ t("identification number") }
                                                   placeholder={ t("enter") + ' ' + t("identification number").toLowerCase() }
                                                   isRequired
                                                />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="email"
                                                   label={ t("email") }
                                                   placeholder={ t("enter") + ' ' + t("email").toLowerCase() }
                                                   isRequired
                                                />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="password"
                                                   type={ "password" }
                                                   label={ t("password") }
                                                   placeholder={ t("enter") + ' ' + t("password").toLowerCase() }
                                                   isRequired={ !props.values.id }
                                                />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="password_confirmation"
                                                   type={ "password" }
                                                   label={ t("password confirmation") }
                                                   placeholder={ t("enter confirm password") }
                                                   isRequired={ !props.values.id }
                                                />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="phone"
                                                   label={ t("phone") }
                                                   placeholder={ t("enter") + ' ' + t("phone").toLowerCase() }
                                                />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSwitchField }
                                               name="is_active"
                                               label={ t("email verified") }
                                               needBoolean
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSwitchField }
                                               name="is_suspended"
                                               label={ t("want to suspend login?") }
                                               needBoolean
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                        </Col>
                                        {
                                            props.values.is_suspended &&
                                            <>
                                                <Col sm={ 12 } md={ 6 }>
                                                    <Field component={ InputDateTime }
                                                       name="from_suspended_at"
                                                       type={ "datetime" }
                                                       label={ t("from suspend date/time") }
                                                       placeholder={ t("pick a date") }
                                                       isRequired
                                                    />
                                                </Col>
                                                <Col sm={ 12 } md={ 6 }>
                                                    <Field component={ InputDateTime }
                                                       name="to_suspended_at"
                                                       type={ "datetime" }
                                                       label={ t("to suspend date/time") }
                                                       placeholder={ t("pick a date") }
                                                    />
                                                </Col>
                                                <Col sm={ 12 } md={ 12 }>
                                                    <Field component={ InputField }
                                                       name="suspend_reason"
                                                       label={ t("suspend reason") }
                                                       placeholder={ t("enter") + ' ' + t("suspend reason").toLowerCase() }
                                                       isRequired
                                                    />
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <div className="filter-card-footer"
                                         style={ { width: '785px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-save me-2" />
                                            { t("let's save") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ onReset }
                                                tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                size={ "small" } severity={ "warning" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-refresh me-2" />
                                            { t("reset") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                                onClick={ () => operationCancel(null, null) }
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("cancel") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

AccountForm.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(AccountForm);
