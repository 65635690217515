import React from "react";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, CardFooter, Col, Row } from "react-bootstrap";
import { FormikProps, Formik, Field } from "formik";
import { find, findIndex, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { InputField } from "../../../../components/Shared/InputField";
import { random } from "../../../../utils/Helper";
import InputAttachment from "../../../../components/Shared/InputAttachment";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from "primereact/button";

type TInputForm = {
    name: string,
    document: string,
    token: string,
}

const DEFAULT_FORM_STATE = {
    token: random(10),
    name: null,
    document: null,
};

interface IProps {
    documents?: any,
    onUpdate?: any,
    t?: any,
    toastify: any;
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        name: yup.string().required(t('the field is required.', { field: t('name') })).nullable(),
        document: yup.string().required(t('the field is required.', { field: t('document') })).nullable(),
    });
};

function ContractDocument(props: IProps) {
    const { t, onUpdate, toastify } = props;
    const [initFormState, setInitFormState] = useState<any>(DEFAULT_FORM_STATE);
    const [contractDocuments, setContractDocuments] = useState<any>([]);

    const onSave = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        const _contractDocuments: any = [...contractDocuments];
        let existing = find(contractDocuments, { token: payloads.token });

        if ( !isEmpty(existing) ) {
            const index = findIndex(_contractDocuments, { token: payloads.token });
            existing = { ...payloads };
            _contractDocuments[index] = existing;
        } else {
            _contractDocuments.push({
                ...payloads,
                token: random(10),
            });
        }

        onUpdate(_contractDocuments);
        resetForm();
        setInitFormState(DEFAULT_FORM_STATE);
    };

    const onEdit = (token: any) => {
        const document: any = find(contractDocuments, { token: token });

        if ( document ) {
            setInitFormState(document);
        } else {
            toastify(t("no data"), { type: "error" });
        }
    };

    const onDelete = (token: any) => {
        const _contractDocuments: any = contractDocuments.filter((item: any) => item.token !== token);
        setContractDocuments(_contractDocuments);
        onUpdate(_contractDocuments);
    };

    const actionBodyTemplate = (rowData: any) => {
        return (
            <React.Fragment>
                <a className={ 'me-2' } onClick={ () => onEdit(rowData.token) }>
                    <i className={ 'fas fa-edit' } />
                </a>
                <a className={ 'me-2' } href={`${process.env.REACT_APP_DOCUMENT_URL}/${rowData.id}`} target='_blank'>
                    <i className={ 'fas fa-download' } />
                </a>
                <a onClick={ () => onDelete(rowData.token) }>
                    <i className={ 'fas fa-trash' } />
                </a>
            </React.Fragment>
        );
    };

    useEffect(() => {
        setContractDocuments(props.documents);
    }, [props.documents]);

    return (
        <Formik
            enableReinitialize={ true }
            initialValues={ initFormState }
            onSubmit={ onSave }
            validationSchema={ validationSchema(t) }
        >
            { (props: FormikProps<TInputForm>) => {
                return (
                    <Card className={ "border-secondary mb-5 pt-2" }>
                        <CardHeader className={ "bg-body-tertiary p-2" }>
                            <h4 className="card-title m-0">{ t('documents') }</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col sm={ 12 }>
                                    <Field component={ InputField }
                                           name="name"
                                           label={ t("name") }
                                           placeholder={ "Ex. Contract Copy" }
                                           isRequired />
                                </Col>
                                <Col sm={ 12 }>
                                    <Row>
                                        <Col sm={ 12 } md={ 8 }>
                                            <Field component={ InputAttachment }
                                                   name="document"
                                                   label={ t("document") }
                                                   needBoolean
                                                   isRequired
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 4 } className={ "text-end" }>
                                            <div><label className="text-g-800 form-label">&nbsp;</label></div>
                                            <Button type="button" size={"small"}
                                                    onClick={ () => props.handleSubmit() }
                                                    title={ t("to data save into database") }
                                                    className="btn btn-success waves-effect waves-light">
                                                <i className="fas fa-plus" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className={"mt-2"}>
                            <DataTable value={ contractDocuments } editMode="row" dataKey="id">
                                <Column field="name" header="Name" />
                                <Column body={ actionBodyTemplate } headerStyle={ { width: '10%', minWidth: '8rem' } }
                                        bodyStyle={ { textAlign: 'center' } } />
                            </DataTable>
                        </CardFooter>
                    </Card>
                );
            } }
        </Formik>
    );
}

ContractDocument.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(ContractDocument);
