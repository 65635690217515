import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useRef, useState } from "react";
import { request } from "../../../../utils/Request";
import Tooltip from "../../../../components/Common/Display/Tooltip";
import HasAccess from "../../../../utils/HasAccess";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import StateLessGrid from "../../../../components/Shared/StateLessGrid";
import { Button } from "primereact/button";
import { Col, Row } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../../../components/Shared/InputField";
import InvoiceFilter from "./InvoiceFilter";
import { get, includes, isEmpty, sumBy } from "lodash";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { formatDate, utcDateToLocalDate } from "../../../../utils/Helper";
import InvoiceAmountDisplay from "./InvoiceAmountDisplay";
import InvoiceStatus from "./InvoiceStatus";
import InvoiceForm from "./InvoiceForm";
import Auth from "../../../../utils/Auth";
import InvoicePayment from "./InvoicePayment";
import InvoiceDetail from "./InvoiceDetail";
import { ButtonGroup } from "primereact/buttongroup";
import InvoiceUpdateNumber from "./InvoiceUpdateNumber";

const InvoiceList = (props: any) => {
    const { t, moduleState, toastify } = props;
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [operation, setOperation] = useState<any>(searchParams.get('operation') ?? null);
    const [operationId, setOperationId] = useState<any>(searchParams.get('operationId') ?? null);
    const [extra, setExtra] = useState<any>([]);
    const [filterPanel, setFilterPanel] = useState<boolean>(false);
    const [initSearchFormState, setInitSearchFormState] = useState<any>({ search: moduleState.search ?? null });
    const [isInvoicePaymentPanelActive, setIsInvoicePaymentPanelActive] = useState<any>(null);
    const tableRef: any = useRef();
    const [total, setTotal] = useState(0);
    const [isInvoiceUpdateNumber, setIsInvoiceUpdateNumber] = useState<any>(null);
    const auth = new Auth();

    const onDelete = async (payloads: any): Promise<void> => {
        try {
            await request({
                url: `suppliers/invoices/${ payloads.id }`,
                method: 'DELETE',
                data: { _method: 'DELETE' }
            });

            toastify(t("record deleted", { item: t('invoice') }), "info");
            onReset();
        } catch (e: any) {
            if(e.status === 422) {
                return toastify(e.data.message, "error");
            }

            toastify(t("server error"), "error");
        }
    };

    const onFinalInvoice = async (payloads: any): Promise<void> => {
        try {
            await request({
                url: `suppliers/invoices/finalize/${ payloads.id }`,
                method: 'POST',
            });

            toastify(t("record updated", { item: t('invoice') }), "info");
            onReset();
        } catch (e: any) {
            if(e.status === 422) {
                return toastify(e.data.message, "error");
            }

            toastify(t("server error"), "error");
        }
    };

    const onPantheonTransfer = async (payloads: any): Promise<void> => {
        try {
            await request({
                url: `suppliers/invoices/pantheon/transfer/${ payloads }`,
                method: 'post',
                data: { _method: 'post' }
            });

            toastify(t("transfer started", { item: t('invoice') }), "success");
        } catch (e: any) {
            toastify(t("server error"), "error");
        }
    };

    const columns = [
        { field: 'client', title: t('client'), render: (row: any) => get(row, ['client', 'name']), sorting: false },
        {
            field: 'legal_entity',
            title: t('legal entity'),
            sorting: false,
            render: (row: any) => get(row, ['legal_entity', 'company_name'])
        },
        {
            field: 'status',
            title: t('status'),
            sorting: true,
            render: (row: any) => <InvoiceStatus status={ row.status } paid={ !isEmpty(row.paid_at) } />
        },
        {
            field: 'number',
            title: t('invoice number'),
            sorting: true,
            render: (row: any) => ( row.status === 'draft' ? get(row, ['draft_invoice_number']) : get(row, ['final_invoice_number']) )
        },
        {
            field: 'final_total',
            title: t('amount'),
            sorting: true,
            render: (row: any) => <>
                <InvoiceAmountDisplay
                    total={ row.grand_total } paid={ row.paid_total }
                    status={ row.status } />
            </>
        },
        {
            field: 'payment_mode',
            title: t('payment mode'),
            sorting: false,
            render: (row: any) => <>
              <div>
                  <div>{t("bank")}: { row.payments ? row.grand_total - sumBy(row.payments.filter((i: any) => i.payment_mode === "cash"), (item: any) => Number(item.total ?? 0)) : 0 }</div>
                  <div>{t("cash")}: { row.payments ? sumBy(row.payments.filter((i: any) => i.payment_mode === "cash"), (item: any) => Number(item.total ?? 0)) : 0}</div>
                  {/*<div>{t("internal discount")}: { (row.is_internal_discount && row.discount) ? row.discount ?? 0 : 0}</div>*/}
              </div>
            </>
        },
        {
            field: 'modified_at',
            title: t('modified at'),
            sorting: false,
            render: ({ updated_at }: any) => ( ( updated_at ) && <span>{ utcDateToLocalDate(updated_at) }</span> )
        },
        {
            field: 'finalize_date',
            title: t('finalize date'),
            sorting: true,
            render: ({ finalize_date }: any) => ( ( finalize_date ) &&
              <span>{ formatDate(finalize_date, 'DD-MM-YYYY hh:mm A') }</span> )
        },
        { field: 'modifier', title: t('modifier'), render: (row: any) => get(row, ['modifier', 'name']), sorting: true, },
        { field: 'qty', title: t('qty'), sorting: true, },
        {
            field: 'action',
            title: t('action'),
            sorting: false,
            render: (_row: any) => {
                return (
                    <div>
                        { _row.status === 'draft' &&
                        <>
                            <HasAccess hasAllAccess={ ['invoicesupdate'] }>
                                <Tooltip
                                  tooltip={ t('edit item') }>
                                    <a onClick={ () => onOperationClick('edit', _row.id) }>
                                        <i className="fas fa-edit" />
                                    </a>
                                </Tooltip>
                            </HasAccess>
                            <HasAccess hasAllAccess={ ['invoicesupdate'] }>
                                <Tooltip
                                  tooltip={ t('mark as final') }>
                                    <a
                                      title={ t('mark as final') }
                                      className={ "cursor-pointer" }
                                      onClick={ (event: any) => confirmPopup({
                                          target: event.currentTarget,
                                          message: t('do you want to mark this invoice as final?'),
                                          icon: 'pi pi-info-circle',
                                          // @ts-ignorer
                                          defaultFocus: 'reject',
                                          acceptClassName: 'p-button-danger',
                                          accept: () => onFinalInvoice(_row),
                                          reject: () => {
                                          },
                                      }) }>
                                        <i className="fas fa-check-circle ms-2 text-success" />
                                    </a>
                                </Tooltip>
                            </HasAccess>
                            <HasAccess hasAllAccess={ ['invoicesdestroy'] }>
                                <Tooltip
                                  tooltip={ t('delete item') }>
                                    <a
                                      title={ t('delete item') }
                                      className={ "cursor-pointer ms-2" }
                                      onClick={ (event: any) => confirmPopup({
                                          target: event.currentTarget,
                                          message: t('do you want to delete this record?'),
                                          icon: 'pi pi-info-circle',
                                          // @ts-ignorer
                                          defaultFocus: 'reject',
                                          acceptClassName: 'p-button-danger',
                                          accept: () => onDelete(_row),
                                          reject: () => {
                                          },
                                      }) }>
                                        <i className="fas fa-trash-alt" />
                                    </a>
                                </Tooltip>
                            </HasAccess>
                        </>
                        }
                        <HasAccess hasAnyAccess={ ['invoicepaymentsstore'] }>
                          <Tooltip tooltip={ t("make payment") }>
                              <a
                                className={ "cursor-pointer ms-2 text-green-500" }
                                onClick={ () => setIsInvoicePaymentPanelActive(_row.id) }>
                                  <i className="fas fa-credit-card" />
                              </a>
                          </Tooltip>
                        </HasAccess>
                        <HasAccess hasAnyAccess={ ['invoicesview'] }>
                            {
                                _row.status === 'draft'
                                &&
                              <Tooltip tooltip={ t("draft pdf") }>
                                  <a
                                    title={ t('draft pdf') }
                                    className={ "cursor-pointer ms-2" }
                                      /*@ts-ignore*/
                                    href={ `${ process.env.REACT_APP_API_URL }/w/invoice/document/${ _row.id }?status=draft&types=pdf&uToken=` + auth.getUser().id }
                                    target={ "_blank" }
                                    rel="noopener noreferrer"
                                  >
                                      <i className="far fa-file-pdf" />
                                  </a>
                              </Tooltip>
                            }
                            {
                                _row.status === 'final'
                                &&
                              <Tooltip
                                tooltip={ t("final pdf") }
                                position={ "left" }>
                                  <a
                                    className={ "cursor-pointer ms-2" }
                                      /*@ts-ignore*/
                                    href={ `${ process.env.REACT_APP_API_URL }/w/invoice/document/${ _row.id }?status=final&types=pdf&uToken=` + auth.getUser().id }
                                    target={ "_blank" }
                                    rel="noopener noreferrer"
                                  >
                                      <i className="far fa-file-pdf" />
                                  </a>
                              </Tooltip>
                            }
                        </HasAccess>
                        <HasAccess hasAnyAccess={ ['invoicepaymentsview'] }>
                            {
                                ( _row.status === 'final' && _row.paid_total > 0)
                                &&
                              <Tooltip
                                tooltip={ t("payment report pdf") }>
                                  <a
                                    className={ "cursor-pointer ms-2" }
                                      /*@ts-ignore*/
                                    href={ `${ process.env.REACT_APP_API_URL }/w/invoice/document/${ _row.id }?status=final&types=pdf&payment=1&uToken=` + auth.getUser().id }
                                    target={ "_blank" }
                                    rel="noopener noreferrer"
                                  >
                                      <i className="far fa-file-pdf" />
                                  </a>
                              </Tooltip>
                            }
                        </HasAccess>

                        {
                            _row.status === 'final' &&
                          <span>
                              <HasAccess hasAnyAccess={ ['invoicesupdate'] }>
                                  <Tooltip
                                    tooltip={ t('replace invoice number') }>
                                      <a onClick={ () => onOperationUpdateInvoiceNumberClick(_row.id) }>
                                          <i className="fas fa-tag ms-2" />
                                      </a>
                                  </Tooltip>
                              </HasAccess>
                              <HasAccess hasAnyAccess={ ['invoicesview'] }>
                                  <Tooltip
                                    tooltip={ t('pantheon transfer') }>
                                      <a
                                        className={ "cursor-pointer ms-2" }
                                        onClick={ (event: any) => confirmPopup({
                                            target: event.currentTarget,
                                            message: t('are you sure to transfer the invoice to pantheon?'),
                                            icon: 'pi pi-info-circle',
                                            // @ts-ignorer
                                            defaultFocus: 'reject',
                                            acceptClassName: 'p-button-danger',
                                            accept: () => onPantheonTransfer(_row.id),
                                            reject: () => {
                                            },
                                        }) }>
                                          <i className="fas fa-fighter-jet" />
                                      </a>
                                  </Tooltip>
                              </HasAccess>
                          </span>
                        }

                        <HasAccess hasAnyAccess={ ['invoicesview'] }>
                            <Tooltip tooltip={ t('detail of item') }>
                                <a
                                    className={ "cursor-pointer ms-2" }
                                    onClick={ () => onOperationClick('detail', _row.id) }>
                                    <i className="fas fa-eye" />
                                </a>
                            </Tooltip>
                        </HasAccess>
                    </div>
                );
            }
        },
    ];

    const onContentLoaded = (response: any) => setExtra(response.extra);

    // const DetailRow = ({ data }: any) => {
    //         return (
    //
    //         );
    //     }
    // ;

    const onOperationUpdateInvoiceNumberClick = (id: any) => setIsInvoiceUpdateNumber(id);

    const onOperationClick = (operationToBePerformed: any = 'add', id: any = null) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('operation', operationToBePerformed);
        searchParams.set('operationId', id);

        if ( isEmpty(operationToBePerformed) ) {
            searchParams.delete('operation');
        }

        if ( isEmpty(id) ) {
            searchParams.delete('operationId');
        }

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        }, { replace: true });
        setOperationId(id);
        setOperation(operationToBePerformed);
        focusOnSearch();
    };

    const onCloseOperation = (reset: any = true) => {
        onOperationClick(null, null);
        onReset(reset);
    };

    const onReset = (reset: any = true) => {
        const mStates = { ...moduleState };

        if ( reset ) {
            mStates.filters = [];
            mStates.page = 1;
            mStates.search = null;
        }

        props.setQuery(mStates);
    };

    const onSearch = ({ search }: any) => {
        props.setQuery({
            ...moduleState,
            search: search
        });
    };

    const onFilterClick = () => {
        setFilterPanel(true);
    };

    const focusOnSearch = () => {
        const searchInput: HTMLInputElement | null = document.querySelector('input[name="search"]');
        if ( searchInput ) {
            searchInput.focus();
        }
    };

    return (
        <div className={ "block-viewer" }>
            <div className="block-section">
                {
                    (!includes(['add', 'edit'], operation)) &&
                  <>
                      {
                          !includes(['detail'], operation)
                          &&
                        <div>
                            <div className="block-header">
                                <span className="block-title">
                                    <h5 className={ "mb-0" }>{ t('invoices') } ({total})</h5>
                                </span>
                                <div className="block-actions">
                                </div>
                            </div>
                            <div className={ `block-content` }>
                                <Row className={ "p-2" }>
                                    {
                                        extra && extra.map((item: any, index: number) => {
                                            return (
                                                <Col sm={ 12 } md={ 3 } key={ index }>
                                                    <div className="mt-1 p-1 d-block"
                                                         style={ {
                                                             fontSize: '10px',
                                                             border: '1px solid var(--surface-400)',
                                                             backgroundColor: 'var(--gray-50)'
                                                         } }>
                                                        <h6 className={ "mb-1" }>{ item.name }</h6>
                                                        <div className={ "d-flex" }>
                                                            <div>
                                                                <span>{ t('draft total') }: <strong>{ get(item, ['indicators', 'draft']) }</strong></span>
                                                            </div>
                                                            <div>
                                                        <span
                                                            className={ "ms-2" }>{ t('final total') }: <strong>{ get(item, ['indicators', 'final']) }</strong></span>
                                                            </div>
                                                        </div>
                                                        <div className={ "d-flex" }>
                                                            <div>
                                                                <span>{ t('paid total') }: <strong>{ get(item, ['indicators', 'paid']) }</strong></span>
                                                            </div>
                                                            <div>
                                                        <span
                                                            className={ "ms-2" }>{ t('open total') }: <strong>{ get(item, ['indicators', 'open']) }</strong></span>
                                                            </div>
                                                        </div>
                                                        <div className={ "d-flex" }>
                                                            {
                                                                get(item, ['indicators', 'discount'])
                                                                .map((i: any, index: any) => {
                                                                    return (
                                                                        index % 2 === 0 ? (
                                                                            <div key={ index } className="ms-0">
                                                                              <span>{ get(i, ['code']) }: <strong
                                                                                  className={ "ms-1" }>{ get(i, ['total']) }</strong></span>
                                                                            </div>
                                                                        ) : (
                                                                            <div key={ index } className="ms-2">
                                                                              <span>{ get(i, ['code']) }: <strong
                                                                                  className={ "ms-1" }>{ get(i, ['total']) }</strong></span>
                                                                            </div>
                                                                        )
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>
                                <Row className={ "p-2" }>
                                    <Col
                                      sm={ 12 } md={ 6 } className={ "mb-2" }>
                                        <Formik
                                          enableReinitialize={ true }
                                          initialValues={ initSearchFormState }
                                          onSubmit={ onSearch }
                                        >
                                            { (props: FormikProps<any>) => {
                                                return (
                                                    <Form onSubmit={ props.handleSubmit }>
                                                        <Col sm={ 12 } md={ 8 }>
                                                            <Field component={ InputField } withoutLabel={ true }
                                                                   autoFocus={ true }
                                                                   name="search"
                                                                   formGroupClass={ "mb-0" }
                                                                   placeholder={ t("search") } />
                                                        </Col>
                                                    </Form>
                                                );
                                            } }
                                        </Formik>
                                    </Col>

                                    <Col
                                      sm={ 12 } md={ 6 } className={ "text-right mb-2" }>
                                    <ButtonGroup>
                                        <Button size={ "small" } outlined tooltip={ t('add item') }
                                                tooltipOptions={ { position: 'top' } }
                                                onClick={ () => onOperationClick('add') }>
                                            <i className="pi pi-plus" />
                                            <span className={ "ms-1" }>{ t('add') }</span>
                                        </Button>
                                        <Button icon="pi pi-filter-fill" size={ "small" } outlined
                                                tooltip={ t('filter') }
                                                onClick={ onFilterClick }
                                                tooltipOptions={ { position: 'top' } } />
                                        <Button onClick={ onReset } icon="pi pi-refresh" size={ "small" } outlined
                                                tooltip={ t('reset') }
                                                tooltipOptions={ { position: 'top' } } />
                                    </ButtonGroup>
                                    </Col>

                                    <Col sm={ 12 }>
                                        <StateLessGrid
                                          size={ "normal" }
                                          url={ '/suppliers/invoices?1=1' }
                                          name={ "invoiceList" }
                                          columns={ columns }
                                          moduleState={ moduleState }
                                          setQuery={ props.setQuery }
                                          ref={ tableRef }
                                          getMetaData={ onContentLoaded }
                                          itemSize={ 45 }
                                          setTotal={ setTotal }
                                            // detailRow={ (data: any) => <DetailRow data={ data } /> }
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                      }

                      {
                          ( operation === 'detail' && !isEmpty(operationId) ) &&
                        <>
                            <InvoiceDetail
                              setQuery={ props.setQuery }
                              toastify={ props.toastify }
                              dropdowns={ props.dropdowns }
                              params={ { operation, operationId } }
                              onClose={ () => onCloseOperation(false) }
                            />
                        </>
                      }
                  </>
                }

                {/*{*/ }
                {/*    (operation === 'detail' && !isEmpty(operationId)) &&*/ }
                {/*    <></>*/ }
                {/*}*/ }

                <InvoiceFilter
                    moduleState={ moduleState }
                    setQuery={ props.setQuery }
                    toastify={ props.toastify }
                    dropdowns={ props.dropdowns }
                    visibility={ filterPanel }
                    onClose={ () => setFilterPanel(false) }
                />

                {
                    ( includes(['add', 'edit'], operation) ) &&
                  <InvoiceForm
                    moduleState={ moduleState }
                    setQuery={ props.setQuery }
                    toastify={ props.toastify }
                    params={ { operation, operationId } }
                    onClose={ onCloseOperation }
                    operationCancel={ () => onOperationClick(null, null) }
                  />
                }
            </div>

            <ConfirmPopup />

            <InvoicePayment
                id={ isInvoicePaymentPanelActive }
                toastify={ props.toastify }
                onClose={ () => {
                    setIsInvoicePaymentPanelActive(null);
                    focusOnSearch();
                    if ( tableRef.current ) {
                        tableRef.current.onRefresh();
                    }
                } }
            />

            <InvoiceUpdateNumber
                id={ isInvoiceUpdateNumber }
                toastify={ props.toastify }
                onClose={ () => {
                    setIsInvoiceUpdateNumber(null);
                    focusOnSearch();
                    if ( tableRef.current ) {
                        tableRef.current.onRefresh();
                    }
                } }
            />
        </div>
    );
};

InvoiceList.propTypes =
    {
        // t: PropTypes.any,
    }
;

export default withTranslation()(WithRouter(InvoiceList));

