import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Field, Form } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Col, Row } from "react-bootstrap";
import { InputField } from "../../../../components/Shared/InputField";
import { Sidebar } from "primereact/sidebar";
import { get, has, includes, trim, find, isEmpty, findIndex } from "lodash";
import { Button } from "primereact/button";
import { random } from "../../../../utils/Helper";
import InputAttachment from "../../../../components/Shared/InputAttachment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

type TInputForm = {
    title: string,
    document: string,
    token: string,
}

const DEFAULT_FORM_STATE = {
    token: random(10),
    title: null,
    document: null,
};

interface IProps {
    moduleState?: any,
    setQuery?: any,
    documents?: any,
    onUpdate?: any,
    t?: any,
    toastify: any,
    params?: any,
    dropdowns?: any,
    onClose?: any,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        title: yup.string().required(t('the field is required.', { field: t('title') })).nullable(),
        document: yup.string().required(t('the field is required.', { field: t('document') })).nullable(),
    });
};

function AccountDocument(props: IProps) {
    const { t, toastify, params, dropdowns } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<any>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);
    const [operationId, setOperationId] = useState<any>(null);
    const [entity, setEntity] = useState<any>({});
    const [documents, setDocuments] = useState<any>([]);

    // const [searchParams] = useSearchParams();

    const onClose = () => {
        setPanelState(false);
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm();
        }

        if ( props.onClose ) {
            props.onClose();
        }
    };


    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        const _documents: any = [...documents];
        let existing = find(documents, { token: payloads.token });

        if ( !isEmpty(existing) ) {
            const index = findIndex(_documents, { token: payloads.token });
            existing = { ...payloads };
            _documents[index] = existing;
        } else {
            _documents.push({
                ...payloads,
                token: random(10),
            });
        }

        try {
            const response = await request({
                method: 'PATCH',
                url: `/admin/suppliers/${ entity.id }`,
                data: {
                    documents: _documents.map((item: any) => ( {
                        ...item,
                        document: item.document.id ?? item.document
                    } )),
                    email: entity.email,
                    first_name: entity.first_name,
                    last_name: entity.last_name,
                    phone: entity.phone,
                    is_active: entity.is_active > 0,
                    identification_number: entity.identification_number
                },
            });

            toastify(t("record updated", { item: t('document') }), "success");

            resetForm();
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");

            }
        }
    };

    const onReset = () => {
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm();
        }
    };


    const onEdit = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/admin/suppliers/${ id }`
            });

            const { data } = response.data;

            setEntity(data);
            setDocuments(data.documents.map((item: any) => ( { ...item, token: item.id } )));
            setOperationId(id);
            setPanelState(true);
        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };


    const onDelete = (token: any) => {
        const _documents: any = documents.filter((item: any) => item.token !== token);
        setDocuments(_documents);
    };

    const onEditDocument = (item: any) => {
        const formData: TInputForm = {
            'token': item.token,
            'title': item.title,
            'document': item.document.id
        };
        setInitFormState(formData);
    };


    const actionBodyTemplate = (rowData: any) => {
        return (
            <React.Fragment>
                <a className={ 'me-2' } onClick={ () => onEditDocument(rowData) }>
                    <i className={ 'fas fa-edit' } />
                </a>
                <a className={ 'me-2' } href={ `${ process.env.REACT_APP_DOCUMENT_URL }/${ rowData.id }` }
                   target="_blank">
                    <i className={ 'fas fa-download' } />
                </a>
                <a onClick={ () => onDelete(rowData.token) }>
                    <i className={ 'fas fa-trash' } />
                </a>
            </React.Fragment>
        );
    };

    const onAfterUpload = (name: any, size: any) => {
        const base: any = get(formRef.current, ['values']);

        setInitFormState({
            ...base,
            title: name,
            size: size
        });
    };

    useEffect(() => {
        setDocuments([]);
        setInitFormState(DEFAULT_FORM_STATE);
        if ( includes(['account-documents'], get(params, ['operation'])) ) {
            onEdit(params.operationId);
        }
    }, [params]);

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('account documents') + ' ' + t('action') }</h5>
                            <p>{ t("using this form you can add or update the record") }</p>
                        </div>
                    }
                    visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                        validationSchema={ validationSchema(t) }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4" }>
                                        <Col sm={ 12 }>
                                            <Field component={ InputField }
                                                   name="title"
                                                   label={ t("title") }
                                                   placeholder={ "Ex. Contract Copy" }
                                                   isRequired />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Row>
                                                <Col sm={ 12 } md={ 8 }>
                                                    <Field component={ InputAttachment }
                                                           name="document"
                                                           label={ t("document") }
                                                           needBoolean
                                                           isRequired
                                                           onAfterUpload={ onAfterUpload }
                                                    />
                                                </Col>
                                                <Col sm={ 12 } md={ 4 } className={ "text-end" }>
                                                    <div><label className="text-g-800 form-label">&nbsp;</label></div>
                                                    <Button type="button" size={ "small" }
                                                            onClick={ () => props.handleSubmit() }
                                                            title={ t("to data save into database") }
                                                            className="btn btn-success waves-effect waves-light">
                                                        <i className="fas fa-plus" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className={ "mt-4" }>
                                        <DataTable value={ documents } editMode="row" dataKey="id">
                                            <Column field="title" header="Name" />
                                            <Column body={ actionBodyTemplate }
                                                    headerStyle={ { width: '10%', minWidth: '8rem' } }
                                                    bodyStyle={ { textAlign: 'center' } } />
                                        </DataTable>
                                    </Row>
                                    <div className="filter-card-footer"
                                         style={ { width: '785px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-save me-2" />
                                            { t("let's save") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ () => onReset() }
                                                tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                size={ "small" } severity={ "warning" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-refresh me-2" />
                                            { t("reset") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ () => onClose() }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("cancel") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

AccountDocument.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(AccountDocument);
