import { withTranslation } from "react-i18next";
import WithRouter from "../../../../../components/Common/WithRouter";
import React, { useRef, useState } from "react";
import { request } from "../../../../../utils/Request";
import Tooltip from "../../../../../components/Common/Display/Tooltip";
import HasAccess from "../../../../../utils/HasAccess";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import StateLessGrid from "../../../../../components/Shared/StateLessGrid";
import { Button } from "primereact/button";
import { Col, Row } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../../../../components/Shared/InputField";
import { utcDateToLocalDate } from "../../../../../utils/Helper";
import { ButtonGroup } from "primereact/buttongroup";

const SentList = (props: any) => {
    const { t, moduleState, toastify } = props;
    const [initSearchFormState] = useState<any>({
        search: moduleState.search ?? null
    });
    const [total, setTotal] = useState(0);
    const tableRef: any = useRef();

    const onConfirm = async (payloads: any): Promise<void> => {
        try {
            await request({
                url: '/suppliers/sent/webhook/logs',
                method: 'POST',
                data: { token: payloads.id }
            });

            toastify(t("record deleted", { item: t('user') }), "warning");
            onReset();
        } catch (e: any) {
            toastify(t("server error"), "error");
        }
    };

    const columns = [
        {
            field: 'props',
            title: t('vin number'),
            sorting: false,
            render: (_row: any) => ( JSON.parse(_row.parameters) || {} ).vin_number,
        },
        {
            field: 'url', title: t('url'),
            render: (_row: any) => ( _row.url ),
        },
        {
            field: 'type',
            title: t('type'),
            sorting: false,
            render: (_row: any) => {
                return ( _row.webhook_url?.type );
            },
        },
        {
            field: 'called_at', title: t('called at'),
            render: (_row: any) => utcDateToLocalDate(_row.called_at)
        },
        {
            field: 'failed_at', title: t('failed at'),
            render: (_row: any) => utcDateToLocalDate(_row.failed_at)
        },
        {
            field: 'created_at', title: t('created at'),
            render: (_row: any) => utcDateToLocalDate(_row.created_at)
        },
        {
            field: 'action',
            title: t('action'),
            sorting: false,
            align: 'right',
            render: (_row: any) => {
                return (
                    <>
                        <HasAccess hasAllAccess={ ['webhooklogsview'] }>
                            <Tooltip
                                tooltip={ t('resend hook') }>
                                <a
                                    title={ t('resend hook') }
                                    className={ "cursor-pointer ms-2 btn btn-soft-danger waves-effect waves-light btn-xs" }
                                    onClick={ (event: any) => confirmPopup({
                                        target: event.currentTarget,
                                        message: t('resend hook') + ' ?',
                                        icon: 'pi pi-info-circle',
                                        // @ts-ignorer
                                        defaultFocus: 'reject',
                                        acceptClassName: 'p-button-danger',
                                        accept: () => onConfirm(_row),
                                        reject: () => {
                                        },
                                    }) }>
                                    <i className="fas fa-redo-alt" />
                                </a>
                            </Tooltip>
                        </HasAccess>
                    </>
                );
            }
        },
    ];

    const onReset = () => {
        tableRef.current.onRefresh();
    };

    const onSearch = ({ search }: any) => {
        props.setQuery({
            ...moduleState,
            search: search
        });
    };

    return (
        <div className={ "block-viewer" }>
            <div className="block-section">
                <div className="block-header">
                    <span className="block-title">
                        <h5 className={ "mb-0" }>{ t('webhook') } ({total})</h5>
                    </span>
                    <div className="block-actions">
                    </div>
                </div>
                <div className="block-content">
                    <Row className={ "p-2" }>
                        <Col
                            sm={ 12 } md={ 6 } className={ "mb-2" }>
                            <Formik
                                enableReinitialize={ true }
                                initialValues={ initSearchFormState }
                                onSubmit={ onSearch }
                            >
                                { (props: FormikProps<any>) => {
                                    return (
                                        <Form onSubmit={ props.handleSubmit }>
                                            <Col sm={ 12 } md={ 8 }>
                                                <Field component={ InputField } withoutLabel={ true }
                                                       autoFocus={ true }
                                                       name="search"
                                                       formGroupClass={ "mb-0" }
                                                       placeholder={ t("search") } />
                                            </Col>
                                        </Form>
                                    );
                                } }
                            </Formik>
                        </Col>

                        <Col
                            sm={ 12 } md={ 6 } className={ "text-right mb-2" }>
                                <ButtonGroup>
                                    <Button onClick={ onReset } icon="pi pi-refresh" size={ "small" } outlined
                                            tooltip={ t('reset') }
                                            tooltipOptions={ { position: 'top' } } />
                                </ButtonGroup>
                        </Col>

                        <Col sm={ 12 }>
                            <StateLessGrid
                                size={ "normal" }
                                url={ '/suppliers/sent/webhook/logs?1=1' }
                                name={ "webhookSentList" }
                                columns={ columns }
                                moduleState={ moduleState }
                                setQuery={ props.setQuery }
                                ref={ tableRef }
                                itemSize={ 45 }
                                setTotal={ setTotal }
                                // detailRow={ (data: any) => <DetailRow data={ data } /> }
                            />
                        </Col>
                    </Row>

                    <ConfirmPopup />
                </div>
            </div>
        </div>
    );
};

SentList.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(SentList));
