import { PrimeReactContext } from "primereact/api";
import { InputSwitchChangeEvent } from "primereact/inputswitch";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { Sidebar } from "primereact/sidebar";
import React, { useContext, useEffect, useRef, useState } from "react";
import { LayoutContext } from "../../contexts/LayoutContext";
import { AppConfigProps, ColorScheme } from "../../types";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { request } from "../../utils/Request";
import { Toast } from "primereact/toast";
import Auth from "../../utils/Auth";
import store from "store";

const AppConfig = (props: AppConfigProps) => {
    const {
        layoutConfig,
        setLayoutConfig,
        layoutState,
        setLayoutState,
        isSlim,
        isSlimPlus,
        isHorizontal,
    } = useContext(LayoutContext);
    const { changeTheme } = useContext(PrimeReactContext);
    const { t } = useTranslation();
    const scales = [12, 13, 14, 15, 16];
    const componentThemes = [
        { name: "indigo", color: "#6366F1" },
        { name: "blue", color: "#3B82F6" },
        { name: "purple", color: "#8B5CF6" },
        { name: "teal", color: "#14B8A6" },
        { name: "cyan", color: "#06b6d4" },
        { name: "green", color: "#10b981" },
        { name: "orange", color: "#f59e0b" },
        { name: "pink", color: "#d946ef" },
    ];
    const toast = useRef(null);
    const auth = new Auth();
    const user: any = auth.getUser();

    const toastify = (message: any, color: string = 'info', summary: any = null) => {
        if ( toast && toast.current ) {
            // @ts-ignore
            toast.current.show({ severity: color, summary: summary, detail: message });
        }
    };

    const onSubmit = async () => {
        try {
            const response = await request({
                method: 'patch',
                url: 'auth/app/settings',
                data: { app_settings: layoutConfig, _method: "post" }
            });

            const { data } = response.data;

            if ( data.app_settings ) {
                store.set(`${ process.env.REACT_APP_STORAGE_PREFIX }.layoutConfig`, data.app_settings);
                user.app_settings = data.app_settings;
                auth.setUser(user)
            }

            // auth.setAppSettings(data.app_settings)

            toastify(t("record updated", { item: t('app settings') }), "success");
            setTimeout(() => window.location.reload(), 1000);
        } catch (e: any) {

        }
    };

    useEffect(() => {
        if ( isSlim() || isSlimPlus() || isHorizontal() ) {
            setLayoutState((prevState) => ( { ...prevState, resetMenu: true } ));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutConfig.menuMode]);

    const onConfigButtonClick = () => {
        setLayoutState((prevState) => ( {
            ...prevState,
            configSidebarVisible: true,
        } ));
    };

    const onConfigSidebarHide = () => {
        setLayoutState((prevState) => ( {
            ...prevState,
            configSidebarVisible: false,
        } ));
    };

    const changeInputStyle = (e: RadioButtonChangeEvent) => {
        setLayoutConfig((prevState) => ( { ...prevState, inputStyle: e.value } ));
    };

    const changeRipple = (e: InputSwitchChangeEvent) => {
        setLayoutConfig((prevState) => ( {
            ...prevState,
            ripple: e.value as boolean,
        } ));
    };

    const changeMenuMode = (e: RadioButtonChangeEvent) => {
        setLayoutConfig((prevState) => ( { ...prevState, menuMode: e.value } ));
    };

    const changeMenuTheme = (e: RadioButtonChangeEvent) => {
        setLayoutConfig((prevState) => ( { ...prevState, menuTheme: e.value } ));
    };

    const changeColorScheme = (colorScheme: ColorScheme) => {
        changeTheme?.(
            layoutConfig.colorScheme,
            colorScheme,
            "theme-link",
            () => {
                setLayoutConfig((prevState) => ( { ...prevState, colorScheme } ));
            }
        );
    };

    const _changeTheme = (theme: string) => {
        changeTheme?.(layoutConfig.theme, theme, "theme-link", () => {
            setLayoutConfig((prevState) => ( { ...prevState, theme } ));
        });
    };

    const decrementScale = () => {
        setLayoutConfig((prevState) => ( {
            ...prevState,
            scale: prevState.scale - 1,
        } ));
    };

    const incrementScale = () => {
        setLayoutConfig((prevState) => ( {
            ...prevState,
            scale: prevState.scale + 1,
        } ));
    };

    const applyScale = () => {
        document.documentElement.style.fontSize = layoutConfig.scale + "px";
    };

    const changeLanguage = (e: any) => {
        // @ts-ignore
        setLayoutConfig((prevState) => ( { ...prevState, lang: e } ));
    };

    useEffect(() => {
        applyScale();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutConfig.scale]);

    return (
        <>
            <button
                className="layout-config-button config-link"
                type="button"
                onClick={ onConfigButtonClick }
            >
                <i className="pi pi-cog"></i>
            </button>

            <Sidebar
                visible={ layoutState.configSidebarVisible }
                onHide={ onConfigSidebarHide }
                position="right"
                className="layout-config-sidebar w-18rem"
            >

                <Toast ref={ toast } />
                <h5 className={"mt-1"}>{ t('themes') }</h5>
                <div className="flex flex-wrap row-gap-3">
                    { componentThemes.map((theme, i) => {
                        return (
                            <div key={ i } className="w-3">
                                <button
                                    type="button"
                                    className="cursor-pointer p-link w-2rem h-2rem border-circle flex-shrink-0 flex align-items-center justify-content-center"
                                    onClick={ () => _changeTheme(theme.name) }
                                    style={ { backgroundColor: theme.color } }
                                >
                                    { theme.name == layoutConfig.theme && (
                                        <i className="pi pi-check text-white"></i>
                                    ) }
                                </button>
                            </div>
                        );
                    }) }
                </div>

                <h5>{ t('scales') }</h5>
                <div className="flex align-items-center">
                    <Button
                        icon="pi pi-minus"
                        type="button"
                        onClick={ decrementScale }
                        className="w-2rem h-2rem mr-2"
                        rounded
                        text
                        disabled={ layoutConfig.scale === scales[0] }
                    ></Button>
                    <div className="flex gap-2 align-items-center">
                        { scales.map((s, i) => {
                            return (
                                <i
                                    key={ i }
                                    className={ classNames(
                                        "pi pi-circle-fill text-300",
                                        {
                                            "text-primary-500":
                                                s === layoutConfig.scale,
                                        }
                                    ) }
                                ></i>
                            );
                        }) }
                    </div>
                    <Button
                        icon="pi pi-plus"
                        type="button"
                        onClick={ incrementScale }
                        className="w-2rem h-2rem ml-2"
                        rounded
                        text
                        disabled={
                            layoutConfig.scale === scales[scales.length - 1]
                        }
                    ></Button>
                </div>

                { !props.minimal && (
                    <>
                        <h5>{ t('menu theme') }</h5>
                        <div className="field-radiobutton">
                            <RadioButton
                                name="menuTheme"
                                value="colorScheme"
                                checked={
                                    layoutConfig.menuTheme === "colorScheme"
                                }
                                onChange={ (e) => changeMenuTheme(e) }
                                inputId="menutheme-colorscheme"
                            ></RadioButton>
                            <label htmlFor="menutheme-colorscheme">
                                { t('color scheme') }
                            </label>
                        </div>
                        <div className="field-radiobutton">
                            <RadioButton
                                name="menuTheme"
                                value="primaryColor"
                                checked={
                                    layoutConfig.menuTheme === "primaryColor"
                                }
                                onChange={ (e) => changeMenuTheme(e) }
                                inputId="menutheme-primarycolor"
                            ></RadioButton>
                            <label htmlFor="menutheme-primarycolor">
                                { t('primary color') }
                            </label>
                        </div>
                        <div className="field-radiobutton">
                            <RadioButton
                                name="menuTheme"
                                value="transparent"
                                checked={
                                    layoutConfig.menuTheme === "transparent"
                                }
                                onChange={ (e) => changeMenuTheme(e) }
                                inputId="menutheme-transparent"
                            ></RadioButton>
                            <label htmlFor="menutheme-transparent">
                                { t('transparent') }
                            </label>
                        </div>
                    </>
                ) }

                <h5>{ t('color scheme') }</h5>
                <div className="field-radiobutton">
                    <RadioButton
                        name="colorScheme"
                        value="light"
                        checked={ layoutConfig.colorScheme === "light" }
                        onChange={ (e) => changeColorScheme(e.value) }
                        inputId="mode-light"
                    ></RadioButton>
                    <label htmlFor="mode-light">{ t('light') }</label>
                </div>
                <div className="field-radiobutton">
                    <RadioButton
                        name="colorScheme"
                        value="dim"
                        checked={ layoutConfig.colorScheme === "dim" }
                        onChange={ (e) => changeColorScheme(e.value) }
                        inputId="mode-dim"
                    ></RadioButton>
                    <label htmlFor="mode-dim">{ t('dim') }</label>
                </div>
                <div className="field-radiobutton">
                    <RadioButton
                        name="colorScheme"
                        value="dark"
                        checked={ layoutConfig.colorScheme === "dark" }
                        onChange={ (e) => changeColorScheme(e.value) }
                        inputId="mode-dark"
                    ></RadioButton>
                    <label htmlFor="mode-dark">{ t('dark') }</label>
                </div>

                <h5>{ t('language') }</h5>
                <div className="field-radiobutton">
                    <RadioButton
                        name="language"
                        value="eng"
                        checked={ layoutConfig.lang === "eng" }
                        onChange={ (e) => changeLanguage(e.value) }
                        inputId="lang-eng"
                    ></RadioButton>
                    <label htmlFor="lang-en">{ t('english') }</label>
                </div>
                <div className="field-radiobutton">
                    <RadioButton
                        name="language"
                        value="gr"
                        checked={ layoutConfig.lang === "gr" }
                        onChange={ (e) => changeLanguage(e.value) }
                        inputId="lang-gr"
                    ></RadioButton>
                    <label htmlFor="lang-de">{ t('german') }</label>
                </div>
                <div className="field-radiobutton">
                    <RadioButton
                        name="language"
                        value="rs"
                        checked={ layoutConfig.lang === "rs" }
                        onChange={ (e) => changeLanguage(e.value) }
                        inputId="lang-rs"
                    ></RadioButton>
                    <label htmlFor="lang-rs">{ t('russian') }</label>
                </div>
                <div className="field-radiobutton">
                    <RadioButton
                        name="language"
                        value="it"
                        checked={ layoutConfig.lang === "it" }
                        onChange={ (e) => changeLanguage(e.value) }
                        inputId="lang-it"
                    ></RadioButton>
                    <label htmlFor="lang-it">{ t('italian') }</label>
                </div>
                <div className="field-radiobutton">
                    <RadioButton
                        name="language"
                        value="sp"
                        checked={ layoutConfig.lang === "sp" }
                        onChange={ (e) => changeLanguage(e.value) }
                        inputId="lang-sp"
                    ></RadioButton>
                    <label htmlFor="lang-sp">{ t('spanish') }</label>
                </div>
                <div className="field-radiobutton">
                    <RadioButton
                        name="language"
                        value="sl"
                        checked={ layoutConfig.lang === "sl" }
                        onChange={ (e) => changeLanguage(e.value) }
                        inputId="lang-sl"
                    ></RadioButton>
                    <label htmlFor="lang-sl">{ t('slovenian') }</label>
                </div>

                <Button onClick={ () => onSubmit() }
                        tooltip={ t("to data save into database") }
                        size={ "small" } outlined
                        tooltipOptions={ { position: 'top' } }
                >
                    <i className="pi pi-save me-2" />
                    { t("let's save") }
                </Button>

            </Sidebar>
        </>
    );
};

export default AppConfig;
