import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Field, Form } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Col, Row } from "react-bootstrap";
import { InputField } from "../../../../components/Shared/InputField";
import { Sidebar } from "primereact/sidebar";
import { get, includes } from "lodash";
import { Button } from "primereact/button";
import { InputSingleSelectField } from "../../../../components/Shared/InputSingleSelectField";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    dropdowns: any;
    params: any;
    toastify: any;
    onClose?: any;
    operationCancel?: any;
}

type TInputForm = {
    id: null | any,
    address: null | any,
    street_line_1: null | any,
    street_line_2: null | any,
    landmark: null | any,
    zip: null | any,
    country: null | any,
    city: null | any,
    is_disable: null | any,
    latitude: null | any,
    longitude: null | any,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        address: yup.string().trim(t('the field is invalid.')).max(100).nullable(), // required(t('the field is required.', { field: t('address') })).
        city: yup.string().trim(t('the field is invalid.')).required(t('the field is required.', { field: t('city') })).max(100).nullable(),
        street_line_1: yup.string().trim(t('the field is invalid.')).max(100).nullable(), // .required(t('the field is required.', { field: t('location name') }))
        zip: yup.string().trim(t('the field is invalid.')).required(t('the field is required.', { field: t('zip') })).max(50).nullable(),
        landmark: yup.string().trim(t('the field is invalid.')).max(50).nullable(), // required(t('the field is required.', { field: t('landmark') }))
        street_line_2: yup.string().trim(t('the field is invalid.')).nullable().max(100).nullable(),
        country: yup.string().required(t('the field is required.', { field: t('country') })).nullable(),
    });
};

const DEFAULT_FORM_STATE = {
    id: null,
    address: null,
    street_line_1: null,
    street_line_2: null,
    landmark: null,
    zip: null,
    country: null,
    city: null,
    is_disable: false,
    latitude: null,
    longitude: null,
};

function LocationForm(props: IFormProps) {
    const { t, toastify, params, dropdowns, operationCancel } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: ( !payloads.id ? 'POST' : 'PATCH' ),
                url: ( !payloads.id ? `/suppliers/locations` : `/suppliers/locations/${ payloads.id }` ),
                data: {
                    ...payloads,
                },
            });

            if ( payloads.id ) {
                toastify(t("record updated", { item: t('location') }), "success");
            } else {
                toastify(t("record added", { item: t('location') }), "info");
            }

            resetForm();
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");

            }
        }
    };

    const onReset = () => {
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm(
                !includes(['edit'], get(params, ['operation']))
            );
        }
    };

    const onClose = () => {
        setPanelState(false);
        onReset();

        if ( props.onClose ) {
            props.onClose();
        }
    };

    const onEdit = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/locations/${ id }`
            });

            const { data } = response.data;
            const formData: TInputForm = { ...data }; // , brand_id: brand.id
            setInitFormState(formData);
            setPanelState(true);
        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        if ( includes(['add'], get(params, ['operation'])) ) {
            setPanelState(true);
        }

        if ( includes(['edit'], get(params, ['operation'])) ) {
            onEdit(params.operationId);
        }
    }, [params]);

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('location') + ' ' + t('action') }</h5>
                            <p>{ t("using this form you can add or update the record") }</p>
                        </div>
                    }
                    visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } } closeOnEscape={ false }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                        validationSchema={ validationSchema(t) }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4 md:mb-7 sm:mb-7" }>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="street_line_1"
                                                   label={ t("location name") }
                                                   placeholder={ t("enter") + ' ' + t("location name").toLowerCase() }
                                                   autoFocus />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="street_line_2"
                                                   label={ t("street name") }
                                                   placeholder={ t("enter") + ' ' + t("street name").toLowerCase() }
                                                   disabled={ props.values.is_disable }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="city"
                                                   label={ t("city") }
                                                   placeholder={ t("enter") + ' ' + t("city").toLowerCase() }
                                                   disabled={ props.values.is_disable }
                                                   isRequired
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="zip"
                                                   label={ t("zip") }
                                                   placeholder={ t("enter") + ' ' + t("zip").toLowerCase() }
                                                   disabled={ props.values.is_disable }
                                                   isRequired
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSingleSelectField }
                                                   name="country"
                                                   options={ dropdowns?.countries }
                                                   label={ t('country') }
                                                   disabled={ props.values.is_disable }
                                                   placeholder={ t("pick") + ' ' + t("country").toLowerCase() }
                                                   panelClassName={ "hide-p-toggler" }
                                                   isRequired
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="landmark"
                                                   label={ t("landmark") }
                                                   placeholder={ t("enter") + ' ' + t("landmark").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   type="number" step="0.1"
                                                   name="latitude"
                                                   label={ t("latitude") }
                                                   placeholder={ t("enter") + ' ' + t("latitude").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   type="number" step="0.1"
                                                   name="longitude"
                                                   label={ t("longitude") }
                                                   placeholder={ t("enter") + ' ' + t("longitude").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="address"
                                                   label={ t("address") }
                                                   placeholder={ t("enter") + ' ' + t("address").toLowerCase() }
                                            />
                                        </Col>
                                    </Row>
                                    <div className="filter-card-footer"
                                         style={ { width: '785px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-save me-2" />
                                            { t("let's save") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ onReset }
                                                tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                size={ "small" } severity={ "warning" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-refresh me-2" />
                                            { t("reset") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                                onClick={ () => onClose() }
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("cancel") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

LocationForm.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(LocationForm);
