import React from 'react';
import { Editor } from 'primereact/editor';
import { ErrorMessage } from "formik";
import { FieldLabel } from "./FieldLabel";
import { FormGroup } from "react-bootstrap";

interface IInputFieldProps {
    label?: string,
    field: any,
    form?: any,
    icon?: any,
    isRequired?: boolean,
    formGroupClass?: any,
    placeholder?: any,
    invalid?: boolean,
    height?: any
}

export class InputQuillEditor extends React.Component<IInputFieldProps, {}> {
    static defaultProps = {
        isRequired: false,
        height: "200px"
    };

    constructor(props: IInputFieldProps) {
        super(props);
    }

    setValue = (value: any) => {
        this.props.form.setFieldValue(this.props.field.name, value);
    };

    render() {
        const {
            field,
            form,
            isRequired,
            formGroupClass,
            height,
            ...props
        } = this.props;
        const { errors } = form;
        return (
            <FormGroup className={ formGroupClass }>
                <FieldLabel { ...this.props } />
                <div className="widget-container">
                    <Editor
                        style={ { height: height } }
                        value="Always bet on Prime!"
                        onTextChange={ (e: any) => this.setValue(e.htmlValue) }
                        { ...props }
                    />
                </div>
                <ErrorMessage name={ field.name } component="div"
                              className="invalid-feedback first-letter-capitalized" />
            </FormGroup>
        );
    }
}
