import React, { useRef } from "react";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { get, map, includes, isEmpty, sumBy, find } from "lodash";
import PropTypes from "prop-types";
import { request } from "../../../../utils/Request";
import { Field, Formik, FormikProps, Form } from "formik";
import { setLastUpdate } from "../../../../utils/Helper";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputField } from "../../../../components/Shared/InputField";
import { Col, Row } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import InputGroupText from "react-bootstrap/InputGroupText";
import { InputSingleSelectField } from "../../../../components/Shared/InputSingleSelectField";
import { InputSwitchField } from "../../../../components/Shared/InputSwitchField";
import { Divider } from "primereact/divider";

type TInputForm = {
    a_s_price: number,
    price: number,
    price_type: string,
    invoiceable_at: any | boolean,
    is_custom_km: any | boolean,
    number_of_km: number,
    cmr: any,
    orders: any,
}

const DEFAULT_FORM_STATE = {
    a_s_price: 0,
    price: 0,
    number_of_km: 0,
    price_type: 'per_load',
    invoiceable_at: false,
    is_custom_km: false,
    cmr: null,
    orders: []
};

interface IProps {
    id?: any,
    onClose: any;
    dropdowns: any,
    toastify: any,
    t?: any
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        a_s_price: yup.number().min(0, t("the field value is not valid", { field: t('total') })).required(t('the field is required.', { field: t('total') })).nullable(),
        price: yup.number().min(0, t("the field value is not valid", { field: t('price per unit') })).required(t('the field is required.', { field: t('price per unit') })).nullable(),
        price_type: yup.string().required(t('the field is required.', { field: t('price type') })).nullable(),
        number_of_km: yup.number().min(0, t("the field value is not valid", { field: t('number of kilometers') })).required(t('the field is required.', { field: t('number of kilometers') })).nullable(),
        cmr: yup.string().nullable(),
        orders: yup.array().nullable(),
    });
};

function AlpSpotLoadUpdatePrice(props: IProps) {
    const { t, toastify, id } = props;
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const toggle = () => setOpen(!open);
    const [initFormState, setInitFormState] = useState<any>({ ...DEFAULT_FORM_STATE });
    const [entity, setEntity] = useState<any>(null);
    const [previousTotal, setPreviousTotal] = useState(0);
    const formRef: any = useRef();

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: 'POST',
                url: `/suppliers/alp/spot/loads/${ props.id }/update/price`,
                data: {
                    ...payloads,
                },
            });

            toastify(t("record updated", { item: t('price') }), "success");
            setLastUpdate(props.id, 'AlpSpotLoad');
            onClose();
            setInitFormState({ ...DEFAULT_FORM_STATE });
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    const onDetail = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/alp/spot/loads/${ id }`
            });

            const { data } = response.data;

            const { alp_spot_load_route_price } = data;

            const orderDefaultPrice = data.alp_spot_load_orders.map((load_order: any) => ({
                id: load_order.id,
                input_price: load_order.input_price ?? 0,
            }));

            setInitFormState({
                ...DEFAULT_FORM_STATE,
                invoiceable_at: ( data.status === "unloaded" || data.status === "archived" ) ? true : !isEmpty(data.invoiceable_at),
                a_s_price: get(alp_spot_load_route_price, ['price_type']) == 'per_unit' ? orderDefaultPrice.reduce((sum:any, item:any) => sum + (parseFloat(item.input_price) || 0), 0) : (get(alp_spot_load_route_price, ['total']) ?? 0),
                number_of_km: get(alp_spot_load_route_price, ['total_km']) ?? 0,
                is_custom_km: get(alp_spot_load_route_price, ['is_custom_km']) ?? false,
                price_type: get(alp_spot_load_route_price, ['price_type']) ?? "per_load",
                price: get(alp_spot_load_route_price, ['input_price']) ?? 0,
                cmr: get(data, ['cmr']),
                orders: orderDefaultPrice
            });

            setEntity(data);
            if ( get(alp_spot_load_route_price, ['price_type']) == 'per_unit' ) {
                setPreviousTotal(orderDefaultPrice.reduce((sum:any, item:any) => sum + (parseFloat(item.input_price) || 0), 0));
            }

            setOpen(true);

        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };

    const onClose = () => {
        setOpen(false);
        setEntity(null);
        props.onClose();
    };

    const onSelectPriceType = (value: any) => {
        if ( value.id === 'per_load' ) {
            setInitFormState({
                ...get(formRef.current, ['values']),
                price_type: value.id,
                price: 0
            });
        }
    };

    const onBlurPerUnit = (value: any) => {
        const prices = {
            a_s_price: get(formRef.current.values, ['a_s_price']),
            price: value > 0 ? value : 0,
        };

        if ( formRef.current && formRef.current.values && formRef.current.values.price_type === 'per_unit' ) {
            prices.a_s_price = Number(( prices.price * entity.qty ).toFixed(2));
        } else if ( formRef.current && formRef.current.values && formRef.current.values.price_type === 'per_km' && formRef.current.values.number_of_km > 0 ) {
            prices.a_s_price = Number(( prices.price * Number(formRef.current.values.number_of_km) ).toFixed(2));
        } else {
            prices.a_s_price = Number(0).toFixed(2);
        }

        setInitFormState({
            ...get(formRef.current, ['values']),
            ...prices
        });
    };

    const onBlurNumberOfKilometer = (value: any) => {
        const prices = {
            a_s_price: get(formRef.current.values, ['a_s_price']),
            price: get(formRef.current.values, ['price']),
        };

        if ( formRef.current && formRef.current.values && formRef.current.values.price_type === 'per_km' && value > 0 ) {
            prices.a_s_price = ( prices.price * Number(value) ).toFixed(2);
        } else {
            prices.a_s_price = Number(0).toFixed(2);
        }

        setInitFormState({
            ...get(formRef.current, ['values']),
            ...prices
        });
    };

    const onBlurPriceTotal = (value: any) => {
        if (get(formRef.current.values, ['price_type']) === 'per_unit') {
            const orders = get(formRef.current.values, ['orders']);
            const ordersLength = orders.length;

            if ( ordersLength > 0 ) {
                const newPricePerOrder: any = Math.max(value / ordersLength, 0);
                const updatedOrders = orders.map((order: any) => ( {
                    ...order,
                    input_price: parseFloat(newPricePerOrder).toFixed(2),
                } ));

                setInitFormState({
                    ...get(formRef.current, ['values']),
                    orders: updatedOrders,
                    a_s_price: value,
                });
            }
        }
    };

    const onBlurOrderPrice = (value: any, loadOrderId: string) => {
        const orders: any[] = [];

        map(get(formRef.current.values, ['orders']), (loadOrder: any) => {
            if ( loadOrder.id === loadOrderId ) {
                orders.push({
                    id: loadOrderId,
                    input_price: parseFloat(value).toFixed(2)
                });
            } else {
                orders.push(loadOrder);
            }
        });

        setInitFormState({
            ...get(formRef.current, ['values']),
            orders: orders,
            a_s_price: sumBy(orders, (item: any) => (item.input_price ? parseFloat(item.input_price): 0)).toFixed(2)
        });
    };

    useEffect(() => {
        if ( !isEmpty(id) ) {
            onDetail(id);
        }
    }, [id]);

    return (
        <div>
            <Formik
                innerRef={ formRef }
                enableReinitialize={ true }
                initialValues={ initFormState }
                onSubmit={ onSubmit }
                validationSchema={ validationSchema(t) }
            >
                { (props: FormikProps<TInputForm>) => {
                    const {values} = props;

                    return (
                        <>
                            <Dialog
                                header={ t("update") + `#${ get(entity, ['load_number']) }` }
                                visible={ open }
                                style={{ width: '600px' }}
                                modal
                                onHide={ () => {
                                    if ( !open ) {
                                        return;
                                    }
                                    onClose();
                                } }>
                                <div>
                                    <Form onSubmit={ props.handleSubmit }>
                                        <div className="flex align-items-center gap-2">
                                            <Row>
                                                {
                                                    // entity && !includes(['delivered', 'unloaded', 'archived'], entity.status)
                                                    entity
                                                    &&
                                                    <>
                                                        <Col sm={ 12 } md={ 6 }>
                                                            <label>{ t("is billable") }</label>
                                                            <div>
                                                                <Field component={ InputSwitchField }
                                                                       name="invoiceable_at"
                                                                       trueLabel={ t("yes") }
                                                                       falseLabel={ t("no") }
                                                                       disabled={ ( entity && ( entity.status === "unloaded" || entity.status === "archived" ) ) }
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col sm={ 12 } md={ 6 }>
                                                            <Field component={ InputSingleSelectField }
                                                                   name="price_type"
                                                                   options={ [
                                                                       { key: "per_load", label: t("per load") },
                                                                       { key: "per_unit", label: t("per unit") },
                                                                       { key: "per_km", label: t("per km") },
                                                                   ] }
                                                                   label={ t('price type') }
                                                                   onSelect={ (value: any) => onSelectPriceType(value) }
                                                            />
                                                        </Col>
                                                        { (props.values.price_type == 'per_km') &&
                                                        <Col sm={ 12 } md={ 6 }>
                                                            <Field component={ InputField }
                                                                   name="price"
                                                                   label={ props.values.price_type === 'per_km' ? t("price per km") : t("price per unit") }
                                                                   onBlur={ (event: any) => onBlurPerUnit(event.target.value) }
                                                                   isRequired />
                                                        </Col>
                                                        }
                                                        <Col sm={ 12 }
                                                             md={ ( props.values.price_type == 'per_km' ? 6 : 12 ) }>
                                                            <Field component={ InputField }
                                                                   name="a_s_price"
                                                                   label={ t("total") }
                                                                   onBlur={ (event: any) => onBlurPriceTotal(event.target.value) }
                                                                   isRequired />
                                                        </Col>

                                                        {
                                                            props.values.price_type === "per_km" &&
                                                            <>
                                                                <Col sm={ 12 } md={ 6 }>
                                                                    <label>{ t("custom") }</label>
                                                                    <div>
                                                                        <Field component={ InputSwitchField }
                                                                               name="is_custom_km"
                                                                               trueLabel={ t("yes") }
                                                                               falseLabel={ t("no") }
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col sm={ 12 } md={ 6 }>
                                                                    <label>{ t("number of kilometers") }</label>
                                                                    <div className={ "mb-3" }>
                                                                        <Field component={ InputField }
                                                                               type={"number"}
                                                                               formGroupClass={ "mb-0-c" }
                                                                               name="number_of_km"
                                                                               onBlur={ (event: any) => onBlurNumberOfKilometer(event.target.value) }
                                                                               withoutLabel={ true }
                                                                               disabled={ props.values.is_custom_km != true }
                                                                           isRequired />
                                                                    </div>
                                                                </Col>
                                                            </>
                                                        }
                                                    </>
                                                }

                                                <Col sm={ 12 }>
                                                    <Field component={ InputField }
                                                           name="cmr"
                                                           label={ t("cmr") } />
                                                </Col>

                                                <Col sm={ 12 }>
                                                    <Divider />
                                                    <h5 className="card-title">{ t("vin numbers") }</h5>
                                                    <ol className="list-group list-group-flush pl-3">
                                                        {
                                                            (entity && entity.alp_spot_load_orders && values) && values.orders.map((item: any, index: number) => {
                                                                const order = get(entity.alp_spot_load_orders.filter((load_order: any) => (load_order.id === item.id)), [0]);

                                                                return <li key={ index }
                                                                           className="list-group-item p-1">
                                                                    <Row>
                                                                        <Col sm={6}>
                                                                            { (order && order.order) && <>
                                                                                <div>{ order.order.vin_number }</div>
                                                                                <div> {order.order.brand.title} </div>
                                                                                <div> { order.order.brand_model.title }/{ order.order.brand_model.version_code} </div>
                                                                            </> }
                                                                        </Col>
                                                                        <Col sm={6} className={'text-end'}>
                                                                            { props.values.price_type === 'per_unit' && (
                                                                                <Field component={ InputField } className="w-25" type="number"
                                                                                       onBlur={ (e:any) => onBlurOrderPrice(e.target.value, item.id) }
                                                                                       name={ `orders[${index}].input_price` } />
                                                                            ) }
                                                                        </Col>
                                                                    </Row>
                                                                </li>;
                                                            })
                                                        }
                                                    </ol>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row>
                                            <div className="text-end">
                                                <Button type={ "submit" } disabled={ props.isSubmitting }
                                                        tooltip={ t("to data save into database") }
                                                        size={ "small" } outlined
                                                        tooltipOptions={ { position: 'top' } }
                                                >
                                                    <i className="pi pi-save me-2" />
                                                    { t("let's save") }
                                                </Button>
                                                <Button type={ "button" } disabled={ props.isSubmitting }
                                                        tooltip={ t("operation cancel") } className={ "ms-2" }
                                                        size={ "small" } severity={ "danger" } outlined
                                                        tooltipOptions={ { position: 'top' } }
                                                        onClick={ () => onClose() }
                                                >
                                                    <i className="pi pi-arrow-left me-2" />
                                                    { t("cancel") }
                                                </Button>
                                            </div>
                                        </Row>
                                    </Form>
                                </div>
                            </Dialog>
                        </>
                    );
                } }
            </Formik>
        </div>
    );
}

AlpSpotLoadUpdatePrice.propTypes =
    {
        t: PropTypes.any,
    }
;

export default withTranslation()(AlpSpotLoadUpdatePrice);
