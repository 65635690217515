import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Form, Field } from "formik";
import { Col, Row } from "react-bootstrap";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { capitalize, map, pickBy } from "lodash";
import { InputCheckboxField } from "../../../../../components/Shared/InputCheckboxField";
import * as XLSX from "xlsx";
import qs from "qs";
import { request } from "../../../../../utils/Request";
import { getOrderStatus } from "../../../../../components/Common/Display/OrderStatus";
import { getLocation } from "../../../../../components/Common/Display/Location";
import { utcDateToLocalDate } from "../../../../../utils/Helper";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    dropdowns: any;
    visibility: any;
    toastify: any;
    onClose?: any;
}

type TInputForm = {
    consider_all_pages: boolean,
    brand: boolean,
    client: boolean,
    brand_model: boolean,
    vin_number: boolean,
    order_cd: boolean,
    status: boolean,
    from_location: boolean,
    to_location: boolean,
    availability_date: boolean,
    height: boolean,
    width: boolean,
    length: boolean,
    net_weight: boolean,
    priority: boolean,
};

const DEFAULT_FORM_STATE = {
    consider_all_pages: true,
    brand: true,
    client: true,
    brand_model: true,
    vin_number: true,
    order_cd: true,
    status: true,
    from_location: true,
    to_location: true,
    availability_date: true,
    height: true,
    length: true,
    width: true,
    net_weight: true,
    priority: true,
};

// @ts-ignore
function OrderExcel(props: IFormProps) {
    const { t, visibility, dropdowns } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<any>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);

    const onSubmit = async (payloads: TInputForm): Promise<void> => {
        const _params: any = props.moduleState;
        const _payloads: any = payloads;

        if ( payloads.consider_all_pages ) {
            delete _params.page;
            delete _params.per_page;
        }

        try {
            const response = await request({
                url: `/suppliers/archived/orders`,
                params: _params,
                paramsSerializer: {
                    serialize: (params: any) => qs.stringify(params)
                },
            });

            delete _payloads.consider_all_pages;
            const columns = pickBy(_payloads, (item: boolean) => ( item ));
            const title: any = getTitles();
            const data: any = [];
            map(response.data.data, (value: any) => {
                const row: any = {
                    brand: `${ capitalize(value.brand.title) } - ${ value.brand.alternate_title ?? '' }`,
                    brand_model: `${ capitalize(value.brand_model.title) } - ${ value.brand_model.version_code ?? '' }`,
                    vin_number: `${ value.vin_number }`,
                    order_cd: `${ value.order_cd }`,
                    status: getOrderStatus(value.status),
                    from_location: getLocation(value.current_journey.from_location),
                    to_location: getLocation(value.current_journey.to_location),
                    availability_date: utcDateToLocalDate(value.created_at),
                    height: value.height,
                    client: value.client?.name,
                    width: value.width,
                    net_weight: value.net_weight,
                    length: value.length,
                    priority: ( value.priorisation > 0 ? ( value.built_to_order_cd > 0 ? 'SuperHigh' : 'High' ) : ( value.built_to_order_cd > 0 ? 'SuperHigh' : '' ) ),
                };

                const _row: any = {};
                map(columns, (_, key: string) => ( _row[key] = row[key] ));
                const arrayOfRow = Object.keys(_row).map((key) => _row[key]);
                data.push(arrayOfRow);
            });

            const _title = map(columns, (_, key: string) => ( title[key] = title[key] ));
            const excel = XLSX.utils.book_new();
            const sheet = XLSX.utils.aoa_to_sheet([_title, ...data]);
            XLSX.utils.book_append_sheet(excel, sheet, 'Orders');
            XLSX.writeFile(excel, "orders.xlsx");
        } catch (e: any) {
            props.toastify(t("server error"), "error");
        }
    };

    const onReset = () => {
        const base: any = formRef.current;
        if ( base ) {
            base.resetForm();
            setInitFormState({
                ...DEFAULT_FORM_STATE
            });
        }

        props.setQuery({
            ...props.moduleState,
            filters: { statuses: ['produced'] }
        });
    };

    const onClose = () => {
        setPanelState(false);

        if ( props.onClose ) {
            props.onClose();
        }
    };

    const getTitles = () => {
        return {
            brand: t('brand'),
            client: t('client'),
            brand_model: t('model'),
            vin_number: 'VIN',
            order_cd: t('order code'),
            status: t('status'),
            from_location: t('from location'),
            to_location: t('to location'),
            availability_date: t('availability date'),
            height: t('height'),
            width: t('width'),
            net_weight: t('net weight'),
            length: t('length'),
            priority: t('priority'),
        };
    };

    useEffect(() => {
        if ( visibility ) {
            setPanelState(visibility);
            setInitFormState({ ...DEFAULT_FORM_STATE, ...props.moduleState.filters ?? [] });
        }
    }, [visibility]);


    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t("export") } { t("orders") }</h5>
                            <p>
                                { t("select columns to be exported.") }
                            </p>
                        </div>
                    }
                    visible={ panelState } position="left" onHide={ onClose } blockScroll={ true }
                    style={ { width: '400px' } } closeOnEscape={ false }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4 md:mb-7 sm:mb-7" }>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="consider_all_pages"
                                                component={ InputCheckboxField }
                                                label={ t("consider all pages") }
                                            />
                                        </Col>

                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="brand"
                                                component={ InputCheckboxField }
                                                label={ t("brand") }
                                            />

                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="brand_model"
                                                component={ InputCheckboxField }
                                                label={ t("model") }
                                            />

                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="client"
                                                component={ InputCheckboxField }
                                                label={ t("client") }
                                            />

                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="vin_number"
                                                component={ InputCheckboxField }
                                                label={ t("vin number") }
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="order_cd"
                                                component={ InputCheckboxField }
                                                label={ t("order code") }
                                            />

                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="status"
                                                component={ InputCheckboxField }
                                                label={ t("status") }
                                            />

                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="from_location"
                                                component={ InputCheckboxField }
                                                label={ t("from location") }
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="to_location"
                                                component={ InputCheckboxField }
                                                label={ t("to location") }
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="availability_date"
                                                component={ InputCheckboxField }
                                                label={ t("availability date") }
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="height"
                                                component={ InputCheckboxField }
                                                label={ t("height") }
                                            />

                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="length"
                                                component={ InputCheckboxField }
                                                label={ t("length") }
                                            />

                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="width"
                                                component={ InputCheckboxField }
                                                label={ t("width") }
                                            />

                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                inline={ true }
                                                name="net_weight"
                                                component={ InputCheckboxField }
                                                label={ t("net weight") }
                                            />

                                        </Col>
                                        <Col sm={ 12 } className={ "mb-5" }>
                                            <Field
                                                inline={ true }
                                                name="priority"
                                                component={ InputCheckboxField }
                                                label={ t("priority") }
                                            />

                                        </Col>
                                    </Row>
                                    <div className="filter filter-card-footer"
                                         style={ { width: '385px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("apply filter") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-filter me-2" />
                                            { t("filter") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ onReset }
                                                tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                size={ "small" } severity={ "warning" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-refresh me-2" />
                                            { t("reset") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ onClose }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("close") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

OrderExcel.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(OrderExcel);
