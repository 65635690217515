import React from 'react';
import { FormGroup, FormText } from "react-bootstrap";
import { isEmpty, get, has, isArray } from "lodash";
import { FieldLabel } from "./FieldLabel";
import { request } from "../../utils/Request";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button } from 'primereact/button';
import { ButtonGroup } from "primereact/buttongroup";

interface IProps extends WithTranslation {
    action: string,
    label?: string,
    field: any,
    form?: any,
    value: any,
    formGroupClass?: any,
    isRequired?: boolean
    onAfterUpload?: any,
    size?: any,
    formText?: any,
}

interface IState {
    file?: any,
    isSubmitting?: boolean
    errors?: any
}

class InputAttachment extends React.Component<IProps, IState> {
    fileUploader: any;
    buttonRef: any;

    constructor(props: IProps) {
        super(props);

        this.state = {
            file: null,
            isSubmitting: false,
            errors: null
        };

        this.fileUploader = React.createRef();
        this.buttonRef = React.createRef();
    }

    handleFileChange = (e: any) => {
        if ( e.target.files ) {
            this.setState({
                file: e.target.files[0]
            }, () => {
                this.handleUploadClick();
            });
        }
    };

    handleUploadClick = () => {
        this.setState({
            isSubmitting: true
        });

        this.setState({
            errors: null
        });

        if ( !isEmpty(get(this.state.file, ['name'])) ) {
            const formData: any = new FormData();
            const fileCopy = this.state.file;
            formData.append(
                "file",
                this.state.file,
                this.state.file.name
            );

            request({
                url: "/upload",
                method: 'POST',
                headers: { 'content-type': 'multipart/form-data' },
                data: formData,
            })
            .then((res) => {
                const { data } = res.data;
                const { form, field } = this.props;
                form.setFieldValue(field.name, data.id);

                setTimeout(() => {
                    if ( this.props.onAfterUpload ) {
                        this.props.onAfterUpload(fileCopy.name, fileCopy.size);
                    }

                    if ( this.buttonRef.current ) {
                        this.buttonRef.current.focus();
                    }
                }, 1000);
            })
            .catch((err) => {
                let _err = ["Server error"];
                if ( err.status === 422 ) {
                    _err = err.data.errors.file;
                }

                this.setState({
                    errors: {
                        file: _err
                    }
                });
            })
            .finally(() => {
                this.setState({
                    isSubmitting: false
                });
            });
        } else {
            this.setState({
                isSubmitting: false
            });
        }
    };

    onClickFileUpload = () => {
        this.fileUploader.current.click();
    };

    onClickRemove = () => {
        const { form, field } = this.props;
        form.setFieldValue(field.name, null);
        this.fileUploader.current.value = null;
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if ( this.props.field && isEmpty(this.props.field.value) && this.fileUploader.current && !isEmpty(this.fileUploader.current.value) ) { // !isEmpty(this.state.file)
            this.setState({
                file: null
            });
            this.fileUploader.current.value = null;
        }
    }

    render() {
        const {
            field,
            form,
            formGroupClass,
            size,
            ...props
        } = this.props;
        const {errors} = form;

        return (
            <FormGroup className={ formGroupClass }>
                <FieldLabel { ...props } />
                {/*@ts-ignore*/}
                <ButtonGroup className="flex mt-1">
                    <Button type="button" size="small"
                            icon="pi pi-upload" outlined
                            severity={ !isEmpty(errors[field.name]) ? 'danger' : undefined }
                            onClick={ this.onClickFileUpload }
                            loading={ this.state.isSubmitting }
                            disabled={ this.state.isSubmitting || field.value }>
                        <span className={"ms-1"}>{ this.props.t("upload") }</span>
                    </Button>
                    { field.value &&
                    <>
                        <Button type="button" size="small" outlined
                                ref={ this.buttonRef }
                                severity={ !isEmpty(errors[field.name]) ? 'danger' : undefined }
                                onClick={ this.onClickRemove }
                                disabled={ this.state.isSubmitting }>
                            <i className="fas fa-times" />
                        </Button>
                        <Button size={"small"}  outlined
                          severity={ isEmpty(errors[field.name]) ? 'danger' : undefined }
                          onClick={() => window.open(`${ process.env.REACT_APP_DOCUMENT_URL }/${ field.value }`, '_blank')}>
                            <i className="fas fa-cloud-download-alt" />
                        </Button>
                    </>
                    }
                </ButtonGroup>

                <input type="file" onChange={ this.handleFileChange } ref={ this.fileUploader }
                       style={ { display: "none" } } />

                { props.formText && <div><FormText>{ props.formText }</FormText></div> }
                {
                    (errors && errors[field.name] && isArray(errors[field.name])) &&
                    errors[field.name].map((item: any, index: any) => <div
                        className="text-red-500" key={ index }>{ item }</div>)
                }
                {
                    ( errors && errors[field.name] && !isArray(errors[field.name]) ) &&
                    <div className="text-red-500">{ errors[field.name] }</div>
                }
            </FormGroup>
        );
    }
}

export default withTranslation()(InputAttachment);
