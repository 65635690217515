import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useRef, useState } from "react";
import { request } from "../../../../utils/Request";
import Tooltip from "../../../../components/Common/Display/Tooltip";
import HasAccess from "../../../../utils/HasAccess";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import StateLessGrid from "../../../../components/Shared/StateLessGrid";
import { Button } from "primereact/button";
import { Col, Row } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../../../components/Shared/InputField";
import OrderForm from "./OrderForm";
import OrderFilter from "./OrderFilter";
import { utcDateToLocalDate } from "../../../../utils/Helper";
import { get, includes, isEmpty } from "lodash";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import VinWithType from "../../../../components/Common/Display/VinWithType";
import OrderStatus from "../../../../components/Common/Display/OrderStatus";
import Location from "../../../../components/Common/Display/Location";
import AddJourneyToMultipleOrders from "./AddJourneyToMultipleOrders";
import AddJourneysToOrder from "./AddJourneysToOrder";
import UpdateAllJourney from "./UpdateAllJourney";
import OrderDetail from "./OrderDetail";
import { ButtonGroup } from "primereact/buttongroup";
import { Dialog } from "primereact/dialog";
import OrderExcel from "./OrderExcel";

const OrderList = (props: any) => {
    const { t, moduleState, toastify } = props;
    const [updateSingleOrderJourneyId, setSingleOrderJourneyId] = useState<any>(null);
    const [updateOrderJourneyId, setOrderJourneyId] = useState<any>(null);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [operation, setOperation] = useState<any>(searchParams.get('operation') ?? null);
    const [operationId, setOperationId] = useState<any>(searchParams.get('operationId') ?? null);
    const [filterPanel, setFilterPanel] = useState<boolean>(false);
    const [excelPanel, setExcelPanel] = useState<boolean>(false);
    const [initSearchFormState] = useState<any>({ search: null });
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [isAddJourneyToMultipleOrdersPanelActive, setAddJourneyToMultipleOrdersPanelActive] = useState<boolean>(false);
    const [total, setTotal] = useState(0);
    const [orderLegendModal, setOrderLegendModal] = useState<boolean>(false);
    const tableRef: any = useRef();

    const onDelete = async (payloads: any): Promise<void> => {
        try {
            await request({
                url: `suppliers/orders/${ payloads.id }`,
                method: 'DELETE',
                data: { _method: 'DELETE' }
            });

            toastify(t("record deleted", { item: t('contract') }), "info");
            onReset();
        } catch (e: any) {
            if(e.status === 422) {
                return toastify(e.data.message, "error");
            }

            toastify(t("server error"), "error");
        }
    };

    const columns = [
        {
            field: 'brand',
            title: t("brand") + '/' + t("model"),
            sorting: true,
            sortField: "brand_id",
            render: ({ brand, brand_model }: any) => <div className={ "text-capitalize" }>
                { brand.title }
                { brand.alternate_title && <div> { brand.alternate_title }</div> }
                <div>
                    { brand_model.title }
                    { brand_model.version_code && <div> { brand_model.version_code } </div> }
                </div>
            </div>
        },
        {
            field: 'client',
            title: t("client"),
            sorting: true,
            sortField: "client_id",
            render: ({ client }: any) => <div className={ "text-capitalize" }>
                { client?.name }
            </div>
        },
        {
            field: 'vin_number',
            title: t('vin number'),
            sorting: true,
            sortField: "vin_number",
            render: (_row: any) => (
                <>
                    <VinWithType vinNumber={ _row.vin_number } type={ _row.belongs_to }
                                 priorisation={ _row.priorisation } builtToOrderCd={ _row.built_to_order_cd } />
                </>
            )
        },
        { field: 'order_cd', title: t('order'), sortField: "order_cd", sorting: true },
        { field: 'ldg_nr', title: t('ldg nr'), sortField: "ldg_nr", sorting: true },
        {
            field: 'status',
            title: t('status'),
            sorting: true,
            sortField: "status",
            render: (_row: any) => <div className={ "text-capitalize" }>
                <OrderStatus status={ _row.status } />
            </div>
        },
        {
            field: 'from_location',
            sorting: false,
            title: t('current location'),
            render: (_row: any) => {
                const { current_journey } = _row;
                return (
                    <>
                        {
                            ( _row.status === "delivered" && current_journey && current_journey.to_location ) &&
                          <>
                              { current_journey.to_location.street_line_1 &&
                                <small className={ "d-block" }>{ current_journey.to_location.street_line_1 }</small> }
                              <Location item={ current_journey.to_location } titled={ 1 } />
                          </>
                        }
                        {
                            ( _row.status !== "delivered" && current_journey && current_journey.from_location ) &&
                          <>
                              { current_journey.from_location.street_line_1 &&
                                <small className={ "d-block" }>{ current_journey.from_location.street_line_1 }</small> }
                              <Location item={ current_journey.from_location } titled={ 1 } />
                          </>
                        }
                    </>
                );
            }
        },
        {
            field: 'journeys',
            title: t('journeys'),
            sorting: false,
            width: 300,
            render: (_row: any) => {
                const { order_journeys, current_journey } = _row;
                return (
                    <>
                        <div className={ "mb-2" }>
                            {
                                order_journeys.map((item: any, index: number) => {
                                    return <div key={ index } className={ "mt-1" }>
                                        <div className={
                                            `border border-1 ps-2 pe-2 border-dashed ${ current_journey && current_journey.token === item.token ? 'border-info' : '' } font-size-12px`
                                        }>
                                            <div>
                                                { ( current_journey && current_journey.token === item.token ) &&
                                                  <>
                                                      {
                                                          item.status === "completed" &&
                                                        <Tooltip tooltip={ t('journey is finished') }>
                                                            <small><i className="fas fa-circle text-secondary" /></small>
                                                        </Tooltip>
                                                      }
                                                      {
                                                          item.status !== "completed" &&
                                                          <Tooltip tooltip={ t('active') }>
                                                              <small><i className="fas fa-circle text-success" /></small>
                                                          </Tooltip>
                                                      }
                                                  </>
                                                }
                                                { ( current_journey && current_journey.token !== item.token && item.status === "pending" ) &&
                                                  <Tooltip tooltip={ t('pending') + ': ' + t('click to active') }>
                                                      <small onClick={ () => onUpdateJourney(_row, item) }><i
                                                        className="fas fa-circle text-info" /></small>
                                                  </Tooltip>
                                                }
                                                { ( current_journey && current_journey.token !== item.token && item.status === "completed" ) &&
                                                  <Tooltip tooltip={ t('complete') }>
                                                      <small>
                                                          <i className="fas fa-circle text-secondary" />
                                                      </small>
                                                  </Tooltip>
                                                }
                                                <small className={ "ps-1" }>
                                                    <Tooltip tooltip={ t('loading') } >
                                                        { item.from_location.street_line_1 && item.from_location.street_line_1 }
                                                    </Tooltip>
                                                    <Tooltip tooltip={ t('unloading') } >
                                                        { item.to_location.street_line_1 && ` - ${ item.to_location.street_line_1 }` }
                                                    </Tooltip>
                                                </small>
                                            </div>
                                            <div>
                                                <Location item={ item.from_location } titled={ 1 } /> - <Location
                                                item={ item.to_location } titled={ 1 } />
                                            </div>
                                        </div>
                                    </div>;
                                })
                            }
                        </div>

                        {
                            includes(['delivered', 'pending', 'produced'], _row.status) &&
                          <a className={ "cursor-pointer text-xs text-blue-600" } onClick={ () => setSingleOrderJourneyId(_row.id) }>
                              <small><i className="fas fa-plus" /></small> { t('add') } { t('journey') }
                          </a>
                        }
                    </>
                );
            },
        },
        {
            field: 'created_at',
            title: t('created at'),
            sorting: true,
            render: ({ created_at }: any) => ( created_at &&
              <Tooltip tooltip={ utcDateToLocalDate(created_at) }>{ utcDateToLocalDate(created_at) } </Tooltip> ),
        },
        {
            field: 'updated_at',
            title: t('modified at'),
            sorting: true,
            render: ({ updated_at }: any) => ( updated_at &&
              <Tooltip tooltip={ utcDateToLocalDate(updated_at) }>{ utcDateToLocalDate(updated_at) } </Tooltip> ),
        },
        {
            field: 'added_into_load_at',
            title: t('load creation date'),
            sorting: true,
            render: ({ added_into_load_at }: any) => ( added_into_load_at &&
              <Tooltip
                tooltip={ utcDateToLocalDate(added_into_load_at) }>{ utcDateToLocalDate(added_into_load_at) } </Tooltip> ),
        },
        {
            field: 'action',
            title: t('action'),
            sorting: false,
            align: 'right',
            render: (_row: any) => {
                return (<>
                    {
                        includes(['archived', 'delivered', 'pending', 'produced'], _row.status)
                        &&
                      <HasAccess hasAllAccess={ ['ordersupdate'] }>
                          <Tooltip
                            tooltip={ `${ t('update') } ${ t('order journeys') }` }>
                              <a className={"ms-2"} onClick={() => setOrderJourneyId(_row.id) }>
                                  <i className="fas fa-fan" />
                              </a>
                          </Tooltip>
                      </HasAccess>
                    }

                    <HasAccess hasAllAccess={ ['ordersstore'] }>
                        <Tooltip
                            tooltip={ t('clone order') }>
                            <a onClick={ () => onOperationClick('clone', _row.id) } className={ "ms-2" }>
                                <i className="fas fa-tablets" />
                            </a>
                        </Tooltip>
                    </HasAccess>

                    <HasAccess hasAllAccess={ ['ordersupdate'] }>
                        <Tooltip
                            tooltip={ t('edit item') }>
                            <a onClick={ () => onOperationClick('edit', _row.id) } className={ "ms-2" }>
                                <i className="fas fa-edit" />
                            </a>
                        </Tooltip>
                    </HasAccess>

                    {
                        includes(['produced'], _row.status)
                        &&
                        <HasAccess hasAnyAccess={ ['ordersdestroy'] }>
                            <Tooltip
                                tooltip={ t('delete item') }>
                                <a
                                    className={"ms-2"}
                                    title={ t('delete item') }
                                    onClick={ (event: any) => confirmPopup({
                                        target: event.currentTarget,
                                        message: t('do you want to delete this record?'),
                                        icon: 'pi pi-info-circle',
                                        // @ts-ignorer
                                        defaultFocus: 'reject',
                                        acceptClassName: 'p-button-danger',
                                        accept: () => onDelete(_row),
                                        reject: () => {
                                        },
                                    }) }>
                                    <i className="fas fa-trash-alt" />
                                </a>
                            </Tooltip>
                        </HasAccess>
                    }

                    <Tooltip
                        tooltip={ t('detail of item') }>
                        <a onClick={ () => onOperationClick('detail', _row.id) } className={ 'ms-2' }>
                            <i className="fas fa-eye" />
                        </a>
                    </Tooltip>
                </> )
            }
        },
    ];

    const DetailRow = ({ data }: any) => {

    };

    const onOperationClick = (operationToBePerformed: any = 'add', id: any = null) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('operation', operationToBePerformed);
        searchParams.set('operationId', id);

        if ( isEmpty(operationToBePerformed) ) {
            searchParams.delete('operation');
        }

        if ( isEmpty(id) ) {
            searchParams.delete('operationId');
        }

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        }, { replace: true });
        setOperationId(id);
        setOperation(operationToBePerformed);
    };

    const onCloseOperation = (reset: any = true) => {
        onOperationClick(null, null);
        onReset(reset);
        focusOnSearch();
    };

    const onReset = (reset: any = true) => {
        const mStates = { ...moduleState };

        if ( reset ) {
            mStates.filters = {
                statuses: ['produced']
            };
            mStates.page = 1;
            mStates.search = null;
        }

        props.setQuery(mStates);
    };

    const onSearch = ({ search }: any) => {
        props.setQuery({
            ...moduleState,
            search: search
        });
    };

    const onFilterClick = () => {
        setFilterPanel(true);
    };

    const onExcelClick = () => {
        setExcelPanel(true);
    };

    const onRowSelection = (selectedRowsData: any) => {
        const _selectedOrders: any = selectedRowsData.filter((row: any) => includes(['pending', 'delivered', 'produced'], row.status))
        .map((item: any) => {
            return {
                id: item.id,
                vin_number: item.vin_number,
                to_location_id: get(item, ['current_journey', 'to_location', 'id']),
                order_journeys: item.order_journeys
            };
        });

        setSelectedOrders(_selectedOrders);
    };

    const onUpdateJourney = async (row: any, journey: any) => {
        if ( includes(['added-to-load', 'load-accepted', 'load-rejected', 'in-transit', 'unload-request'], row.status) ) {
            toastify(t("unable to change journey because order is under process"), "info" );
            return;
        }

        try {
            const response = await request({
                method: 'POST',
                url: `/suppliers/orders/journey`,
                data: {
                    id: row.id,
                    token: journey.token,
                    from_location_id: journey.from_location.id,
                    to_location_id: journey.to_location.id,
                    is_active: true
                },
            });

            toastify(t("record updated", { item: t('order') }), 'success');
            tableRef.current.onRefresh();
        } catch (e: any) {
            if ( e.status === 422 ) {
                toastify(t("unprocessable entity"), 'error');
            } else {
                toastify(t("server error"), 'error');
            }
        }
    };

    const onClickOpenOrderLegends = () => {
        setOrderLegendModal(true);
    };

    const focusOnSearch = () => {
        const searchInput: HTMLInputElement | null = document.querySelector('input[name="search"]');
        if ( searchInput ) {
            searchInput.focus();
        }
    };

    return (
        <div className={ "block-viewer" }>
            <div className="block-section">
                <div className="block-header">
                    <span className="block-title">
                        <h5 className={ "mb-0" }>
                            { t('orders') } ({total})
                        </h5>
                    </span>
                    <div className="block-actions">
                        {
                            operation == 'detail' &&
                          <div>
                              <Button type={ "button" }
                                      tooltip={ t("operation cancel") } className={ "ms-2" }
                                      size={ "small" } severity={ "warning" }
                                      tooltipOptions={ { position: 'top' } } onClick={ () => onCloseOperation(false) }>
                                  <i className="pi pi-arrow-left me-2" /> { t("cancel") }

                              </Button>
                          </div>
                        }
                    </div>
                </div>
                <div className="block-content">
                    <Row className={ `p-2 ${operation === 'detail' ? 'position-minus' : ''}` }>
                      <Col
                        sm={ 12 } md={ 6 } className={ "mb-2" }>
                          <Formik
                            enableReinitialize={ true }
                            initialValues={ initSearchFormState }
                            onSubmit={ onSearch }
                          >
                              { (props: FormikProps<any>) => {
                                  return (
                                      <Form onSubmit={ props.handleSubmit }>
                                              <Col sm={ 12 } md={ 8 }>
                                                  <div className={'d-flex'}>
                                                      <Field component={ InputField }
                                                             withoutLabel={ true }
                                                             autoFocus={true}
                                                             name="search"
                                                             formGroupClass={ "mb-0" }
                                                             placeholder={ t("search") } />
                                                      <Tooltip className="ms-2"
                                                               target={ `.order-legends` } />
                                                      <a
                                                          data-pr-tooltip={ `${ t("price legends") }` }
                                                          data-pr-position="left"
                                                          onClick={ onClickOpenOrderLegends }
                                                          className={"order-legends cursor-pointer"}>
                                                          <i className="fas fa-info-circle text-blue-400" style={ {
                                                              fontSize: '1.2rem',
                                                              marginTop: '0.8rem',
                                                              cursor: 'pointer'
                                                          } } />
                                                      </a>
                                                  </div>
                                              </Col>
                                          </Form>
                                      );
                                  } }
                              </Formik>
                          </Col>

                      <Col
                        sm={ 12 } md={ 6 } className={ "text-right mb-2" }>
                        <ButtonGroup>
                            <Button size={ "small" } outlined tooltip={ t('add item') }
                                    tooltipOptions={ { position: 'top' } }
                                    onClick={ () => onOperationClick('add') }>
                                <i className="pi pi-plus" />
                                <span className={ "ms-1" }>{ t('add') }</span>
                            </Button>
                            <Button icon="pi pi-filter-fill" size={ "small" } outlined tooltip={ t('filter') }
                                    onClick={ onFilterClick }
                                    tooltipOptions={ { position: 'top' } } />
                            <Button size={ "small" } outlined tooltip={ t('append journey to selected orders') }
                                    tooltipOptions={ { position: 'left' } }
                                    onClick={ () => setAddJourneyToMultipleOrdersPanelActive(true) }>
                                <i className="pi pi-plus" />
                                <span className={ "ms-1" }>{ t('journey') }</span>
                            </Button>
                            <Button icon="pi pi-file-excel" size={ "small" } outlined tooltip={ t('excel') }
                                    onClick={ onExcelClick }
                                    tooltipOptions={ { position: 'top' } } />
                            <Button onClick={ onReset } icon="pi pi-refresh" size={ "small" } outlined
                                    tooltip={ t('reset') }
                                    tooltipOptions={ { position: 'top' } } />
                        </ButtonGroup>
                      </Col>

                      <Col sm={ 12 }>
                          <StateLessGrid
                            size={ "normal" }
                            url={ '/suppliers/orders?1=1' }
                            name={ "orderList" }
                            columns={ columns }
                            moduleState={ moduleState }
                            setQuery={ props.setQuery }
                            ref={ tableRef }
                            itemSize={ 130 }
                            setTotal={ setTotal }
                            onRowSelection={ onRowSelection }
                              // detailRow={ (data: any) => <DetailRow data={ data } /> }
                          />
                      </Col>
                    </Row>

                    {
                        (operation === 'detail' && !isEmpty(operationId)) &&
                      <OrderDetail
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        params={ { operation, operationId } }
                        onClose={ onCloseOperation }
                      />
                    }

                    {
                        (includes(['add', 'edit', 'clone'], operation)) &&
                        <OrderForm
                            moduleState={ moduleState }
                            setQuery={ props.setQuery }
                            toastify={ props.toastify }
                            dropdowns={ props.dropdowns }
                            params={ { operation, operationId } }
                            operationCancel={ onOperationClick }
                            onClose={ onCloseOperation }
                        />
                    }

                    <AddJourneyToMultipleOrders
                        visible={ isAddJourneyToMultipleOrdersPanelActive }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        selectedOrders={ selectedOrders }
                        onClose={ () => {
                            setAddJourneyToMultipleOrdersPanelActive(false)
                            if ( tableRef.current ) {
                                tableRef.current.onRefresh();
                            }
                            focusOnSearch();
                        } }
                    />

                    <AddJourneysToOrder
                        visible={ updateSingleOrderJourneyId }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        onClose={ () => {
                            setSingleOrderJourneyId(null);
                            if ( tableRef.current ) {
                                tableRef.current.onRefresh();
                            }
                            focusOnSearch();
                        } }
                    />

                    <UpdateAllJourney
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        visible={ updateOrderJourneyId }
                        onClose={ () => {
                            setOrderJourneyId(null);
                            if ( tableRef.current ) {
                                tableRef.current.onRefresh();
                            }
                            focusOnSearch();
                        } }
                    />

                    <OrderFilter
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        visibility={ filterPanel }
                        onClose={ () => {
                            setFilterPanel(false);
                            focusOnSearch();
                        } }
                    />

                    <OrderExcel
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        visibility={ excelPanel }
                        onClose={ () => {
                            setExcelPanel(false);
                            focusOnSearch();
                        } }
                    />

                    <Dialog header={
                        t('order legends')
                    } visible={orderLegendModal} style={{ width: '50vw' }} onHide={() => setOrderLegendModal(false)}
                            breakpoints={{ '960px': '85vw', '641px': '100vw' }}>
                        <div>
                            <p className={ "m-0 fs-6" }>
                                <i className="fas fa-circle text-secondary me-1" />
                                { t('journey is finished') }
                            </p>
                            <p className={ "m-0 fs-5 mt-1" }>
                                <i className="fas fa-circle text-success me-1" />
                                { t('active journey') }
                            </p>
                            <p className={ "m-0 fs-5 mt-1" }>
                                <i className="fas fa-circle text-info me-1" />
                                { t('pending is journey') }
                            </p>
                        </div>
                    </Dialog>

                    <ConfirmPopup />

                </div>
            </div>
        </div>
    );
};

OrderList.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(OrderList));
