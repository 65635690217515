import React from 'react';

interface IFieldLabel {
    label?: any,
    labelClass?: any,
    isRequired?: any
}

export class FieldLabel extends React.Component<IFieldLabel, {}> {
    render() {
        const props = this.props;
        return (
            <div>
                { ( props.label ) ?
                    <label className={ props.labelClass ? props.labelClass : 'text-g-800' }>
                        { props.label }{ ( props.isRequired ) ? <strong style={ { color: 'var(--red-500)' } }> *</strong> : '' }
                    </label> : ''
                }
            </div>
        );
    }
}
