import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useRef, useState } from "react";
import { request } from "../../../../utils/Request";
import Tooltip from "../../../../components/Common/Display/Tooltip";
import HasAccess from "../../../../utils/HasAccess";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import StateLessGrid from "../../../../components/Shared/StateLessGrid";
import { Button } from "primereact/button";
import { Col, Row } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../../../components/Shared/InputField";
import LocationForm from "./LocationForm";
import LocationFilter from "./LocationFilter";
import { includes, isEmpty } from "lodash";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import LocationDetail from "./LocationDetail";
import { ButtonGroup } from "primereact/buttongroup";
import LocationExcel from "./LocationExcel";

const LocationList = (props: any) => {
    const { t, moduleState, toastify } = props;
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [operation, setOperation] = useState<any>(searchParams.get('operation') ?? null);
    const [operationId, setOperationId] = useState<any>(searchParams.get('operationId') ?? null);
    const [filterPanel, setFilterPanel] = useState<boolean>(false);
    const [excelPanel, setExcelPanel] = useState<boolean>(false);
    const [initSearchFormState, setInitSearchFormState] = useState<any>({
        search: moduleState.search ?? null
    });
    const tableRef: any = useRef();
    const [total, setTotal] = useState(0);

    const onDelete = async (payloads: any): Promise<void> => {
        try {
            await request({
                url: `suppliers/locations/${ payloads.id }`,
                method: 'DELETE',
                data: { _method: 'DELETE' }
            });

            toastify(t("record deleted", { item: t('location') }), "info");
            onReset();
        } catch (e: any) {
            if(e.status === 422) {
                return toastify(e.data.message, "error");
            }

            toastify(t("server error"), "error");
        }
    };

    const columns = [
        { field: 'street_line_1', title: t('location name'), sorting: true, },
        { field: 'street_line_2', title: t('street name'), sorting: true, },
        { field: 'city', title: t('city'), sorting: true, },
        { field: 'zip', title: t('zip'), sorting: true, },
        {
            field: 'country',
            title: t('country'),
            sorting: true,
            render: (_row: any) => ( <span className={ "text-capitalize" }>{ _row.country }</span> )
        },
        {
            field: 'latitude', title: t('coordinates'), sorting: true, render: (_row: any) => (
                <>
                    <span className={ "text-capitalize" }>
                        { [_row.latitude, _row.longitude].filter((i: any) => !isEmpty(i)).join(',') }
                    </span>
                </>
            )
        },
        {
            field: 'action',
            title: t('action'),
            sorting: false,
            align: 'left',
            render: (_row: any) => {
                return (
                    <div>
                        { !isEmpty(_row.supplier) &&
                        <>
                            <HasAccess hasAllAccess={ ['locationsupdate'] }>
                                <Tooltip
                                  tooltip={ t('edit item') }>
                                    <a onClick={ () => onOperationClick('edit', _row.id) }>
                                        <i className="fas fa-edit" />
                                    </a>
                                </Tooltip>
                            </HasAccess>
                            <HasAccess hasAllAccess={ ['locationsdestroy'] }>
                                <Tooltip
                                  tooltip={ t('delete item') }>
                                    <a
                                      title={ t('delete item') }
                                      className={ "cursor-pointer ms-2" }
                                      onClick={ (event: any) => confirmPopup({
                                          target: event.currentTarget,
                                          message: t('do you want to delete this record?'),
                                          icon: 'pi pi-info-circle',
                                          // @ts-ignorer
                                          defaultFocus: 'reject',
                                          acceptClassName: 'p-button-danger',
                                          accept: () => onDelete(_row),
                                          reject: () => {
                                          },
                                      }) }>
                                        <i className="fas fa-trash-alt" />
                                    </a>
                                </Tooltip>
                            </HasAccess>
                            <HasAccess hasAllAccess={ ['locationsupdate'] }>
                                <Tooltip tooltip={ t('detail of item') }>
                                    <a
                                      className={ "cursor-pointer ms-2" }
                                      onClick={ () => onOperationClick('detail', _row.id) }>
                                        <i className="fas fa-eye" />
                                    </a>
                                </Tooltip>
                            </HasAccess>
                        </>
                        }
                    </div>
                );
            }
        },
    ];

    const DetailRow = ({ data }: any) => {
            return (
                <>
                    <div>
                        <strong className={ 'text-capitalize' }>{ t('location code') }:</strong> { data.location_code }
                    </div>
                    <div>
                        <strong className={ 'text-capitalize' }>{ t('landmark') }:</strong> { data.landmark }
                    </div>
                    <div>
                        <strong className={ 'text-capitalize' }>{ t('address') }:</strong> { data.address }
                    </div>
                </>
            );
        }
    ;

    const onOperationClick = (operationToBePerformed: any = 'add', id: any = null) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('operation', operationToBePerformed);
        searchParams.set('operationId', id);

        if ( isEmpty(operationToBePerformed) ) {
            searchParams.delete('operation');
        }

        if ( isEmpty(id) ) {
            searchParams.delete('operationId');
        }

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        }, { replace: true });
        setOperationId(id);
        setOperation(operationToBePerformed);
    };

    const onCloseOperation = (reset: any = true) => {
        onOperationClick(null, null);
        onReset(reset);
        focusOnSearch();
    };

    const onReset = (reset: any = true) => {
        const mStates = { ...moduleState };

        if ( reset ) {
            mStates.filters = [];
            mStates.page = 1;
            mStates.search = null;
        }

        props.setQuery(mStates);
    };

    const onSearch = ({ search }: any) => {
        props.setQuery({
            ...moduleState,
            search: search
        });
    };

    const onFilterClick = () => {
        setFilterPanel(true);
    };

    const onExcelClick = () => {
        setExcelPanel(true);
    };

    const focusOnSearch = () => {
        const searchInput: HTMLInputElement | null = document.querySelector('input[name="search"]');
        if ( searchInput ) {
            searchInput.focus();
        }
    };

    return (
        <div className={ "block-viewer" }>
            <div className="block-section">
                <div className="block-header">
                    <span className="block-title">
                        <h5 className={ "mb-0" }>{ t('locations') } ({total})</h5>
                    </span>
                    <div className="block-actions">
                        {
                            operation == 'detail' &&
                          <div>
                              <Button type={ "button" }
                                      tooltip={ t("operation cancel") } className={ "ms-2" }
                                      size={ "small" } severity={ "warning" }
                                      tooltipOptions={ { position: 'top' } } onClick={ () => onCloseOperation(false) }>
                                  <i className="pi pi-arrow-left me-2" /> { t("cancel") }
                              </Button>
                          </div>
                        }
                    </div>
                </div>
                <div className="block-content">
                    <Row className={ `p-2 ${operation === 'detail' ? 'position-minus' : ''}` }>
                        <Col
                            sm={ 12 } md={ 6 } className={ "mb-2" }>
                            <Formik
                                enableReinitialize={ true }
                                initialValues={ initSearchFormState }
                                onSubmit={ onSearch }
                            >
                                { (props: FormikProps<any>) => {
                                    return (
                                        <Form onSubmit={ props.handleSubmit }>
                                            <Col sm={ 12 } md={ 8 }>
                                                <Field component={ InputField } withoutLabel={ true }
                                                       autoFocus={ true }
                                                       name="search"
                                                       formGroupClass={ "mb-0" }
                                                       placeholder={ t("search") } />
                                            </Col>
                                        </Form>
                                    );
                                } }
                            </Formik>
                        </Col>

                        <Col
                            sm={ 12 } md={ 6 } className={ "text-right mb-2" }>
                                <ButtonGroup>
                                    <Button size={ "small" } outlined tooltip={ t('add item') }
                                            tooltipOptions={ { position: 'top' } }
                                            onClick={ () => onOperationClick('add') }>
                                        <i className="pi pi-plus" />
                                        <span className={ "ms-1" }>{ t('add') }</span>
                                    </Button>
                                    <Button icon="pi pi-filter-fill" size={ "small" } outlined tooltip={ t('filter') }
                                            onClick={ onFilterClick }
                                            tooltipOptions={ { position: 'top' } } />
                                    <Button icon="pi pi-file-excel" size={ "small" } outlined tooltip={ t('excel') }
                                            onClick={ onExcelClick }
                                            tooltipOptions={ { position: 'top' } } />
                                    <Button onClick={ onReset } icon="pi pi-refresh" size={ "small" } outlined
                                            tooltip={ t('reset') }
                                            tooltipOptions={ { position: 'top' } } />
                                </ButtonGroup>
                        </Col>

                        <Col sm={ 12 }>
                            <StateLessGrid
                                size={ "normal" }
                                url={ '/suppliers/locations?1=1' }
                                name={ "locationList" }
                                columns={ columns }
                                moduleState={ moduleState }
                                setQuery={ props.setQuery }
                                ref={ tableRef }
                                itemSize={ 45 }
                                setTotal={ setTotal }
                                detailRow={ (data: any) => <DetailRow data={ data } /> }
                            />
                        </Col>
                    </Row>

                    {
                        (operation === 'detail' && !isEmpty(operationId)) &&
                        <>
                            <LocationDetail
                              setQuery={ props.setQuery }
                              toastify={ props.toastify }
                              dropdowns={ props.dropdowns }
                              params={ { operation, operationId } }
                              onClose={ onCloseOperation }
                            />
                        </>
                    }

                    {
                        (includes(['add', 'edit'], operation)) &&
                        <LocationForm
                            moduleState={ moduleState }
                            setQuery={ props.setQuery }
                            toastify={ props.toastify }
                            dropdowns={ props.dropdowns }
                            params={ { operation, operationId } }
                            onClose={ onCloseOperation }
                        />
                    }

                    <LocationFilter
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        visibility={ filterPanel }
                        onClose={ () => {
                            setFilterPanel(false);
                            focusOnSearch();
                        } }
                    />

                    <LocationExcel
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        visibility={ excelPanel }
                        onClose={ () => {
                            setExcelPanel(false);
                            focusOnSearch();
                        } }
                    />

                    <ConfirmPopup />
                </div>
            </div>
        </div>
    );
};

LocationList.propTypes =
    {
        // t: PropTypes.any,
    }
;

export default withTranslation()(WithRouter(LocationList));

