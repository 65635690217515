import React, { useEffect, useRef, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { request } from "../../../../utils/Request";
import { find, get, isEmpty, map, uniq } from 'lodash';
import { Sidebar } from "primereact/sidebar";
import { Card, CardBody, CardHeader, Col, Row } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { random } from "../../../../utils/Helper";
import moment from "moment-timezone";
import VinWithType from "../../../../components/Common/Display/VinWithType";

interface IFormProps {
    load: string | null,
    t: any | null,
    onRefresh?: any | null,
    dropdowns?: any | null,
    onClose?: any | null,
    toastify?: any | null,
}

type TInputForm = {
    id?: any,
};

const DEFAULT_FORM_STATE: TInputForm = {
    id: null,
};

const validationSchema = (t: Function): any => {

};

function ShowInvoiceableLoad(props: IFormProps) {
    const { dropdowns, toastify } = props;
    const { t } = useTranslation();
    const [entity, setEntity] = useState<any>(null);
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [panelState, setPanelState] = useState<boolean>(false);

    const onClose = () => {
        setPanelState(false);

        if ( props.onClose ) {
            props.onClose();
        }
    };

    const getDetail = async (id: string) => {
        try {
            const response = await request({
                url: `suppliers/spot/loads/create/invoice`,
                method: 'POST',
                data: {
                    _method: "POST",
                    load_number: id,
                    load_type: get(props,['load', 'type'])
                }
            });

            const { data } = response.data;
            setPanelState(true);
            const grouped: any[] = [];
            map(data, (item: any) => {
                // client id , load id, ((orderid * load order group token) ~ (orderid * load order group token))
                const group_token_vins = item.orders.map((order: any) => `${ order.id }*${ order.group_token }`).join("~");

                grouped.push({
                    group_token: ( item.load_type === "SpotLoad" )
                        ? `${ item.client_id },${ item.load_id },${ group_token_vins }`
                        : `${ item.load_id }, ${ item.load_id }, ${ item.load_id }*${ item.load_id }`
                    ,
                    invoiceable_at: item.invoiceable_at,
                    group_type: item.load_type,
                    id: `${ item.client_id },${ item.load_id },${ group_token_vins }`,
                    price_type: item.price_type,
                    load_type: item.load_type,
                    route: item.route_detailed,
                    route_invoice_title: item.route_invoice_title,
                    client: item.client,
                    client_id: item.client_id,
                    load_number: item.load_number,
                    total: item.total,
                    orders: item.orders,
                    routes: item.routes,
                    legal_entity_title: item.legal_entity_title,
                    pre_payments: item.pre_payments,
                    legal_entity_id: item.legal_entity_id,
                    from_addresses: uniq(item.from_locations.map((location: any) => `${ location.street_line_1 } ${ location.zip } ${ location.city } ${ location.sort_name }`)),
                    to_addresses: uniq(item.to_locations.map((location: any) => `${ location.street_line_1 } ${ location.zip } ${ location.city } ${ location.sort_name }`)),
                    ldg_nr: uniq(item.orders.map((order: any) => order.ldg_nr)),
                    vin_number: uniq(item.orders.map((order: any) => order.vin_number)),
                    brand: uniq(item.orders.map((order: any) => order.brand)).join(", "),
                    model: uniq(item.orders.map((order: any) => order.model_title)).join(", "),
                });
            });

            setEntity(grouped);
            setTimeout(() => setSelectedRow([]), 700);
        } catch (e: any) {
            toastify(t("server error"), "error");
        }
    };

    const onSelectionChange = ({ value }: any) => {
        setSelectedRow(value);
    };

    const onSubmit = async () => {
        const invoices: any[] = [];
        map(selectedRow, (invoiceable: any) => {
            const invoice_setting = get(find(dropdowns.clients, {id: invoiceable.client_id}), ['invoice_settings']);
            const emails =  get(find(invoice_setting, {title: 'invoice_email_addresses'}), ['value']) ?? "";
            const type = get(find(invoice_setting, {title: 'invoice_recipient_type'}), ['value']) ?? "eu";
            const service_fee = get(find(invoice_setting, {title: 'invoice_service_fee'}), ['value']) ?? 0;
            const price = Number(invoiceable.total).toFixed(2);
            const serviceTotal = ( service_fee > 0 ) ? Number(( Number(invoiceable.total) * Number(service_fee) ) / 100).toFixed(2) : 0;
            const vatTotal = type === "ino" ? Number(( ( Number(invoiceable.total) + Number(serviceTotal) ) * 25 ) / 100).toFixed(2) : 0;
            const grandTotal = ( Number(price) + Number(serviceTotal) + Number(vatTotal) ).toFixed(2);
            const lgd_numbers: any = uniq(invoiceable.orders.map((order: any) => order.ldg_nr)).join(', ');
            const vin_numbers: any = uniq(invoiceable.orders.map((order: any) => order.vin_number)).join(', ');
            let title: string = "";

            if ( !isEmpty(lgd_numbers) ) {
                title = `${ t("ldg nr") } ${ lgd_numbers } \n`;
            }

            title = `${title}${ invoiceable.load_number }# ${ invoiceable.route_invoice_title } \n ${vin_numbers}`;
            const row = {
                id: null,
                status: 'draft',
                receiver_id: invoiceable.client_id,
                recipient_type: type,
                currency: 'EUR',
                service_fee: service_fee,
                email: emails ? emails.split(',') : [],
                footer_description: null,
                header_description: null,
                finalize_date: moment().format('YYYY-MM-DD HH:mm:ss'),
                legal_entity_id: invoiceable.legal_entity_id,
                discount_code_id: null,
                bank_account_number: null,
                is_vatable: type === "ino",
                pre_payment_total: 0,
                pre_payments: invoiceable.pre_payments,
                items: [{
                    id: null,
                    token: random(15),
                    title: title,
                    price: Number(invoiceable.total),
                    qty: Number(1),
                    is_active_service_fee: (service_fee > 0),
                    total: Number(grandTotal),
                    vat: type === "ino" ? 25 : 0,
                    discount: 0,
                    discount_percentage: 0,
                    vat_total: vatTotal,
                    service_fee_amount: serviceTotal,
                    description: null,
                    group_type: invoiceable.load_type,
                    group_token: invoiceable.group_token,
                    price_type: invoiceable.load_type,
                    load_type: invoiceable.load_type,
                    discount_type: 'type_value',
                    is_vatable: type === "ino",
                    ldg_nr: lgd_numbers,
                }]
            };

            invoices.push(row);
        });

        if ( invoices.length <= 0 ) {
            toastify(t("minimum item(s) required."), "error");
            return;
        }

        try {
            const response = await request({
                url: `suppliers/spot/loads/store/invoice`,
                method: 'POST',
                data: {
                    _method: "POST",
                    invoiceable: invoices,
                }
            });

            toastify(t("invoice(s) added"), "success");
            setTimeout(() => setSelectedRow([]), 700);
            setPanelState(false);
        } catch (e: any) {
            if ( e.status === 510 ) {
                const { data } = e.data;
                let summary: any = '';

                map(data, (item: any) => {
                    summary += t("error while generating a invoice for") + `: ${ item.client_name } ` + '\n';
                });

                toastify(t("server error"), "error", summary);
            } else {
                toastify(t("server error"), "error",);
            }

        }
    };

    useEffect(() => {
        setEntity(null);
        if ( !isEmpty(props.load) && !isEmpty(get(props, ['load', 'id'])) ) {
            // @ts-ignore
            getDetail(props.load.id);
        }
    }, [props.load]);
    return (
        <>
            <div>
                {
                    entity &&
                  <>

                      <Sidebar
                        visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                        fullScreen={ true } closeOnEscape={ false }
                      >
                          <Card className={ "border-0 p-0" }>
                              <CardHeader className={ "mb-2" }>
                                  <Row>
                                      <Col sm={ 12 } md={ 6 } lg={ 6 }>
                                          <h5 className={ "card-title mt-0" }>{ t('generate draft invoice(s)') }</h5>
                                          <p>{ t("create invoice(s) for the load ") }{ entity && ` #${ get(props, ['load','load_number']) }` }</p>
                                      </Col>
                                      <Col sm={ 12 } md={ 6 } lg={ 6 } className={ "text-end" }>
                                          <Button type="button" severity={ undefined } outlined size={ "small" }
                                                  onClick={ onSubmit } className="me-2">
                                              <i className="fas fa-save me-1" /> { t("generate draft") }
                                          </Button>
                                          <Button type="button" severity={ "danger" } outlined size={ "small" }
                                                  onClick={ onClose } title={ t("operation cancel") }
                                                  className="me-2">
                                              <i className="fas fa-angle-left me-1" /> { t("cancel") }
                                          </Button>
                                      </Col>
                                  </Row>
                              </CardHeader>
                              <Divider />
                              <CardBody style={ { border: '1px solid var(surface-50)' } }>
                                  {
                                      isEmpty(entity) && <span>{ t('no data') }</span>
                                  }
                                  {
                                      !isEmpty(entity) &&
                                    <>
                                        <Row className={ "mt-4 mb-7" }>
                                            <Col sm={ 12 }>
                                                <DataTable
                                                  value={ entity } dataKey="id"
                                                  reorderableColumns size={ "small" }
                                                  selectionMode="multiple"
                                                  selection={ selectedRow }
                                                  onSelectionChange={ onSelectionChange }
                                                  dragSelection
                                                  resizableColumns showGridlines stateStorage="session"
                                                  stateKey="dt-load-single-invoiceable-list"
                                                  scrollable scrollHeight="750px"
                                                  virtualScrollerOptions={ { itemSize: 50 } }>
                                                    <Column selectionMode="multiple"
                                                            headerStyle={ { width: '2rem' } } />
                                                    <Column field="load_number" header={ t('load number') } />
                                                    <Column field="total" header={ t('total') } />
                                                    <Column field="client" header={ t('client') } />
                                                    <Column field="legal_entity_title" header={t('legal entity')} />
                                                    <Column field="from_addresses" header={ t('from address') }
                                                            body={ (row: any) => row.from_addresses.map((item: any) => (
                                                                <div>{ item }</div> )) } />
                                                    <Column field="to_addresses" header={ t('to address') }
                                                            body={ (row: any) => row.to_addresses.map((item: any) => (
                                                                <div>{ item }</div> )) } />
                                                    <Column field="route" header={ t('route') }
                                                            body={ (data: any) => ( <span
                                                                dangerouslySetInnerHTML={ { __html: data.route } }></span> ) } />
                                                    <Column field="vin_number" header={ t('vin(s)') }
                                                            body={ (row: any) => row.orders.map((item: any) => (
                                                                <div>
                                                                    <VinWithType vinNumber={ item.vin_number } />
                                                                </div> )) } />
                                                    <Column field="brand" header={ t('brand') } />
                                                    <Column field="model" header={ t('model') } />
                                                </DataTable>
                                            </Col>
                                        </Row>
                                    </>
                                  }
                              </CardBody>
                          </Card>
                      </Sidebar>
                  </>
                }
            </div>
        </>
    );
}

ShowInvoiceableLoad.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(ShowInvoiceableLoad);
