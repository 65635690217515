import React from "react";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { FormikProps, Formik, Field } from "formik";
import {  find, findIndex, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { random } from "../../../../utils/Helper";
import { InputField } from "../../../../components/Shared/InputField";
import { InputTextAreaField } from "../../../../components/Shared/InputTextAreaField";
import { Card } from "primereact/card";
import { CardBody, CardFooter, CardHeader, Col, Row } from "react-bootstrap";
import { Button } from "primereact/button";

type TInputForm = {
    bank_name: string,
    account_number: string,
    account_owner: string,
    iban: string,
    swift: string,
    token: string,
    bank_address: string,
}

const DEFAULT_FORM_STATE = {
    bank_name: null,
    account_number: null,
    account_owner: null,
    iban: null,
    swift: null,
    token: null,
    bank_address: null,
};

interface IProps {
    bankDetails?: any,
    onUpdate?: any,
    t?: any
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        bank_name: yup.string().required(t('the field is required.', { field: t('bank name') })).nullable(),
        account_number: yup.string().required(t('the field is required.', { field: t('account number') })).nullable(),
        account_owner: yup.string().required(t('the field is required.', { field: t('account owner') })).nullable(),
        iban: yup.string().required(t('the field is required.', { field: t('iban') })).nullable(),
        swift: yup.string().required(t('the field is required.', { field: t('swift') })).nullable(),
        bank_address: yup.string().required(t('the field is required.', { field: t('address') })).nullable(),
    });
};

function BankDetail(props: IProps) {
    const { t, onUpdate } = props;
    const [initFormState, setInitFormState] = useState<any>(DEFAULT_FORM_STATE);
    const [bankDetails, setBankDetails] = useState<any>([]);

    const onSave = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        const _bankDetails: any = [...bankDetails];
        let existing = find(bankDetails, { token: payloads.token });

        if ( !isEmpty(existing) ) {
            const index = findIndex(_bankDetails, { token: payloads.token });
            existing = {...payloads};
            _bankDetails[index] = existing;
        } else {
            _bankDetails.push({
                ...payloads,
                token: random(10),
            });
        }

        onUpdate(_bankDetails);
        resetForm();
        setInitFormState(DEFAULT_FORM_STATE);
    };

    const onEdit = (token: any) => {
        const bankDetail: any = find(bankDetails, { token: token });

        if ( bankDetail ) {
            setInitFormState(bankDetail);
        } else {
            // toast(t("no data"), { type: "error" });
        }
    };

    const onDelete = (token: any) => {
        const _bankDetails: any = bankDetails.filter((item: any) => item.token !== token);
        setBankDetails(_bankDetails);
        onUpdate(_bankDetails);
    };

    useEffect(() => {
        setBankDetails(props.bankDetails);
    }, [props.bankDetails]);

    return (
        <Formik
            enableReinitialize={ true }
            initialValues={ initFormState }
            onSubmit={ onSave }
            validationSchema={ validationSchema(t) }
        >
            { (props: FormikProps<TInputForm>) => {
                return (
                    <Card className={"border-secondary mb-5 fl-card"}>
                        <CardHeader className={ "bg-body-tertiary p-2" }>
                            <h4 className="card-title m-0">{ t('bank details') }</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col sm={ 12 } md={ 4 }>
                                    <Field component={ InputField }
                                           name="bank_name"
                                           label={ t("bank name") }
                                           placeholder={ "Ex. OTP banka Hrvatska" } />
                                </Col>
                                <Col sm={ 12 } md={ 4 }>
                                    <Field component={ InputField }
                                           name="account_number"
                                           label={ t("account number") }
                                           placeholder={ "Ex. HR0027589" } />
                                </Col>
                                <Col sm={ 12 } md={ 4 }>
                                    <Field component={ InputField }
                                           name="account_owner"
                                           label={ t("account owner") }
                                           placeholder={ "Ex. ABC" }
                                       />
                                </Col>
                                <Col sm={ 12 } md={ 12 }>
                                    <Field component={ InputTextAreaField }
                                           name="bank_address"
                                           label={ t("bank address") }
                                           placeholder={ "Ex. Bärengasse 7, 8001 Zurich Switzerland" } />
                                </Col>
                                <Col sm={ 12 } md={ 3 }>
                                    <Field component={ InputField }
                                           name="iban"
                                           label={ t("iban") }
                                           placeholder={ "Ex. HR0027589" } />
                                </Col>
                                <Col sm={ 12 } md={ 3 }>
                                    <Field component={ InputField }
                                           name="swift"
                                           label={ t("swift") }
                                           placeholder={ "Ex. OTPVHR2X" } />
                                </Col>
                                <Col sm={ 12 } md={ 3 }>
                                    <div><label className="text-g-800 form-label">&nbsp;</label></div>
                                    <Button type={ "button" } disabled={ props.isSubmitting }
                                        tooltip={ t("to data save into database") }
                                        size={ "small" } outlined
                                        onClick={ () => props.handleSubmit() }
                                        tooltipOptions={ { position: 'top' } }
                                    >
                                        <i className="pi pi-save me-2" />
                                        { t("update bank") }
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter>
                            <table className={"fl-table"}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{t("bank name")}</th>
                                        <th>{t("account number")}</th>
                                        <th>{t("account owner")}</th>
                                        <th>{t("iban")}</th>
                                        <th>{t("swift")}</th>
                                        <th>{t("action")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bankDetails && bankDetails.map((item: any, index: any) => {
                                        return (
                                            <>
                                                <tr key={index} className={(index <= 0 || index % 2 === 0 ? "bg-light" : "")}>
                                                    <td scope={"row"}>{ index + 1 }</td>
                                                    <td>{ item.bank_name }</td>
                                                    <td>{ item.account_number }</td>
                                                    <td>{ item.account_owner }</td>
                                                    <td>{ item.iban }</td>
                                                    <td>{ item.swift }</td>
                                                    <td>
                                                        <a
                                                            onClick={ () => onEdit(item.token) }
                                                            className={ `cursor-pointer text-info me-2` }>
                                                            <i className="fas fa-edit" />
                                                        </a>
                                                        <a
                                                            onClick={ () => onDelete(item.token) }
                                                            className={ `cursor-pointer text-danger` }>
                                                            <i className="fas fa-trash-alt" />
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr key={ `${ index }-${ random(10) }`} className={(index <= 0 || index % 2 === 0 ? "bg-light" : "")}>
                                                    <td colSpan={7}  scope={"row"}>
                                                        <label>{ t("bank address") }</label>:
                                                        <span className={"ps-1"}>{item.bank_address ?? ""}</span>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </CardFooter>
                    </Card>
                );
            } }
        </Formik>
    );
}

BankDetail.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(BankDetail);
