import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useRef, useState } from "react";
import { ConfirmPopup } from 'primereact/confirmpopup';
import StateLessGrid from "../../../../components/Shared/StateLessGrid";
import { Button } from "primereact/button";
import { Col, Row } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../../../components/Shared/InputField";
import { utcDateToLocalDate } from "../../../../utils/Helper";
import EmailLogFilter from "./EmailLogFilter";
import { Badge } from "primereact/badge";
import EmailLogDetail from "./EmailLogDetail";
import Tooltip from "../../../../components/Common/Display/Tooltip";
import EmailResend from "./EmailResend";
import { ButtonGroup } from "primereact/buttongroup";

const EmailLogList = (props: any) => {
    const { t, moduleState, toastify } = props;
    const [operation, setOperation] = useState<any>(null);
    const [operationId, setOperationId] = useState<any>(null);
    const [initSearchFormState, setInitSearchFormState] = useState<any>({
        search: moduleState.search ?? null
    });
    const [filterPanel, setFilterPanel] = useState<boolean>(false);
    const tableRef: any = useRef();
    const [total, setTotal] = useState(0);

    const onDetail = (id: any) => {
        setOperationId(id);
        setOperation("detail");
    };

    const onResend = (id: any) => {
        setOperationId(id);
        setOperation("resend");
    };

    const columns = [
        {
            field: 'status', title: t('status'),
            render: (_row: any) => <>
                { _row.status == 1 &&
                <Badge severity="success" value={ t('success') } /> }
                { _row.status != 1 &&
                <Badge severity="danger" value={ t('failed') } />
                }
            </>
        },
        { field: 'sent_from', title: t('sent from') },
        { field: 'sent_to', title: t('sent to') },
        { field: 'subject', title: t('subject') },
        { field: 'process', title: t('process') },
        {
            field: 'sent_at', title: t('sent at'),
            render: (_row: any) => ( _row.sent_at && utcDateToLocalDate(_row.sent_at) )
        },
        {
            field: 'resend_at', title: t('resend at'),
            render: (_row: any) => utcDateToLocalDate(_row.resend_at)
        },
        {
            field: 'action',
            title: t('action'),
            sorting: false,
            align: 'right',
            render: (_row: any) => {
                return (
                    <>
                        <Tooltip
                            tooltip={ t('resend') }>
                            <a onClick={ () => onResend(_row.id) }>
                                <i className="fas fa-redo-alt" />
                            </a>
                        </Tooltip>
                        <Tooltip
                            tooltip={ t('detail of item') }>
                            <a onClick={ () => onDetail(_row.id) } className={ 'ms-2' }>
                                <i className="fas fa-eye" />
                            </a>
                        </Tooltip>
                    </>
                );
            }
        }
    ];

    const onCloseOperation = () => {
        setOperation(null);
        setOperationId(null);
        onReset();
    };

    const onReset = () => {
        if ( tableRef && tableRef.current ) {
            tableRef.current.onRefresh();
        }
    };

    const onSearch = ({ search }: any) => {
        props.setQuery({
            ...moduleState,
            search: search
        });
    };

    const onFilterClick = () => {
        setFilterPanel(true);
    };

    const DetailRow = ({ data }: any) => {
        return (
            <>
                <div>
                    <strong className={ 'text-capitalize' }>{ t('cc emails') }:</strong> { data.cc_emails }
                </div>
                <div>
                    <strong className={ 'text-capitalize' }>{ t('bcc emails') }:</strong> { data.bcc_emails }
                </div>
            </>
        );
    };

    const focusOnSearch = () => {
        const searchInput: HTMLInputElement | null = document.querySelector('input[name="search"]');
        if ( searchInput ) {
            searchInput.focus();
        }
    };

    return (
        <div className={ "block-viewer" }>
            <div className="block-section">
                <div className="block-header">
                    <span className="block-title">
                        <h5 className={ "mb-0" }>{ t('email logs') } ({total})</h5>
                    </span>
                    <div className="block-actions">
                        {
                            operation == 'detail' &&
                            <div>
                                <Button type={ "button" }
                                        tooltip={ t("operation cancel") } className={ "ms-2" }
                                        size={ "small" } severity={ "warning" }
                                        tooltipOptions={ { position: 'top' } } onClick={ onCloseOperation }>
                                    <i className="pi pi-arrow-left me-2" /> { t("cancel") }

                                </Button>
                            </div>
                        }
                    </div>
                </div>
                <div className="block-content">
                    {
                        operation !== 'detail' &&
                        <Row className={ "p-2" }>
                            <Col
                              sm={ 12 } md={ 6 } className={ "mb-2" }>
                                <Formik
                                  enableReinitialize={ true }
                                  initialValues={ initSearchFormState }
                                  onSubmit={ onSearch }
                                >
                                    { (props: FormikProps<any>) => {
                                        return (
                                            <Form onSubmit={ props.handleSubmit }>
                                                <Col sm={ 12 } md={ 8 }>
                                                    <Field component={ InputField } withoutLabel={ true }
                                                           autoFocus={true}
                                                           name="search"
                                                           formGroupClass={ "mb-0" }
                                                           placeholder={ t("search") } />
                                                </Col>
                                            </Form>
                                        );
                                    } }
                                </Formik>
                            </Col>

                            <Col
                              sm={ 12 } md={ 6 } className={ "text-right mb-2" }>
                                <ButtonGroup>
                                    <Button icon="pi pi-filter-fill" size={ "small" } outlined
                                            tooltip={ t('filter') } onClick={ onFilterClick }
                                            tooltipOptions={ { position: 'top' } } />

                                    <Button onClick={ onReset } icon="pi pi-refresh" size={ "small" } outlined
                                            tooltip={ t('reset') }
                                            tooltipOptions={ { position: 'top' } } />
                                </ButtonGroup>
                            </Col>

                            <Col sm={ 12 }>
                                <StateLessGrid
                                  size={ "normal" }
                                  url={ '/suppliers/email/logs' }
                                  name={ "emailLog" }
                                  columns={ columns }
                                  moduleState={ moduleState }
                                  setQuery={ props.setQuery }
                                  ref={ tableRef }
                                  itemSize={ 45 }
                                  detailRow={ (data: any) => <DetailRow data={ data } /> }
                                  setTotal={ setTotal }
                                />
                            </Col>
                        </Row>
                    }

                    {
                        operation == 'detail' &&
                        <EmailLogDetail
                          setQuery={ props.setQuery }
                          toastify={ props.toastify }
                          dropdowns={ props.dropdowns }
                          params={ { operation, operationId } }
                          onClose={ onCloseOperation }
                        />
                    }

                    <EmailResend
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        params={ { operation, operationId } }
                        onClose={ onCloseOperation }
                    />


                    <EmailLogFilter
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        visibility={ filterPanel }
                        onClose={ () => {
                            setFilterPanel(false);
                            focusOnSearch();
                        } }
                    />

                    <ConfirmPopup />
                </div>
            </div>
        </div>
    );
};

EmailLogList.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(EmailLogList));
