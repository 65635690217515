import React, { useEffect, useState } from "react";
import { get, find, map, isEmpty, findIndex } from "lodash";

//import Breadcrumbs
import config from "../../../config";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import WithRouter from "../../../components/Common/WithRouter";
import { request } from "../../../utils/Request";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Chart } from "primereact/chart";

const DEFAULT_COUNTER_WIDGET = {
    orders: 0,
    spot_loads: 0,
    transport_vehicles: 0,
    users: 0,
};

const DEFAULT_LIST_WIDGET = {
    day_off_vehicles: [],
    transported_vehicles_by_country: [],
    transported_vehicles_by_sub_contractor: [],
    in_progress_orders: [],
    grouped_orders: [],
};

const Dashboard = (props: any) => {
    const { t } = props;
    document.title = `${ t("dashboard") } | ${ config.appNameForTitle }`;

    const [groupedOrderByCountry, setGroupedOrderByCountry] = useState([]);
    const [groupedOrderByCity, setGroupedOrderByCity] = useState([]);
    const [counterWidget, setCounterWidget] = useState(DEFAULT_COUNTER_WIDGET);
    const [listWidget, setListWidget] = useState(DEFAULT_LIST_WIDGET);
    const [numberOfTrVehiclesByCountry, setNumberOfTrVehiclesByCountry] = useState<any>([]);
    const [numberOfTrVehiclesByTransportCompanies, setNumberOfTrVehiclesByTransportCompanies] = useState<any>([]);
    const [numberOfActiveLoads, setNumberOfActiveLoads] = useState<any>([]);
    const [numberOfTrVehiclesByMonth, setNumberOfTrVehiclesByMonth] = useState({});
    const [lineChartOptions, setLineChartOptions] = useState({});
    const [pieChartOptions, setPieChartOptions] = useState({});

    const getDashboardCounter = async () => {
        try {
            const response = await request({
                url: '/suppliers/dashboards/counter',
                params: {}
            });

            const { data } = response.data;
            setCounterWidget(data);
        } catch (e) {

        }
    };

    const getDashboardList = async () => {
        try {
            const response = await request({
                url: '/suppliers/dashboards/list',
                params: {}
            });

            const documentStyle = getComputedStyle(document.documentElement);
            const { data } = response.data;
            setListWidget(data);

            if ( data.transported_vehicles_by_country ) {
                let xBar: any = [];
                let series: any = [];

                data.transported_vehicles_by_country.map((item: any) => {
                    xBar.push(item.country);
                    series.push(item.vehicles);
                })

                setNumberOfTrVehiclesByCountry({
                    labels: xBar,
                    datasets: [{
                        label: t("total transportation"),
                        data: series,
                    }]
                })
            }

            if ( data.in_progress_orders ) {
                setNumberOfActiveLoads({
                    labels: [t('total'), t('in transit')],
                    datasets: [{
                        data: [data.in_progress_orders.total, data.in_progress_orders.in_progress],
                    }]
                })
            }

            if ( data.transported_vehicles_by_sub_contractor ) {
                let xBar: any = [];
                let series: any = [];

                data.transported_vehicles_by_sub_contractor.map((item: any) => {
                    xBar.push(item.name);
                    series.push(Number(item.vehicles));
                })

                setNumberOfTrVehiclesByTransportCompanies({
                    labels: xBar,
                    datasets: [{
                        data: series,
                    }]
                })
            }

            if ( data.grouped_orders && data.grouped_orders.country ) {
                const newArray: any = [];
                data.grouped_orders.country.map((order: any) => {
                    const { country, status, number_of_orders } = order;
                    let item: any = {
                        country,
                        announced: 0,
                        inTransit: 0,
                        delivered: 0,
                        total: 0,
                    };
                    let existingItem: any = find(newArray, { country: country });

                    if ( !isEmpty(existingItem) ) {
                        item = existingItem;
                    }

                    switch (status) {
                        case 'announced':
                            item.announced += number_of_orders;
                            item.total += number_of_orders;
                            break;
                        case 'in-transit':
                            item.inTransit += number_of_orders;
                            item.total += number_of_orders;
                            break;
                        case 'delivered':
                            item.delivered += number_of_orders;
                            item.total += number_of_orders;
                            break;
                        default:
                            break;
                    }

                    if ( !isEmpty(existingItem) ) {
                        newArray[findIndex(newArray, { country: country })] = item
                    } else {
                        newArray.push(item)
                    }
                }, {});

                setGroupedOrderByCountry(newArray);
            }

            if ( data.grouped_orders && data.grouped_orders.city ) {
                const newArray: any = [];
                data.grouped_orders.city.map((order: any) => {
                    const { city, status, number_of_orders } = order;
                    let item: any = {
                        city,
                        announced: 0,
                        inTransit: 0,
                        delivered: 0,
                        total: 0,
                    };
                    let existingItem: any = find(newArray, { city: city });

                    if ( !isEmpty(existingItem) ) {
                        item = existingItem;
                    }

                    switch (status) {
                        case 'announced':
                            item.announced += number_of_orders;
                            item.total += number_of_orders;
                            break;
                        case 'in-transit':
                            item.inTransit += number_of_orders;
                            item.total += number_of_orders;
                            break;
                        case 'delivered':
                            item.delivered += number_of_orders;
                            item.total += number_of_orders;
                            break;
                        default:
                            break;
                    }

                    if(!isEmpty(existingItem)) {
                        newArray[findIndex(newArray, { city: city })] = item
                    } else {
                        newArray.push(item)
                    }
                }, {});
                setGroupedOrderByCity(newArray);
            }
        } catch (e) {

        }
    };

    useEffect(() => {
        // setDocumentStyle();
        async function init() {
            await getDashboardCounter();
            await getDashboardList();

            setTimeout(() => {
                const documentStyle = getComputedStyle(document.documentElement);
                const textColor = documentStyle.getPropertyValue('--text-color');
                const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
                const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
                setLineChartOptions({
                    maintainAspectRatio: false,
                    aspectRatio: 0.6,
                    plugins: {
                        legend: {
                            labels: {
                                color: textColor
                            }
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: textColorSecondary
                            },
                            grid: {
                                color: surfaceBorder
                            }
                        },
                        y: {
                            ticks: {
                                color: textColorSecondary
                            },
                            grid: {
                                color: surfaceBorder
                            }
                        }
                    }
                });
                setPieChartOptions({
                    plugins: {
                        legend: {
                            labels: {
                                usePointStyle: true
                            }
                        }
                    }
                })
                // static
                setNumberOfTrVehiclesByMonth({
                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'September', 'October', 'November', 'December'],
                    datasets: [
                        {
                            label: 'Total transportation',
                            data: [15, 20, 30, 40, 45, 50, 49, 60, 65, 70, 91],
                            fill: true,
                            borderColor: documentStyle.getPropertyValue('--orange-500'),
                            tension: 0.4,
                            backgroundColor: 'rgba(255,167,38,0.2)'
                        }
                    ]
                })
            }, 2000);
        }

        init();
    }, []);

    return (
        <div>
            <Row>
                <Col sm={ 12 } md={ 6 } xl={ 3 }>
                    <div className="card h-full">
                        <span className="font-semibold text-lg">
                            <Link to={ "/orders" }>{ t('orders') }</Link>
                        </span>
                        <div className="flex justify-content-between align-items-start mt-3">
                            <div className="w-6">
                                <span className="text-4xl font-bold text-900">
                                    { counterWidget.orders }
                                </span>
                            </div>
                            <div className="w-6">
                                <svg
                                    width="100%"
                                    viewBox="0 0 258 96"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 93.9506L4.5641 94.3162C8.12821 94.6817 15.2564 95.4128 22.3846 89.6451C29.5128 83.8774 36.641 71.6109 43.7692 64.4063C50.8974 57.2018 58.0256 55.0592 65.1538 58.9268C72.2821 62.7945 79.4103 72.6725 86.5385 73.5441C93.6667 74.4157 100.795 66.2809 107.923 65.9287C115.051 65.5765 122.179 73.0068 129.308 66.8232C136.436 60.6396 143.564 40.8422 150.692 27.9257C157.821 15.0093 164.949 8.97393 172.077 6.43766C179.205 3.9014 186.333 4.86425 193.462 12.0629C200.59 19.2616 207.718 32.696 214.846 31.0487C221.974 29.4014 229.103 12.6723 236.231 5.64525C243.359 -1.38178 250.487 1.29325 254.051 2.63076L257.615 3.96827"
                                        style={ {
                                            strokeWidth: "2px",
                                            stroke: "var(--primary-color)",
                                        } }
                                        stroke="10"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col sm={ 12 } md={ 6 } xl={ 3 }>
                    <div className="card h-full">
                        <span className="font-semibold text-lg">
                            <Link to={ "/loads" }>{ t('spot loads') }</Link>
                        </span>
                        <div className="flex justify-content-between align-items-start mt-3">
                            <div className="w-6">
                                <span className="text-4xl font-bold text-900">
                                    { counterWidget.spot_loads }
                                </span>
                            </div>
                            <div className="w-6">
                                <svg
                                    width="100%"
                                    viewBox="0 0 258 96"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 93.9506L4.5641 94.3162C8.12821 94.6817 15.2564 95.4128 22.3846 89.6451C29.5128 83.8774 36.641 71.6109 43.7692 64.4063C50.8974 57.2018 58.0256 55.0592 65.1538 58.9268C72.2821 62.7945 79.4103 72.6725 86.5385 73.5441C93.6667 74.4157 100.795 66.2809 107.923 65.9287C115.051 65.5765 122.179 73.0068 129.308 66.8232C136.436 60.6396 143.564 40.8422 150.692 27.9257C157.821 15.0093 164.949 8.97393 172.077 6.43766C179.205 3.9014 186.333 4.86425 193.462 12.0629C200.59 19.2616 207.718 32.696 214.846 31.0487C221.974 29.4014 229.103 12.6723 236.231 5.64525C243.359 -1.38178 250.487 1.29325 254.051 2.63076L257.615 3.96827"
                                        style={ {
                                            strokeWidth: "2px",
                                            stroke: "var(--primary-color)",
                                        } }
                                        stroke="10"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col sm={ 12 } md={ 6 } xl={ 3 }>
                    <div className="card h-full">
                        <span className="font-semibold text-lg">
                            <Link to={ "/transport/vehicles" }>{ t('transport vehicles') }</Link>
                        </span>
                        <div className="flex justify-content-between align-items-start mt-3">
                            <div className="w-6">
                                <span className="text-4xl font-bold text-900">
                                    { counterWidget.transport_vehicles }
                                </span>
                            </div>
                            <div className="w-6">
                                <svg
                                    width="100%"
                                    viewBox="0 0 258 96"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 93.9506L4.5641 94.3162C8.12821 94.6817 15.2564 95.4128 22.3846 89.6451C29.5128 83.8774 36.641 71.6109 43.7692 64.4063C50.8974 57.2018 58.0256 55.0592 65.1538 58.9268C72.2821 62.7945 79.4103 72.6725 86.5385 73.5441C93.6667 74.4157 100.795 66.2809 107.923 65.9287C115.051 65.5765 122.179 73.0068 129.308 66.8232C136.436 60.6396 143.564 40.8422 150.692 27.9257C157.821 15.0093 164.949 8.97393 172.077 6.43766C179.205 3.9014 186.333 4.86425 193.462 12.0629C200.59 19.2616 207.718 32.696 214.846 31.0487C221.974 29.4014 229.103 12.6723 236.231 5.64525C243.359 -1.38178 250.487 1.29325 254.051 2.63076L257.615 3.96827"
                                        style={ {
                                            strokeWidth: "2px",
                                            stroke: "var(--primary-color)",
                                        } }
                                        stroke="10"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col sm={ 12 } md={ 6 } xl={ 3 }>
                    <div className="card h-full">
                        <span className="font-semibold text-lg">
                            <Link to={ "/users" }>{ t('users') }</Link>
                        </span>
                        <div className="flex justify-content-between align-items-start mt-3">
                            <div className="w-6">
                                <span className="text-4xl font-bold text-900">
                                    { counterWidget.users }
                                </span>
                            </div>
                            <div className="w-6">
                                <svg
                                    width="100%"
                                    viewBox="0 0 258 96"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 93.9506L4.5641 94.3162C8.12821 94.6817 15.2564 95.4128 22.3846 89.6451C29.5128 83.8774 36.641 71.6109 43.7692 64.4063C50.8974 57.2018 58.0256 55.0592 65.1538 58.9268C72.2821 62.7945 79.4103 72.6725 86.5385 73.5441C93.6667 74.4157 100.795 66.2809 107.923 65.9287C115.051 65.5765 122.179 73.0068 129.308 66.8232C136.436 60.6396 143.564 40.8422 150.692 27.9257C157.821 15.0093 164.949 8.97393 172.077 6.43766C179.205 3.9014 186.333 4.86425 193.462 12.0629C200.59 19.2616 207.718 32.696 214.846 31.0487C221.974 29.4014 229.103 12.6723 236.231 5.64525C243.359 -1.38178 250.487 1.29325 254.051 2.63076L257.615 3.96827"
                                        style={ {
                                            strokeWidth: "2px",
                                            stroke: "var(--primary-color)",
                                        } }
                                        stroke="10"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col sm={ 12 } md={ 6 } xl={ 6 } className={"mt-4"}>
                    <div className="card">
                        <div
                            className="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
                            <div className="text-900 text-xl font-semibold mb-3 md:mb-0">
                                { t("vehicle status by country") }
                            </div>
                            <div className="inline-flex align-items-center">

                            </div>
                        </div>
                        <DataTable
                            value={ groupedOrderByCountry }
                            dataKey="id"
                            paginator
                            rows={ 9 }
                            className="datatable-responsive"
                            emptyMessage={ t('no data') }
                        >
                            <Column field="country" header={ t("country") } bodyClassName={"text-capitalize"} sortable/>
                            <Column field="announced" header={ t("announced") } sortable />
                            <Column field="inTransit" header={ t("in transit") } sortable />
                            <Column field="delivered" header={ t("delivered") } sortable />
                            <Column field="total" header={ t("total") } sortable />
                        </DataTable>
                    </div>
                </Col>

                <Col sm={ 12 } md={ 6 } xl={ 6 } className={"mt-4"}>
                    <div className="card">
                        <div
                            className="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
                            <div className="text-900 text-xl font-semibold mb-3 md:mb-0">
                                { t("vehicle status by city") }
                            </div>
                            <div className="inline-flex align-items-center">

                            </div>
                        </div>
                        <DataTable
                            value={ groupedOrderByCity }
                            dataKey="id"
                            paginator
                            rows={ 9 }
                            className="datatable-responsive"
                            emptyMessage={ t('no data') }
                        >
                            <Column field="city" header={ t("city") } bodyClassName={ "text-capitalize" } sortable/>
                            <Column field="announced" header={ t("announced") } sortable />
                            <Column field="inTransit" header={ t("in transit") } sortable />
                            <Column field="delivered" header={ t("delivered") } sortable />
                            <Column field="total" header={ t("total") } sortable />
                        </DataTable>
                    </div>
                </Col>

                <Col sm={ 12 } md={ 6 } xl={ 6 } className={"mt-4"}>
                    <div className="card">
                        <div
                            className="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
                            <div className="text-900 text-xl font-semibold mb-3 md:mb-0">
                                { t("vehicle with days out of use") }
                            </div>
                            <div className="inline-flex align-items-center">

                            </div>
                        </div>
                        <DataTable
                            value={ listWidget.day_off_vehicles }
                            dataKey="id"
                            paginator
                            rows={ 9 }
                            className="datatable-responsive"
                            emptyMessage={ t('no data') }
                        >
                            <Column field="truck_plate_number" header={ t("truck plate number") } />
                            <Column field="trailer_plate_number" header={ t("trailer plate number") } />
                            <Column field="days" header={ t("days") }
                                    body={ (_row: any) => {
                                        const days = _row.days_since_last_journey.split(' ')
                                        return get(days, [1]) == 'days' ? (get(days, [0]) + ' ' + get(days, [1])) : 0
                                    } }/>
                        </DataTable>
                    </div>
                </Col>

                <Col sm={ 12 } md={ 6 } xl={ 6 } className={"mt-4"}>
                    <div className="card">
                        <div
                            className="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
                            <div className="text-900 text-xl font-semibold mb-3 md:mb-0">
                                { t("transported vehicles over time (monthly)") }
                            </div>
                            <div className="inline-flex align-items-center">
                            </div>
                        </div>
                        <div>
                            <Chart type="line" data={numberOfTrVehiclesByMonth} options={lineChartOptions} height={'485'} />
                        </div>
                    </div>
                </Col>

                <Col sm={ 12 } md={ 6 } xl={ 6 } className={"mt-4"}>
                    <div className="card">
                        <div
                            className="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
                            <div className="text-900 text-xl font-semibold mb-3 md:mb-0">
                                { t("transported vehicles by transport companies") }
                            </div>
                            <div className="inline-flex align-items-center">
                            </div>
                        </div>
                        <div>
                            <Chart type="pie" data={numberOfTrVehiclesByTransportCompanies} options={pieChartOptions} className="w-full md:w-30rem"/>
                        </div>
                    </div>
                </Col>

                <Col sm={ 12 } md={ 6 } xl={ 6 } className={"mt-4"}>
                    <div className="card">
                        <div
                            className="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
                            <div className="text-900 text-xl font-semibold mb-3 md:mb-0">
                                { t("active loads") }
                            </div>
                            <div className="inline-flex align-items-center">
                            </div>
                        </div>
                        <div>
                            <Chart type="doughnut" data={numberOfActiveLoads} options={pieChartOptions} className="w-full md:w-30rem"/>
                        </div>
                    </div>
                </Col>

                <Col sm={ 12 } md={ 6 } xl={ 6 } className={"mt-4"}>
                    <div className="card">
                        <div
                            className="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
                            <div className="text-900 text-xl font-semibold mb-3 md:mb-0">
                                { t("transported vehicles over time (monthly)") }
                            </div>
                            <div className="inline-flex align-items-center">
                            </div>
                        </div>
                        <div>
                            <Chart type="bar" data={numberOfTrVehiclesByCountry} options={lineChartOptions} height={'485'} />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

Dashboard.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(WithRouter(Dashboard));
