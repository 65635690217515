import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { get, isEmpty } from "lodash";
import { request } from "../../../utils/Request";
import UrlHelper from "js-url-helper";
import qs from "qs";
import { Route, Routes, useLocation } from "react-router-dom";
import { Toast } from "primereact/toast";
import OrderList from "./partials/OrderList";

const DEFAULT_DROPDOWNS = {
    locations: [],
    brands: [],
    models: [],
    clients: [],
    statuses: [],
    automaticStartNextJourney: []
};

const url = new UrlHelper();

const Order = (props: any) => {
    const { t } = props;
    const toast = useRef(null);
    const [dropdowns, setDropdowns] = useState<any>(null);
    const { state } = useLocation();

    const [moduleState, setModuleState] = useState<any>({
        page: 1,
        per_page: 20,
        search: null,
        initialized: false,
        filters: []
    });

    const getDropdowns = async () => {
        try {
            const response = await request({
                url: '/suppliers/orders/create',
                params: {
                    type: 'list',
                }
            });
            const { data } = response.data;

            setDropdowns({
                locations: data.locations.map((item: any) => ( { ...item, key: item.id } )),
                clients: data.clients,
                brands: data.brands,
                models: data.models,
                statuses: data.statuses,
                automaticStartNextJourney: data.automaticStartNextJourney
            });
        } catch (e) {
            setDropdowns(DEFAULT_DROPDOWNS);
        } finally {
            const prevUrl = qs.parse(url.getSearchParam().prev_url ?? "");

            setModuleState({
                page: !isEmpty(prevUrl.page) ? url.getSearchParam().page : 1,
                per_page: !isEmpty(prevUrl.per_page) ? url.getSearchParam().per_page : 20,
                search: !isEmpty(prevUrl.search) ? url.getSearchParam().search : null,
                initialized: true,
                filters: {
                    vin_numbers: get(state,['vin_numbers']),
                    statuses: ['produced'], // !isEmpty(get(state,['vin_numbers'])) ? [] :
                }
            });
        }
    };

    useEffect(() => {
        document.title = t("orders");

        async function init() {
            await getDropdowns();
        }

        init();
    }, []);

    const toastify = (message: any, color: string = 'info', summary: any = null) => {
        if ( toast && toast.current ) {
            // @ts-ignore
            toast.current.show({ severity: color, summary: summary, detail: message });
        }
    };

    return (
        <div className={ "module-container" }>
            <Toast ref={ toast } />
            <div className="layout-content">
                { ( moduleState && moduleState.initialized ) ?
                    <React.Fragment>
                        <Routes>
                            <Route path={ "/" } element={
                                <OrderList
                                    moduleState={ moduleState }
                                    dropdowns={ dropdowns }
                                    toastify={ toastify }
                                    setQuery={ setModuleState }
                                /> } />
                        </Routes>
                    </React.Fragment>
                    : <h6>{ t('loading') }</h6> }
            </div>
        </div>
    );
};

// Order.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(Order);
