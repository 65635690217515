import React from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "primereact/badge";

function AlpSpotLoadStatus(props: any) {
    const { status, label, textable } = props;
    const { t } = useTranslation();
    if ( status === 'announced' ) {
        return (
            <>
                { textable &&
                <span className={ "text-primary-500" }>
                    { label ? label : t('announced') }
                </span>
                }
                {
                    !textable &&
                    <Badge
                      value={ label ? label : t('announced') }
                      severity="info" />
                }
            </>
        );
    }

    if ( status === 'rejected' ) {
        return (
            <>
                { textable &&
                <span className={ "text-red-500" }>
                        { label ? label : t('rejected') }
                    </span>
                }
                {
                    !textable &&
                    <Badge severity={ "danger" } value={ label ? label : t('rejected') } />
                }
            </>
        );
    }

    if ( status === 'accepted' || status === 'confirmed' ) {
        return (
            <>
                { textable &&
                <span className={ "text-green-500" }>
                    { label ? label : t('confirmed') }
                    </span>
                }
                {
                    !textable &&
                    <Badge severity={ "success" } value={ label ? label : t('confirmed') } />
                }
            </>
        );
    }

    if ( status === 'ready-to-be-loaded' ) {
        return (
            <>
                { textable &&
                <span className={ "text-red-500" }>
                        { label ? label : t('ready to be loaded') + ' Pending' }
                    </span>
                }
                {
                    !textable &&
                    <Badge severity={ "danger" } value={ label ? label : t('ready to be loaded') + ' Pending' } />
                }
            </>
        );
    }

    if ( status === 'transport-order-pending' ) {
        return (
            <>
                { textable &&
                <span className={ "text-red-500" }>
                        { label ? label : t('pending transport order') }
                    </span>
                }
                {
                    !textable &&
                    <Badge severity={ "danger" } value={ label ? label : t('pending transport order') } />
                }
            </>
        );
    }

    if ( status === 'transport-order-declined' ) {
        return (
            <>
                { textable &&
                <span className={ "text-red-500" }>
                { label ? label : t('decline transport order') }
                    </span>
                }
                {
                    !textable &&
                    <Badge severity={ "danger" } value={ label ? label : t('decline transport order') } />
                }
            </>
        );
    }

    if ( status === 'ready-to-load' ) {
        return (
            <>
                { textable &&
                <span className={ "text-green-500" }>
                { label ? label : t('ready to load') }
                    </span>
                }
                {
                    !textable &&
                    <Badge severity={ "success" } value={ label ? label : t('ready to load') } />
                }
            </>
        );
    }

    if ( status === 'updated' ) {
        return (
            <>
                { textable &&
                <span className={ "text-black-500" }>
                    { label ? label : t('updated') }
                        </span>
                }
                {
                    !textable &&
                    <Badge severity={ "info" } value={ label ? label : t('updated') } />
                }
            </>
        );
    }

    if ( status === 'gate-out-confirmation-pending' ) {
        return (
            <>
                { textable &&
                <span className={ "text-red-500" }>
                { label ? label : t('gate out pending') }
                        </span>
                }
                {
                    !textable &&
                    <Badge severity={ "danger" } value={ label ? label : t('gate out pending') } />
                }
            </>
        );
    }

    if ( status === 'gate-out-confirmation-completed' ) {
        return (
            <>
                { textable &&
                <span className={ "text-green-500" }>
                { label ? label : t('gate out completed') }
                        </span>
                }
                {
                    !textable &&
                    <Badge severity={ "success" } value={ label ? label : t('gate out completed') } />
                }
            </>
        );
    }

    if ( status === 'in-transit' ) {
        return (
            <>
                { textable &&
                <span className={ "text-yellow-500" }>
                    { label ? label : t('in transit') }
                </span>
                }
                {
                    !textable &&
                    <Badge severity={ "warning" } value={ label ? label : t('in transit') } />
                }
            </>
        );
    }

    if ( status === 'unload-requested' ) {
        return (
            <>
                { textable &&
                <span className={ "text-black-500" }>
                    { label ? label : t('unload requested') }
                </span>
                }
                {
                    !textable &&
                    <Badge severity={ "info" } value={ label ? label : t('unload requested') } />
                }
            </>
        );
    }

    if ( status === 'unloaded' ) {
        return (
            <>
                { textable &&
                <span className={ "text-green-500" }>
                { label ? label : t('delivered') }
                        </span>
                }
                {
                    !textable &&
                    <Badge severity={ "success" } value={ label ? label : t('delivered') } />
                }
            </>
        );
    }

    if ( status === 'archived' ) {
        return (
            <>
                { textable &&
                <span className={ "text-green-500" }>
                { label ? label : t('archived') }
                        </span>
                }
                {
                    !textable &&
                    <Badge severity={ "success" } value={ label ? label : t('archived') } />
                }
            </>
        );
    }

    return (
        <span>-</span>
    );
}

export default AlpSpotLoadStatus;
