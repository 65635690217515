import {
    Breadcrumb,
    ChildContainerProps,
    LayoutConfig,
    LayoutContextProps,
    LayoutState,
} from "../types";
import React, { useEffect, useState } from "react";
import store from "store";
import Auth from "../utils/Auth";

export const LayoutContext = React.createContext({} as LayoutContextProps);

export const LayoutProvider = (props: ChildContainerProps) => {
    const auth = new Auth();
    const user: any = auth.getUser();

    const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
    const [layoutConfig, setLayoutConfig] = useState<LayoutConfig>({
        ripple: true,
        inputStyle: "outlined",
        menuMode: "static",
        menuTheme: "primaryColor",
        colorScheme: "light",
        theme: "indigo",
        scale: 14,
        lang: 'eng',
        ...( user || {} ).app_settings
    });

    const [layoutState, setLayoutState] = useState<LayoutState>({
        staticMenuDesktopInactive: false,
        overlayMenuActive: false,
        overlaySubmenuActive: false,
        profileSidebarVisible: false,
        configSidebarVisible: false,
        staticMenuMobileActive: false,
        menuHoverActive: false,
        resetMenu: false,
        sidebarActive: false,
        anchored: false,
        ...store.get(`${ process.env.REACT_APP_STORAGE_PREFIX }.layoutState`)
    });
    const onMenuToggle = () => {
        if ( isOverlay() ) {
            setLayoutState((prevLayoutState: any) => ( {
                ...prevLayoutState,
                overlayMenuActive: !prevLayoutState.overlayMenuActive,
            } ));
        }

        if ( isDesktop() ) {
            setLayoutState((prevLayoutState: any) => ( {
                ...prevLayoutState,
                staticMenuDesktopInactive:
                    !prevLayoutState.staticMenuDesktopInactive,
            } ));
        } else {
            setLayoutState((prevLayoutState: any) => ( {
                ...prevLayoutState,
                staticMenuMobileActive: !prevLayoutState.staticMenuMobileActive,
            } ));
        }
    };

    const showConfigSidebar = () => {
        setLayoutState((prevLayoutState: any) => ( {
            ...prevLayoutState,
            configSidebarVisible: true,
        } ));
    };

    const showProfileSidebar = () => {
        setLayoutState((prevLayoutState: any) => ( {
            ...prevLayoutState,
            profileSidebarVisible: !prevLayoutState.profileSidebarVisible,
        } ));
    };

    const isOverlay = () => {
        return layoutConfig.menuMode === "overlay";
    };

    const isSlim = () => {
        return layoutConfig.menuMode === "slim";
    };

    const isSlimPlus = () => {
        return layoutConfig.menuMode === "slim-plus";
    };

    const isHorizontal = () => {
        return layoutConfig.menuMode === "horizontal";
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };

    useEffect(() => {
        store.set(`${ process.env.REACT_APP_STORAGE_PREFIX }.layoutState`, layoutState);
    }, [layoutState]);

    useEffect(() => {
        //this ne cancelled to update all coz store change if color change
        // store.set(`${ process.env.REACT_APP_STORAGE_PREFIX }.layoutConfig`, layoutConfig);
    }, [layoutConfig]);

    useEffect(() => {
        const linkElement: any = document.getElementById('theme-link');
        if ( linkElement ) {
            linkElement.href = `/assets/theme-${ layoutConfig.colorScheme }/${ layoutConfig.theme }/theme.css`;
        }
        // console.log('first')
    }, []);

    const value = {
        layoutConfig,
        setLayoutConfig,
        layoutState,
        setLayoutState,
        onMenuToggle,
        showConfigSidebar,
        showProfileSidebar,
        isSlim,
        isSlimPlus,
        isHorizontal,
        isDesktop,
        breadcrumbs,
        setBreadcrumbs,
    };

    return (
        <LayoutContext.Provider value={ value }>
            { props.children }
        </LayoutContext.Provider>
    );
};
