import { withTranslation } from "react-i18next";
import WithRouter from "../../../../../components/Common/WithRouter";
import React, { useRef, useState } from "react";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import StateLessGrid from "../../../../../components/Shared/StateLessGrid";
import { Button } from "primereact/button";
import { Col, Row } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../../../../components/Shared/InputField";
import InvoiceFilter from "./InvoiceFilter";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { isEmpty, get, sumBy } from "lodash";
import { utcDateToLocalDate, formatDate } from "../../../../../utils/Helper";
import InvoiceStatus from "../../../invoices/partials/InvoiceStatus";
import InvoiceAmountDisplay from "../../../invoices/partials/InvoiceAmountDisplay";
import InvoiceDetail from "../../../invoices/partials/InvoiceDetail";
import Tooltip from "../../../../../components/Common/Display/Tooltip";
import HasAccess from "../../../../../utils/HasAccess";
import Auth from "../../../../../utils/Auth";
import { request } from "../../../../../utils/Request";
import InvoicePayment from "../../../invoices/partials/InvoicePayment";
import { ButtonGroup } from "primereact/buttongroup";

const InvoiceList = (props: any) => {
    const { t, moduleState, toastify } = props;
    const auth = new Auth();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [operation, setOperation] = useState<any>(searchParams.get('operation') ?? null);
    const [operationId, setOperationId] = useState<any>(searchParams.get('operationId') ?? null);
    const [filterPanel, setFilterPanel] = useState<boolean>(false);
    let [isInvoicePaymentPanelActive, setIsInvoicePaymentPanelActive] = useState<any>(null);
    const [extra, setExtra] = useState<any>([]);
    const [initSearchFormState, setInitSearchFormState] = useState<any>({
        search: moduleState.search ?? null
    });
    const tableRef: any = useRef();
    const [total, setTotal] = useState(0);

    const onPantheonTransfer = async (payloads: any): Promise<void> => {
        try {
            await request({
                url: `suppliers/invoices/pantheon/transfer/${ payloads.id }`,
                method: 'post',
                data: { _method: 'post' }
            });

            toastify(t("transfer started", { item: t('invoice') }), "info");
            onReset();
        } catch (e: any) {
            toastify(t("server error"), "error");
        }
    };

    const onContentLoaded = (response: any) => setExtra(response.extra);

    const columns = [
        {
            field: 'client', title: t('client'),
            render: (row: any) => get(row, ['client', 'name']),
            sorting: false
        },
        {
            field: 'status', title: t('status'),
            render: (row: any) => <InvoiceStatus status={ row.status } paid={ !isEmpty(row.paid_at) } />
        },
        {
            field: 'number',
            title: t('invoice number'),
            sorting: false,
            render: (row: any) => ( row.status === 'draft' ? get(row, ['draft_invoice_number']) : get(row, ['final_invoice_number']) )
        },
        {
            field: 'final_total',
            title: t('amount'),
            render: (row: any) => <>
                <InvoiceAmountDisplay
                    total={ row.grand_total } paid={ row.paid_total }
                    status={ row.status } />
            </>
        },
        {
            field: 'payment_mode',
            title: t('payment mode'),
            sorting: false,
            render: (row: any) => <>
                <div>
                    <div>{t("bank")}: { row.payments ? sumBy(row.payments.filter((i: any) => i.payment_mode === "bank transfer"), 'total') : 0 }</div>
                    <div>{t("cash")}: { row.payments ? sumBy(row.payments.filter((i: any) => i.payment_mode === "cash"), 'total') : 0}</div>
                    {/*<div>{t("credit card")}: { sumBy(row.payments.filter((i: any) => i.payment_mode === "credit card"), 'total') }</div>*/}
                </div>
            </>
        },
        {
            field: 'modified_at',
            title: t('modified at'),
            render: ({ updated_at }: any) => {
                return (
                    <>
                        { ( updated_at ) && <span>{ utcDateToLocalDate(updated_at) }</span> }
                    </>
                );
            },
        },
        {
            field: 'finalize_date',
            title: t('finalize date'),
            render: ({ finalize_date }: any) => {
                return (
                    <>
                        { ( finalize_date ) && <span>{ formatDate(finalize_date, 'DD-MM-YYYY hh:mm A') }</span> }
                    </>
                );
            },
        },
        {
            field: 'modifier',
            title: t('modifier'),
            render: (row: any) => get(row, ['modifier', 'name']),
        },
        {
            field: 'qty',
            title: t('qty'),
        },
        {
            field: 'action',
            title: t('action'),
            sorting: false,
            align: 'left',
            render: (_row: any) => {
                return (
                    <>
                        <HasAccess hasAnyAccess={ ['invoicepaymentsview'] }>
                            {
                                _row.status === 'final'
                                &&
                                <Tooltip
                                  tooltip={ t('make payment') }
                                  position={ "left" }>
                                    <a
                                      className={ "cursor-pointer text-info me-2" }
                                      onClick={ () => setIsInvoicePaymentPanelActive(_row.id) }>
                                        <i className="fas fa-credit-card" />
                                    </a>
                                </Tooltip>
                            }
                            {
                                ( _row.status === 'final' && _row.paid_total > 0 )
                                &&
                                <Tooltip
                                  tooltip={ t('payment report pdf') }
                                  position={ "left" }>
                                    <a
                                      className={ "cursor-pointer text-warning me-2" }
                                        /*@ts-ignore*/
                                      href={ `${ process.env.REACT_APP_API_URL }/w/invoice/document/${ _row.id }?status=final&types=pdf&payment=1&uToken=` + auth.getUser().id }
                                      target={ "_blank" }
                                      rel="noopener noreferrer"
                                    >
                                        <i className="far fa-file-pdf" />
                                    </a>
                                </Tooltip>
                            }
                        </HasAccess>
                        <HasAccess hasAnyAccess={ ['archivedinvoicesview'] }>
                            <Tooltip
                                tooltip={ t('draft pdf') }
                                position={ "left" }>
                                <a
                                    className={ "cursor-pointer text-info me-2" }
                                    /*@ts-ignore*/
                                    href={ `${ process.env.REACT_APP_API_URL }/w/invoice/document/${ _row.id }?status=final&types=pdf&uToken=` + auth.getUser().id }
                                    target={ "_blank" }
                                    rel="noopener noreferrer"
                                >
                                    <i className="far fa-file-pdf" />
                                </a>
                            </Tooltip>
                        </HasAccess>
                        {
                            _row.status === 'final' &&
                            <HasAccess hasAnyAccess={ ['invoicesview'] }>

                                <Tooltip
                                  tooltip={ t('pantheon transfer') }>
                                    <a
                                      title={ t('pantheon transfer') }
                                      className={ "cursor-pointer text-info me-2" }
                                      onClick={ (event: any) => confirmPopup({
                                          target: event.currentTarget,
                                          icon: 'pi pi-info-circle',
                                          // @ts-ignorer
                                          defaultFocus: 'reject',
                                          acceptClassName: 'p-button-danger',
                                          accept: () => onPantheonTransfer({ id: _row.id }),
                                          reject: () => {
                                          },
                                      }) }>
                                        <i className="fas fa-fighter-jet" />
                                    </a>
                                </Tooltip>
                            </HasAccess>
                        }
                        <HasAccess hasAllAccess={ ['archivedinvoicesview'] }>
                            <Tooltip
                                tooltip={ t('detail of item') }>
                                <a onClick={ () => onOperationClick('detail', _row.id) }>
                                    <i className="fas fa-eye" />
                                </a>
                            </Tooltip>
                        </HasAccess>
                    </>
                );
            }
        },
    ];

    const onOperationClick = (operationToBePerformed: any = 'add', id: any = null) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('operation', operationToBePerformed);
        searchParams.set('operationId', id);

        if ( isEmpty(operationToBePerformed) ) {
            searchParams.delete('operation');
        }

        if ( isEmpty(id) ) {
            searchParams.delete('operationId');
        }

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        }, { replace: true });
        setOperationId(id);
        setOperation(operationToBePerformed);
    };

    const onCloseOperation = (reset: any = true) => {
        onOperationClick(null, null);
        onReset(reset);
        focusOnSearch();
    };

    const onReset = (reset: any = true) => {
        const mStates = { ...moduleState };

        if ( reset ) {
            mStates.filters = [];
            mStates.page = 1;
            mStates.search = null;
        }

        props.setQuery(mStates);
    };

    const onSearch = ({ search }: any) => {
        props.setQuery({
            ...moduleState,
            search: search
        });
    };

    const onFilterClick = () => {
        setFilterPanel(true);
    };

    const focusOnSearch = () => {
        const searchInput: HTMLInputElement | null = document.querySelector('input[name="search"]');
        if ( searchInput ) {
            searchInput.focus();
        }
    };

    return (
        <div className={ "block-viewer" }>
            <div className="block-section">
                <div className="block-header">
                    <span className="block-title">
                        <h5 className={ "mb-0" }>{ t('invoices') } ({total})</h5>
                    </span>
                    <div className="block-actions">
                        {
                            operation == 'detail' &&
                            <div>
                                <Button type={ "button" }
                                        tooltip={ t("operation cancel") } className={ "ms-2" }
                                        size={ "small" } severity={ "warning" }
                                        tooltipOptions={ { position: 'top' } } onClick={ () => onCloseOperation(false) }>
                                    <i className="pi pi-arrow-left me-2" /> { t("cancel") }

                                </Button>
                            </div>
                        }
                    </div>
                </div>
                <div className="block-content">
                    {
                        operation !== 'detail' &&
                        <Row className={ "p-2" }>
                            <Col
                              sm={ 12 } md={ 6 } className={ "mb-2" }>
                                <Formik
                                  enableReinitialize={ true }
                                  initialValues={ initSearchFormState }
                                  onSubmit={ onSearch }
                                >
                                    { (props: FormikProps<any>) => {
                                        return (
                                            <Form onSubmit={ props.handleSubmit }>
                                                <Col sm={ 12 } md={ 8 }>
                                                    <Field component={ InputField } withoutLabel={ true }
                                                           autoFocus={true}
                                                           name="search"
                                                           formGroupClass={ "mb-0" }
                                                           placeholder={ t("search") } />
                                                </Col>
                                            </Form>
                                        );
                                    } }
                                </Formik>
                            </Col>

                            <Col
                              sm={ 12 } md={ 6 } className={ "text-right mb-2" }>
                                <ButtonGroup>
                                    <Button icon="pi pi-filter-fill" size={ "small" } outlined tooltip={ t('filter') }
                                            onClick={ onFilterClick }
                                            tooltipOptions={ { position: 'top' } } />
                                    <Button onClick={ onReset } icon="pi pi-refresh" size={ "small" } outlined
                                            tooltip={ t('reset') }
                                            tooltipOptions={ { position: 'top' } } />
                                </ButtonGroup>
                            </Col>

                            <Col sm={ 12 }>
                                <div>
                                    <Row>
                                        {
                                            extra && extra.map((item: any, index: number) => {
                                                return (
                                                    <Col sm={ 12 } md={ 3 } key={ index }>
                                                        <div className="border border-1 mt-1 p-1 d-block"
                                                             style={ { fontSize: '10px' } }>
                                                            { item.name }
                                                            <div className={ "d-flex" }>
                                                                <div>
                                                                    <span>{ t('draft total') }: <strong>{ get(item, ['indicators', 'draft']) }</strong></span>
                                                                </div>
                                                                <div>
                                                    <span
                                                        className={ "ms-2" }>{ t('final total') }: <strong>{ get(item, ['indicators', 'final']) }</strong></span>
                                                                </div>
                                                            </div>
                                                            <div className={ "d-flex" }>
                                                                <div>
                                                                    <span>{ t('paid total') }: <strong>{ get(item, ['indicators', 'paid']) }</strong></span>
                                                                </div>
                                                                <div>
                                                    <span
                                                        className={ "ms-2" }>{ t('open total') }: <strong>{ get(item, ['indicators', 'open']) }</strong></span>
                                                                </div>
                                                            </div>
                                                            <div className={ "d-flex" }>
                                                                {
                                                                    get(item, ['indicators', 'discount'])
                                                                    .map((i: any, index: any) => {
                                                                        return (
                                                                            index % 2 === 0 ? (
                                                                                <div key={ index } className="ms-0">
                                                                                    <span>{ get(i, ['code']) }: <strong
                                                                                        className={ "ms-1" }>{ get(i, ['total']) }</strong></span>
                                                                                </div>
                                                                            ) : (
                                                                                <div key={ index } className="ms-2">
                                                                                    <span>{ get(i, ['code']) }: <strong
                                                                                        className={ "ms-1" }>{ get(i, ['total']) }</strong></span>
                                                                                </div>
                                                                            )
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </Col>
                                                );
                                            })
                                        }
                                    </Row>
                                </div>
                            </Col>

                            <Col sm={ 12 }>
                                <StateLessGrid
                                  size={ "normal" }
                                  url={ '/suppliers/archived/invoices' }
                                  name={ "archiveInvoiceList" }
                                  columns={ columns }
                                  moduleState={ moduleState }
                                  setQuery={ props.setQuery }
                                  ref={ tableRef }
                                  itemSize={ 45 }
                                  setTotal={ setTotal }
                                  getMetaData={ onContentLoaded }
                                />
                            </Col>
                        </Row>
                    }

                    {
                        ( operation === 'detail' && !isEmpty(operationId) ) &&
                        <InvoiceDetail
                          setQuery={ props.setQuery }
                          toastify={ props.toastify }
                          dropdowns={ props.dropdowns }
                          params={ { operation, operationId } }
                          onClose={ onCloseOperation }
                        />
                    }

                    <InvoiceFilter
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        visibility={ filterPanel }
                        onClose={ () => {
                            setFilterPanel(false);
                            focusOnSearch();
                        } }
                    />

                    <InvoicePayment
                        toastify={ toastify }
                        id={ isInvoicePaymentPanelActive }
                        onClose={ () => {
                            setIsInvoicePaymentPanelActive(null);
                            onReset();
                            focusOnSearch()
                        } }
                    />

                    <ConfirmPopup />
                </div>
            </div>
        </div>
    );
};

InvoiceList.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(InvoiceList));
