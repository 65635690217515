import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const composeEnhancers = composeWithDevTools(
    applyMiddleware()
);

export function configureStore(initialState: any) {
    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers,
    );

    return store;
}


export default configureStore;
