import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import UrlHelper from "js-url-helper";
import LoadList from "./partials/LoadList";
import { Route, Routes } from "react-router-dom";
import { Toast } from "primereact/toast";

const DEFAULT_DROPDOWNS = {
    loads: [],
};

const url = new UrlHelper();

const DriverLoad = (props: any) => {
    const { t } = props;
    const toast = useRef(null);
    const [dropdowns, setDropdowns] = useState<any>(null);
    const [moduleState, setModuleState] = useState<any>({
        page: 1,
        per_page: 20,
        search: null,
        initialized: true,
    });

    useEffect(() => {
        document.title = t("loads");
    }, []);

    const toastify = (message: any, color: string = 'info', summary: any = null) => {
        if ( toast && toast.current ) {
            // @ts-ignore
            toast.current.show({ severity: color, summary: summary, detail: message });
        }
    };

    return (
        <div className={ "module-container" }>
            <Toast ref={ toast } />
            <div className="layout-content">
                { ( moduleState && moduleState.initialized ) ?
                    <React.Fragment>
                        <Routes>
                            <Route path={ "/" } element={
                                <LoadList
                                    moduleState={ moduleState }
                                    dropdowns={ dropdowns }
                                    toastify={ toastify }
                                    setQuery={ setModuleState }
                                /> } />
                        </Routes>
                    </React.Fragment>
                    : <h6>{ t('loading') }</h6> }
            </div>
        </div>
    );
};

// DriverLoad.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(DriverLoad);
