import { withTranslation } from "react-i18next";
import WithRouter from "../../../../../components/Common/WithRouter";
import React, { useRef, useState } from "react";
import { ConfirmPopup } from 'primereact/confirmpopup';
import StateLessGrid from "../../../../../components/Shared/StateLessGrid";
import { Button } from "primereact/button";
import { Col, Row } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../../../../components/Shared/InputField";
import { utcDateToLocalDate } from "../../../../../utils/Helper";
import { ButtonGroup } from "primereact/buttongroup";

const ReceivedList = (props: any) => {
    const { t, moduleState } = props;
    const [initSearchFormState] = useState<any>({
        search: moduleState.search ?? null
    });
    const tableRef: any = useRef();
    const [total, setTotal] = useState(0);

    const columns = [
        {
            field: 'load_number',
            title: t('load number'),
            sorting: false,
            render: (_row: any) => ( JSON.parse(_row.response) || {} ).load_number,
        },
        {
            field: 'event_type',
            title: t('event type'),
        },
        {
            field: 'status',
            title: t('status'),
            render: (_row: any) => <span className="text-uppercase">{ _row.status }</span>,
        },
        {
            field: 'created_at', title: t('created at'),
            allowHiding: true,
            render: (_row: any) => utcDateToLocalDate(_row.created_at)
        },
    ];

    const onReset = () => {
        tableRef.current.onRefresh();
    };

    const onSearch = ({ search }: any) => {
        props.setQuery({
            ...moduleState,
            search: search
        });
    };

    const DetailRow = ({ data }: any) => {
        return (
            <div>
                <table>
                    <tbody>
                        {
                            Object.keys(JSON.parse(data.response)).map(function (key: any, index: any) {
                                // @ts-ignore
                                const _data = JSON.parse(data.response);
                                return (
                                    <tr key={ index }>
                                        <td>{ key }</td>
                                        <td>{ _data[key] }</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div className={ "block-viewer" }>
            <div className="block-section">
                <div className="block-header">
                    <span className="block-title">
                        <h5 className={ "mb-0" }>ALP { t('webhook') } ({total})</h5>
                    </span>
                    <div className="block-actions">
                    </div>
                </div>
                <div className="block-content">
                    <Row className={ "p-2" }>
                        <Col
                            sm={ 12 } md={ 6 } className={ "mb-2" }>
                            <Formik
                                enableReinitialize={ true }
                                initialValues={ initSearchFormState }
                                onSubmit={ onSearch }
                            >
                                { (props: FormikProps<any>) => {
                                    return (
                                        <Form onSubmit={ props.handleSubmit }>
                                            <Col sm={ 12 } md={ 8 }>
                                                <Field component={ InputField } withoutLabel={ true }
                                                       autoFocus={ true }
                                                       name="search"
                                                       formGroupClass={ "mb-0" }
                                                       placeholder={ t("search") } />
                                            </Col>
                                        </Form>
                                    );
                                } }
                            </Formik>
                        </Col>

                        <Col
                            sm={ 12 } md={ 6 } className={ "text-right mb-2" }>
                                <ButtonGroup>
                                    <Button onClick={ onReset } icon="pi pi-refresh" size={ "small" } outlined
                                            tooltip={ t('reset') }
                                            tooltipOptions={ { position: 'top' } } />
                                </ButtonGroup>
                        </Col>

                        <Col sm={ 12 }>
                            <StateLessGrid
                                size={ "normal" }
                                url={ '/suppliers/received/webhook/logs?1=1' }
                                name={ "receivedWebhookList" }
                                columns={ columns }
                                moduleState={ moduleState }
                                setQuery={ props.setQuery }
                                ref={ tableRef }
                                itemSize={ 45 }
                                setTotal={ setTotal }
                                detailRow={ (data: any) => <DetailRow data={ data } /> }
                            />
                        </Col>
                    </Row>

                    <ConfirmPopup />
                </div>
            </div>
        </div>
    );
};

ReceivedList.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(ReceivedList));
