import React, { useRef } from 'react';

function CopyAndSelectText({ text }: any) {
    const textRef: any = useRef(null);

    const handleCopyAndSelect = () => {
        if ( textRef.current ) {
            const range = document.createRange();
            range.selectNodeContents(textRef.current);
            const selection: any = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
            console.warn('Selection copied to clipboard: ' + text);
        }
    };

    return (
        <span onClick={ handleCopyAndSelect } ref={ textRef } style={ { cursor: 'pointer' } } className={ "ps-1" }>
          { text }
        </span>
    );
}

export default CopyAndSelectText;
