import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useRef, useState } from "react";
import { ConfirmPopup } from 'primereact/confirmpopup';
import StateLessGrid from "../../../../components/Shared/StateLessGrid";
import { Button } from "primereact/button";
import { Col, Row } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../../../components/Shared/InputField";
import { utcDateToLocalDate } from "../../../../utils/Helper";
import CvsApiLogFilter from "./CvsApiLogFilter";
import { Badge } from "primereact/badge";
import Tooltip from "../../../../components/Common/Display/Tooltip";
import CvsApiLogDetail from "./CvsApiLogDetail";
import moment from "moment-timezone";
import { ButtonGroup } from "primereact/buttongroup";

const CvsApiLogList = (props: any) => {
    const { t, moduleState, toastify } = props;
    const [operation, setOperation] = useState<any>(null);
    const [operationId, setOperationId] = useState<any>(null);
    const [initSearchFormState, setInitSearchFormState] = useState<any>({
        search: moduleState.search ?? null
    });
    const [filterPanel, setFilterPanel] = useState<boolean>(false);
    const tableRef: any = useRef();
    const [total, setTotal] = useState(0);

    const onDetail = (id: any) => {
        setOperationId(id);
        setOperation("detail");
    };

    const columns = [
        { field: 'api_url', title: t('api url') },
        { field: 'request', title: t('request'), sorting: false },
        {
            field: 'response', title: t('status'),
            render: (_row: any) => {
                const response = JSON.parse(_row.response);
                return (
                    response.status === 'OK' ?
                        <Badge color={ "success" } value={ t('complete') } />
                        :
                        <Badge color={ "danger" } value={ t('failed') } />
                );
            },
            sorting: false
        },
        {
            field: 'created_at', title: t('created at'),
            render: (_row: any) => utcDateToLocalDate(_row.created_at)
        },
        {
            field: 'action', title: t('action'),
            render: (_row: any) => {
                return (
                    <>
                        <Tooltip
                            tooltip={ t('detail of item') }>
                            <a onClick={ () => onDetail(_row.id) } className={ 'ms-2' }>
                                <i className="fas fa-eye" />
                            </a>
                        </Tooltip>
                    </>
                );
            }
        },
    ];

    const onReset = () => {
        props.setQuery({
            ...moduleState,
            filters: {
                fromDate: moment().startOf("week").format("YYYY-MM-DD[T]HH:mm:ss"),
                toDate: moment().endOf("week").format("YYYY-MM-DD[T]HH:mm:ss"),
            },
            page: 1,
            search: null
        });
    };

    const onSearch = ({ search }: any) => {
        props.setQuery({
            ...moduleState,
            search: search
        });
    };

    const onCloseOperation = () => {
        setOperation(null);
        setOperationId(null);
        onReset();
    };

    const onFilterClick = () => {
        setFilterPanel(true);
    };

    const focusOnSearch = () => {
        const searchInput: HTMLInputElement | null = document.querySelector('input[name="search"]');
        if ( searchInput ) {
            searchInput.focus();
        }
    };

    return (
        <div className={ "block-viewer" }>
            <div className="block-section">
                <div className="block-header">
                    <span className="block-title">
                        <h5 className={ "mb-0" }>{ t('cvs api logs') } ({total})</h5>
                    </span>
                    <div className="block-actions">
                        {
                            operation == 'detail' &&
                            <div>
                                <Button type={ "button" }
                                        tooltip={ t("operation cancel") } className={ "ms-2" }
                                        size={ "small" } severity={ "warning" }
                                        tooltipOptions={ { position: 'top' } } onClick={ onCloseOperation }>
                                    <i className="pi pi-arrow-left me-2" /> { t("cancel") }

                                </Button>
                            </div>
                        }
                    </div>
                </div>
                <div className="block-content">
                    {
                        operation !== 'detail' &&
                        <Row className={ "p-2" }>
                            <Col
                              sm={ 12 } md={ 6 } className={ "mb-2" }>
                                <Formik
                                  enableReinitialize={ true }
                                  initialValues={ initSearchFormState }
                                  onSubmit={ onSearch }
                                >
                                    { (props: FormikProps<any>) => {
                                        return (
                                            <Form onSubmit={ props.handleSubmit }>
                                                <Col sm={ 12 } md={ 8 }>
                                                    <Field component={ InputField } withoutLabel={ true }
                                                           autoFocus={ true }
                                                           name="search"
                                                           formGroupClass={ "mb-0" }
                                                           placeholder={ t("search") } />
                                                </Col>
                                            </Form>
                                        );
                                    } }
                                </Formik>
                            </Col>

                            <Col
                              sm={ 12 } md={ 6 } className={ "text-right mb-2" }>
                                <ButtonGroup>
                                    <Button icon="pi pi-filter-fill" size={ "small" } outlined
                                            tooltip={ t('filter') } onClick={ onFilterClick }
                                            tooltipOptions={ { position: 'top' } } />

                                    <Button onClick={ onReset } icon="pi pi-refresh" size={ "small" } outlined
                                            tooltip={ t('reset') }
                                            tooltipOptions={ { position: 'top' } } />
                                </ButtonGroup>
                            </Col>

                            <Col sm={ 12 }>
                                <StateLessGrid
                                  size={ "normal" }
                                  url={ '/suppliers/cvs/api/logs' }
                                  name={ "cvsApiLog" }
                                  columns={ columns }
                                  moduleState={ moduleState }
                                  setQuery={ props.setQuery }
                                  ref={ tableRef }
                                  itemSize={ 45 }
                                  setTotal={ setTotal }
                                />
                            </Col>
                        </Row>
                    }

                    {
                        operation == 'detail' &&
                        <CvsApiLogDetail
                          setQuery={ props.setQuery }
                          toastify={ props.toastify }
                          dropdowns={ props.dropdowns }
                          params={ { operation, operationId } }
                          onClose={ onCloseOperation }
                        />
                    }

                    <CvsApiLogFilter
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        visibility={ filterPanel }
                        onClose={ () => {
                            setFilterPanel(false);
                            focusOnSearch();
                        } }
                    />

                    <ConfirmPopup />
                </div>
            </div>
        </div>
    );
};

CvsApiLogList.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(CvsApiLogList));
