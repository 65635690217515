import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { get, includes } from "lodash";
import { request } from "../../../../utils/Request";
import { formatDate, utcDateToLocalDate } from "../../../../utils/Helper";
import Location from "../../../../components/Common/Display/Location";
import OrderStatus from "../../../../components/Common/Display/OrderStatus";
import VinWithType from "../../../../components/Common/Display/VinWithType";
import { TabPanel, TabView } from "primereact/tabview";
import { Card } from "primereact/card";

const OrderDetail = (props: any) => {
    const { t, toastify, params } = props;
    const [entity, setEntity] = useState<any>(null);

    const getDetail = async (id: string) => {
        try {
            const response = await request({
                url: `/suppliers/orders/${ id }`
            });

            const { data } = response.data;
            setEntity(data);
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        if ( includes(['detail'], get(params, ['operation'])) ) {
            getDetail(params.operationId);
        }
    }, [params]);

    return (
        <div>
            {
                entity &&
              <>
                  <Card className={"p-0 m-0 shadow-none border-none"}>
                      <Row>
                          <Col sm="12" md="12">
                              <Row>
                                  <Col md="6" lg="6" sm="12">
                                      <h6>
                                          <span className="text-capitalize">{ t('vin number') }</span>
                                      </h6>
                                  </Col>
                                  <Col md="6" lg="6" sm="12">
                                      <div className="text-end">
                                          <VinWithType vinNumber={ entity.vin_number } type={ entity.belongs_to } />
                                      </div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md="6" lg="6" sm="12">
                                      <h6>
                                          <span className="text-capitalize">{ t('belongs to') }</span>
                                      </h6>
                                  </Col>
                                  <Col md="6" lg="6" sm="12">
                                      <div className="text-end">
                                          {entity.belongs_to}
                                      </div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md="6" lg="6" sm="12">
                                      <h6><span className="text-capitalize">{ t('created at') }</span>
                                      </h6>
                                  </Col>
                                  <Col md="6" lg="6" sm="12">
                                      <div
                                        className="text-end">{ utcDateToLocalDate(entity.created_at) }</div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md="6" lg="6" sm="12">
                                      <h6><span className="text-capitalize">{ t('brand') }</span>
                                      </h6>
                                  </Col>
                                  <Col md="6" lg="6" sm="12">
                                      <div
                                        className="text-end">
                                          { entity.brand?.title }
                                          { ( entity.brand && entity.brand.alternate_title ) && ` - ${ entity.brand.alternate_title }` }
                                      </div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md="6" lg="6" sm="12">
                                      <h6><span className="text-capitalize">{ t('model') }</span>
                                      </h6>
                                  </Col>
                                  <Col md="6" lg="6" sm="12">
                                      <div
                                        className="text-end">
                                          { entity.brand_model?.title }
                                          {
                                              entity.brand_model?.version_code &&
                                            <span> - { entity.brand_model?.version_code }</span>
                                          }
                                      </div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md="6" lg="6" sm="12">
                                      <h6><span className="text-capitalize">{ t('supplier') }</span>
                                      </h6>
                                  </Col>
                                  <Col md="6" lg="6" sm="12">
                                      <div
                                        className="text-end">
                                          { entity.supplier.name }
                                      </div>
                                  </Col>
                              </Row>
                              {
                                  ( entity.current_journey && entity.current_journey.from_location ) &&
                                <Row>
                                    <Col md="6" lg="6" sm="12">
                                        <h6>
                                            <span className="text-capitalize">{ t('from location') }</span>
                                        </h6>
                                    </Col>
                                    <Col md="6" lg="6" sm="12">
                                        <div className="text-end">
                                            <Location item={ entity.current_journey.from_location }
                                                      titled={ 1 } />
                                        </div>
                                    </Col>
                                </Row>
                              }
                              {
                                  ( entity.current_journey && entity.current_journey.to_location ) &&
                                <Row>
                                    <Col md="6" lg="6" sm="12">
                                        <h6>
                                            <span className="text-capitalize">{ t('to location') }</span>
                                        </h6>
                                    </Col>
                                    <Col md="6" lg="6" sm="12">
                                        <div className="text-end">
                                            <Location item={ entity.current_journey.to_location }
                                                      titled={ 1 } />
                                        </div>
                                    </Col>
                                </Row>
                              }
                              <Row>
                                  <Col md="6" lg="6" sm="12">
                                      <h6>
                                          <span className="text-capitalize">{ t('client') }</span>
                                      </h6>
                                  </Col>
                                  <Col md="6" lg="6" sm="12">
                                      <div className="text-end text-capitalize">
                                          { entity.client?.name }
                                      </div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md="6" lg="6" sm="12">
                                      <h6>
                                          <span className="text-capitalize">{ t('ldg nr') }</span>
                                      </h6>
                                  </Col>
                                  <Col md="6" lg="6" sm="12">
                                      <div className="text-end text-capitalize">
                                          { entity.ldg_nr }
                                      </div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md="6" lg="6" sm="12">
                                      <h6>
                                          <span className="text-capitalize">{ t('order code') }</span>
                                      </h6>
                                  </Col>
                                  <Col md="6" lg="6" sm="12">
                                      <div className="text-end">{ entity.order_cd }</div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md="6" lg="6" sm="12">
                                      <h6>
                                          <span className="text-capitalize">{ t('status') }</span>
                                      </h6>
                                  </Col>
                                  <Col md="6" lg="6" sm="12">
                                      <div className="text-end">
                                          <OrderStatus status={ entity.status } />
                                      </div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md="6" lg="6" sm="12">
                                      <h6>
                                          <span className="text-capitalize">{ t('customer allocation') }</span>
                                      </h6>
                                  </Col>
                                  <Col md="6" lg="6" sm="12">
                                      <div className="text-end">{ entity.built_to_order_cd > 0 ? t("yes") : t("no") }</div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md="6" lg="6" sm="12">
                                      <h6>
                                          <span className="text-capitalize">{ t('priorisation') }</span>
                                      </h6>
                                  </Col>
                                  <Col md="6" lg="6" sm="12">
                                      <div className="text-end">{ entity.priorisation > 0 ? t("high") : t("normal") }</div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md="6" lg="6" sm="12">
                                      <h6>
                                          <span className="text-capitalize">{ t('automatic start next journey') }</span>
                                      </h6>
                                  </Col>
                                  <Col md="6" lg="6" sm="12">
                                      <div
                                        className="text-end">{ entity.automatic_start_next_journey > 0 ? t("yes") : t("no") }</div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md="6" lg="6" sm="12">
                                      <h6>
                                          <span className="text-capitalize">{ t('lead time start date') }</span>
                                      </h6>
                                  </Col>
                                  <Col md="6" lg="6" sm="12">
                                      <div className="text-end">
                                          { entity.lead_time_start_date ? formatDate(entity.lead_time_start_date) : '' }
                                      </div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md="6" lg="6" sm="12">
                                      <h6>
                                          <span className="text-capitalize">{ t('lead time start time') }</span>
                                      </h6>
                                  </Col>
                                  <Col md="6" lg="6" sm="12">
                                      <div className="text-end">
                                          { entity.lead_time_start_time }
                                      </div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md="6" lg="6" sm="12">
                                      <h6>
                                          <span className="text-capitalize">{ t('net weight') }</span>
                                      </h6>
                                  </Col>
                                  <Col md="6" lg="6" sm="12">
                                      <div className="text-end">{ entity.net_weight }</div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md="6" lg="6" sm="12">
                                      <h6>
                                          <span className="text-capitalize">{ t('length') }</span>
                                      </h6>
                                  </Col>
                                  <Col md="6" lg="6" sm="12">
                                      <div className="text-end">{ entity.length }</div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md="6" lg="6" sm="12">
                                      <h6>
                                          <span className="text-capitalize">{ t('width') }</span>
                                      </h6>
                                  </Col>
                                  <Col md="6" lg="6" sm="12">
                                      <div className="text-end">{ entity.width }</div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md="6" lg="6" sm="12">
                                      <h6>
                                          <span className="text-capitalize">{ t('height') }</span>
                                      </h6>
                                  </Col>
                                  <Col md="6" lg="6" sm="12">
                                      <div className="text-end">{ entity.height }</div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md="6" lg="6" sm="12">
                                      <h6>
                                          <span className="text-capitalize">{ t('load creation date') }</span>
                                      </h6>
                                  </Col>
                                  <Col md="6" lg="6" sm="12">
                                      <div className="text-end">{ utcDateToLocalDate(entity.added_into_load_at) }</div>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col sm="12">
                                      {
                                          ( entity && entity.status_histories ) &&
                                        <TabView>
                                            <TabPanel header={ t("status histories") }>
                                                <table className={ "fl-table" }>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>{ t('author') }</th>
                                                            <th>{ t('status') }</th>
                                                            <th>{ t('system note') }</th>
                                                            <th>{ t('created at') }</th>
                                                            <th>{ t('operation date') }</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { entity.status_histories && entity.status_histories.map((row: any, index: number) => (
                                                            <tr
                                                                key={ row.token }
                                                            >
                                                                <td>{ index + 1 }</td>
                                                                <td>{ row.author?.name }</td>
                                                                <td>
                                                                    <OrderStatus status={ row.status } />
                                                                </td>
                                                                <td>{ row.system_note }</td>
                                                                <td>{ utcDateToLocalDate(row.created_at, 'DD-MM-YYYY HH:mm A') }</td>
                                                                <td>{ utcDateToLocalDate(row.operation_date, 'DD-MM-YYYY HH:mm A') }</td>
                                                            </tr>
                                                        )) }
                                                    </tbody>
                                                    {
                                                        ( !entity.status_histories || entity.status_histories.length <= 0 ) &&
                                                      <tfoot>
                                                          <tr>
                                                              <td colSpan={ 5 }>{ t('no data') }</td>
                                                          </tr>
                                                      </tfoot>
                                                    }
                                                </table>
                                            </TabPanel>
                                            <TabPanel header={ t("order journeys") }>
                                                <table className={ "fl-table" }>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>{ t("created at") }</th>
                                                            <th>{ t("from location") }</th>
                                                            <th>{ t("to location") }</th>
                                                            <th>{ t("status") }</th>
                                                            <th>{ t("system note") }</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            entity.order_journeys.map((journey: any, orderIndex: number) => {
                                                                return (
                                                                    <tr key={ orderIndex }>
                                                                        <td>{ orderIndex + 1 }</td>
                                                                        <td>{ utcDateToLocalDate(get(journey, ['created_at']), 'DD-MM-YYYY HH:mm A') }</td>
                                                                        <td>{ get(journey, ['from_location', 'label']) }</td>
                                                                        <td>{ get(journey, ['to_location', 'label']) }</td>
                                                                        <td className={ "text-uppercase" }>{ journey.status }</td>
                                                                        <td className={ "text-uppercase" }>{ journey.system_note }</td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </TabPanel>
                                            <TabPanel header={ t("order process journeys") }>
                                                <table className={ "fl-table" }>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>{ t("load number") }</th>
                                                            <th>{ t("route") }</th>
                                                            <th>{ t("drivers") }</th>
                                                            <th>{ t("transport vehicles") }</th>
                                                            <th>{ t("load status") }</th>
                                                            <th>{ t("created at") }</th>
                                                            <th>{ t("in transit at") }</th>
                                                            <th>{ t("delivered at") }</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            entity.process_journeys && entity.process_journeys.map((journey: any, orderIndex: number) => {
                                                                return (
                                                                    <tr key={ orderIndex }>
                                                                        <td>{ orderIndex + 1 }</td>
                                                                        <td>{ get(journey, ['load_number']) }</td>
                                                                        <td>{ get(journey, ['route_title']) }</td>
                                                                        <td>{ get(journey, ['drivers']) }</td>
                                                                        <td>{ get(journey, ['transport_vehicle']) }</td>
                                                                        <td className={"capitalize"}><strong>{ get(journey, ['item_status']) }</strong></td>
                                                                        <td>{ utcDateToLocalDate(get(journey, ['created_at']), 'DD-MM-YYYY HH:mm A') }</td>
                                                                        <td>{ utcDateToLocalDate(get(journey, ['in_transit_at']), 'DD-MM-YYYY HH:mm A') }</td>
                                                                        <td>{ utcDateToLocalDate(get(journey, ['finished_at']), 'DD-MM-YYYY HH:mm A') }</td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </TabPanel>
                                            <TabPanel header={ t("transport documents") }>
                                                <table className={ "fl-table" }>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>{ t("title") }</th>
                                                            <th>{ t("document types") }</th>
                                                            <th>{ t("size") }(KB)</th>
                                                            <th className={ "text-end" }>{ t("action") }</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            entity.order_documents &&
                                                            entity.order_documents
                                                            .filter(
                                                                (document: any) =>
                                                                    (
                                                                        !includes(document.tags.map((tag: any) => tag), 'damage documents')
                                                                        || document.tags.length > 1
                                                                    )
                                                            )
                                                            .map((history: any, historyIndex: number) => {
                                                                return (
                                                                    <tr key={ historyIndex }>
                                                                        <td>{ historyIndex + 1 }</td>
                                                                        <td>{ history.title }</td>
                                                                        <td className={ "text-capitalize" }>{ history.tags && history.tags.join(",")}</td>
                                                                        <td>{ history.size > 0 ? ( history.size / 1024 ).toFixed(2) : 0 }</td>
                                                                        <td className={ "text-end" }>
                                                                            <a
                                                                                href={ `${ process.env.REACT_APP_DOCUMENT_URL }/${ history.document_id }` }
                                                                                className={ `btn waves-effect waves-light btn-info btn-sm ms-1` }
                                                                                target={ "_blank" }
                                                                                rel="noopener noreferrer">
                                                                                <i className="fas fa-cloud-download-alt" />
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </TabPanel>
                                            <TabPanel header={ t("damage documents") }>
                                                <table className={ "fl-table" }>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>{ t("title") }</th>
                                                            <th>{ t("size") }(KB)</th>
                                                            <th className={ "text-end" }>{ t("action") }</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            entity.order_documents &&
                                                            entity.order_documents
                                                            .filter((document: any) => includes(document.tags.map((tag: any) => tag), 'damage documents'))
                                                            .map((history: any, historyIndex: number) => {
                                                                return (
                                                                    <tr key={ historyIndex }>
                                                                        <td>{ historyIndex + 1 }</td>
                                                                        <td>{ history.title }</td>
                                                                        <td>{ history.size > 0 ? ( history.size / 1024 ).toFixed(2) : 0 }</td>
                                                                        <td className={ "text-end" }>
                                                                            <a
                                                                                href={ `${ process.env.REACT_APP_DOCUMENT_URL }/${ history.document_id }` }
                                                                                className={ `btn waves-effect waves-light btn-info btn-sm ms-1` }
                                                                                target={ "_blank" }
                                                                                rel="noopener noreferrer">
                                                                                <i className="fas fa-cloud-download-alt" />
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </TabPanel>
                                        </TabView>
                                      }
                                  </Col>
                              </Row>
                          </Col>
                      </Row>
                  </Card>
              </>
            }
        </div>

    );
};

OrderDetail.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(OrderDetail));
