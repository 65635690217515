import React from "react";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { request } from "../../../utils/Request";
import { Card, CardBody, CardHeader, Col, Row, Form } from "react-bootstrap";
import { FormikProps, Formik, Field } from "formik";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { formatDate } from "../../../utils/Helper";
import { Sidebar } from "primereact/sidebar";
import { InputField } from "../../../components/Shared/InputField";
import { Button } from "primereact/button";

type TInputAlpProfileForm = {
    email: null | any,
    password: null | any,
    secret: null | any,
}

const defaultAlpProfileForm = {
    email: null,
    password: null,
    secret: null,
};

interface IProps {
    connected: boolean,
    user?: any,
    open?: any,
    t?: any,
    toastify?: any,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        email: yup.string().email(t('the email must be a valid email address.')).required(t('the field is required.', { field: t('email') })).nullable(),
        password: yup.string().required(t('the field is required.', { field: t('password') })).nullable(),
        secret: yup.string().required(t('the field is required.', { field: t('secret') })).nullable(),
    });
};

function LoginDialog(props: any) {
    const { toastify } = props;
    const [open, setOpen] = React.useState(false);
    const [initFormState, setInitFormState] = useState<any>(defaultAlpProfileForm);
    const { t } = useTranslation();

    useEffect(() => {
        if ( props.open ) {
            setOpen(true);
        }
    }, [props.open]);

    const onClose = () => {
        setOpen(false);
        props.onClose();
    };

    const onSubmit = async (payloads: TInputAlpProfileForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: 'post',
                url: 'alp/sign/in',
                data: { ...payloads, _method: "post" }
            });

            setOpen(false);
            setTimeout(() => window.location.reload(), 1000);
            toastify(t("login successful"), "success");
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    return (
        <div>
            <Sidebar
                header={
                    <div className="bg-body-tertiary card-header">
                        <h5 className={ "card-title mt-0" }>{ t('connect to alp') }</h5>
                        <p>{ t("using this form to login on alp") }</p>
                    </div>
                }
                visible={ open } position="right" onHide={ onClose } blockScroll={ true }
                style={ { width: '800px' } }
            >
                <div style={ {
                    height: '3px',
                    marginBottom: '5px',
                    background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                } }></div>
                <Formik
                    enableReinitialize={ true }
                    initialValues={ initFormState }
                    onSubmit={ onSubmit }
                    validationSchema={ validationSchema(t) }
                >
                    { (props: FormikProps<TInputAlpProfileForm>) => {
                        return (
                            <Form onSubmit={ props.handleSubmit }>
                                <Row className={"mt-5"}>
                                    <Col sm={ 12 } md={ 6 }>
                                        <Field component={ InputField }
                                               name="email"
                                               label={ t("email") }
                                               placeholder={ t("enter") + ' ' + t("email").toLowerCase() }
                                               isRequired
                                        />
                                    </Col>
                                    <Col sm={ 12 } md={ 6 }>
                                        <Field component={ InputField }
                                               name="password"
                                               type={ "password" }
                                               label={ t("password") }
                                               placeholder={ t("enter") + ' ' + t("password").toLowerCase() }
                                               isRequired
                                        />
                                    </Col>
                                    <Col sm={ 12 } md={ 12 }>
                                        <Field component={ InputField }
                                               name="secret"
                                               label={ t("secret") }
                                               placeholder={ t("enter") + ' ' + t("secret").toLowerCase() }
                                               isRequired
                                        />
                                    </Col>
                                </Row>

                                <div className="filter-card-footer"
                                     style={ { width: '785px' } }>
                                    <Button type={ "submit" } disabled={ props.isSubmitting }
                                            tooltip={ t("to data save into database") }
                                            size={ "small" } outlined
                                            tooltipOptions={ { position: 'top' } }
                                    >
                                        <i className="pi pi-save me-2" />
                                        { t("let's login") }
                                    </Button>
                                    <Button type={ "button" } disabled={ props.isSubmitting }
                                            onClick={ onClose }
                                            tooltip={ t("operation cancel") } className={ "ms-2" }
                                            size={ "small" } severity={ "danger" } outlined
                                            tooltipOptions={ { position: 'top' } }
                                    >
                                        <i className="pi pi-arrow-left me-2" />
                                        { t("cancel") }
                                    </Button>
                                </div>
                            </Form>
                        );
                    } }
                </Formik>
            </Sidebar>
        </div>
    );
}

function AlpProfile(props: IProps) {
    const [open, setOpen] = useState(false);
    const { user, t } = props;

    return (
        <Card className={'mb-3'}>
            <CardHeader className={ "bg-body-tertiary" }>
                <h4 className="card-title">{ t('ALP') } { t('connection') }</h4>
            </CardHeader>
            <CardBody>
                {
                    props.connected
                        ? (
                            <>
                                <div>
                                    <Button size={ 'small' } type="button" severity="secondary"
                                            outlined disabled className="w-full">
                                        <i className="fas fa-tv me-2" />
                                        { t('connected to alp') }
                                    </Button>
                                </div>
                                <div>
                                    <Button size={ 'small' } type="button" outlined
                                            onClick={ () => setOpen(true) }
                                            className="w-full mt-2 text-center">
                                        <i className="fas fa-arrow-circle-left me-2" />
                                        { t('re login') }
                                    </Button>
                                </div>
                            </>
                        )
                        : (
                            <Button size={ 'small' } type="button" onClick={ () => setOpen(true) }
                                    className="w-full">
                                <i className="bx bx-tv me-2" />
                                { t('connect to alp') }
                            </Button>
                        )
                }

                <LoginDialog open={ open } onClose={ () => setOpen(false) } />

                <div className={ "mt-2" }>
                    {
                        ( user && user.alp_user && user.alp_user.token_expires_at ) &&
                        <>
                            { t('login expires at') } : { formatDate(moment(user.alp_user.token_expires_at).subtract('day', 1)) }
                        </>
                    }
                </div>
            </CardBody>
        </Card>
    );
}

AlpProfile.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(AlpProfile);
