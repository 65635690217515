import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { get, includes } from "lodash";
import { request } from "../../../../utils/Request";
import Tooltip from "../../../../components/Common/Display/Tooltip";
import { formatDate } from "../../../../utils/Helper";

const PriceDetail = (props: any) => {
    const { t, toastify, params } = props;
    const [entity, setEntity] = useState<any>(null);

    const getDetail = async (id: string) => {
        try {
            const response = await request({
                url: `/suppliers/prices/${ id }`
            });

            const { data } = response.data;
            setEntity(data);
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        if ( includes(['detail'], get(params, ['operation'])) ) {
            getDetail(params.operationId);
        }
    }, [params]);

    return (
        <div className={ "p-4 bg-white" }>
            {
                entity &&
              <>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('first name') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ entity.first_name }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('identification number') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ entity.identification_number }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('loading factors') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end text-uppercase">{ entity.loading_factors }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('brands') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end text-uppercase">
                              { entity && entity.brands.map((brand: any, index: number) => (<span key={index} className="text-dark badge bg-light me-1">{ brand.title }</span>) ) }
                          </div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('models') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end text-uppercase">
                              { entity && entity.brand_models.map((model: any, index: number) => (<span key={index} className="text-dark badge bg-light me-1">{ model.title }</span>) ) }
                          </div>
                      </Col>
                  </Row>
                  <Row>
                      <Col sm={ 12 } md={ 12 } className={"mb-5"}>
                          <hr />
                          <h4 className="card-title">{ t('price periods') }</h4>
                          { entity &&
                            <div className={'table-responsive'}>
                                <table className={"fl-table"}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{ t("route") }</th>
                                            <th>{ t("price ftl") }</th>
                                            <th>{ t("price spl") }</th>
                                            <th>{ t("price unit") }</th>
                                            <th>{ t("start date") }</th>
                                            <th>{ t("end date") }</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            // @ts-ignore
                                            entity.price_periods.map((row: any, index: number) => (
                                                <tr key={ index }>
                                                    <th>{ index + 1 }</th>
                                                    <td>
                                                        <div>{ row.route?.title }</div>
                                                        <span className={"btn btn-xs btn-link btn-rounded waves-effect"}>
                                                            <Tooltip tooltip={ t('price type') }>
                                                                <span className={"text-uppercase"}>{ row._type_of_price }</span>
                                                            </Tooltip>
                                                        </span>
                                                        <span className={"btn btn-xs btn-link btn-rounded waves-effect"}>
                                                            <Tooltip tooltip={ t('mode of transport') }>
                                                              <span>{ row.mode_of_transport }</span>
                                                            </Tooltip>
                                                        </span>
                                                    </td>
                                                    <td>{ row.price_ftl }</td>
                                                    <td>{ row.price_spl }</td>
                                                    <td>
                                                        {
                                                            row.type_of_price === 1 &&
                                                            row.price_unit
                                                        }
                                                    </td>
                                                    <td>{ formatDate(row.start_date) }</td>
                                                    <td>{ formatDate(row.end_date) }</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                          }
                      </Col>
                  </Row>
              </>
            }
        </div>
    );
};

PriceDetail.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(PriceDetail));
