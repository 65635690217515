import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { ConfirmPopup } from 'primereact/confirmpopup';
import { Col, Row } from "react-bootstrap";
import WithRouter from "../../../../../components/Common/WithRouter";
import * as yup from "yup";
import { find, isEmpty, map } from "lodash";
import { request } from "../../../../../utils/Request";
import { Field, FieldArray, Form, Formik, FormikProps } from "formik";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputField } from "../../../../../components/Shared/InputField";
import CopyAndSelectText from "../../../../../utils/CopyAndSelectText";
import { InputChip } from "../../../../../components/Shared/InputChip";
import { InputRichTextEditor } from "../../../../../components/Shared/InputRichTextEditor";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        items: yup.array(
            yup.object({
                subject: yup.string().required(t('the field is required.', { field: t('subject') })),
                from_email: yup.string().nullable(),
                cc_emails: yup.array(),
                bcc_emails: yup.array(),
                content: yup.string().required(),
                id: yup.string().required(),
            })
        ),
    });
};

const DEFAULT_FORM_STATE = {
    items: []
};

type EmailSettingForm = {
    items: any,
};

const ITEMS_ERROR_FIELDS = [
    'id',
    'subject',
    'from_email',
    'cc_emails',
    'bcc_emails',
    'content',
];

interface IFormProps {
    t?: any | undefined;
    setQuery: any;
    toastify: any;
    onClose?: any;
}

const EmailSettingForm = (props: IFormProps) => {
    const { t, toastify } = props;
    const [initFormState, setInitFormState] = useState<any>(DEFAULT_FORM_STATE);
    const [settings, setSettings] = useState<any>(DEFAULT_FORM_STATE);

    const onSubmit = async (payloads: EmailSettingForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: 'POST',
                url: `/suppliers/settings/email/settings`,
                data: {
                    items: payloads.items.map((item: any) => ({
                        ...item,
                        bcc_emails: item.bcc_emails.map((item: any) => item.value).join(','),
                        cc_emails: item.cc_emails.map((item: any) => item.value).join(','),
                    }))
                },
            });

            toastify(t("record updated", { item: t('setting') }), "success");
            resetForm();
            window.location.reload();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    const getSettings = async (): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/settings/email/settings`
            });

            const { data } = response.data;
            setSettings(data);

            const settings = map(data, function (value: any) {
                return {
                    subject: value.subject,
                    listener_type: value.listener_type,
                    content: value.content,
                    id: value.id,
                    from_email: value.from_email,
                    cc_emails: value.cc_emails ? value.cc_emails.split(',').map((item: any) => ( {
                        label: item,
                        value: item
                    } )) : [],
                    bcc_emails: value.bcc_emails ? value.bcc_emails.split(',').map((item: any) => ( {
                        label: item,
                        value: item
                    } )) : [],
                };
            });

            setInitFormState({ items: settings });
        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        setInitFormState(DEFAULT_FORM_STATE);
        getSettings();
    }, []);

    return (
        <div className={ "block-viewer" }>
            <div className="block-section">
                <Formik
                    enableReinitialize={ true }
                    initialValues={ initFormState }
                    onSubmit={ onSubmit }
                    validationSchema={ validationSchema(t) }
                >
                    { (props: FormikProps<EmailSettingForm>) => {
                        const { values, errors }: any = props;
                        return (
                            <div>
                                <div className="block-header">
                                    <span className="block-title">
                                        <h5 className={ "mb-0" }>{ t('email settings') }</h5>
                                    </span>
                                    <div className="block-actions">
                                        <Button disabled={ props.isSubmitting }
                                                onClick={props.submitForm}
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "success" }
                                                tooltipOptions={ { position: 'top' } }>
                                            <i className="pi pi-save me-2" /> { t("save") }

                                        </Button>
                                    </div>
                                </div>
                                <div>
                                    <div className="block-content bg-white pl-3 pr-3 pb-3">
                                        <Form onSubmit={ props.handleSubmit }>
                                            <FieldArray
                                                name="items"
                                                render={ (arrayHelpers: any) => (
                                                    <Row>
                                                        {
                                                            ( values.items && values.items.length > 0 )
                                                                ?
                                                                    values.items.map((row: any, index: number) => {
                                                                        const hasErrors = ITEMS_ERROR_FIELDS.filter((field: string) => errors[`items.${ index }.${ field }`]);
                                                                        return (
                                                                            <Col sm={ 12 } md={ 6 }
                                                                                 className={ "mt-3" }>
                                                                                <Panel header={ t(row.listener_type) }
                                                                                       toggleable>
                                                                                    <Field component={ InputField }
                                                                                           name={ `items.${ index }.subject` }
                                                                                           label={ t('subject') }
                                                                                           formText={
                                                                                               !isEmpty(find(settings, { id: row.id }))
                                                                                                   ? map(find(settings, { id: row.id }).tags.split(" "),
                                                                                                       (item: any, key: number) =>
                                                                                                           <CopyAndSelectText
                                                                                                               text={ item }
                                                                                                               key={ key } />)
                                                                                                   : ""
                                                                                           } />

                                                                                    <Field component={ InputField }
                                                                                           name={ `items.${ index }.from_email` }
                                                                                           label={ t('from email') }
                                                                                           type={ "email" } />


                                                                                    <Field component={ InputChip }
                                                                                           isEmail={ true }
                                                                                           name={ `items.${ index }.cc_emails` }
                                                                                           label={ t('cc emails') }
                                                                                           placeHolder={ t("type something and press tab...") }
                                                                                           formText={ t("any invalid emails may not be processed") }
                                                                                    />

                                                                                    <Field component={ InputChip }
                                                                                           isEmail={ true }
                                                                                           name={ `items.${ index }.bcc_emails` }
                                                                                           label={ t('bcc emails') }
                                                                                           placeHolder={ t("type something and press tab...") }
                                                                                           formText={ t("any invalid emails may not be processed") }
                                                                                    />

                                                                                    <Field
                                                                                        component={ InputRichTextEditor }
                                                                                        name={ `items.${ index }.content` }
                                                                                        label={ t('content') }
                                                                                    />
                                                                                </Panel>
                                                                            </Col>
                                                                        );
                                                                    })
                                                                    : <>{ t('loading...') }</>
                                                            }
                                                        </Row>
                                                    ) }
                                                />
                                            </Form>
                                    </div>
                                </div>
                            </div>
                        )
                    } }
                </Formik>
                <ConfirmPopup />
            </div>
        </div>
    );
};

EmailSettingForm.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(EmailSettingForm));
