import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation, withTranslation } from "react-i18next";
import moment from "moment/moment";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import * as yup from "yup";
import { request } from "../../utils/Request";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { utcDateToLocalDate } from "../../utils/Helper";
import { Toast } from "primereact/toast";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../Shared/InputField";
import { TabPanel, TabView } from "primereact/tabview";
import { Button } from "primereact/button";
import { isEmpty } from "lodash";

interface IProps {
    onClose?: any;
    visible: any;
}

type TInputForm = {
    id?: any,
    title?: string | null,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        title: yup.string().trim(t('the field is invalid.')).required(t('the field is required.', { field: t('title') })).nullable(),
    });
};

const DEFAULT_FORM_STATE = {
    id: null,
    title: null,
};

const DEFAULT_FILTER_FORM = {
    status: null,
    fromDate: moment(moment().startOf('day').add('1', 'minute').toDate()).format('YYYY-MM-DD HH:mm:ss'),
    toDate: moment(moment().endOf('day').toDate()).format('YYYY-MM-DD HH:mm:ss'),
};

const TodoWindow = (props: IProps) => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState<boolean>(false);
    const toast = useRef(null);
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [records, setRecords] = useState<any>([]);
    const [filter, setFilter] = useState(DEFAULT_FILTER_FORM);

    const [activeIndex, setActiveIndex] = useState(0);

    const onClose = () => {
        if ( props.onClose ) {
            props.onClose();
            setVisible(false);
        }
    };

    const toastify = (message: any, color: string = 'info', summary: any = null) => {
        if ( toast && toast.current ) {
            // @ts-ignore
            toast.current.show({ severity: color, summary: summary, detail: message });
        }
    };

    const getList = async () => {
        // @ts-ignore
        try {
            const response = await request({
                url: `/suppliers/tasks`,
                params: {
                    'filters[fromDate]': filter.fromDate,
                    'filters[toDate]': filter.toDate,
                    'filters[status]': activeIndex == 1 ? 'completed' : 'pending'
                }
            });

            const { data } = response.data;
            setRecords(data);
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: ( !payloads.id ? 'POST' : 'PATCH' ),
                url: ( !payloads.id ? `/suppliers/tasks` : `/suppliers/tasks/${ payloads.id }` ),
                data: {
                    ...payloads,
                },
            });

            if ( payloads.id ) {
                toastify(t("record updated", { item: t('task') }), "success");
            } else {
                toastify(t("record added", { item: t('task') }), "info");
                getList();
            }

            resetForm();

        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                // toastify(t("server error"), "error");
            }
        }
    };

    const onUpdate = async (id: string, data: any) => {
        if ( isEmpty(id) ) {
            return;
        }

        try {
            const response = await request({
                method: 'PATCH',
                url: `/suppliers/tasks/${ id }`,
                data: data,
            });

            toastify(t("record updated", { item: t('task') }), "success");
        } catch (e: any) {
            // toastify(t("server error"), "error");
        }
    };

    const textEditor = (options: any) => {
        return (
            <InputText
                type="text"
                value={ options.value }
                onChange={ (e) => options.editorCallback(e.target.value) }
                placeholder={ "would you please enter the task title?" }
                className={ "border-none" }
            />
        );
    };

    const onCellEditComplete = (e: any) => {
        let { rowData, newValue, field, originalEvent: event } = e;
        if ( newValue.trim().length > 0 ) {
            rowData[field] = newValue;
        } else {
            event.preventDefault();
        }

        if ( rowData['id'] ) {
            onUpdate(rowData['id'], { title: newValue });
        }
    };

    const onFinishTask = async (id: any) => {
        if ( id ) {
            await onUpdate(id, { complete: true });
            await getList();
        }
    };

    const onRowReorder = async (e: any) => {
        try {
            const response = await request({
                method: 'POST',
                url: `/suppliers/tasks/re/order`,
                data: { tasks: e.value.map((i: any) => i.id) },
            });

            getList();
        } catch (e: any) {
            // toastify(t("server error"), "error");
        }
    };

    const handleTabChange = (e: any) => {
        setActiveIndex(e.index);
    };

    const TableTemplate = (props: any) => {
        return (
            <DataTable value={ records }
                       editMode="cell"
                       className="editable-cells-table mt-1"
                       dataKey="id"
                       size={ "small" }
                       reorderableColumns
                       reorderableRows
                       onRowReorder={ onRowReorder }
                       onRowClick={ (e) => e.originalEvent.stopPropagation() }
            >
                {
                    !props.isCompleted &&
                  <Column rowReorder style={ { width: "30px" } } headerStyle={ { width: '30px' } } />
                }
                <Column field="title" header={ t('title') }
                        editor={ (options) => textEditor(options) }
                        onCellEditComplete={ onCellEditComplete }
                        body={ ({ title }: any) => {
                            return <>
                                <div className={ "ps-2 text-sm font-medium" }>
                                    { title }
                                </div>
                            </>;
                        } }
                />
                <Column field="task_date" header={ '' }
                        style={ { width: '30%' } } align={ "right" }
                        body={ ({ task_date, id }: any) => {
                            return <>
                                <div className={ "text-sm" }>
                                    {
                                        !props.isCompleted &&
                                      <Button className={ "p-button-x-sm" } link
                                              onClick={ () => onFinishTask(id) }
                                              style={ { padding: 0, marginRight: '3px' } }
                                              tooltip={ t('click: mark as completed') }
                                      >
                                          <i className="pi pi-check text-sm font-bold text-green-500" />
                                      </Button>
                                    }
                                    <span className={ "text-primary" }>{ utcDateToLocalDate(task_date) }</span>
                                </div>
                            </>;
                        } }
                />
            </DataTable>
        );
    };

    useEffect(() => {
        if ( props.visible ) {
            setVisible(true);

            getList();
        }
    }, [props.visible]);

    useEffect(() => {
        getList();
    }, [activeIndex]);


    return (
        <>
            <Toast ref={ toast } />
            <Dialog visible={ visible } onHide={ onClose } keepInViewport={ true }
                    className={ "chat-window" } draggable={ true } modal={ false }
                    closeOnEscape={ false } position={ "bottom-right" } resizable={ true }
                    header={ <>
                        <div className="flex align-items-center">
                            <div className="relative flex align-items-center mr-3">
                                <img
                                    src={ `/images/logo/alcolm-logo-dark.png` }
                                    alt={ "Alcolm" }
                                    height={ "50px" }
                                />
                            </div>
                        </div>
                        <Divider className={ "mb-0" } />
                    </> }>
                <Formik
                    innerRef={ formRef }
                    enableReinitialize={ true }
                    initialValues={ initFormState }
                    onSubmit={ onSubmit }
                    validationSchema={ validationSchema(t) }
                >
                    { (props: FormikProps<TInputForm>) => {
                        return (
                            <Form onSubmit={ props.handleSubmit } className={ 'mb-1' }>
                                <Field component={ InputField }
                                       name="title"
                                       inputClass={ "shadow-none" }
                                       placeholder={ "would you please enter the task title?" }
                                       autoFocus />
                            </Form>
                        );
                    } }
                </Formik>

                <div
                    className="p-0 md:px-4 lg:px-6 lg:py-4 mt-2 overflow-y-auto"
                    style={ { height: '50vh' } }
                >
                    <TabView activeIndex={ activeIndex } onTabChange={ handleTabChange }>
                        <TabPanel header={ t('pending task') }>
                            <TableTemplate />
                        </TabPanel>
                        <TabPanel header={ t('completed task') }>
                            <TableTemplate isCompleted={ true } />
                        </TabPanel>
                    </TabView>
                </div>
            </Dialog>
        </>
    );
};

TodoWindow.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(TodoWindow);
