import React, { useEffect, useRef, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { utcDateToLocalDate } from "../../../../utils/Helper";
import { Card, CardBody, CardHeader, Col, Row } from "react-bootstrap";
import { request } from "../../../../utils/Request";
import { isEmpty, get, random } from "lodash";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputSelectField } from "../../../../components/Shared/InputSelectField";
import { Divider } from "primereact/divider";
import VinWithType from "../../../../components/Common/Display/VinWithType";
import OrderStatus from "../../../../components/Common/Display/OrderStatus";
import Location from "../../../../components/Common/Display/Location";
import Tooltip from "../../../../components/Common/Display/Tooltip";
import { InputField } from "../../../../components/Shared/InputField";
import * as yup from "yup";
import { Dialog } from "primereact/dialog";
import { InputSingleSelectField } from "../../../../components/Shared/InputSingleSelectField";

interface IOrderPickerListProps {
    visible: any;
    dropdowns?: any | undefined;
    onClose: any;
    toastify: any;
    setSelectedOrders: any,
    setOrdersWithRoute: any,
    loadOrders: any,
}

const DEFAULT_FILTER_FORM_STATE = {
    fromLocations: [],
    toLocations: [],
    clients: [],
    vinNumbers: null,
    statuses: ['produced'],
};

const DEFAULT_DROPDOWNS = {
    clients: [],
    vins: [],
    routes: [],
};

function SwitchUnloadingLocation(props: any) {
    const [open, setOpen] = useState(false);
    const [orders, setOrders] = useState<any>([]);
    const [initFormState, setInitFormState] = useState<any>({to_location_id: null});
    const { t } = useTranslation();

    const validationSchema = (t: Function): any => {
        return yup.object().shape({
            to_location_id: yup.string().required(t('the field is required.', { field: t('location') })).nullable(),
        });
    };

    const onClose = () => {
        setOpen(false);

        if ( props.onClose ) {
            props.onClose();
        }
    }

    const onSubmit = async (payloads: any, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: 'POST',
                url:'/suppliers/orders/location',
                data: {
                    ids: orders.map((item: any) => item.id),
                    to_location_id: payloads.to_location_id,
                },
            });

            props.toastify(t("record updated", { item: t('order') }), "info");
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                props.toastify(t("server error"), "error");
            }
        }
    };

    const onClickOpen = () => {
        if ( props.getSelectedOrders().length > 0 ) {
            setOpen(true)
        }
    }

    useEffect(() => {
        if ( open ) {
            setOrders(props.getSelectedOrders());
        } else {
            setOrders([]);
        }
    }, [open]);

    return <>

        <Button type="button" severity={ "warning" } outlined size={ "small" }
                onClick={ onClickOpen } className={"ms-2"}
                title={ t("filter") }>
            <i className="fas fa-pencil-alt me-1" /> { t("update") } { t("location") }
        </Button>
        <Dialog header={ <>{ t("update order location") }</> } visible={open} style={{ width: '50vw' }}
                onHide={onClose}
                breakpoints={{ '960px': '85vw', '641px': '100vw' }}>
            <Formik
                enableReinitialize={ true }
                initialValues={ initFormState }
                onSubmit={ onSubmit }
                validationSchema={ validationSchema(t) }
            >
                { (formProps: FormikProps<any>) => {
                    return (
                        <Form onSubmit={ formProps.handleSubmit }>
                            <Field component={ InputSingleSelectField }
                                   name="to_location_id"
                                   options={ props.dropdowns.locations }
                                   label={ t('to location') }
                                   showClear
                                   isRequired
                            />
                            <Button outlined>
                                { t('update') }
                            </Button>{ ' ' }
                            <Button severity="secondary" onClick={ onClose } outlined>
                                { t('cancel') }
                            </Button>
                        </Form>
                    );
                } }
            </Formik>
        </Dialog>
    </>
}

// @ts-ignore
function OrderPickerList(props: IOrderPickerListProps) {
    const { setSelectedOrders, dropdowns, toastify } = props;
    const { t } = useTranslation();
    const [isPanelActive, setPanelActive] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [filteredData, setFilteredData] = useState<any>([]);
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [_, setRandom] = useState<any>(random(10));
    const [orderLegendModal, setOrderLegendModal] = useState<boolean>(false);
    const [defaultFilters, setDefaultFilters] = useState<any>([]);
    const tableRef = useRef();

    const toggleFilter = () => setIsOpen(!isOpen);

    const filterFormState = { ...DEFAULT_FILTER_FORM_STATE };

    const onCloseDrawer = () => {
        setPanelActive(false);
        props.onClose();
    };

    const onSelectionChange = ({ value }: any) => {
        setSelectedRow(value);
    };

    const onClose = (toBeAdded: boolean = false) => {
        if ( toBeAdded && setSelectedOrders && !isEmpty(props.visible) ) {
            const index = props.visible.split("~~~")[1];

            if ( index < 0 ) { // pick-orders-with-route
                props.setOrdersWithRoute(selectedRow);
                setPanelActive(false);
                return;
            }

            const loadOrderItem: any = (props.loadOrders) ? props.loadOrders[index] : null;
            if ( loadOrderItem ) {
                setSelectedOrders(selectedRow, loadOrderItem.route.id, index);
            }
        }

        onCloseDrawer()
    };

    useEffect(() => {
        if ( !isEmpty(props.visible) ) {
            setData([]);
            setSelectedRow([]);
            setPanelActive(true);
            const index = props.visible.split("~~~")[1];
            const loadOrderItem: any = (props.loadOrders && index >= 0) ? props.loadOrders[index] : {};
            const filters: any = {
                statuses: ['produced'],
                clients: [],
                belongsTo: [],
                fromLocations: [],
                toLocations: [],
                vinNumbers: null
            };

            if ( !isEmpty(loadOrderItem) ) {
                filters.fromLocations = [loadOrderItem.route.from_location_id];
                filters.toLocations = [loadOrderItem.route.to_location_id];
            }

            setDefaultFilters(filters);
        }

    }, [props]);

    // @ts-ignore
    useEffect(() => {
        if ( isPanelActive ) {
            onLoad()
        } else {
            setData([]);
        }

    }, [isPanelActive]);

    const onFilter = (values: any) => {
        let filtered: any = data;

        if ( !isEmpty(values.clients) ) {
            filtered = filtered.filter((item: any) => (!isEmpty(item.client) && values.clients.includes(item.client.id)));
        }

        if ( !isEmpty(values.vinNumbers) ) {
            filtered = filtered.filter((item: any) => values.vinNumbers.includes(item.vin_number));
        }

        if ( !isEmpty(values.fromLocations) ) {
            filtered = filtered.filter((item: any) => (!isEmpty(item.current_journey.from_location) && values.fromLocations.includes(item.current_journey.from_location.id)));
        }

        if ( !isEmpty(values.toLocations) ) {
            filtered = filtered.filter((item: any) => (!isEmpty(item.current_journey.to_location) && values.toLocations.includes(item.current_journey.to_location.id)));
        }

        setFilteredData(filtered);
    };

    const onFilterReset = (props: any) => {
        props.resetForm();
        setFilteredData(data);
    };

    const columns = [
        {
            field: 'brand',
            title: t("brand") + '/' + t("model"),
            sorting: false,
            render: ({ brand, brand_model }: any) => <div className={ "text-capitalize" }>
                { brand.title }
                { brand.alternate_title && <div> { brand.alternate_title }</div> }
                <div>
                    { brand_model.title }
                    { brand_model.version_code && <div> { brand_model.version_code } </div> }
                </div>
            </div>
        },
        {
            field: 'client',
            title: t("client"),
            sorting: false,
            render: ({ client }: any) => <div className={ "text-capitalize" }>
                { client?.name }
            </div>
        },
        {
            field: 'vin_number',
            title: t('vin number'),
            render: (_row: any) => (
                <>
                    <VinWithType vinNumber={ _row.vin_number } type={ _row.belongs_to }
                                 priorisation={ _row.priorisation } builtToOrderCd={ _row.built_to_order_cd } />
                </>
            )
        },
        { field: 'order_cd', title: t('order') },
        { field: 'ldg_nr', title: t('ldg nr') },
        {
            field: 'status',
            title: t('status'),
            render: (_row: any) => <div className={ "text-capitalize" }>
                <OrderStatus status={ _row.status } />
            </div>
        },
        {
            field: 'route',
            sorting: false,
            title: t('route'),
            render: ({ current_journey }: any) => {
                const systemRoute = dropdowns.routes
                .filter(({ from_location, to_location }: any) => (
                    from_location.id === current_journey.from_location.id && to_location.id === current_journey.to_location.id )
                );

                return (
                    <div>
                        { !isEmpty(systemRoute) ? get(systemRoute, ['0', 'title']) :
                            <span className={ "text-uppercase text-danger" }>{ t("route missing") }</span> }
                    </div>
                );
            }
        },
        {
            field: 'from_location',
            sorting: false,
            title: t('current location'),
            render: (_row: any) => {
                const { current_journey } = _row;
                return (
                    <>
                        {
                            ( _row.status === "delivered" && current_journey && current_journey.to_location ) &&
                            <Location item={ current_journey.to_location } titled={ 1 } />
                        }
                        {
                            ( _row.status !== "delivered" && current_journey && current_journey.from_location ) &&
                            <Location item={ current_journey.from_location } titled={ 1 } />
                        }
                    </>
                );
            }
        },
        {
            field: 'journeys',
            title: t('journeys'),
            sorting: false,
            width: 300,
            render: (_row: any) => {
                const { order_journeys, current_journey } = _row;
                return (
                    <>
                        <div className={ "mb-2" }>
                            {
                                order_journeys.map((item: any, index: number) => {
                                    return <div key={ index } className={ "mt-1" }>
                                        <div>
                                            { ( current_journey && current_journey.token === item.token ) &&
                                              <>
                                                  {
                                                      item.status === "completed" &&
                                                    <Tooltip tooltip={ t('journey is finished') }>
                                                        <small><i className="fas fa-circle text-secondary" /></small>
                                                    </Tooltip>
                                                  }
                                                  {
                                                      item.status !== "completed" &&
                                                    <Tooltip tooltip={ t('active') } >
                                                        <small><i className="fas fa-circle text-success" /></small>
                                                    </Tooltip>
                                                  }
                                              </>
                                            }
                                            { ( current_journey && current_journey.token !== item.token && item.status === "pending" ) &&
                                              <Tooltip tooltip={ t('pending') }>
                                                <small><i className="fas fa-circle text-info" /></small>
                                              </Tooltip>
                                            }
                                            { ( current_journey && current_journey.token !== item.token && item.status === "completed" ) &&
                                              <Tooltip tooltip={ t('complete') } >
                                                  <small><i className="fas fa-circle text-secondary" /></small>
                                              </Tooltip>
                                            }
                                            {" "} <Location item={ item.from_location } titled={ 1 } /> - <Location item={ item.to_location } titled={ 1 } />
                                        </div>
                                    </div>;
                                })
                            }
                        </div>
                    </>
                );
            },
        },
        {
            field: 'available_at',
            title: t('availability'),
            sorting: true,
            render: ({ available_at }: any) => ( available_at &&
              <Tooltip tooltip={ utcDateToLocalDate(available_at) }>{ utcDateToLocalDate(available_at) } </Tooltip> ),
        },
    ];

    const onCloseLocationUpdate = () => {
        setSelectedRow([]);
        setData([]);
        setFilteredData([]);
        setRandom(random(10));
        onLoad();
    };

    const onLoad = () => {
        request({
            url: `/suppliers/orders`,
            params: { filters: defaultFilters },
        })
        .then((response: any) => {
            if ( !isEmpty(response.data) ) {
                const {data} = response.data;
                setData(data);
                setFilteredData(data);
            }
        })
        .catch((e: any) => {
            toastify(t("server error"), { type: "error" });
        });
    }

    const onClickOpenOrderLegends = () => {
        setOrderLegendModal(true);
    };

    return (
        <>
            <div className={ "border" }>
                <Sidebar
                    visible={ isPanelActive } onHide={ onCloseDrawer } blockScroll={ true }
                    fullScreen
                >
                    <Card className={ "border-0 p-0" }>
                        <CardHeader className={ "mb-3" }>
                            <Row>
                                <Col sm={ 12 } md={ 6 } lg={ 6 }>
                                    <h4 className="card-title">{ t("Orders") }</h4>
                                    <p className="card-title-desc">{ t("select orders to add into load.") }
                                        <Tooltip className="ms-2"
                                                 target={ `.order-legends` } />
                                        <a
                                            data-pr-tooltip={ `${ t("price legends") }` }
                                            data-pr-position="left"
                                            onClick={ onClickOpenOrderLegends }
                                            className={"order-legends cursor-pointer"}>
                                            <i className="fas fa-info-circle text-blue-400" style={ {
                                                fontSize: '1.2rem',
                                                marginTop: '0.8rem',
                                                cursor: 'pointer'
                                            } } />
                                        </a>
                                    </p>
                                </Col>
                                <Col sm={ 12 } md={ 6 } lg={ 6 } className={ "text-end" }>
                                <Button type="button" severity={ undefined } outlined size={ "small" }
                                            onClick={ () => onClose(true) }
                                            className="me-2">
                                        <i className="fas fa-save me-1" /> { t("let's add") }
                                    </Button>
                                    <Button type="button" severity={ "danger" } outlined size={ "small" }
                                            onClick={ () => onClose(false) }
                                            title={ t("operation cancel") }
                                            className="me-2">
                                        <i className="fas fa-angle-left me-1" /> { t("cancel") }
                                    </Button>
                                    <Button type="button" severity={ "info" } outlined size={ "small" }
                                            onClick={ toggleFilter }
                                            title={ t("filter") }>
                                        <i className="fas fa-filter me-1" /> { t("filter") }
                                    </Button>
                                    <SwitchUnloadingLocation
                                        toastify={toastify}
                                        getSelectedOrders={ () => selectedRow }
                                        dropdowns={ props.dropdowns }
                                        onClose={ onCloseLocationUpdate } />
                                </Col>
                            </Row>
                            <Divider className={ "mt-2" } />
                            <Row className={ `${ !isOpen ? 'd-none' : '' } bg-gray-50 py-2` }>
                                <Formik
                                    initialValues={ filterFormState }
                                    onSubmit={ onFilter }
                                >
                                    { (props: FormikProps<any>) => {
                                        return (
                                            <Form onSubmit={ props.handleSubmit }>
                                                <Row>
                                                    <Col sm={ 12 } md={ 4 } lg={ 3 }>
                                                        <Field component={ InputSelectField }
                                                               name="clients"
                                                               options={ dropdowns.clients }
                                                               label={ t('clients') }
                                                               optionLabel={"name"}
                                                               showClear
                                                        />
                                                    </Col>
                                                    <Col sm={ 12 } md={ 4 } lg={ 3 }>
                                                        <Field component={ InputSelectField }
                                                               name="fromLocations"
                                                               options={ dropdowns.locations }
                                                               label={ t('from locations') }
                                                               showClear
                                                        />
                                                    </Col>
                                                    <Col sm={ 12 } md={ 4 } lg={ 3 }>
                                                        <Field component={ InputSelectField }
                                                               name="toLocations"
                                                               options={ dropdowns.locations }
                                                               label={ t('to locations') }
                                                               showClear
                                                        />
                                                    </Col>
                                                    <Col sm={ 12 } md={ 4 } lg={ 3 }>
                                                        <Field component={ InputField }
                                                               name="vinNumbers"
                                                               label={ t('vin(s)') }
                                                               formText={t("a series of vin number values, each separated by a space")} />
                                                    </Col>
                                                    <Col sm={ 12 } md={ 4 } lg={ 3 } className={ "md:mt-4 sm:mt-0" }>
                                                        <Button type="submit" severity={ undefined } size={ "small" }
                                                                outlined>
                                                            <i className="fas fa-save me-1" /> { t('filter') }
                                                        </Button>
                                                        <Button type="button" severity={ "danger" } size={ "small" }
                                                                onClick={ () => onFilterReset(props) } outlined
                                                                className="ms-2">
                                                            <i className="fas fa-cut me-1" /> { t('reset') }
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        );
                                    } }
                                </Formik>
                            </Row>
                        </CardHeader>
                        <Divider />
                        <CardBody style={ { border: '1px solid var(surface-50)' } }>
                            {
                                isEmpty(filteredData) && <span>{ t('no data') }</span>
                            }
                            {
                                !isEmpty(filteredData) &&
                              <>
                                  <DataTable
                                    className={"mb-3"}
                                    value={ filteredData } dataKey="id"
                                    reorderableColumns size={ "small" }
                                    selectionMode="multiple"
                                    selection={ selectedRow }
                                    onSelectionChange={ onSelectionChange }
                                    dragSelection
                                    resizableColumns showGridlines
                                    scrollable scrollHeight="750px" virtualScrollerOptions={{ itemSize: 75 }}>
                                      <Column selectionMode="multiple" headerStyle={ { width: '2rem' } } />

                                      {
                                          columns
                                          && columns
                                          .map((item: any) => {
                                              return (
                                                  <Column
                                                      // @ts-ignore
                                                      alignHeader={ item.align ?? 'left' }
                                                      bodyStyle={ { textAlign: item.align ?? 'left' } }
                                                      field={ item.field } header={ item.title } body={ item.render }
                                                      hidden={ item.hidden ?? false } key={ item.field }
                                                  />
                                              );
                                          })
                                      }
                                  </DataTable>
                              </>
                            }
                        </CardBody>
                    </Card>
                </Sidebar>

                <Dialog header={
                    t('order legends')
                } visible={orderLegendModal} style={{ width: '50vw' }} onHide={() => setOrderLegendModal(false)}
                        breakpoints={{ '960px': '85vw', '641px': '100vw' }}>
                    <div>
                        <p className={ "m-0 fs-6" }>
                            <i className="fas fa-circle text-secondary me-1" />
                            { t('journey is finished') }
                        </p>
                        <p className={ "m-0 fs-5 mt-1" }>
                            <i className="fas fa-circle text-success me-1" />
                            { t('active journey') }
                        </p>
                        <p className={ "m-0 fs-5 mt-1" }>
                            <i className="fas fa-circle text-info me-1" />
                            { t('pending is journey') }
                        </p>
                    </div>
                </Dialog>

            </div>
        </>
    );
}

export default withTranslation()(OrderPickerList);
