import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { get, includes } from "lodash";
import { request } from "../../../../utils/Request";

const LegalEntityDetail = (props: any) => {
    const { t, toastify, params } = props;
    const [entity, setEntity] = useState<any>(null);

    const getDetail = async (id: string) => {
        try {
            const response = await request({
                url: `/suppliers/legal/entities/${ id }`
            });

            const { data } = response.data;
            setEntity(data);
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        if ( includes(['detail'], get(params, ['operation'])) ) {
            getDetail(params.operationId);
        }
    }, [params]);

    return (
        <div className={ "p-4 bg-white" }>
            {
                entity &&
              <>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('name') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ entity.company_name }</div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('email') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ get(entity, ['email']) }</div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('phone') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ entity.phone }</div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('color') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ entity.color }</div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('is default?') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">{ entity.is_default ? t('yes') : t('no') }</div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('vat number') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_vat_number'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('mb') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_mb'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('oib') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_oib'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('fax') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_fax'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('mbs') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_mbs'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('telephone') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_telephone'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('footer') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_footer'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('web') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_web'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('company address') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_company_address'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('email addresses') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_email_addresses'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('username') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_pantheon_username'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('password') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_pantheon_password'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>

                  <Row>
                      <Col md="6" lg="6" sm="12">
                          <h6>
                              <span className="text-capitalize">{ t('company db') }</span>
                          </h6>
                      </Col>
                      <Col md="6" lg="6" sm="12">
                          <div className="text-end">
                              { get(entity.invoice_settings.filter((i: any) => i.title === 'invoice_pantheon_company_db'), [0, 'value']) }
                          </div>
                      </Col>
                  </Row>
              </>
            }
        </div>
    );
};

LegalEntityDetail.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(LegalEntityDetail));
