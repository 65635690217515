import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useRef, useState } from "react";
import { request } from "../../../../utils/Request";
import Tooltip from "../../../../components/Common/Display/Tooltip";
import HasAccess from "../../../../utils/HasAccess";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import StateLessGrid from "../../../../components/Shared/StateLessGrid";
import { Button } from "primereact/button";
import { Col, Row } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../../../components/Shared/InputField";
import { Badge } from "primereact/badge";
import LegalEntityForm from "./LegalEntityForm";
import LegalEntityFilter from "./LegalEntityFilter";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { includes, isEmpty } from "lodash";
import LegalEntitySetting from "./LegalEntitySetting";
import LegalEntityDetail from "./LegalEntityDetail";
import { ButtonGroup } from "primereact/buttongroup";

const LegalEntityList = (props: any) => {
    const { t, moduleState, toastify } = props;
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [operation, setOperation] = useState<any>(searchParams.get('operation') ?? null);
    const [operationId, setOperationId] = useState<any>(searchParams.get('operationId') ?? null);
    const [filterPanel, setFilterPanel] = useState<boolean>(false);
    const [initSearchFormState, setInitSearchFormState] = useState<any>({
        search: moduleState.search ?? null
    });
    const [total, setTotal] = useState(0);
    const tableRef: any = useRef();

    const onDelete = async (payloads: any): Promise<void> => {
        try {
            await request({
                url: `suppliers/legal/entities/${ payloads.id }`,
                method: 'DELETE',
                data: { _method: 'DELETE' }
            });

            toastify(t("record deleted", { item: t('legal entity') }), "info");
        } catch (e: any) {
            if(e.status === 422) {
                return toastify(e.data.message, "error");
            }

            toastify(t("server error"), "error");
        }
    };

    const columns = [
        {
            field: 'company_name',
            title: t('company name'),
            sorting: true,
            render: (_row: any) => {
                return <>
                    <span className={ "box" } style={ { backgroundColor: _row.color } }>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <span className={ "text-capitalize ms-2" }>{ _row.company_name }</span>
                </>;
            }
        },
        {
            field: 'email',
            title: t('email'),
            sorting: true,
            render: (_row: any) => <span>{ _row.email }</span>
        },
        {
            field: 'phone',
            title: t('phone'),
            sorting: true,
            render: (_row: any) => <span>{ _row.phone }</span>
        },
        {
            field: 'is_default',
            title: t('is default'),
            sorting: true,
            render: ({ is_default }: any) => {
                return (
                    <>
                        { ( is_default ) && <Badge severity="success" value={ t('yes') } /> }
                        { ( !is_default ) && <Badge severity="danger" value={ t('no') } /> }
                    </>
                );
            }
        },
        {
            field: 'is_disable',
            title: t('active'),
            sorting: true,
            render: ({ is_disable }: any) => {
                return (
                    <>
                        { ( !is_disable ) && <Badge severity="success" value={ t('yes') } /> }
                        { ( is_disable ) && <Badge severity="danger" value={ t('no') } /> }
                    </>
                );
            }
        },
        {
            field: 'action',
            title: t('action'),
            sorting: false,
            render: (_row: any) => {
                return (
                    <>
                        <HasAccess hasAllAccess={ ['legalentitiesupdate'] }>
                            <Tooltip
                                tooltip={ t('edit item') }>
                                <a onClick={ () => onOperationClick('edit', _row.id) }>
                                    <i className="fas fa-edit" />
                                </a>
                            </Tooltip>
                        </HasAccess>
                        <HasAccess hasAllAccess={ ['legalentitiesupdate'] }>
                            <Tooltip
                                tooltip={ t('update invoice settings') }>
                                <a onClick={ () => onEditInvoiceSetting(_row.id) } className={ 'ms-2' }>
                                    <i className="fas fa-landmark" />
                                </a>
                            </Tooltip>
                        </HasAccess>
                        <HasAccess hasAllAccess={ ['legalentitiesdestroy'] }>
                            <Tooltip
                                tooltip={ t('delete item') }>
                                <a
                                    title={ t('delete item') }
                                    className={ "cursor-pointer ms-2 btn btn-soft-danger waves-effect waves-light btn-xs" }
                                    onClick={ (event: any) => confirmPopup({
                                        target: event.currentTarget,
                                        message: t('do you want to delete this record?'),
                                        icon: 'pi pi-info-circle',
                                        // @ts-ignorer
                                        defaultFocus: 'reject',
                                        acceptClassName: 'p-button-danger',
                                        accept: () => onDelete(_row),
                                        reject: () => {
                                        },
                                    }) }>
                                    <i className="fas fa-trash-alt" />
                                </a>
                            </Tooltip>
                        </HasAccess>
                        <HasAccess hasAllAccess={ ['legalentitiesview'] }>
                            <Tooltip tooltip={ t('detail of item') }>
                                <a
                                    className={ "cursor-pointer ms-2" }
                                    onClick={ () => onOperationClick('detail', _row.id) }>
                                    <i className="fas fa-eye" />
                                </a>
                            </Tooltip>
                        </HasAccess>
                    </>
                );
            }
        },
    ];

    const onOperationClick = (operationToBePerformed: any = 'add', id: any = null) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('operation', operationToBePerformed);
        searchParams.set('operationId', id);

        if ( isEmpty(operationToBePerformed) ) {
            searchParams.delete('operation');
        }

        if ( isEmpty(id) ) {
            searchParams.delete('operationId');
        }

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        }, { replace: true });
        setOperationId(id);
        setOperation(operationToBePerformed);
        focusOnSearch();
    };

    const onCloseOperation = (reset: any = true) => {
        onOperationClick(null, null);
        onReset(reset);
    };

    const onEditInvoiceSetting = (id: any) => {
        setOperationId(id);
        setOperation("invoice-settings");
    };

    const onReset = (reset: any = true) => {
        const mStates = { ...moduleState };

        if ( reset ) {
            mStates.filters = [];
            mStates.page = 1;
            mStates.search = null;
        }

        props.setQuery(mStates);
    };

    const onSearch = ({ search }: any) => {
        props.setQuery({
            ...moduleState,
            search: search
        });
    };

    const onFilterClick = () => {
        setFilterPanel(true);
    };

    const focusOnSearch = () => {
        const searchInput: HTMLInputElement | null = document.querySelector('input[name="search"]');
        if ( searchInput ) {
            searchInput.focus();
        }
    };

    return (
        <div className={ "block-viewer" }>
            <div className="block-section">
                <div className="block-header">
                    <span className="block-title">
                        <h5 className={ "mb-0" }>{ t('legal entities') } ({total})</h5>
                    </span>
                    <div className="block-actions">
                        {
                            operation == 'detail' &&
                          <div>
                              <Button type={ "button" }
                                      tooltip={ t("operation cancel") } className={ "ms-2" }
                                      size={ "small" } severity={ "warning" }
                                      tooltipOptions={ { position: 'top' } } onClick={ () => onCloseOperation(false) }>
                                  <i className="pi pi-arrow-left me-2" /> { t("cancel") }

                              </Button>
                          </div>
                        }
                    </div>
                </div>
                <div className="block-content">
                    {
                        operation !== 'detail' &&
                        <Row className={ "p-2" }>
                            <Col
                                sm={ 12 } md={ 6 } className={ "mb-2" }>
                                <Formik
                                    enableReinitialize={ true }
                                    initialValues={ initSearchFormState }
                                    onSubmit={ onSearch }
                                >
                                    { (props: FormikProps<any>) => {
                                        return (
                                            <Form onSubmit={ props.handleSubmit }>
                                                <Col sm={ 12 } md={ 8 }>
                                                    <Field component={ InputField } withoutLabel={ true }
                                                           autoFocus={ true }
                                                           name="search"
                                                           formGroupClass={ "mb-0" }
                                                           placeholder={ t("search") } />
                                                </Col>
                                            </Form>
                                        );
                                    } }
                                </Formik>
                            </Col>

                            <Col
                                sm={ 12 } md={ 6 } className={ "text-right mb-2" }>
                                    <ButtonGroup>
                                        <Button size={ "small" } outlined tooltip={ t('add item') }
                                                tooltipOptions={ { position: 'top' } }
                                                onClick={ () => onOperationClick('add') }>
                                            <i className="pi pi-plus" />
                                            <span className={ "ms-1" }>{ t('add') }</span>
                                        </Button>
                                        <Button icon="pi pi-filter-fill" size={ "small" } outlined tooltip={ t('filter') }
                                                onClick={ onFilterClick }
                                                tooltipOptions={ { position: 'top' } } />
                                        <Button icon="pi pi-refresh" size={ "small" } outlined tooltip={ t('reset') }
                                                tooltipOptions={ { position: 'top' } } />
                                    </ButtonGroup>
                            </Col>

                            <Col sm={ 12 }>
                                <StateLessGrid
                                    size={ "normal" }
                                    url={ '/suppliers/legal/entities?1=1' }
                                    name={ "legalEntityList" }
                                    columns={ columns }
                                    moduleState={ moduleState }
                                    setQuery={ props.setQuery }
                                    ref={ tableRef }
                                    itemSize={ 45 }
                                    setTotal={ setTotal }
                                    // detailRow={ (data: any) => <DetailRow data={ data } /> }
                                />
                            </Col>
                        </Row>
                    }

                    {
                        (operation === 'detail' && !isEmpty(operationId)) &&
                      <LegalEntityDetail
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        params={ { operation, operationId } }
                        onClose={ onCloseOperation }
                      />
                    }

                    {
                        (includes(['add', 'edit'], operation)) &&
                        <LegalEntityForm
                            moduleState={ moduleState }
                            setQuery={ props.setQuery }
                            toastify={ props.toastify }
                            dropdowns={ props.dropdowns }
                            params={ { operation, operationId } }
                            onClose={ onCloseOperation }
                        />
                    }

                    <LegalEntityFilter
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        visibility={ filterPanel }
                        onClose={ () => setFilterPanel(false) }
                    />

                    <LegalEntitySetting
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        params={ { operation, operationId } }
                        onClose={ onCloseOperation }
                    />

                    <ConfirmPopup />
                </div>
            </div>
        </div>
    );
};

LegalEntityList.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(LegalEntityList));
