import * as store from "store";
import { map, findIndex } from "lodash";

const LOCALSTORAGE_PREFIX = process.env.REACT_APP_STORAGE_PREFIX || 'AlcolmCALP';


class Auth {
    isLoggedIn() {
        return ( store.get(`${ LOCALSTORAGE_PREFIX }.auth`) || false );
    }

    getToken(): string | undefined {
        const user: any = this.getUser();
        return ( user || {} ).token;
    }

    getUser(): object | null | undefined {
        const user = store.get(`${ LOCALSTORAGE_PREFIX }.user`);
        return ( user ?? null );
    }

    getLevel(): number | null | undefined {
        const user: any = this.getUser();
        return ( user ? user.level : null );
    }

    getParentLevel(): number | null | undefined {
        const user: any = this.getUser();
        return ( user ? user.parent_level : null );
    }

    isAdminLevelUser(): boolean {
        const user: any = this.getUser();

        if ( !user ) {
            return false;
        }

        if ( this.getLevel() == 20 ) {
            return true;
        }

        return ( this.getLevel() == 4 && this.getParentLevel() == 20 );
    };

    isSupplierLevelUser(): boolean {
        const user: any = this.getUser();

        if ( !user ) {
            return false;
        }

        if ( this.getLevel() == 8 ) {
            return true;
        }

        return ( this.getLevel() == 4 && this.getParentLevel() == 8 );
    };

    isDriverLevelUser(): boolean {
        const user: any = this.getUser();

        if ( !user ) {
            return false;
        }

        return ( this.getLevel() == 7 && this.hasRole("SystemDriver") );
    };

    setUser(user: object | null): void {
        store.set(`${ LOCALSTORAGE_PREFIX }.user`, user);
    }

    logout(): void {
        const user: any = this.getUser();

        store.remove(`${ LOCALSTORAGE_PREFIX }.user`);

        store.remove(`last-activation-` + user.id);
    }

    // @ts-ignore
    hasAnyAccess(permissions: any): boolean {
        const user: any = this.getUser();
        if ( !user ) {
            return false;
        }

        const { roles } = user;
        if ( !roles ) {
            return false;
        }

        let permissionCollections: any = [];
        map(roles, (item: any) => {
            permissionCollections = [...permissionCollections, ...item.permissions];
        });

        let counter = 0;

        permissions.map((item: any) => {
            const hasIndex = findIndex(permissionCollections, { slug: item });
            if ( hasIndex >= 0 ) {
                counter++;
            }
        });

        return ( counter > 0 );
    }

    // @ts-ignore
    hasAllAccess(permissions: any): boolean {
        const user: any = this.getUser();
        if ( !user ) {
            return false;
        }

        const { roles } = user;
        if ( !roles ) {
            return false;
        }

        let permissionCollections: any = [];
        map(roles, (item: any) => {
            permissionCollections = [...permissionCollections, ...item.permissions];
        });

        let counter = 0;

        permissions.map((item: any) => {
            const hasIndex = findIndex(permissionCollections, { slug: item });
            if ( hasIndex >= 0 ) {
                counter++;
            }
        });

        return ( counter === permissions.length );
    }

    hasRole(role: string): boolean {
        const storedUser: any = this.getUser();

        if ( !storedUser ) {
            return false;
        }

        const { roles } = storedUser;
        if ( roles ) {

            const has = findIndex(roles, { name: role });
            if ( has >= 0 ) {
                return true;
            }
        }

        return false;
    }
}

export default Auth;
