import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Field, Form } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Col, Row, FormGroup } from "react-bootstrap";
import { InputField } from "../../../../components/Shared/InputField";
import { Sidebar } from "primereact/sidebar";
import { get, includes, findIndex, map } from "lodash";
import { Button } from "primereact/button";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    dropdowns: any;
    params: any;
    toastify: any;
    onClose?: any;
    operationCancel: any;
}

type TInputForm = {
    id?: any,
    name?: string | null,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        name: yup.string().trim(t('the field is invalid.')).required(t('the field is required.', { field: t('title') })).nullable(),
    });
};

const DEFAULT_FORM_STATE = {
    id: null,
    name: null,
};

function RoleForm(props: IFormProps) {
    const { t, toastify, params, dropdowns, operationCancel } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);
    const [permissions, setPermissions] = useState(dropdowns.permissions.map((i: any) => ( { ...i, checked: false } )));
    const [checkedPermissions, setCheckedPermissions] = useState(new Array(dropdowns.permissions.length).fill(false));

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: ( !payloads.id ? 'POST' : 'PATCH' ),
                url: ( !payloads.id ? `/suppliers/roles` : `/suppliers/roles/${ payloads.id }` ),
                data: {
                    ...payloads,
                    permissions: permissions.filter((item: any) => item.checked).map((item: any) => item.slug)
                },
            });

            if ( payloads.id ) {
                toastify(t("record updated", { item: t('role') }), "success");
            } else {
                toastify(t("record added", { item: t('role') }), "info");
            }

            resetForm();
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    const onReset = () => {
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm();
        }
    };

    const onClose = () => {
        setPanelState(false);
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm();
        }

        if ( props.onClose ) {
            props.onClose(
                !includes(['edit'], get(params, ['operation']))
            );
        }
    };

    const handleOnChange = (item: any, status: boolean) => {
        let shallowPermissions: any = permissions;
        const index = findIndex(shallowPermissions, (loop: any) => loop.slug === item.slug);
        let newItem: any = { ...shallowPermissions[index], checked: undefined };
        newItem.checked = status;
        shallowPermissions[index] = newItem;
        setPermissions(shallowPermissions);

        const updatedCheckedState = checkedPermissions.map((item, cIndex) => ( cIndex === index ? status : item ));
        setCheckedPermissions(updatedCheckedState);
    };

    const handleOnChangeAllPermission = (item: any, status: boolean) => {
        let shallowPermissions: any = permissions;
        let shallowCheckPermissions = checkedPermissions;

        const newItems: any = map(shallowPermissions, (i: any, index: number) => {
            if ( i.description === item.description ) {
                shallowCheckPermissions[index] = status;

                return {
                    ...i,
                    checked: status
                };
            }

            return i;
        });

        setPermissions(newItems);
        setCheckedPermissions(shallowCheckPermissions);
    };

    const onEdit = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/roles/${ id }`
            });

            const { data } = response.data;
            const formData: TInputForm = { ...data };
            if ( data.permissions.length > 0 ) {
                map(data.permissions, (item: any) => {
                    handleOnChange(item, true);
                });

                // bogus code to make default checkbox checked
                const checkedCheckboxCopy = checkedPermissions;
                map(permissions, (item: any, index: any) => checkedCheckboxCopy[index] = item.checked);
                setCheckedPermissions(checkedCheckboxCopy);
            }
            setInitFormState(formData);
            setPanelState(true);
        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        // setInitFormState(DEFAULT_FORM_STATE);

        if ( includes(['add'], get(params, ['operation'])) ) {
            setPanelState(true);
        }

        if ( includes(['edit'], get(params, ['operation'])) ) {
            onEdit(params.operationId);
        }
    }, [params]);

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('role') + ' ' + t('action') }</h5>
                            <p>{ t("using this form you can add or update the record") }</p>
                        </div>
                    }
                    visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } } closeOnEscape={ false }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                        validationSchema={ validationSchema(t) }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4 md:mb-7 mb-7 sm:mb-7" }>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="name"
                                                   label={ t("title") }
                                                   placeholder={ t("enter") + ' ' + t("title").toLowerCase() }
                                                   isRequired
                                                   autoFocus />
                                        </Col>
                                        <Col sm={ 12 } md={ 12 }>
                                            <div className="col-sm-12">
                                                <fieldset>
                                                    <h4 className={ 'p-l-5 p-r-5 text-capitalize' }>{ t('permissions') }:</h4>
                                                    <div className={ 'p-2' }>
                                                        {
                                                            permissions &&
                                                            permissions.map((item: any, index: any) => {
                                                                let group = item.description;
                                                                let prevGroup = ( index <= 0 ) ? item.description : permissions[index - 1].description;

                                                                return (
                                                                    <span key={ index } className={ "mb-2" }>
                                                                                {
                                                                                    ( group !== prevGroup || index <= 0 ) ?
                                                                                        <div className={ "mt-2" }>
                                                                                            <hr />
                                                                                            <span
                                                                                                className={ "text-capitalize mt-2 fw-semibold" }>{ t(group) }</span>
                                                                                            <label
                                                                                                className="form-check-label">
                                                                                                <input type="checkbox"
                                                                                                       className="ms-1 form-check-input"
                                                                                                       onChange={ (e) => handleOnChangeAllPermission(item, e.target.checked) }
                                                                                                />
                                                                                                <small>Select
                                                                                                    All</small>
                                                                                            </label>
                                                                                        </div>
                                                                                        : ''
                                                                                }
                                                                                <FormGroup key={ index }
                                                                                   className={ "d-inline-block ms-2" }>
                                                                                    <label>
                                                                                        <input type="checkbox"
                                                                                               onChange={ (e) => handleOnChange(item, e.target.checked) }
                                                                                               checked={ checkedPermissions[index] } />
                                                                                        <span
                                                                                            className={ "fw-normal" }>{ item.name }</span>
                                                                                    </label>
                                                                                </FormGroup>
                                                                            </span>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="filter-card-footer"
                                         style={ { width: '785px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-save me-2" />
                                            { t("let's save") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ onReset }
                                                tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                size={ "small" } severity={ "warning" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-refresh me-2" />
                                            { t("reset") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                                onClick={() => operationCancel(null, null)}
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("cancel") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

RoleForm.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(RoleForm);
