import React, { useEffect } from "react";

//import Breadcrumbs
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import WithRouter from "../../../components/Common/WithRouter";

const Dashboard = (props: any) => {
    const { t } = props;

    useEffect(() => {
        async function init() {

        }

        init();
    }, []);

    return (
        <div>
            <h3>{ t("welcome") }</h3>
        </div>
    );
};

Dashboard.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(WithRouter(Dashboard));
