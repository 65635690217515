import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Field, Form } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Col, Row } from "react-bootstrap";
import { InputField } from "../../../../components/Shared/InputField";
import { Sidebar } from "primereact/sidebar";
import { get, includes, find, isEmpty } from "lodash";
import { Button } from "primereact/button";
import { InputChip } from "../../../../components/Shared/InputChip";
import { InputTextAreaField } from "../../../../components/Shared/InputTextAreaField";
import InputAttachment from "../../../../components/Shared/InputAttachment";
import { Divider } from "primereact/divider";
import BankDetail from "../../clients/partials/BankDetail";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    dropdowns: any;
    params: any;
    toastify: any;
    onClose?: any;
}

type TInputForm = {
    invoice_oib: any,
    invoice_vat_number: any,
    invoice_email_addresses: any,
    invoice_legal_entity_logo: any,
    invoice_mb: any,
    invoice_fax: any,
    invoice_mbs: any,
    invoice_web: any,
    invoice_telephone: any,
    invoice_footer: any,
    invoice_bank_details: any,
    invoice_company_address: any,
    invoice_pantheon_username: any,
    invoice_pantheon_password: any,
    invoice_pantheon_company_db: any,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        invoice_vat_number: yup.string().required(t('the field is required.', { field: t('vat number') })).nullable(),
        invoice_legal_entity_logo: yup.string().nullable(), // required(t('the field is required.', { field: t('logo') })).
        invoice_mb: yup.string().required(t('the field is required.', { field: t('mb') })).nullable(),
        invoice_oib: yup.string().required(t('the field is required.', { field: t('oib') })).nullable(),
        invoice_fax: yup.string().required(t('the field is required.', { field: t('fax') })).nullable(),
        invoice_mbs: yup.string().required(t('the field is required.', { field: t('mbs') })).nullable(),
        invoice_telephone: yup.string().required(t('the field is required.', { field: t('telephone') })).nullable(),
        invoice_web: yup.string().url(t('the field should be url', { field: t('web') })).required(t('the field is required.', { field: t('web') })).nullable(),
        invoice_company_address: yup.string().required(t('the field is required.', { field: t('company address') })).nullable(),
        invoice_footer: yup.string().required(t('the field is required.', { field: t('footer') })).nullable(),
        invoice_email_addresses: yup.array().min(1, t('the field is required.', { field: t('email') })).nullable(),
        invoice_pantheon_username: yup.string().nullable(),
        invoice_pantheon_password: yup.string().nullable(),
        invoice_pantheon_company_db: yup.string().nullable(),
    });
};

const DEFAULT_FORM_STATE = {
    invoice_vat_number: null,
    invoice_legal_entity_logo: null,
    invoice_oib: null,
    invoice_mb: null,
    invoice_fax: null,
    invoice_mbs: null,
    invoice_web: null,
    invoice_telephone: null,
    invoice_footer: null,
    invoice_email_addresses: [],
    invoice_bank_details: null,
    invoice_company_address: null,
    invoice_pantheon_username: null,
    invoice_pantheon_password: null,
    invoice_pantheon_company_db: null,
};

function ClientInvoiceSetting(props: IFormProps) {
    const { t, toastify, params, dropdowns } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);
    const [operationId, setOperationId] = useState<any>(null);
    // const [searchParams] = useSearchParams();

    const onClose = () => {
        setPanelState(false);
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm();
        }

        if ( props.onClose ) {
            props.onClose();
        }
    };

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: 'PATCH',
                url: `/suppliers/legal/entities/${ operationId }`,
                data: {
                    ...payloads,
                    invoice_email_addresses: payloads.invoice_email_addresses.map((item: any) => item).join(','),
                    type: 'invoice-settings'
                },
            });

            toastify(t("record updated", { item: t('settings') }), "success");

            resetForm();
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");

            }
        }
    };


    const onEditInvoiceSetting = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/legal/entities/${ id }`
            });

            const { data } = response.data;
            setInitFormState({ ...DEFAULT_FORM_STATE });
            const settings = data.invoice_settings;
            const email = find(settings, { title: 'invoice_email_addresses' })?.value ?? [];
            const details = find(settings, {title: 'invoice_bank_details'})?.value;

            const formData: TInputForm = {
                invoice_vat_number: find(settings, { title: 'invoice_vat_number' })?.value ?? null,
                invoice_legal_entity_logo: find(settings, { title: 'invoice_legal_entity_logo' })?.value ?? null,
                invoice_mb: find(settings, { title: 'invoice_mb' })?.value ?? null,
                invoice_oib: find(settings, { title: 'invoice_oib' })?.value ?? null,
                invoice_fax: find(settings, { title: 'invoice_fax' })?.value ?? null,
                invoice_mbs: find(settings, { title: 'invoice_mbs' })?.value ?? null,
                invoice_telephone: find(settings, { title: 'invoice_telephone' })?.value ?? null,
                invoice_footer: find(settings, { title: 'invoice_footer' })?.value ?? null,
                invoice_web: find(settings, { title: 'invoice_web' })?.value ?? null,
                invoice_pantheon_username: find(settings, { title: 'invoice_pantheon_username' })?.value ?? null,
                invoice_pantheon_password: find(settings, { title: 'invoice_pantheon_password' })?.value ?? null,
                invoice_pantheon_company_db: find(settings, { title: 'invoice_pantheon_company_db' })?.value ?? null,
                invoice_company_address: find(settings, { title: 'invoice_company_address' })?.value ?? null,
                invoice_email_addresses: email.length ? email.split(',') : [],
                invoice_bank_details: !isEmpty(details) ? JSON.parse(details) : [],
            };

            setInitFormState(formData);
            setOperationId(id);
            setPanelState(true);
        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };

    const onUpdateBankDetail = (payloads: any) => {
        const base: any = get(formRef.current, ['values']);
        setInitFormState({ ...base, invoice_bank_details: payloads });
    }

    useEffect(() => {
        if ( includes(['invoice-settings'], get(params, ['operation'])) ) {
            onEditInvoiceSetting(params.operationId);
        }
    }, [params]);

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('legal entity setting') + ' ' + t('action') }</h5>
                            <p>{ t("using this form you can add or update the record") }</p>
                        </div>
                    }
                    visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                        validationSchema={ validationSchema(t) }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4 mb-5" }>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="invoice_vat_number"
                                                   label={ t("vat number") }
                                                   placeholder={ "Ex. 8809998" } />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="invoice_oib"
                                                   label={ t("oib") }
                                                   placeholder={ "Ex. 66688889999" } />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="invoice_mb"
                                                   label={ t("mb") }
                                                   placeholder={ "Ex. 080000888" } />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="invoice_mbs"
                                                   label={ t("mbs") }
                                                   placeholder={ "Ex. 080000888" } />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="invoice_fax"
                                                   label={ t("fax") }
                                                   placeholder={ "Ex. +123-456-7890" } />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="invoice_telephone"
                                                   label={ t("telephone") }
                                                   placeholder={ "Ex. +123-456-7890" } />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="invoice_web"
                                                   label={ t("web") }
                                                   placeholder={ "Ex. https://linkedin.com" } />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputAttachment }
                                                   name="invoice_legal_entity_logo"
                                                   label={ t("logo") } />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputChip }
                                                   isEmail={ true }
                                                   name={ 'invoice_email_addresses' }
                                                   label={ t('email addresses') }
                                                   placeHolder={ t("type something and press tab...") }
                                                   formText={ t("any invalid emails may not be processed") } />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputTextAreaField }
                                                   name="invoice_company_address"
                                                   label={ t("company address") }/>
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="invoice_footer"
                                                   label={ t("footer") }
                                            />
                                        </Col>

                                        <Col sm={ 12 } md={ 12 }>
                                            <BankDetail onUpdate={ onUpdateBankDetail }
                                                        bankDetails={ props.values.invoice_bank_details } />
                                        </Col>
                                        <Divider />

                                        <Col sm={ 12 }>
                                            <h4 className="mb-3">
                                                { t('pantheon') } <small className="text-muted">{ t('fields') }</small>
                                            </h4>
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="invoice_pantheon_username"
                                                   label={ t("username") }
                                                   placeholder={ "Ex. +123-456-7890" } />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="invoice_pantheon_password"
                                                   label={ t("password") }
                                                   placeholder={ "Ex. +123-456-7890" } />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 } className={" mb-5"}>
                                            <Field component={ InputField }
                                                   name="invoice_pantheon_company_db"
                                                   label={ t("company db") }
                                                   placeholder={ "Ex. +123-456-7890" } />
                                        </Col>
                                    </Row>
                                    <div className="filter-card-footer"
                                         style={ { width: '785px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-save me-2" />
                                            { t("let's save") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                size={ "small" } severity={ "warning" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-refresh me-2" />
                                            { t("reset") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("cancel") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

ClientInvoiceSetting.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(ClientInvoiceSetting);
