import AppSubMenu from "./AppSubMenu";
import { MenuModel } from "../../types";
import Auth from "../../utils/Auth";
import { SupplierNav } from "./navigations/SupplierNav";
import { useTranslation } from "react-i18next";
import { DriverNav } from "./navigations/DriverNav";
import { AdminNav } from "./navigations/AdminNav";

const AppMenu = (props: any) => {
    const auth = new Auth();
    const { t } = useTranslation();

    if ( auth.isSupplierLevelUser() ) {
        return <AppSubMenu model={ SupplierNav({ auth, t }) } />;
    }

    if ( auth.isDriverLevelUser() ) {
        return <AppSubMenu model={ DriverNav({ auth, t }) } />;
    }

    if ( auth.isAdminLevelUser() ) {
        return <AppSubMenu model={ AdminNav({ auth, t }) } />;
    }

    const model: MenuModel[] = [
        {
            label: "Dashboards",
            icon: "pi pi-home",
            items: [
                {
                    label: "E-Commerce",
                    icon: "pi pi-fw pi-home",
                    to: "/",
                },
            ],
        },
    ];

    return <AppSubMenu model={ model } />;
};

export default AppMenu;
