import { withTranslation } from "react-i18next";
import WithRouter from "../../../../../components/Common/WithRouter";
import React, { useRef, useState } from "react";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import StateLessGrid from "../../../../../components/Shared/StateLessGrid";
import { Button } from "primereact/button";
import { Col, Row } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../../../../components/Shared/InputField";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { includes, isEmpty } from "lodash";
import { utcDateToLocalDate } from "../../../../../utils/Helper";
import Tooltip from "../../../../../components/Common/Display/Tooltip";
import Auth from "../../../../../utils/Auth";
import { request } from "../../../../../utils/Request";
import VinWithType from "../../../../../components/Common/Display/VinWithType";
import OrderStatus from "../../../../../components/Common/Display/OrderStatus";
import Location from "../../../../../components/Common/Display/Location";
import OrderFilter from "./OrderFilter";
import OrderDetail from "../../../orders/partials/OrderDetail";
import HasAccess from "../../../../../utils/HasAccess";
import { ButtonGroup } from "primereact/buttongroup";
import OrderExcel from "./OrderExcel";

const OrderList = (props: any) => {
    const { t, moduleState, toastify } = props;
    const auth = new Auth();
    const [searchParams] = useSearchParams();
    const [excelPanel, setExcelPanel] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [operation, setOperation] = useState<any>(searchParams.get('operation') ?? null);
    const [operationId, setOperationId] = useState<any>(searchParams.get('operationId') ?? null);
    const [filterPanel, setFilterPanel] = useState<boolean>(false);
    const [extra, setExtra] = useState<any>([]);
    const [initSearchFormState, setInitSearchFormState] = useState<any>({
        search: moduleState.search ?? null
    });
    const [total, setTotal] = useState(0);
    const tableRef: any = useRef();

    const onDelete = async (payloads: any): Promise<void> => {
        try {
            await request({
                method: 'PATCH',
                url: `/suppliers/archived/orders/${ payloads.id }`,
            });

            toastify(t("record updated", { item: t('orders') }), "info");
            onReset();
        } catch (e: any) {
            if(e.status === 422) {
                return toastify(e.data.message, "error");
            }

            toastify(t("server error"), "error");
        }
    };

    const onPantheonTransfer = async (payloads: any): Promise<void> => {
        try {
            await request({
                url: `suppliers/invoices/pantheon/transfer/${ payloads.id }`,
                method: 'post',
                data: { _method: 'post' }
            });

            toastify(t("transfer started", { item: t('invoice') }), "info");
            onReset();
        } catch (e: any) {
            toastify(t("server error"), "error");
        }
    };

    const onContentLoaded = (response: any) => setExtra(response.extra);

    const columns = [
        {
            field: 'brand',
            title: t("brand") + '/' + t("model"),
            sorting: true,
            sortField: "brand_id",
            render: ({ brand, brand_model }: any) => <div className={ "text-capitalize" }>
                { brand.title }
                { brand.alternate_title && <div> { brand.alternate_title }</div> }
                <div>
                    { brand_model.title }
                    { brand_model.version_code && <div> { brand_model.version_code } </div> }
                </div>
            </div>
        },
        {
            field: 'client',
            title: t("client"),
            sorting: true,
            sortField: "client_id",
            render: ({ client }: any) => <div className={ "text-capitalize" }>
                { client?.name }
            </div>
        },
        {
            field: 'vin_number',
            title: t('vin number'),
            sorting: true,
            sortField: "vin_number",
            render: (_row: any) => (
                <>
                    <VinWithType vinNumber={ _row.vin_number } type={ _row.belongs_to }
                     priorisation={ _row.priorisation } builtToOrderCd={ _row.built_to_order_cd } />
                </>
            )
        },
        { field: 'order_cd', title: t('order'), sortField: "order_cd", sorting: true },
        { field: 'ldg_nr', title: t('ldg nr'), sortField: "ldg_nr", sorting: true },
        {
            field: 'status',
            title: t('status'),
            sorting: true,
            sortField: "status",
            render: (_row: any) => <div className={ "text-capitalize" }>
                <OrderStatus status={ _row.status } />
            </div>
        },
        {
            field: 'journeys',
            title: t('journeys'),
            sorting: false,
            width: 300,
            render: (_row: any) => {
                const { order_journeys, current_journey } = _row;
                return (
                    <>
                        <div className={ "mb-2" }>
                            {
                                order_journeys.map((item: any, index: number) => {
                                    return <div key={ index } className={ "mt-1" }>
                                        <div className={
                                            `border border-1 ps-2 pe-2 border-dashed ${ current_journey && current_journey.token === item.token ? 'border-info' : '' } font-size-12px`
                                        }>
                                            <div>
                                                { ( current_journey && current_journey.token !== item.token && item.status === "completed" ) &&
                                                  <Tooltip tooltip={ t('complete') } >
                                                      <small><i className="fas fa-circle text-secondary" /></small>
                                                  </Tooltip>
                                                }
                                                <small className={ "ps-1" }>
                                                    <Tooltip tooltip={ t('loading') } >
                                                        { item.from_location.street_line_1 && item.from_location.street_line_1 }
                                                    </Tooltip>
                                                    <Tooltip tooltip={ t('unloading') } >
                                                        { item.to_location.street_line_1 && ` - ${ item.to_location.street_line_1 }` }
                                                    </Tooltip>
                                                </small>
                                            </div>
                                            <div>
                                                <Location item={ item.from_location } titled={ 1 } /> - <Location
                                                item={ item.to_location } titled={ 1 } />
                                            </div>
                                        </div>
                                    </div>;
                                })
                            }
                        </div>
                    </>
                );
            },
        },
        {
            field: 'created_at',
            title: t('created at'),
            sorting: true,
            render: ({ created_at }: any) => ( created_at &&
              <Tooltip tooltip={ utcDateToLocalDate(created_at) }>{ utcDateToLocalDate(created_at) } </Tooltip> ),
        },
        {
            field: 'updated_at',
            title: t('modified at'),
            sorting: true,
            render: ({ updated_at }: any) => ( updated_at &&
              <Tooltip tooltip={ utcDateToLocalDate(updated_at) }>{ utcDateToLocalDate(updated_at) } </Tooltip> ),
        },
        {
            field: 'action',
            title: t('action'),
            sorting: false,
            align: 'right',
            render: (_row: any) => {
                return (<>

                    {
                        includes(['archived'], _row.status)
                        &&
                      <HasAccess hasAnyAccess={ ['archivedordersupdate'] }>
                          <Tooltip
                            tooltip={ t('change status') }>
                              <a
                                className={"ms-2"}
                                title={ t('change status') }
                                onClick={ (event: any) => confirmPopup({
                                    target: event.currentTarget,
                                    message: t('do you want to switch status?'),
                                    icon: 'pi pi-info-circle',
                                    // @ts-ignorer
                                    defaultFocus: 'reject',
                                    acceptClassName: 'p-button-danger',
                                    accept: () => onDelete(_row),
                                    reject: () => {
                                    },
                                }) }>
                                  <i className="fas fa-allergies" />
                              </a>
                          </Tooltip>
                      </HasAccess>
                    }

                    <Tooltip
                        tooltip={ t('detail of item') }>
                        <a onClick={ () => onOperationClick('detail', _row.id) } className={ 'ms-2' }>
                            <i className="fas fa-eye" />
                        </a>
                    </Tooltip>
                </> )
            }
        },
    ];

    const onOperationClick = (operationToBePerformed: any = 'add', id: any = null) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('operation', operationToBePerformed);
        searchParams.set('operationId', id);

        if ( isEmpty(operationToBePerformed) ) {
            searchParams.delete('operation');
        }

        if ( isEmpty(id) ) {
            searchParams.delete('operationId');
        }

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        }, { replace: true });
        setOperationId(id);
        setOperation(operationToBePerformed);
    };

    const onCloseOperation = (reset: any = true) => {
        onOperationClick(null, null);
        onReset(reset);
    };

    const onReset = (reset: any = true) => {
        const mStates = { ...moduleState };

        if ( reset ) {
            mStates.filters = [];
            mStates.page = 1;
            mStates.search = null;
        }

        props.setQuery(mStates);
    };

    const onSearch = ({ search }: any) => {
        props.setQuery({
            ...moduleState,
            search: search
        });
    };

    const onFilterClick = () => {
        setFilterPanel(true);
    };

    const onExcelClick = () => {
        setExcelPanel(true);
    };

    const focusOnSearch = () => {
        const searchInput: HTMLInputElement | null = document.querySelector('input[name="search"]');
        if ( searchInput ) {
            searchInput.focus();
        }
    };

    return (
        <div className={ "block-viewer" }>
            <div className="block-section">
                <div className="block-header">
                    <span className="block-title">
                        <h5 className={ "mb-0" }>{ t('orders') } ({total})</h5>
                    </span>
                    <div className="block-actions">
                        {
                            operation == 'detail' &&
                            <div>
                                <Button type={ "button" }
                                        tooltip={ t("operation cancel") } className={ "ms-2" }
                                        size={ "small" } severity={ "warning" }
                                        tooltipOptions={ { position: 'top' } } onClick={ () => onCloseOperation(false) }>
                                    <i className="pi pi-arrow-left me-2" /> { t("cancel") }
                                </Button>
                            </div>
                        }
                    </div>
                </div>
                <div className="block-content">
                    {
                        operation !== 'detail' &&
                        <Row className={ "p-2" }>
                            <Col
                              sm={ 12 } md={ 6 } className={ "mb-2" }>
                                <Formik
                                  enableReinitialize={ true }
                                  initialValues={ initSearchFormState }
                                  onSubmit={ onSearch }
                                >
                                    { (props: FormikProps<any>) => {
                                        return (
                                            <Form onSubmit={ props.handleSubmit }>
                                                <Col sm={ 12 } md={ 8 }>
                                                    <Field component={ InputField } withoutLabel={ true }
                                                           autoFocus={ true }
                                                           name="search"
                                                           formGroupClass={ "mb-0" }
                                                           placeholder={ t("search") } />
                                                </Col>
                                            </Form>
                                        );
                                    } }
                                </Formik>
                            </Col>

                            <Col
                              sm={ 12 } md={ 6 } className={ "text-right mb-2" }>
                                <ButtonGroup>
                                    <Button icon="pi pi-filter-fill" size={ "small" } outlined tooltip={ t('filter') }
                                            onClick={ onFilterClick }
                                            tooltipOptions={ { position: 'top' } } />
                                    <Button icon="pi pi-file-excel" size={ "small" } outlined tooltip={ t('excel') }
                                            onClick={ onExcelClick }
                                            tooltipOptions={ { position: 'top' } } />
                                    <Button onClick={ onReset } icon="pi pi-refresh" size={ "small" } outlined
                                            tooltip={ t('reset') }
                                            tooltipOptions={ { position: 'top' } } />
                                </ButtonGroup>
                            </Col>

                            <Col sm={ 12 }>
                                <StateLessGrid
                                  size={ "normal" }
                                  url={ '/suppliers/archived/orders' }
                                  name={ "archiveOrderList" }
                                  columns={ columns }
                                  moduleState={ moduleState }
                                  setQuery={ props.setQuery }
                                  ref={ tableRef }
                                  itemSize={ 45 }
                                  setTotal={ setTotal }
                                  getMetaData={ onContentLoaded }
                                />
                            </Col>
                        </Row>
                    }

                    {
                        (operation === 'detail' && !isEmpty(operationId)) &&
                      <OrderDetail
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        params={ { operation, operationId } }
                        onClose={ onCloseOperation }
                      />
                    }

                    <OrderFilter
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        visibility={ filterPanel }
                        onClose={ () => {
                            setFilterPanel(false);
                            focusOnSearch();
                        } }
                    />

                    <OrderExcel
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        visibility={ excelPanel }
                        onClose={ () => {
                            setExcelPanel(false);
                            focusOnSearch();
                        } }
                    />

                    <ConfirmPopup />
                </div>
            </div>
        </div>
    );
};

OrderList.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(OrderList));
