import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import alcolmLogo from "../../assets/images/alcolm-logo.png";
import backgroundImage from "../../assets/images/auth-bg.jpg";
import config from "../../config";
import withRouter from "../../components/Common/WithRouter";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "primereact/button";
import Auth from "../../utils/Auth";
import { AUTH_LOGOUT } from "../../store/actions/auth";

const Logout = (props: any) => {
    const dispatch = useDispatch();
    const { t } = props;
    const auth = new Auth();

    document.title = `${ t("Logout") } | ${ config.appNameForTitle }`;

    useEffect(() => {
        if ( auth.getUser() ) {
            auth.logout();
        }
        // dispatch({ type: AUTH_LOGOUT });
        window.location.href = AUTH_LOGOUT
    }, [dispatch]);

    return (
        <React.Fragment>
            {
                <>
                    <div className="auth-page">
                        <Container fluid className="p-0">
                            <Row className="g-0">
                                <Col lg={ 4 } md={ 5 } className="col-xxl-4">
                                    <div className="px-5 min-h-screen flex align-items-center">
                                        <div
                                            className="w-full border-1 surface-border surface-card border-round py-2 px-4 md:px-7 z-1">
                                            <div className="mb-0 mb-md-0 text-center">
                                                <Link to="/dashboard" className="d-block auth-logo">
                                                    <img
                                                        src={ alcolmLogo }
                                                        height="80"
                                                        alt="ALCOLM"
                                                    />{ " " }
                                                </Link>
                                            </div>

                                            <div className="mt-3 text-center">
                                                <div className="text-900 text-xl font-bold mb-2">
                                                    <h5>{ t("you are logged out") }</h5>
                                                </div>
                                                <span className="text-600 font-medium">
                                                    { t("thank you for using") }{ " " }
                                                    <span className="fw-semibold text-dark">{ config.appName }</span>
                                                </span>

                                                <div className="mt-4">
                                                    <Link to="/dashboard">
                                                        <Button label={ t("Log In") } type={ "button" }
                                                                className={ "w-full" }
                                                                size={ "small" } />
                                                    </Link>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p className="text-muted mb-0">
                                                        { t("don't have an account ?") } { " " }
                                                        <Link
                                                            to="/register"
                                                            className="text-primary fw-semibold"
                                                        >
                                                            { " " }
                                                            { t("signup") }
                                                        </Link>{ " " }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <div className="col-xxl-8 col-lg-8 col-md-7"
                                     style={ {
                                         backgroundImage: `url(${ backgroundImage })`,
                                         backgroundSize: "cover",
                                         backgroundPosition: 'center -50px',
                                         backgroundColor: '#f7f7f7',
                                         backgroundRepeat: "no-repeat"
                                     } }>
                                    &nbsp;
                                </div>
                            </Row>
                        </Container>
                    </div>
                </>
            }

        </React.Fragment>
    );
};

export default withTranslation()(withRouter(Logout));

Logout.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};
