import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useRef, useState } from "react";
import { ConfirmPopup } from 'primereact/confirmpopup';
import StateLessGrid from "../../../../components/Shared/StateLessGrid";
import { Button } from "primereact/button";
import { Col, Row } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../../../components/Shared/InputField";
import { utcDateToLocalDate } from "../../../../utils/Helper";
import TransportVehicleMomentLogFilter from "./TransportVehicleMomentLogFilter";
import { isEmpty } from "lodash";
import { ButtonGroup } from "primereact/buttongroup";

const TransportVehicleMomentLogList = (props: any) => {
    const { t, moduleState, toastify } = props;
    const [initSearchFormState, setInitSearchFormState] = useState<any>({
        search: moduleState.search ?? null
    });
    const [filterPanel, setFilterPanel] = useState<boolean>(false);
    const tableRef: any = useRef();
    const [total, setTotal] = useState(0);

    const columns = [
        {
            field: 'truck_plate_number', title: t('truck plate number'),
        },
        {
            field: 'trailer_plate_number', title: t('trailer plate number'),
        },
        { field: 'latitude', title: t('latitude') },
        { field: 'longitude', title: t('longitude') },
        { field: 'country', title: t('country') },
        { field: 'location', title: t('location') },
        { field: 'speed', title: t('speed') },
        {
            field: 'created_at', title: t('created at'),
            render: (_row: any) => utcDateToLocalDate(_row.created_at)
        }
    ];

    const onReset = () => {
        tableRef.current.onRefresh();
    };

    const onSearch = ({ search }: any) => {
        props.setQuery({
            ...moduleState,
            search: search
        });
    };

    const onFilterClick = () => {
        setFilterPanel(true);
    };

    const DetailRow = ({ data }: any) => {
        return (
            <div>
                <table>
                    <tbody>
                        {
                            !isEmpty(data.response) &&
                            Object.keys(JSON.parse(data.response)).map(function (key: any, index: any) {
                                // @ts-ignore
                                const _data = JSON.parse(data.response);
                                return (
                                    <tr key={ index }>
                                        <td>{ key }</td>
                                        <td>{ _data[key] }</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    };

    const focusOnSearch = () => {
        const searchInput: HTMLInputElement | null = document.querySelector('input[name="search"]');
        if ( searchInput ) {
            searchInput.focus();
        }
    };

    return (
        <div className={ "block-viewer" }>
            <div className="block-section">
                <div className="block-header">
                    <span className="block-title">
                        <h5 className={ "mb-0" }>{ t('transport vehicles moment logs') } ({total})</h5>
                    </span>
                    <div className="block-actions">
                    </div>
                </div>
                <div className="block-content">
                    <Row className={ "p-2" }>
                        <Col
                            sm={ 12 } md={ 6 } className={ "mb-2" }>
                            <Formik
                                enableReinitialize={ true }
                                initialValues={ initSearchFormState }
                                onSubmit={ onSearch }
                            >
                                { (props: FormikProps<any>) => {
                                    return (
                                        <Form onSubmit={ props.handleSubmit }>
                                            <Col sm={ 12 } md={ 8 }>
                                                <Field component={ InputField } withoutLabel={ true }
                                                       autoFocus={ true }
                                                       name="search"
                                                       formGroupClass={ "mb-0" }
                                                       placeholder={ t("search") } />
                                            </Col>
                                        </Form>
                                    );
                                } }
                            </Formik>
                        </Col>

                        <Col
                            sm={ 12 } md={ 6 } className={ "text-right mb-2" }>
                                <ButtonGroup>
                                    <Button icon="pi pi-filter-fill" size={ "small" } outlined
                                            tooltip={ t('filter') } onClick={ onFilterClick }
                                            tooltipOptions={ { position: 'top' } } />

                                    <Button onClick={ onReset } icon="pi pi-refresh" size={ "small" } outlined
                                            tooltip={ t('reset') }
                                            tooltipOptions={ { position: 'top' } } />
                                </ButtonGroup>
                        </Col>

                        <Col sm={ 12 }>
                            <StateLessGrid
                                size={ "normal" }
                                url={ '/suppliers/transport/vehicles/moment/logs' }
                                name={ "transportVehicleMomentLog" }
                                columns={ columns }
                                moduleState={ moduleState }
                                setQuery={ props.setQuery }
                                ref={ tableRef }
                                itemSize={ 45 }
                                detailRow={ (data: any) => <DetailRow data={ data } /> }
                                setTotal={ setTotal }
                            />
                        </Col>
                    </Row>

                    <TransportVehicleMomentLogFilter
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        visibility={ filterPanel }
                        onClose={ () => {
                            setFilterPanel(false);
                            focusOnSearch();
                        } }
                    />

                    <ConfirmPopup />
                </div>
            </div>
        </div>
    );
};

TransportVehicleMomentLogList.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(TransportVehicleMomentLogList));
