import React from "react";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { request } from "../../../utils/Request";
import { Card, CardBody, CardHeader, Form, CardFooter, Col, Row } from "react-bootstrap";
import { FormikProps, Formik, Field } from "formik";
import { find } from "lodash";
import PropTypes from "prop-types";
import { InputField } from "../../../components/Shared/InputField";
import InputAttachment from "../../../components/Shared/InputAttachment";
import { Button } from "primereact/button";

type TInputForm = {
    logo: null | any,
    logo_text: null | any,
    mobile_logo: null | any,
}

const DEFAULT_FORM_STATE = {
    logo: null,
    logo_text: null,
    mobile_logo: null,
};

interface IProps {
    user?: any,
    settings: any,
    t?: any,
    toastify?: any
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        logo: yup.string().required(t('the field is required.', { field: t('logo') })).nullable(),
        mobile_logo: yup.string().required(t('the field is required.', { field: t('mobile logo') })).nullable(),
        // logo_text: yup.string().required(t('the field is required.', { field: t('logo text') })).nullable(),
    });
};

function PanelSettingProfile(props: IProps) {
    const { user, t, settings, toastify } = props;
    const [initFormState, setInitFormState] = useState<any>({ ...DEFAULT_FORM_STATE, ...settings });

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: 'PATCH',
                url: `/auth/setting`,
                data: {
                    ...payloads,
                    type: 'panel',
                    logo_text: null,
                },
            });

            toastify(t("record updated", { item: t('settings') }), "success");
            window.location.reload();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    useEffect(() => {
        setInitFormState({
            logo: find(settings, { title: 'logo' })?.value ?? null,
            // logo_text: find(settings, { title: 'logo_text' })?.value ?? null,
            mobile_logo: find(settings, { title: 'mobile_logo' })?.value ?? null
        });
    }, [settings]);

    return (
        <Formik
            enableReinitialize={ true }
            initialValues={ initFormState }
            onSubmit={ onSubmit }
            validationSchema={ validationSchema(t) }
        >
            { (props: FormikProps<TInputForm>) => {
                return (
                    <Form onSubmit={ props.handleSubmit } className={ 'mt-3' }>
                        <Card>
                            <CardHeader className={ "bg-body-tertiary" }>
                                <h4 className="card-title">{ t('panel settings') }</h4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    {/*<Col sm={ 12 }>*/}
                                    {/*    <Field component={ InputField }*/}
                                    {/*           name="logo_text"*/}
                                    {/*           label={ t("textual logo") }*/}
                                    {/*           placeholder={ "Ex. ALPC" }*/}
                                    {/*           isRequired />*/}
                                    {/*</Col>*/}
                                    <Col sm={ 12 }>
                                        <Field component={ InputAttachment }
                                               name="logo"
                                               label={ t("logo") }
                                               formText={ t("please upload the logo with dimensions of 70x40 pixels") }
                                               needBoolean
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={ 12 }>
                                        <Field component={ InputAttachment }
                                               name="mobile_logo"
                                               label={ t("mobile logo") }
                                               formText={ t("please upload the logo with dimensions of 25x25 pixels") }
                                               needBoolean
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Button type="submit" size={ 'small' }
                                        title={ t("to data save into database") }
                                        className="btn btn-success waves-effect btn-label waves-light mt-2">
                                    <i className="fas fa-save label-icon me-2" /> { t("let's save") }
                                </Button>
                            </CardFooter>
                        </Card>
                    </Form>
                );
            } }
        </Formik>
    );
}

PanelSettingProfile.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(PanelSettingProfile);
