import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { get, includes } from "lodash";
import { request } from "../../../../utils/Request";
import { utcDateToLocalDate } from "../../../../utils/Helper";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const ContractDetail = (props: any) => {
    const { t, toastify, params } = props;
    const [entity, setEntity] = useState<any>(null);

    const getDetail = async (id: string) => {
        try {
            const response = await request({
                url: `/suppliers/contracts/${ id }`
            });

            const { data } = response.data;
            setEntity(data);
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    const actionBodyTemplate = (rowData: any) => {
        return (
            <React.Fragment>
                <a className={ 'me-2' } href={ `${ process.env.REACT_APP_DOCUMENT_URL }/${ rowData.id }` }
                   target="_blank">
                    <i className={ 'fas fa-download' } />
                </a>
            </React.Fragment>
        );
    };


    useEffect(() => {
        if ( includes(['detail'], get(params, ['operation'])) ) {
            getDetail(params.operationId);
        }
    }, [params]);

    return (
        <div className={ "p-4 bg-white" }>
            {
                entity &&
                <>
                    <Row>
                        <Col sm="12" md="12">
                            <Row>
                                <Col md="6" lg="6" sm="12">
                                    <h6><span className="text-capitalize">{ t('created at') }</span>
                                    </h6>
                                </Col>
                                <Col md="6" lg="6" sm="12">
                                    <div
                                      className="text-end">{ utcDateToLocalDate(entity.created_at) }</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" lg="6" sm="12">
                                    <h6><span className="text-capitalize">{ t('sub contractor') }</span>
                                    </h6>
                                </Col>
                                <Col md="6" lg="6" sm="12">
                                    <div
                                      className="text-end ">
                                        { get(entity, ['sub_contractor', 'name']) }
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" lg="6" sm="12">
                                    <h6><span className="text-capitalize">{ t('title') }</span>
                                    </h6>
                                </Col>
                                <Col md="6" lg="6" sm="12">
                                    <div
                                      className="text-end ">
                                        { entity.title }
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" lg="6" sm="12">
                                    <h6><span className="text-capitalize">{ t('start date') }</span>
                                    </h6>
                                </Col>
                                <Col md="6" lg="6" sm="12">
                                    <div
                                      className="text-end ">
                                        { entity.start_date }
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" lg="6" sm="12">
                                    <h6><span className="text-capitalize">{ t('end date') }</span>
                                    </h6>
                                </Col>
                                <Col md="6" lg="6" sm="12">
                                    <div
                                      className="text-end ">
                                        { entity.end_date }
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" lg="6" sm="12">
                                    <h6><span className="text-capitalize">{ t('reminder date') }</span>
                                    </h6>
                                </Col>
                                <Col md="6" lg="6" sm="12">
                                    <div
                                      className="text-end ">
                                        { entity.reminder_date }
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" lg="6" sm="12">
                                    <h6><span className="text-capitalize">{ t('email') }</span>
                                    </h6>
                                </Col>
                                <Col md="6" lg="6" sm="12">
                                    <div
                                      className="text-end">
                                        { entity.email }
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" lg="6" sm="12">
                                    <h6><span className="text-capitalize">{ t('additional notes') }</span>
                                    </h6>
                                </Col>
                                <Col md="12" lg="12" sm="12">
                                    <div>
                                        {/*<span dangerouslySetInnerHTML={{__html: entity.additional_notes}}></span>*/ }
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        {
                            ( entity.documents && entity.documents.length > 0 ) &&
                            (
                                <Col sm="12" md="12">
                                    <h6><span className="text-capitalize">{ t('documents') } :</span>
                                    </h6>

                                    <DataTable value={ entity.documents } editMode="row" dataKey="id">
                                        <Column field="name" header="Name" />
                                        <Column body={ actionBodyTemplate }
                                                headerStyle={ { width: '10%', minWidth: '8rem' } }
                                                bodyStyle={ { textAlign: 'center' } } />
                                    </DataTable>
                                </Col>
                            )
                        }
                    </Row>
                </>
            }
        </div>

    );
};

ContractDetail.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(ContractDetail));
