import React, { useEffect, useState } from "react";
import { request } from "../../../../utils/Request";
import { withTranslation } from "react-i18next";
import { dateToUtcDate, getColors, numberFormat, POINT_DECIMAL } from "../../../../utils/Helper";
import moment from "moment";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";

const RevenuePerClient = (props: any) => {
    const { t, filters } = props;
    const [clientRevenue, setClientRevenue] = useState<any>({
        grid: { top: 8, right: 8, bottom: 24, left: 36 },
        title : {
            // subtext: t('revenue per client'),
            // x:'center'
        },
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
            type: 'category',
            data: [],
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: [],
                type: 'bar',
                smooth: true,
            },
        ],
    });
    const [chartData, setChartData] = useState<any>();
    const [defaultOption, setDefaultOption] = useState<any>();

    const getClientRevenue = async () => {
        try {
            const response = await request({
                url: '/suppliers/dashboards/management/revenue/per/clients',
                params: {
                    from: (
                        filters.from
                            ? moment(filters.from).clone().tz(moment.tz.guess()).utc().format("YYYY-MM-DD 00:00:00")
                            : moment().startOf("year").startOf("day").tz(moment.tz.guess()).utc().format("YYYY-MM-DD 00:00:00")
                    ),
                    to: (
                        filters.to
                            ? moment(filters.to).clone().tz(moment.tz.guess()).utc().format("YYYY-MM-DD 23:59:59")
                            : moment().endOf("year").endOf("day").tz(moment.tz.guess()).utc().format("YYYY-MM-DD 23:59:59")
                    ),
                    from_raw: filters.from,
                    to_raw: filters.to,
                }
            });

            const { data } = response.data;
            setChartData(data);
            const colors = getColors();
            const option: any = {
                ...clientRevenue,
                xAxis: {
                    type: "category",
                    data: data.collection.map((item: any) => item.client),
                    axisLabel: {
                        // inside: true,
                        width: 30, //fixed number of pixels
                        overflow: 'truncate', // or 'break' to continue in a new line
                        interval: 0,
                        rotate: 30 //If the label names are too long you can manage this by rotating the label.
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    z: 10
                },
                dataZoom: [
                    {
                        type: 'inside'
                    }
                ],
                dataGroupId: '',
                grid: { containLabel: true },
                yAxis: {
                    type: "value",
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                    formatter: (params: any) => {
                        return ` <strong>${ params[0].data.groupId }</strong> <br />
                            ${ params[0].seriesName }: ${ numberFormat(params[0].value, POINT_DECIMAL, ',', '.') }
                        `;
                    },
                },
                series: [
                    {
                        data: data.collection.map((item: any) => ({
                            value: item.total,
                            groupId: item.client
                        })),
                        name: t('total'),
                        stack: "one",
                        type: "bar",
                        showBackground: true,
                        itemStyle: {
                            color: (params: any) => colors[params.dataIndex]
                        }
                    },
                ],
                universalTransition: {
                    enabled: true,
                    divideShape: 'clone'
                }
            };
            setDefaultOption(option);
            setClientRevenue(option);
        } catch (e) {

        }
    };

    const onClickOnBarChart = (params: any) => {
        if ( !params.data ) {
            return;
        }

        const subData = chartData.collection.find((data: any) => {
            return data.client === params.data.groupId;
        });

        if (!subData) {
            return;
        }

        setClientRevenue({
            xAxis: {
                data: subData.group.map((item: any) => item[0]),
                axisLabel: {
                    // inside: true,
                    width: 30, //fixed number of pixels
                    overflow: 'truncate', // or 'break' to continue in a new line
                    interval: 0,
                    rotate: 30 //If the label names are too long you can manage this by rotating the label.
                },
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false
                },
            },
            dataGroupId: '',
            grid: { containLabel: true },
            yAxis: {
                type: "value",
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow",
                },
                formatter: (params: any) => {
                    return ` <strong>${ params[0].axisValue }</strong> <br />
                        ${ numberFormat(params[0].data, POINT_DECIMAL, ',', '.') }
                    `;
                },
            },
            series: {
                type: 'line',
                smooth: true,
                dataGroupId: subData.client,
                data: subData.group.map((item: any) => item[1]),
                universalTransition: {
                    enabled: true,
                    divideShape: 'clone'
                }
            },
            graphic: [
                {
                    type: 'text',
                    left: 50,
                    top: 20,
                    style: {
                        text: 'Back',
                        fontSize: 18
                    },
                    onclick: () => setClientRevenue(defaultOption)
                }
            ]
        })
    };

    const onEvents = {
        click: onClickOnBarChart
    }

    useEffect(() => {
        async function init() {
            await getClientRevenue();
        }

        init();
    }, [filters]);

    return (
        <div>
            <ReactECharts
                option={ clientRevenue }
                onEvents={onEvents}
                style={ { height: '500px' } }
            />
        </div>
    );
};

export default withTranslation()(RevenuePerClient);
