import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Tooltip as RToolTip } from 'primereact/tooltip';

const Tooltip = ({ tooltip, onClick, children, ...rest }: any) => {
    const linkRef: any = useRef();
    const [ready, setReady] = useState(false);

    const handleOutsideClick = (event: any) => {
        setReady(false);
    };

    useEffect(() => {
        if ( linkRef.current ) {
            setReady(true);
        }

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [linkRef.current]);

    return (
        <Fragment>
            <span
                ref={ linkRef }
                className={ "cursor-pointer" }
                { ...rest }
            >
                { children }
            </span>

            { tooltip && ready && (
                <RToolTip
                    target={ linkRef.current }
                >
                    { tooltip }
                </RToolTip>
            ) }
        </Fragment>
    );
};

export default Tooltip;
