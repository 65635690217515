import React from 'react';
import { ErrorMessage } from "formik";
import { get, has, isEmpty } from "lodash";
import { MultiSelect } from "primereact/multiselect";

interface IMultiSelectFieldProps {
    label?: string,
    withoutLabel?: boolean,
    field: any,
    form?: any,
    id?: any,
    options?: any[],
    optionLabel?: string,
    optionValue?: string,
    display?: string,
    formGroupClass?: string,
    isRequired?: any,
    inputGroupIconClass?: string,
    placeholder?: string,
    showClear?: boolean,
    panelClassName?: boolean,
    onChange?: (e: { originalEvent: Event, value: any }) => void,
    formText?: any,
    autoFocus?: any,
}

export class InputSelectField extends React.Component<IMultiSelectFieldProps, {}> {
    componentRef: any;

    constructor(props: any) {
        super(props);

        this.componentRef = React.createRef();
    }

    componentDidMount() {
        if ( this.props.autoFocus ) {
            this.componentRef.current.focus();
        }
    }

    render() {
        const {
            field,
            form,
            withoutLabel,
            options,
            display,
            optionLabel,
            optionValue,
            onChange,
            formText,
            formGroupClass,
            inputGroupIconClass,
            isRequired,
            placeholder,
            showClear,
            ...props
        } = this.props;
        const { errors } = form;

        return (
            <div className="field">
                <div className={ `field ${ formGroupClass }` }>
                    { !withoutLabel
                        && <label htmlFor={ field.id ?? field.name }>
                            { props.label }
                            { isRequired && <strong style={ { color: 'var(--red-500)' } }> *</strong> }
                        </label>
                    }

                    {
                        inputGroupIconClass &&
                        <span className="p-input-icon-left">
                            <i className={ `${ inputGroupIconClass }` } />
                            <MultiSelect
                                { ...props }
                                { ...field }
                                id={ props.id ?? field.name }
                                value={ get(field, ['value']) || null }
                                options={ options }
                                onChange={ (e: any) => {
                                    form.setFieldValue(field.name, e.value);
                                    // this.componentRef.current.focus();
                                } }
                                ref={ this.componentRef }
                                showClear={showClear ?? true}
                                optionValue={ optionValue ?? "id" }
                                optionLabel={ optionLabel ?? "label" }
                                display={ display ?? "comma" }
                                placeholder={ placeholder ?? "" }
                                className={ `w-full p-inputtext-sm ${ has(errors, field.name) ? 'p-invalid' : '' }` }
                                virtualScrollerOptions={ { itemSize: 50 } }
                                // @ts-ignore
                                panelClassName={props.panelClassName ?? ""}
                                pt={ { item: { tabIndex: 0 } } }
                                filterPlaceholder={"focus: ctrl + \\"}
                                autoOptionFocus={true}
                                autoFocus={props.autoFocus ?? false}
                                filterInputAutoFocus
                                filter
                                onKeyDown={(e: any) => {
                                    if(e.keyCode == 27) {
                                        this.componentRef.current.hide();
                                        this.componentRef.current.focus();
                                    }

                                    if(e.ctrlKey && e.keyCode === 220) {
                                        const firstMultiselectItem: any = document.querySelector("body .p-multiselect-panel .p-multiselect-filter");
                                        if(firstMultiselectItem) {
                                            firstMultiselectItem.focus();
                                        }
                                    }

                                    if ( e.keyCode == 40 ) {
                                        if ( e.target.classList.contains('p-multiselect-filter') ) {
                                            const firstMultiselectItem: any = document.querySelector("body .p-multiselect-panel .p-multiselect-items li.p-multiselect-item .p-checkbox-input");
                                            if ( firstMultiselectItem ) {
                                                firstMultiselectItem.focus();
                                            }
                                        }

                                        if ( e.target.classList.contains('p-checkbox-input') ) {
                                            const elementNext = e.target.nextElementSibling;
                                            if ( elementNext ) {
                                                elementNext.focus();
                                            }
                                        }
                                    }
                                }}
                            />
                            { formText && <small>{ formText }</small> }
                            <ErrorMessage name={ field.name } component="small" className="p-error" />
                        </span>
                    }

                    { !inputGroupIconClass &&
                        <div>
                            <MultiSelect
                                { ...props }
                                { ...field }
                                id={ props.id ?? field.name }
                                value={ get(field, ['value']) || null }
                                options={ options }
                                onChange={ (e: any) => {
                                    form.setFieldValue(field.name, e.value);
                                    // this.componentRef.current.focus();
                                } }
                                ref={ this.componentRef }
                                showClear={showClear ?? true}
                                optionValue={ optionValue ?? "id" }
                                optionLabel={ optionLabel ?? "label" }
                                display={ display ?? "comma" }
                                placeholder={ placeholder ?? "" }
                                className={ `w-full p-inputtext-sm ${ has(errors, field.name) ? 'p-invalid' : '' }` }
                                virtualScrollerOptions={ { itemSize: 50 } }
                                // @ts-ignore
                                panelClassName={props.panelClassName ?? ""}
                                pt={ { item: { tabIndex: 0 } } }
                                filterPlaceholder={"focus: ctrl + \\"}
                                autoOptionFocus={true}
                                autoFocus={props.autoFocus ?? false}
                                filterInputAutoFocus
                                filter
                                onKeyDown={(e: any) => {
                                    if ( e.keyCode == 27 ) {
                                        this.componentRef.current.hide();
                                        this.componentRef.current.focus();
                                    }

                                    if ( e.ctrlKey && e.keyCode === 220 ) {
                                        const firstMultiselectItem: any = document.querySelector("body .p-multiselect-panel .p-multiselect-filter");
                                        if(firstMultiselectItem) {
                                            firstMultiselectItem.focus();
                                        }
                                    }

                                    if ( e.keyCode == 40 ) {
                                        if ( e.target.classList.contains('p-multiselect-filter') ) {
                                            const firstMultiselectItem: any = document.querySelector("body .p-multiselect-panel .p-multiselect-items li.p-multiselect-item .p-checkbox-input");
                                            if ( firstMultiselectItem ) {
                                                firstMultiselectItem.focus();
                                            }
                                        }

                                        if (
                                            e.target.classList.contains('p-checkbox-input')
                                            && !isEmpty(e.target.parentElement?.parentElement?.parentElement?.nextElementSibling)
                                        ) {
                                            e.target.parentElement.parentElement.parentElement.classList.remove('p-focus');
                                            e.target.parentElement.parentElement.parentElement.nextElementSibling.focus();
                                            e.target.parentElement?.parentElement?.parentElement?.nextElementSibling.classList.add('p-focus');
                                        }
                                    }

                                    if ( e.keyCode == 38 ) {
                                        if (
                                            e.target.classList.contains('p-checkbox-input')
                                            && !isEmpty(e.target.parentElement?.parentElement?.parentElement?.previousElementSibling)
                                        ) {
                                            e.target.parentElement.parentElement.parentElement.classList.remove('p-focus');
                                            e.target.parentElement?.parentElement?.parentElement?.previousElementSibling.focus();
                                            e.target.parentElement?.parentElement?.parentElement?.previousElementSibling.classList.add('p-focus');
                                        }
                                    }
                                }}
                            />
                            { formText && <small>{ formText }</small> }
                            <ErrorMessage name={ field.name } component="small"
                                          className="p-error" />
                        </div>
                    }
                </div>
            </div>
        );
    }
}
