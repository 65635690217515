import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useCallback, useRef, useState } from "react";
import Tooltip from "../../../../components/Common/Display/Tooltip";
import StateLessGrid from "../../../../components/Shared/StateLessGrid";
import { Button } from "primereact/button";
import { Col, Row } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../../../components/Shared/InputField";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { includes, isEmpty } from "lodash";
import Location from "../../../../components/Common/Display/Location";
import { find, uniq } from "lodash";
import SpotLoadStatus from "../../../Suppliers/spotLoads/partials/SpotLoadStatus";
import AlpSpotLoadStatus from "../../../Suppliers/alpSpotLoads/partials/AlpSpotLoadStatus";
import { Sidebar } from "primereact/sidebar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import qs from "qs";

const LoadList = (props: any) => {
    const { t, moduleState, toastify } = props;
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [operation, setOperation] = useState<any>(searchParams.get('operation') ?? null);
    const [initSearchFormState, setInitSearchFormState] = useState<any>({ search: null });
    const [entity, setEntity] = useState<any>(null);
    const [isShowPanelActive, setShowPanelActive] = useState<boolean>(false);
    const [activeStatus, setActiveStatus] = useState<any>(null);

    const tableRef: any = useRef();

    const columns = [
        {
            field: 'type',
            title: t('type'),
            sorting: false,
            render: ({ type, load_type }: any) => ( <>
                <span>{ type }</span>
                { type === "AlpInboundLoad" && <div>{ load_type }</div> }
                { type === "AlpSpotLoad" &&
                <>
                    <div className={ "cursor-pointer" }>
                        { ( load_type === 'compound' ) && t("deliver to: compound") }
                        { ( load_type === 'dealer' ) && t("deliver to: dealer") }
                    </div>
                </>
                }
            </> )
        },
        {
            field: 'load_number',
            title: t('load number'),
            sorting: true,
        },
        {
            field: 'truck_plate_number',
            title: t('transport vehicle'),
            sorting: false,
            render: ({ truck_plate_number, trailer_plate_number }: any) => {
                return (
                    <>
                        <span>{ !isEmpty(truck_plate_number) ? truck_plate_number : "" }</span>
                        <span>{ !isEmpty(trailer_plate_number) ? ` | ${ trailer_plate_number }` : "" }</span>
                    </>
                );
            }
        },
        {
            field: 'client_name',
            title: t('client'),
            sorting: false,
            render: (_row: any) => {
                if ( _row.type === "SpotLoad" ) {
                    return uniq(_row.orders.map((item: any) => item.client_name)).map((item: any, key: number) => <div
                        key={ key }>{ item }</div>);
                }

                return "Emil Frey";
            },
        },
        {
            field: 'location',
            title: t('location'),
            sorting: false,
            render: ({ orders }: any) => {
                const allLocations: any = [];
                const keys = orders.map(({ from_location, to_location }: any) => {
                    allLocations.push(from_location);
                    allLocations.push(to_location);

                    return `${ from_location.id }*${ to_location.id }`;
                });

                const uniqKeys = uniq(keys);
                return uniqKeys.map((item: any, index: number) => {
                    const locations = item.split('*');
                    return (
                        <div key={ index }>
                            <Location item={ find(allLocations, { id: locations[0] }) } titled={ 1 } />
                            <Location item={ find(allLocations, { id: locations[1] }) } titled={ 1 } />
                        </div>
                    );
                });
            }
        },
        {
            field: 'orders',
            title: t('orders'),
            sorting: false,
            render: ({ orders }: any) => {
                return orders.map((item: any) => item.order.vin_number).map((item: any, index: any) => <div
                    key={ index }>{ item }</div>);
            }
        },
        {
            field: 'status', title: t('status'),
            render: (_row: any) => {
                if ( _row.type === "SpotLoad" ) {
                    return (
                        <small
                            className={ `
                                text-uppercase
                                fw-bolder
                                 ${ _row.status === 'delivered' ? ' text-success ' : '' }
                                 ${ _row.status === 'announced' ? ' text-info ' : '' }
                                 ${ _row.status === 'in-transit' ? ' text-primary ' : '' }
                            ` }>
                            {
                                _row.status !== "delivered"
                                &&
                                <SpotLoadStatus status={ _row.status } textable={ 1 } />
                            }
                            { _row.status === "delivered" && _row.status }
                        </small>
                    );
                }

                if ( _row.type === "AlpSpotLoad" ) {
                    return (
                        <small className={ "text-uppercase" }>
                            { <AlpSpotLoadStatus status={ _row.status } textable={ 1 } /> }
                        </small>
                    );
                }

                return ( <span className={ "text-uppercase" }>{ _row.status }</span> );
            }
        },
        {
            field: 'action',
            title: t('action'),
            sorting: false,
            align: 'right',
            render: (_row: any) => {
                return (
                    <>
                        <Tooltip
                            tooltip={ t('detail of item') }>
                            <a onClick={ () => onDetailClick(_row, true) } className={ 'ms-2' }>
                                <i className="fas fa-eye" />
                            </a>
                        </Tooltip>
                    </>
                );
            }
        },
    ];

    const onOperationClick = (operationToBePerformed: any = 'add', id: any = null) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('operation', operationToBePerformed);
        searchParams.set('operationId', id);

        if ( isEmpty(operationToBePerformed) ) {
            searchParams.delete('operation');
        }

        if ( isEmpty(id) ) {
            searchParams.delete('operationId');
        }

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        }, { replace: true });
        setOperation(operationToBePerformed);
    };

    const onCloseOperation = () => {
        onOperationClick(null, null);
        onReset();
    };

    const onReset = () => {
        props.setQuery({
            ...moduleState,
            filters: [],
            page: 1,
            search: null
        });
    };

    const onSearch = ({ search }: any) => {
        props.setQuery({
            ...moduleState,
            search: search
        });
    };

    const onClose = () => {
        onOperationClick(null, null);
        setShowPanelActive(false);
        onReset();
    };

    const onDetailClick = useCallback((data: any, state: any = true) => {
        setShowPanelActive(state);
        setEntity(data);
    }, []);

    const orderTemplate = (rowData: any, field: any) => {
        return (
            <span>
                { rowData.order?.[field] }
            </span>
        );
    };

    const onStatusSelection = (status: any) => {
        setActiveStatus(status);
        props.setQuery({
            ...moduleState,
            filters: qs.parse({ 'status': status }),
            page: 1,
            search: null
        });
        return false;
    };

    return (
        <div className={ "block-viewer" }>
            <div className="block-section">
                <div className="block-header">
                    <span className="block-title">
                        <h5 className={ "mb-0" }>{ t('loads') }</h5>
                    </span>
                    <div className="block-actions">
                        {
                            operation == 'detail' &&
                            <div>
                                <Button type={ "button" }
                                        tooltip={ t("operation cancel") } className={ "ms-2" }
                                        size={ "small" } severity={ "warning" }
                                        tooltipOptions={ { position: 'top' } } onClick={ onCloseOperation }>
                                    <i className="pi pi-arrow-left me-2" /> { t("cancel") }

                                </Button>
                            </div>
                        }
                    </div>
                </div>
                <div className="block-content">
                    {
                        operation !== 'detail' &&
                        <Row className={ "p-2" }>
                            <Col
                              sm={ 12 } md={ 6 } className={ "mb-2" }>
                                <Formik
                                  enableReinitialize={ true }
                                  initialValues={ initSearchFormState }
                                  onSubmit={ onSearch }
                                >
                                    { (props: FormikProps<any>) => {
                                        return (
                                            <Form onSubmit={ props.handleSubmit }>
                                                <Col sm={ 12 } md={ 8 }>
                                                    <Field component={ InputField } withoutLabel={ true }
                                                           inputGroupIconClass={ 'pi pi-search' }
                                                           name="search"
                                                           formGroupClass={ "mb-0" }
                                                           placeholder={ t("search") } />
                                                </Col>
                                            </Form>
                                        );
                                    } }
                                </Formik>
                            </Col>

                            <Col
                              sm={ 12 } md={ 6 } className={ "text-right mb-2" }>
                                <span className="p-buttonset">
                                    <Button onClick={ () => onStatusSelection('in-transit') } size={ "small" } outlined
                                            disabled={ activeStatus == 'in-transit' }
                                            tooltip={ t('in-transit') }
                                            tooltipOptions={ { position: 'top' } }>
                                        { t("in transit") }
                                    </Button>
                                    <Button onClick={ () => onStatusSelection('delivered') } size={ "small" } outlined
                                            disabled={ activeStatus == 'delivered' }
                                            tooltip={ t('delivered') }
                                            tooltipOptions={ { position: 'top' } }>
                                        { t("delivered") }
                                    </Button>
                                    <Button onClick={ onReset } icon="pi pi-refresh" size={ "small" } outlined
                                            tooltip={ t('reset') }
                                            tooltipOptions={ { position: 'top' } } />
                                </span>
                            </Col>

                            <Col sm={ 12 }>
                                <StateLessGrid
                                  size={ "normal" }
                                  url={ '/driver/loads?1=1' }
                                  name={ "loadList" }
                                  columns={ columns }
                                  moduleState={ moduleState }
                                  setQuery={ props.setQuery }
                                  ref={ tableRef }
                                />
                            </Col>
                        </Row>
                    }

                    <Sidebar
                        header={
                            <div className="bg-body-tertiary card-header">
                                <h5 className={ "card-title mt-0" }>{ t('load') + ' ' + t('detail') }</h5>
                            </div>
                        }
                        visible={ isShowPanelActive } position="right" onHide={ onClose } blockScroll={ true }
                        style={ { width: '800px' } }
                    >
                        <div style={ {
                            height: '3px',
                            background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                        } }></div>

                        {
                            entity &&
                            <>
                                <div className={ 'my-4' }>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span className="text-capitalize">{ t('type') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end">
                                                { entity.type }
                                                { entity.type === "AlpInboundLoad" && <div>{ entity.load_type }</div> }
                                                { entity.type === "AlpSpotLoad" &&
                                                <>
                                                    <div className={ "cursor-pointer" }>
                                                        { ( entity.load_type === 'compound' ) && t("deliver to: compound") }
                                                        { ( entity.load_type === 'dealer' ) && t("deliver to: dealer") }
                                                    </div>
                                                </>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span className="text-capitalize">{ t('load number') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end">{ entity.load_number }</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span className="text-capitalize">{ t('transport vehicle') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end">
                                                <span>{ !isEmpty(entity.truck_plate_number) ? entity.truck_plate_number : "" }</span>
                                                <span>{ !isEmpty(entity.trailer_plate_number) ? ` | ${ entity.trailer_plate_number }` : "" }</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" lg="6" sm="12">
                                            <h6>
                                                <span className="text-capitalize">{ t('status') }</span>
                                            </h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="12">
                                            <div className="text-end">
                                                {
                                                    ( entity.type === "SpotLoad" &&
                                                      <small
                                                        className={ `text-uppercase fw-bolder
                                                         ${ entity.status === 'delivered' ? ' text-success ' : '' }
                                                         ${ entity.status === 'announced' ? ' text-info ' : '' }
                                                         ${ entity.status === 'in-transit' ? ' text-primary ' : '' }
                                                    ` }>
                                                          {
                                                              entity.status !== "delivered"
                                                              &&
                                                              <Tooltip
                                                                tooltip={ t("click: update status") }
                                                                position={ "left" }>
                                                                  <SpotLoadStatus status={ entity.status }
                                                                                  textable={ 1 } />
                                                              </Tooltip>
                                                          }
                                                          { entity.status === "delivered" && entity.status }
                                                      </small> )
                                                }

                                                {
                                                    ( entity.type === "AlpSpotLoad" &&
                                                      <small className={ "text-uppercase" }>
                                                          <AlpSpotLoadStatus status={ entity.status } textable={ 1 } />
                                                      </small>
                                                    )
                                                }

                                                {
                                                    ( !includes(['SpotLoad', 'AlpSpotLoad'], entity.type) &&
                                                      <small className={ "text-uppercase" }>{ entity.status }</small>
                                                    )
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" lg="12" sm="12">
                                            <h4 className={ 'mt-3' }>{ t('orders') }</h4>
                                        </Col>
                                        <Col md="12" lg="12" sm="12">
                                            <div className={ 'table-responsive' }>
                                                {
                                                    entity.orders &&
                                                    <DataTable value={ entity.orders } dataKey="id">
                                                        <Column field="vin_number" header={ t('vin number') }
                                                                body={ (rowData) => orderTemplate(rowData, 'vin_number') } />
                                                        <Column field="order_code" header={ t('order code') }
                                                                body={ (rowData) => orderTemplate(rowData, 'order_code') } />
                                                        <Column field="brand_title" header={ t('brand') }
                                                                body={ (rowData) => orderTemplate(rowData, 'brand_title') } />
                                                        <Column field="model_title" header={ t('model') }
                                                                body={ (rowData) => orderTemplate(rowData, 'model_title') } />
                                                        <Column field="from_location" header={ t('from location') }
                                                                body={ (rowData) => {
                                                                    return (
                                                                        rowData.from_location &&
                                                                        <Location key={ rowData.from_location.id }
                                                                                  item={ rowData.from_location }
                                                                                  titled={ 1 } />
                                                                    );
                                                                } } />
                                                        <Column field="to_location" header={ t('to location') }
                                                                body={ (rowData) => {
                                                                    return (
                                                                        rowData.to_location &&
                                                                        <Location key={ rowData.to_location.id }
                                                                                  item={ rowData.to_location }
                                                                                  titled={ 1 } />
                                                                    );
                                                                } } />
                                                        <Column field="client_name" header={ t('client') } />
                                                    </DataTable>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        }
                    </Sidebar>

                </div>
            </div>
        </div>
    );
};

LoadList.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(LoadList));
