import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Badge } from "primereact/badge";

const InvoiceStatus = (props: any) => {
    const { status, paid, t } = props;

    if ( status == 'final' ) {
        return (
            <div>
                <Badge value={t('final')} severity={"warning"}/>
                <br />
                { paid ? (
                    <Badge value={t('paid')} severity={"success"}/>
                ) : (
                    <></>
                ) }
            </div>
        );
    }

    if ( status === "draft" ) {
        return <Badge value={t('draft')} severity={undefined}/>
    }

    if ( status === "archived" ) {
        return <Badge value={t('archived')} severity={"info"}/>
    }

    return <></>;
};

InvoiceStatus.propTypes =
    {
        paid: PropTypes.any,
        t: PropTypes.any,
        status: PropTypes.any,
    }
;

export default withTranslation()(InvoiceStatus);
