import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useRef, useState } from "react";
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import StateLessGrid from "../../../../components/Shared/StateLessGrid";
import { Button } from "primereact/button";
import { Col, Row } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../../../components/Shared/InputField";
import { find, get, includes, isEmpty, map, some, split } from "lodash";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
    ColumnLoadNumber, ColumnRenderDeliveredAt, ColumnRenderForBrandModel,
    ColumnRenderForClient, ColumnRenderForDriver, ColumnRenderForInvoiceNumber,
    ColumnRenderForLegalEntity, ColumnRenderForLocation, ColumnRenderForTransportVehicle,
    ColumnRenderForVin, ColumnRenderInTransitAt, ColumnRenderInvoicedAt,
    ColumnRenderStatus, getColor
} from "./Column";
import { customSplitter, getLastUpdate, utcDateToLocalDate } from "../../../../utils/Helper";
import SpotLoadStatusUpdate from "./SpotLoadStatusUpdate";
import Tooltip from "../../../../components/Common/Display/Tooltip";
import { request } from "../../../../utils/Request";
import HasAccess from "../../../../utils/HasAccess";
import AlpSpotLoadAccept from "./AlpSpotLoadAccept";
import AlpSpotLoadUpdate from "./AlpSpotLoadUpdate";
import AlpSpotLoadReject from "./AlpSpotLoadReject";
import AlpSpotLoadReset from "./AlpSpotLoadReset";
import AlpSpotLoadInTransit from "./AlpSpotLoadInTransit";
import AlpSpotLoadUnloadRequest from "./AlpSpotLoadUnloadRequest";
import SpotLoadFilter from "../../archived/spotLoads/partials/SpotLoadFilter";
import { css, cx } from '@emotion/css';
import AlpSpotLoadUpdatePrice from "../../alpSpotLoads/partials/AlpSpotLoadUpdatePrice";
import { ButtonGroup } from "primereact/buttongroup";
import LoadExcel from "./LoadExcel";
import ShowInvoiceableLoad from "./ShowInvoiceableLoad";
import AllInvoiceableList from "./AllInvoiceableList";
import LoadPrePayment from "./LoadPrePayment";

const LoadList = (props: any) => {
    const { t, moduleState, dropdowns, toastify } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const [spotLoadItemToBeUpdate, setSpotLoadItemToBeUpdate] = useState<any>(null);
    const [filterPanel, setFilterPanel] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const [operation, setOperation] = useState<any>(searchParams.get('operation') ?? null);
    const [operationId, setOperationId] = useState<any>(searchParams.get('operationId') ?? null);
    const [initSearchFormState] = useState<any>({ search: null });
    const [total, setTotal] = useState(0);
    const tableRef: any = useRef();
    const [excelPanel, setExcelPanel] = useState<boolean>(false);
    const [invoiceableLoad, setInvoiceableLoad] = useState<string|null>(null);
    const [bulkInvoiceVisibility, setBulkInvoiceVisibility] = useState(false);
    const [paymentVisibility, setPaymentVisibility] = useState({});

    const onDelete = async (payloads: any): Promise<void> => {
        try {
            await request({
                url: `suppliers/spot/loads/${ payloads.id }`,
                method: 'DELETE',
                data: {
                    _method: 'DELETE',
                    ...payloads
                }
            });

            toastify(t("record deleted", { item: t('spot load') }), "error");

            if ( tableRef.current ) {
                tableRef.current.onRefresh();
            }
        } catch (e: any) {
            toastify(t("server error"), "error");
        }
    };

    const onRaiseInvoiceClick = async (payloads: any): Promise<void> => {
        setBulkInvoiceVisibility(false);
        setInvoiceableLoad(payloads);
    };

    const onInvoicePaymentClick = async (payloads: any): Promise<void> => {
        setPaymentVisibility({
            id: payloads.id,
            type: payloads.type,
            price_type: payloads.price_type,
            load_number: payloads.load_number,
        });
    };

    const verifyDeliveredOrInTransitStatus = (inputString: any, statuses: string[]) => {
        const parts = split(inputString, '||');

        return some(parts, (part: string) => {
            const [, status] = split(part, '___');
            return statuses.includes(status);
        });
    };

    const SpotLoadActionButton = (actionProps: any) => {
        const _row: any = actionProps.row;

        return (
            <>
                <HasAccess hasAnyAccess={ ['spotloadsupdate'] }>
                    <Tooltip tooltip={ t('edit item') } position={ "top" }>
                        <a
                            className={ "cursor-pointer ms-1 text-yellow-500" }
                            onClick={ () => {
                                props.router.navigate(`/spot/loads?back_to=loads&operation=edit&operationId=${ _row.id }`);
                            } }>
                            <i className="fas fa-edit" />
                        </a>
                    </Tooltip>
                </HasAccess>

                {
                    ( _row.has_invoiced_items <= 0 && _row.has_delivered_items <= 0 )
                    &&
                    <HasAccess hasAnyAccess={ ['spotloadsdestroy'] }>
                        <Tooltip tooltip={ t('delete item') } position={"top"}>
                            <a
                              title={ t('delete item') }
                              className={ "cursor-pointer ms-1 text-red-500" }
                              onClick={ (event: any) => confirmPopup({
                                  target: event.currentTarget,
                                  message: t('do you want to delete this record?'),
                                  icon: 'pi pi-info-circle',
                                  // @ts-ignorer
                                  defaultFocus: 'reject',
                                  acceptClassName: 'p-button-danger',
                                  accept: () => onDelete(_row),
                                  reject: () => {
                                  },
                              }) }>
                                <i className="fas fa-trash-alt" />
                            </a>
                        </Tooltip>
                    </HasAccess>
                }

                <Tooltip tooltip={ t('detail of item') } position={"top"}>
                    <a
                        className={ "cursor-pointer ms-1" }
                        onClick={ () => {
                            props.router.navigate(`/spot/loads?back_to=loads&operation=detail&operationId=${ _row.id }`);
                        } }>
                        <i className="fas fa-eye" />
                    </a>
                </Tooltip>

                  <HasAccess hasAnyAccess={ ['invoicesstore'] }>
                      {
                          verifyDeliveredOrInTransitStatus(_row.item_status, ['in-transit', 'delivered', 'archived']) &&
                          <Tooltip tooltip={ t('raise invoice') } position={ "top" }>
                              <a
                                className={ "cursor-pointer ms-1 text-red-300" }
                                onClick={ () => onRaiseInvoiceClick(_row) }>
                                  <i className="fas fa-exclamation-triangle" />
                              </a>
                          </Tooltip>
                      }

                      <Tooltip tooltip={ t('add payments') } position={ "top" }>
                          <a
                            className={ "cursor-pointer ms-1" }
                            onClick={ () => onInvoicePaymentClick(_row) }>
                              <i className="fas fa-credit-card" />
                          </a>
                      </Tooltip>
                  </HasAccess>
            </>
        );
    };

    // ALP SPOT LOAD States and Methods
    const [resetAlpSpotLoadId, setResetAlpSpotLoadId] = useState(null);
    const [rejectAlpSpotLoadId, setRejectAlpSpotLoadId] = useState(null);
    const [acceptAlpSpotLoadId, setAcceptAlpSpotLoadId] = useState(null);
    const [editAlpSpotLoadId, setEditAlpSpotLoadId] = useState(null);
    const [inTransitAlpSpotLoadId, setInTransitAlpSpotLoadId] = useState(null);
    const [unloadRequestAlpSpotLoadId, setUnloadRequestAlpSpotLoadId] = useState(null);
    const [loadId, setLoadId] = useState<any>(null);
    const [syncOn, setSyncOn] = useState(false);

    const onResetAlpSpotLoad = (id: any) => setResetAlpSpotLoadId(id);
    const onRejectAlpSpotLoad = (id: any) => setRejectAlpSpotLoadId(id);
    const onAcceptAlpSpotLoad = (id: any) => setAcceptAlpSpotLoadId(id);
    const onEditAlpSpotLoad = (id: any) => setEditAlpSpotLoadId(id);
    const onInTransitAlpSpotLoad = (id: any) => setInTransitAlpSpotLoadId(id);
    const onUnloadRequestAlpSpotLoad = (id: any) => setUnloadRequestAlpSpotLoadId(id);

    const onAlpSpotLoadSyncList = async () => {
        setSyncOn(true);
        try {
            const response = await request({
                url: `/suppliers/alp/spot/loads/sync/list`,
                method: 'POST',
            });

            refreshTable();
        } catch (e: any) {
            if ( e.status === 422 ) {
                toastify(e.data.errors.driver_id.join(", "), "error");
            } else if ( e.statusText ) {
                toastify(e.statusText, "error");
            }
        } finally {
            setSyncOn(false);
        }
    };

    const onAlpSpotLoadSync = async (id: string) => {
        try {
            const response = await request({
                url: `/suppliers/alp/spot/loads/${ id }/sync`,
                method: 'POST',
            });

            toastify(t("record updated", { item: t('alp spot load') + ' ' + t('spot load') }), "info");
            refreshTable();
        } catch (e: any) {
            if ( e.status === 422 ) {
                toastify(e.data.errors.driver_id.join(", "), "error");
            } else if ( e.statusText ) {
                toastify(e.statusText, "error");
            }
        }
    };

    const AlpSpotLoadActionButton = (actionProps: any) => {
        const _row: any = actionProps.row;

        return (
            <>
                <HasAccess hasAnyAccess={ ['alpspotloadsupdate'] }>
                    <Tooltip tooltip={ t('sync from alp') }>
                        <a onClick={ () => onAlpSpotLoadSync(_row.id) }
                           className={ "cursor-pointer ms-1" }>
                            <i className="fas fa-sync" />
                        </a>
                    </Tooltip>

                    {
                        includes(['accepted'], _row.status) && isEmpty(_row.reset_at)
                        && /*@ts-ignore*/
                        <Tooltip tooltip={ t('reset') }>
                            <a onClick={ () => onResetAlpSpotLoad(_row.id) }
                               className={ "cursor-pointer ms-1 text-primary-500" }>
                                <i className="fas fa-redo-alt" />
                            </a>
                        </Tooltip>
                    }

                    {
                        includes(['announced'], _row.status)
                        &&
                        /*@ts-ignore*/
                        <Tooltip tooltip={ t('accept') }>
                            <a onClick={ () => onAcceptAlpSpotLoad(_row.id) }
                               className={ "cursor-pointer ms-1 text-blue-500" }>
                                <i className="fas fa-check" />
                            </a>
                        </Tooltip>
                    }

                    {
                        includes(['announced'], _row.status)
                        && /*@ts-ignore*/
                        <Tooltip tooltip={ t('reject') }>
                            <a onClick={ () => onRejectAlpSpotLoad(_row.id) }
                               className={ "cursor-pointer ms-1 text-red-500" }>
                                <i className="fas fa-times" />
                            </a>
                        </Tooltip>
                    }

                    {
                        includes(['accepted'], _row.status)
                        && ( isEmpty(_row.edited_at) && isEmpty(_row.reset_at) )
                        &&
                        /*@ts-ignore*/
                        <Tooltip tooltip={ t('edit item') }>
                            <a onClick={ () => onEditAlpSpotLoad(_row.id) }
                               className={ "cursor-pointer ms-1 text-yellow-500" }>
                                <i className="fas fa-edit" />
                            </a>
                        </Tooltip>
                    }

                    {
                        includes(['accepted', 'updated'], _row.status)
                        &&
                        /*@ts-ignore*/
                        <Tooltip tooltip={ t('in transit') }>
                            <a onClick={ () => onInTransitAlpSpotLoad(_row.id) }
                               className={ "cursor-pointer ms-1 text-green-500" }>
                                <i className="fas fa-truck-moving" />
                            </a>
                        </Tooltip>
                    }

                    {
                        includes(['in-transit'], _row.status)
                        && ( (
                                !isEmpty(_row.from_supplier_compound) && !isEmpty(_row.gate_out_confirmed_at)
                            ) || (
                                isEmpty(_row.from_supplier_compound) && isEmpty(_row.gate_out_confirmed_at)
                            )
                        )
                        &&
                        /*@ts-ignore*/
                        <Tooltip tooltip={ t('unload request') }>
                            <a onClick={ () => onUnloadRequestAlpSpotLoad(_row.id) }
                               className={ "cursor-pointer text-green-500 ms-1" }>
                                <i className="fas fa-truck-loading" />
                            </a>
                        </Tooltip>
                    }
                    {
                        ( _row.type == 'AlpSpotLoad' ) &&
                        <Tooltip tooltip={ t('update price') } position={ "left" }>
                            <a
                              className={ "cursor-pointer ms-1 text-primary-500" }
                              onClick={ () => setLoadId(_row.id) }>
                                <i className="fas fa-dollar-sign" />
                            </a>
                        </Tooltip>
                    }

                    <Tooltip tooltip={ t('detail of item') }>
                        <a
                            className={ "cursor-pointer ms-1" }
                            onClick={ () => {
                                props.router.navigate(`/archived/alp/spot/loads?back_to=loads&operation=detail&operationId=${ _row.id }`);
                            } }>
                            <i className="fas fa-eye" />
                        </a>
                    </Tooltip>

                    {
                        includes(['accepted', 'confirmed', 'in-transit', 'unload-requested', 'unloaded', 'delivered', 'archived'], _row.item_status)
                        &&
                        <HasAccess hasAnyAccess={ ['invoicesstore'] }>
                            <Tooltip tooltip={ t('raise invoice') } position={ "top" }>
                                <a
                                    className={ "cursor-pointer ms-1 text-red-300" }
                                    onClick={ () => onRaiseInvoiceClick(_row) }>
                                    <i className="fas fa-exclamation-triangle" />
                                </a>
                            </Tooltip>
                        </HasAccess>
                    }

                  <HasAccess hasAnyAccess={ ['invoicepaymentsstore'] }>
                      <Tooltip tooltip={ t('add payments') } position={ "top" }>
                          <a
                            className={ "cursor-pointer ms-1" }
                            onClick={ () => onInvoicePaymentClick(_row) }>
                              <i className="fas fa-credit-card" />
                          </a>
                      </Tooltip>
                  </HasAccess>
                </HasAccess>
            </>
        );
    };

    const columns = [
        {
            field: 'load_number',
            title: t("load number"),
            sorting: true,
            sortField: "load_number",
            render: (row: any) => <ColumnLoadNumber row={ row } t={ t } />
        },
        {
            field: 'driver_name',
            title: t("driver"),
            sortField: "driver_name",
            sorting: true,
            render: (row: any) => <ColumnRenderForDriver row={ row } t={ t } />
        },
        {
            field: 'cmr',
            title: t("cmr"),
            sortField: "cmr",
            sorting: true,
        },
        {
            field: 'legal_entity',
            title: t("legal entity"),
            render: (row: any) => <ColumnRenderForLegalEntity row={ row } t={ t } dropdowns={ dropdowns }/>
        },
        {
            field: 'ldg_nr',
            title: t("ldg nr"),
            sorting: false,
            render: (row: any) => <>
                <div>
                    { customSplitter(( get(row, ['ldg_nr']) || "" )
                    .split("||"))
                    .map((i: any) => i.value).join(', ') }
                </div>
            </>
        },
        {
            field: 'client',
            title: t('client'),
            sorting: false,
            render: (row: any) => <ColumnRenderForClient row={ row } t={ t } dropdowns={ dropdowns }/>
        },
        {
            field: 'brand_model',
            title: t('model(s)'),
            sorting: false,
            render: (row: any) => <ColumnRenderForBrandModel row={ row } t={ t } dropdowns={ dropdowns } />
        },
        {
            field: 'transport_vehicle',
            title: t('transport vehicle'),
            sorting: false,
            render: (row: any) => <ColumnRenderForTransportVehicle row={ row } t={ t } />
        },
        {
            field: 'locations',
            title: t('location'),
            sorting: false,
            render: (row: any) => <ColumnRenderForLocation row={ row } t={ t } dropdowns={ dropdowns } />
        },
        {
            field: 'order_id',
            title: t('vin(s)'),
            sorting: false,
            render: (row: any) => <ColumnRenderForVin row={ row } t={ t } />
        },
        {
            field: 'qty',
            title: t('qty'),
            sorting: false,
        },
        {
            field: 'status',
            title: t('status'),
            sorting: false,
            render: (row: any) => {
                return <>
                    <ColumnRenderStatus
                        row={ row }
                        t={ t }
                        onClickSpotLoadStatusUpdate={ onClickSpotLoadStatusUpdate } />
                    <div>{ !isEmpty(row.load_invoiced_at) ? t('invoiced') : '' }</div>
                </>;
            }
        },
        {
            field: 'invoiced_at',
            title: t('invoiced at'),
            sorting: false,
            render: (row: any) => <ColumnRenderInvoicedAt row={ row } t={ t } />
        },
        {
            field: 'invoice_number',
            title: t('invoice'),
            sorting: false,
            render: (row: any) => <ColumnRenderForInvoiceNumber row={ row } t={ t } />
        },
        {
            field: 'in_transit_at',
            title: t('loading'),
            sorting: false,
            render: (row: any) => <ColumnRenderInTransitAt row={ row } t={ t } />
        },
        {
            field: 'delivered_at',
            title: t('unloading'),
            sorting: false,
            render: (row: any) => <ColumnRenderDeliveredAt row={ row } t={ t } />
        },
        {
            field: 'action',
            title: t('action'),
            sorting: false,
            align: 'left',
            render: (_row: any) => {
                return ( <>
                    { _row.type === "AlpSpotLoad" && <AlpSpotLoadActionButton row={ _row } /> }
                    { _row.type === "SpotLoad" && <SpotLoadActionButton row={ _row } /> }
                </> );
            }
        },
    ];

    const DetailRow = ({ data }: any) => {

        return ( <>
            <div>
                <strong
                    className={ 'text-capitalize' }>{ t("actual loading") }:
                </strong> {
                data.type === "SpotLoad" &&
                customSplitter(
                    ( get(data, ['actual_loading_time']) || "" )
                    .split("||")
                )
                .map((i: any, index: number) => {
                    if ( isEmpty(i.value) ) {
                        return '';
                    }

                    return (
                        <span key={ index } className={ "ms-3" }>
                            <span className={ 'circle-indicator' }
                                  style={ { color: getColor(data.guid_colors, i.id) } }>
                                <i className="fas fa-circle"></i>
                            </span>
                            { utcDateToLocalDate(i.value) }
                        </span>
                    );
                })
            }
                {data.type === "AlpSpotLoad" && <>{ utcDateToLocalDate(data.actual_loading_time) }</>}
            </div>
            <div>
                <strong
                    className={ 'text-capitalize' }>{ t("actual unloading") }:
                </strong>
                {
                    data.type === "SpotLoad" &&
                    customSplitter(
                        ( get(data, ['actual_unloading_time']) || "" )
                        .split("||")
                    )
                    .map((i: any, index: number) => {
                        if ( isEmpty(i.value) ) {
                            return '';
                        }

                        return (
                            <span key={ index } className={ "ms-3" }>
                                <span className={ 'circle-indicator' }
                                      style={ { color: getColor(data.guid_colors, i.id) } }>
                                    <i className="fas fa-circle"></i>
                                </span>
                                { utcDateToLocalDate(i.value) }
                            </span>
                        );
                    })
                }
                {data.type === "AlpSpotLoad" && <>{ utcDateToLocalDate(data.actual_unloading_time) }</>}
            </div>
        </> );
    };

    const onReset = (reset: any = true) => {
        const mStates = { ...moduleState };

        if ( reset ) {
            mStates.filters = [];
            mStates.page = 1;
            mStates.search = null;
        }

        props.setQuery(mStates);
    };

    const onSearch = ({ search }: any) => {
        props.setQuery({
            ...moduleState,
            search: search
        });
    };

    const onFilterClick = () => {
        setFilterPanel(true);
    };

    const onClickSpotLoadStatusUpdate = (row: any) => {
        if ( row.type === "SpotLoad" ) {
            setSpotLoadItemToBeUpdate(row);
        }
    };

    const setRowClass = (row: any) => {
        const updated: any = getLastUpdate();

        if ( get(row, ['type']) === "SpotLoad" ) {
            const allLegalEntity = row.legal_entity.split("||");
            let announcedColor: any = null;
            let inTransitColor: any = null;
            let deliveredColor: any = null;

            map(allLegalEntity, (item: any) => {
                const legalEntities = item.split("___");
                if ( includes(['announced'], get(legalEntities[1].split("~~~"), ['0']))) {
                    announcedColor = get(find(dropdowns.legalEntities, { id: legalEntities[1].split("~~~")[1] }), ['color']);
                }

                if ( includes(['in-transit'], get(legalEntities[1].split("~~~"), ['0'])) ) {
                    inTransitColor = get(find(dropdowns.legalEntities, { id: legalEntities[1].split("~~~")[1] }), ['color']);
                }

                if ( includes(['delivered'], get(legalEntities[1].split("~~~"), ['0'])) ) {
                    deliveredColor = get(find(dropdowns.legalEntities, { id: legalEntities[1].split("~~~")[1] }), ['color']);
                }
            });

            return css`
                background-color: ${(announcedColor !== null ? announcedColor : (inTransitColor !== null ? inTransitColor : deliveredColor))};
                
                & > td:first-child {
                    border-left: ${get(updated, ['id']) === row.id ? '2px solid blue' : '2px solid transparent'}
                }
            `;
        }

        if ( get(row, ['type']) === "AlpSpotLoad" ) {
            const fromLocation = find(dropdowns.legalEntities, { id: get(row, ['legal_entity']) });
            const color = get(fromLocation, ['color']);

            return css`
                background-color: ${color};
                
                & > td:first-child {
                    border-left: ${get(updated, ['id']) === row.id ? '2px solid blue' : '2px solid transparent'}
                }
            `;
        }

        return {};
    };

    const onOperationClick = (operationToBePerformed: any = 'add', id: any = null) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('operation', operationToBePerformed);
        searchParams.set('operationId', id);

        if ( isEmpty(operationToBePerformed) ) {
            searchParams.delete('operation');
        }

        if ( isEmpty(id) ) {
            searchParams.delete('operationId');
        }

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        }, { replace: true });
        setOperationId(id);
        setOperation(operationToBePerformed);
    };

    const onCloseOperation = (reset: any = true) => {
        onOperationClick(null, null);
        onReset(reset);
    };

    const refreshTable = () => {
        if ( tableRef.current ) {
            tableRef.current.onRefresh();
        }
    };

    const focusOnSearch = () => {
        const searchInput: HTMLInputElement | null = document.querySelector('input[name="search"]');
        if ( searchInput ) {
            searchInput.focus();
        }
    };

    const onExcelClick = () => {
        setExcelPanel(true);
    };

    return (
        <div className={ "block-viewer" }>
            <div className={ ` block-section` }>
                <div className="block-header">
                    <span className="block-title">
                        <h5 className={ "mb-0" }>{ t('loads') } ({total})</h5>
                    </span>
                    <div className="block-actions">
                    </div>
                </div>
                <div className="block-content">
                    <Row className={ `p-2` }>
                        <Col
                            sm={ 12 } md={ 6 } className={ "mb-2" }>
                            <Formik
                                enableReinitialize={ true }
                                initialValues={ initSearchFormState }
                                onSubmit={ onSearch }
                            >
                                { (props: FormikProps<any>) => {
                                    return (
                                        <Form onSubmit={ props.handleSubmit }>
                                            <Col sm={ 12 } md={ 8 }>
                                                <Field component={ InputField } withoutLabel={ true }
                                                       autoFocus={true}
                                                       name="search"
                                                       formGroupClass={ "mb-0" }
                                                       placeholder={ t("search") } />
                                            </Col>
                                        </Form>
                                    );
                                } }
                            </Formik>
                        </Col>

                        <Col
                            sm={ 12 } md={ 6 } className={ "text-right mb-2" }>
                                <ButtonGroup>
                                     <Button onClick={ onAlpSpotLoadSyncList } icon="pi pi-sync" size={ "small" }
                                             outlined disabled={syncOn}
                                             tooltip={ t("sync") + ' ' + t("alp") + ' ' + t("spot load") }
                                             tooltipOptions={ { position: 'top' } } />
                                    <Button size={ "small" } outlined tooltip={ t('add item') }
                                            tooltipOptions={ { position: 'top' } }
                                            onClick={ () => {
                                                props.router.navigate(`/spot/loads?back_to=loads&operation=add`);
                                            } }>
                                        <i className="pi pi-plus" />
                                        <span className={ "ms-1" }>{ t('add') }</span>
                                    </Button>
                                    <Button size={ "small" } outlined tooltip={ t('generate bulk invoice(s)') }
                                            tooltipOptions={ { position: 'top' } }
                                            onClick={ () => setBulkInvoiceVisibility(true) }>
                                        <i className="pi pi-file-plus" />
                                        <span className={ "ms-1" }>{ t('bulk invoices') }</span>
                                    </Button>
                                    <Button icon="pi pi-filter-fill" size={ "small" } outlined tooltip={ t('filter') }
                                            onClick={ onFilterClick }
                                            tooltipOptions={ { position: 'top' } } />
                                    <Button icon="pi pi-file-excel" size={ "small" } outlined tooltip={ t('excel') }
                                            onClick={ onExcelClick }
                                            tooltipOptions={ { position: 'top' } } />
                                    <Button onClick={ onReset } icon="pi pi-refresh" size={ "small" } outlined
                                            tooltip={ t('reset') }
                                            tooltipOptions={ { position: 'top' } } />
                                </ButtonGroup>
                        </Col>

                        <Col sm={ 12 }>
                            <StateLessGrid
                                size={ "normal" }
                                url={ '/suppliers/loads?1=1' }
                                name={ "loadList" }
                                columns={ columns }
                                moduleState={ moduleState }
                                setQuery={ props.setQuery }
                                setRowClass={ setRowClass }
                                ref={ tableRef }
                                setTotal={ setTotal }
                                detailRow={ (data: any) => <DetailRow data={ data } /> }
                            />
                        </Col>
                    </Row>

                    <SpotLoadStatusUpdate
                        toastify={ props.toastify }
                        item={ spotLoadItemToBeUpdate }
                        onClose={ () => {
                            setSpotLoadItemToBeUpdate(null);
                            refreshTable();
                            focusOnSearch();
                        } }
                    />

                    {/* ALP SPOT LOAD Components */ }
                    <AlpSpotLoadAccept
                        id={ acceptAlpSpotLoadId }
                        toastify={ props.toastify }
                        onRefresh={ refreshTable }
                        dropdowns={ props.dropdowns }
                        onClose={ () => {
                            setAcceptAlpSpotLoadId(null);
                            focusOnSearch();
                        } } />

                    <AlpSpotLoadReject
                        id={ rejectAlpSpotLoadId }
                        toastify={ props.toastify }
                        onRefresh={ refreshTable }
                        dropdowns={ props.dropdowns }
                        onClose={ () => {
                            setRejectAlpSpotLoadId(null);
                            focusOnSearch();
                        } } />

                    <AlpSpotLoadUpdate
                        id={ editAlpSpotLoadId }
                        toastify={ props.toastify }
                        onRefresh={ refreshTable }
                        dropdowns={ props.dropdowns }
                        onClose={ () => {
                            setEditAlpSpotLoadId(null);
                            focusOnSearch();
                        } } />

                    <AlpSpotLoadReset
                        id={ resetAlpSpotLoadId }
                        toastify={ props.toastify }
                        onRefresh={ refreshTable }
                        dropdowns={ props.dropdowns }
                        onClose={ () => {
                            setResetAlpSpotLoadId(null);
                            focusOnSearch();
                        } } />

                    <AlpSpotLoadInTransit
                        id={ inTransitAlpSpotLoadId }
                        toastify={ props.toastify }
                        onRefresh={ refreshTable }
                        dropdowns={ props.dropdowns }
                        onClose={ () => {
                            setInTransitAlpSpotLoadId(null);
                            focusOnSearch();
                        } } />

                    <AlpSpotLoadUnloadRequest
                        id={ unloadRequestAlpSpotLoadId }
                        toastify={ props.toastify }
                        onRefresh={ refreshTable }
                        dropdowns={ props.dropdowns }
                        onClose={ () => {
                            setInTransitAlpSpotLoadId(null);
                            focusOnSearch();
                        } } />

                    <AlpSpotLoadUpdatePrice
                        toastify={ toastify }
                        dropdowns={ [] }
                        id={ loadId }
                        onClose={ () => {
                            setLoadId(null);
                            onReset();
                            focusOnSearch();
                        } }
                    />

                    <SpotLoadFilter
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        visibility={ filterPanel }
                        onClose={ () => {
                            setFilterPanel(false);
                            focusOnSearch();
                        } }
                    />

                    <LoadExcel
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        visibility={ excelPanel }
                        onClose={ () => {
                            setExcelPanel(false);
                            focusOnSearch();
                        } }
                    />

                    <ShowInvoiceableLoad
                        toastify={ toastify }
                        dropdowns={ {clients: dropdowns.clients} }
                        load={ invoiceableLoad }
                        onClose={ () => {
                            setInvoiceableLoad(null);
                            setBulkInvoiceVisibility(false);
                        } }
                    />

                    <AllInvoiceableList
                        visibility={ bulkInvoiceVisibility }
                        toastify={ toastify }
                        onClose={ () => {
                            setBulkInvoiceVisibility(false);
                        } }
                    />

                    <LoadPrePayment
                        dropdowns={ props.dropdowns }
                        visibility={ paymentVisibility }
                        toastify={ toastify }
                        onClose={ () => {
                            setPaymentVisibility({});
                        } }
                    />

                    <ConfirmPopup />
                </div>
            </div>
        </div>
    );
};

LoadList.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(LoadList));
