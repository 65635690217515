import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Field, Form } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Col, Row } from "react-bootstrap";
import { InputField } from "../../../../components/Shared/InputField";
import { Sidebar } from "primereact/sidebar";
import { get, includes } from "lodash";
import { Button } from "primereact/button";
import { InputSingleSelectField } from "../../../../components/Shared/InputSingleSelectField";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    dropdowns: any;
    params: any;
    toastify: any;
    onClose?: any;
    operationCancel?: any;
}

type TInputForm = {
    id: any,
    title: null | string,
    version_code: null | string,
    brand_id: null | string,
    description: null | string,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        title: yup.string().trim(t('the field is invalid.')).required(t('the field is required.', { field: t('model name') })).nullable(),
        version_code: yup.string().trim(t('the field is invalid.')).nullable(), // required(t('the field is required.', { field: t('version code') }))
        brand_id: yup.string().required(t('the field is required.', { field: t('brand') })).nullable(),
        description: yup.string().nullable(),
    });
};

const DEFAULT_FORM_STATE = {
    id: null,
    title: null,
    version_code: null,
    brand_id: null,
    description: null,
};

function ModelForm(props: IFormProps) {
    const { t, toastify, params, dropdowns, operationCancel } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: ( !payloads.id ? 'POST' : 'PATCH' ),
                url: ( !payloads.id ? `/suppliers/models` : `/suppliers/models/${ payloads.id }` ),
                data: {
                    ...payloads,
                },
            });

            if ( payloads.id ) {
                toastify(t("record updated", { item: t('model') }), "success");
            } else {
                toastify(t("record added", { item: t('model') }), "info");
            }

            resetForm();
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");

            }
        }
    };

    const onReset = () => {
        const base: any = formRef.current;

        if ( base ) {
            base.resetForm();
        }
    };

    const onClose = () => {
        setPanelState(false);
        onReset();

        if ( props.onClose ) {
            props.onClose(
                !includes(['edit'], get(params, ['operation']))
            );
        }
    };

    const onEdit = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/models/${ id }`
            });

            const { data } = response.data;
            const formData: TInputForm = { ...data, brand_id: data.brand.id };
            setInitFormState(formData);
            setPanelState(true);
        } catch (error: any) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        // setInitFormState(DEFAULT_FORM_STATE);

        if ( includes(['add'], get(params, ['operation'])) ) {
            setPanelState(true);
        }

        if ( includes(['edit'], get(params, ['operation'])) ) {
            onEdit(params.operationId);
        }
    }, [params]);

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('model') + ' ' + t('action') }</h5>
                            <p>{ t("using this form you can add or update the record") }</p>
                        </div>
                    }
                    visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } } closeOnEscape={ false }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                        validationSchema={ validationSchema(t) }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4 md:mb-7 sm:mb-7" }>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSingleSelectField }
                                                   name="brand_id"
                                                   options={ dropdowns.brands }
                                                   label={ t('brand') }
                                                   placeholder={ t('pick') + ' ' + t('brand') }
                                                   panelClassName={"hide-p-toggler"}
                                                   isRequired
                                                   autoFocus
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="title"
                                                   label={ t("model name") }
                                                   placeholder={ t("enter") + ' ' + t("model name").toLowerCase() }
                                                   isRequired
                                                   />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field component={ InputField }
                                                   name="version_code"
                                                   label={ t("version code") }
                                                   placeholder={ t("enter") + ' ' + t("version code").toLowerCase() }
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field component={ InputField }
                                                   name="description"
                                                   label={ t("description") }
                                                   type={ "textarea" }
                                                   placeholder={ t("enter") + ' ' + t("description").toLowerCase() }
                                            />
                                        </Col>
                                    </Row>
                                    <div className="filter-card-footer"
                                         style={ { width: '785px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-save me-2" />
                                            { t("let's save") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ onReset }
                                                tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                size={ "small" } severity={ "warning" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-refresh me-2" />
                                            { t("reset") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                                onClick={ () => operationCancel(null, null) }
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("cancel") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

ModelForm.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(ModelForm);
