import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Form, Field } from "formik";
import { Col, Row } from "react-bootstrap";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { InputSelectField } from "../../../../../components/Shared/InputSelectField";
import InputDateTime from "../../../../../components/Shared/InputDateTime";
import { InputField } from "../../../../../components/Shared/InputField";

interface IFormProps {
    t?: any | undefined;
    moduleState: any;
    setQuery: any;
    dropdowns: any;
    visibility: any;
    toastify: any;
    onClose?: any;
}

type TInputForm = {
    drivers: [],
    transport_vehicles: [],
    from_locations: [],
    to_locations: [],
    legal_entities: [],
    clients: [],
};

const DEFAULT_FORM_STATE = {
    drivers: [],
    transport_vehicles: [],
    from_locations: [],
    to_locations: [],
    legal_entities: [],
    clients: [],
    from_date: null,
    is_invoiced: null,
    to_date: null,
    vin_numbers: null
};

// @ts-ignore
function SpotLoadFilter(props: IFormProps) {
    const { t, visibility, dropdowns } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<any>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);

    const onSubmit = async (payloads: any) => {
        props.setQuery({
            ...props.moduleState,
            filters: payloads
        });
    };

    const onReset = () => {
        const base: any = formRef.current;
        if ( base ) {
            base.resetForm();
        }

        props.setQuery({
            ...props.moduleState,
            filters: []
        });
    };

    const onClose = () => {
        setPanelState(false);

        if ( props.onClose ) {
            props.onClose();
        }
    };

    useEffect(() => {
        if ( visibility ) {
            setPanelState(visibility);
            setInitFormState({ ...DEFAULT_FORM_STATE, ...props.moduleState.filters ?? [] });
        }
    }, [visibility]);

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('advance filters') }</h5>
                            <p>{ t("using filters you can archive better result.") }</p>
                        </div>
                    }
                    visible={ panelState } position="left" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } } closeOnEscape={false}
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4 md:mb-7 sm:mb-7" }>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSelectField }
                                                   name="brands"
                                                   options={ dropdowns?.brands }
                                                   label={ t('brands') }
                                                   placeholder={ t('select') +' '+ t('brands') }
                                                   labelField="label"
                                                   valueField="id"
                                                   isMulti
                                                   autoFocus={true}
                                            />
                                        </Col>

                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSelectField }
                                                   name="models"
                                                   options={ dropdowns?.models }
                                                   label={ t('models') }
                                                   placeholder={ t('select') +' '+ t('models') }
                                                   labelField="label"
                                                   valueField="id"
                                                   isMulti
                                            />
                                        </Col>

                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSelectField }
                                                   name="drivers"
                                                   options={ dropdowns?.drivers }
                                                   label={ t('drivers') }
                                                   placeholder={ t('select') +' '+ t('drivers') }
                                                   labelField="label"
                                                   valueField="id"
                                                   isMulti
                                            />
                                        </Col>

                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSelectField }
                                                   name="transport_vehicles"
                                                   options={ dropdowns.transportVehicles }
                                                   label={ t('transport vehicles') }
                                                   placeholder={ t('select') +' '+ t('transport vehicles') }
                                                   getOptionLabel={ (option: any) => `${ option.truck_plate_number } | ${ option.trailer_plate_number }` }
                                                   labelField="trailer_plate_number"
                                                   valueField="id"
                                                   isMulti
                                            />
                                        </Col>

                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSelectField }
                                                   name="from_locations"
                                                   options={ dropdowns?.locations }
                                                   label={ t('from locations') }
                                                   placeholder={ t('select') +' '+ t('from locations') }
                                                   labelField="label"
                                                   valueField="id"
                                                   isMulti
                                            />
                                        </Col>

                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSelectField }
                                                   name="to_locations"
                                                   options={ dropdowns?.locations }
                                                   label={ t('to locations') }
                                                   placeholder={ t('select') +' '+ t('to locations') }
                                                   labelField="label"
                                                   valueField="id"
                                                   isMulti
                                            />
                                        </Col>

                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSelectField }
                                                   name="legal_entities"
                                                   options={ dropdowns?.legalEntities }
                                                   label={ t('legal entities') }
                                                   placeholder={ t('select') +' '+ t('legal entities') }
                                                   labelField="label"
                                                   valueField="id"
                                                   isMulti
                                            />
                                        </Col>

                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSelectField }
                                                   name="clients"
                                                   options={ dropdowns?.clients }
                                                   label={ t('clients') }
                                                   placeholder={ t('select') +' '+ t('clients') }
                                                   labelField="label"
                                                   valueField="id"
                                                   isMulti
                                            />
                                        </Col>

                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSelectField }
                                                   name="from_cities"
                                                   options={ dropdowns?.cities }
                                                   label={ t('from cities') }
                                                   placeholder={ t('select') +' '+ t('from cities') }
                                                   labelField="label"
                                                   valueField="id"
                                                   isMulti
                                            />
                                        </Col>

                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSelectField }
                                                   name="to_cities"
                                                   options={ dropdowns?.cities }
                                                   label={ t('to cities') }
                                                   placeholder={ t('select') +' '+ t('to cities') }
                                                   labelField="label"
                                                   valueField="id"
                                                   isMulti
                                            />
                                        </Col>

                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSelectField }
                                                   name="from_countries"
                                                   options={ dropdowns?.countries }
                                                   label={ t('from countries') }
                                                   placeholder={ t('select') +' '+ t('from countries') }
                                                   labelField="label"
                                                   valueField="id"
                                                   isMulti
                                            />
                                        </Col>

                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSelectField }
                                                   name="to_countries"
                                                   options={ dropdowns?.countries }
                                                   label={ t('to countries') }
                                                   placeholder={ t('select') +' '+ t('to countries') }
                                                   labelField="label"
                                                   valueField="id"
                                                   isMulti
                                            />
                                        </Col>

                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSelectField }
                                                   name="load_statuses"
                                                   options={ dropdowns?.loadStatuses }
                                                   label={ t('load status') }
                                                   placeholder={ t('select') +' '+ t('load status') }
                                                   labelField="label"
                                                   valueField="id"
                                                   isMulti
                                            />
                                        </Col>

                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="vin_numbers"
                                                   label={ t("vin numbers") }
                                                   formText={ t("a series of vin number values, each separated by a space") }
                                            />
                                        </Col>

                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSelectField }
                                                   name="is_invoiced"
                                                   options={ [
                                                       { "id": "no", "label": t("no") },
                                                       { "id": "yes", "label": t("yes") },
                                                   ] }
                                                   label={ t('should be invoiced') }
                                                   labelField="label"
                                                   valueField="id"
                                            />
                                        </Col>

                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputDateTime }
                                                   name="from_loading_date"
                                                   type={ "datetime" }
                                                   label={ t("from loading date") }
                                                   isClearable={ true }
                                                   enableTimeStartOfDay
                                            />
                                        </Col>

                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputDateTime }
                                                   name="to_loading_date"
                                                   type={ "datetime" }
                                                   label={ t("to loading date") }
                                                   isClearable={ true }
                                                   enableTimeEndOfDay
                                            />
                                        </Col>

                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputDateTime }
                                                   name="from_unloading_date"
                                                   type={ "datetime" }
                                                   label={ t("from unloading date") }
                                                   isClearable={ true }
                                                   enableTimeStartOfDay
                                            />
                                        </Col>

                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputDateTime }
                                                   name="to_unloading_date"
                                                   type={ "datetime" }
                                                   label={ t("to unloading date") }
                                                   isClearable={ true }
                                                   enableTimeEndOfDay
                                            />
                                        </Col>
                                    </Row>
                                    <div className="filter filter-card-footer"
                                         style={ { width: '785px' } }>
                                        <Button type={ "submit" } disabled={ props.isSubmitting }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-filter me-2" />
                                            { t("filter") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ onReset }
                                                tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                size={ "small" } severity={ "warning" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-refresh me-2" />
                                            { t("reset") }
                                        </Button>
                                        <Button type={ "button" } disabled={ props.isSubmitting }
                                                onClick={ onClose }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("close") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                </Sidebar>
            </div>
        </>
    );
}

SpotLoadFilter.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(SpotLoadFilter);
