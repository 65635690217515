import React from "react";
import { includes } from "lodash";
import { Tag } from "primereact/tag";

export function getOrderStatus(status: any) {
    if ( status ) {
        return (
            ( ( status === 'created' ) ? 'Available' : status )
        );
    }

    return status;
}

const OrderStatus = (props: any) => {
    const { status } = props;

    if(includes(['hold-damage', 'hold-service-order', 'hold', 'load-rejected', 'cancelled'], status)) {
        return <Tag value={getOrderStatus(status)} severity="danger" className={"capitalize"} rounded/>
    }

    if(includes(['added-to-load', 'in-transit'], status)) {
        return <Tag value={getOrderStatus(status)} severity="info" className={"capitalize"}  rounded/>
    }

    if(includes(['pending', 'produced', 'unload-request'], status)) {
        return <Tag value={getOrderStatus(status)} severity="warning" className={"capitalize"}  rounded/>
    }

    if(includes(['archived', 'stocked', 'delivered'], status)) {
        return <Tag value={getOrderStatus(status)} severity="success" className={"capitalize"}  rounded/>
    }
    return <>{ getOrderStatus(status) }</>;
};

export default OrderStatus;
