import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { FormikProps, Formik, Form, Field } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Col, Row } from "react-bootstrap";
import { Sidebar } from "primereact/sidebar";
import { get, isEmpty, last, sortBy } from "lodash";
import { Button } from "primereact/button";
import { random } from "../../../../utils/Helper";
import { InputSingleSelectField } from "../../../../components/Shared/InputSingleSelectField";
import { InputSwitchField } from "../../../../components/Shared/InputSwitchField";
import { Divider } from "primereact/divider";

type TOrder = {
    vin_number?: any,
    order_journeys?: any,
    created_at?: any,
    to_location?: any,
    id?: any
}

interface IFormProps {
    t?: any | undefined;
    onClose: any;
    visible: any;
    toastify: any;
    selectedOrders: Array<TOrder>;
    dropdowns: any;
}

type TInputForm = {
    token: any,
    from_location_id: any,
    is_active: any,
    to_location_id: any,
    status: any,
    is_final_journey: any
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        status: yup.string().nullable(),
        is_active: yup.boolean().nullable(),
        from_location_id: yup.string().required(t('the field is required.', { field: t('from location') })).nullable(),
        to_location_id: yup.string().required(t('the field is required.', { field: t('to location') })).nullable(),
        is_final_journey: yup.boolean().nullable()
    });
};

const DEFAULT_FORM_STATE = {
    token: random(5),
    from_location_id: null,
    is_active: false,
    to_location_id: null,
    status: 'pending',
    is_final_journey: false
};

function AddJourneyToMultipleOrders(props: IFormProps) {
    const { t, dropdowns, toastify } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);

    const onClose = () => {
        setPanelState(false);

        if ( props.onClose ) {
            props.onClose();
        }
    };

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: 'POST',
                url: `/suppliers/orders/store/journey/multiple`,
                data: {
                    ...payloads,
                    orders: props.selectedOrders.map((item: any, index: any) => item.id),
                }
            });

            toastify(t("record updated", { item: t('order') }), "success");
            resetForm();
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    useEffect(() => {
        if ( props.visible ) {
            setPanelState(true);
            const _mostRecent: any = sortBy(get(last(props.selectedOrders), ['order_journeys']), [function(o: any) { return o.created_at; }]);

            setInitFormState({
                ...DEFAULT_FORM_STATE,
                from_location_id: get(last(_mostRecent), ['to_location', 'id'])
            });
        }
    }, [props.visible]);

    return (
        <>
            <div className={"md:mb-7 sm:mb-7"}>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('order journey') }</h5>
                            <p>{ t("add new journey to selected orders") }</p>
                        </div>
                    }
                    visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSubmit }
                        validationSchema={ validationSchema(t) }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4" }>
                                        <Col sm={ 12 }>
                                            <Field
                                                component={ InputSingleSelectField }
                                                name="from_location_id"
                                                options={ dropdowns.locations }
                                                label={ t('from location') }
                                                placeholder={ t('pick') + ' ' + t('loading location') }
                                                panelClassName={"max-width-300px hide-p-toggler"}
                                                isRequired
                                                autoFocus
                                            />
                                        </Col>
                                        <Col sm={ 12 }>
                                            <Field
                                                component={ InputSingleSelectField }
                                                name="to_location_id"
                                                options={ dropdowns.locations }
                                                label={ t('to location') }
                                                placeholder={ t('pick') + ' ' + t('unloading location') }
                                                panelClassName={"max-width-300px hide-p-toggler"}
                                                isRequired
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSwitchField }
                                                   name="is_active"
                                                   label={ t("make this journey active") }
                                                   trueLabel={ t("yes") }
                                                   falseLabel={ t("no") }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSwitchField }
                                                   name="is_final_journey"
                                                   label={ t("is final journey") }
                                                   trueLabel={ t("yes") }
                                                   falseLabel={ t("no") }
                                            />
                                        </Col>
                                    </Row>
                                    <div className="filter-card-footer"
                                         style={ { width: '785px' } }>
                                        <Button type={ "submit" }
                                                tooltip={ t("to data save into database") }
                                                size={ "small" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                        >
                                            <i className="pi pi-save me-2" />
                                            { t("let's save") }
                                        </Button>
                                        <Button type={ "button" }
                                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                                size={ "small" } severity={ "danger" } outlined
                                                tooltipOptions={ { position: 'top' } }
                                                onClick={ onClose }
                                        >
                                            <i className="pi pi-arrow-left me-2" />
                                            { t("cancel") }
                                        </Button>
                                    </div>
                                </Form>
                            );
                        } }
                    </Formik>
                    <Divider align={ "left" }>
                        <div className="inline-flex align-items-center">
                            { t("selected orders") }
                        </div>
                    </Divider>
                    <table className={ "fl-table" }>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{ t("vin(s)") }</th>
                            </tr>
                        </thead>
                        <tbody>
                            { props.selectedOrders && props.selectedOrders.map((item: any, index: any) => {
                                return (
                                    <tr key={ index }>
                                        <td>{ index + 1 }</td>
                                        <td>{ item.vin_number }</td>
                                    </tr>
                                );
                            }) }
                        </tbody>
                        {
                            isEmpty(props.selectedOrders) &&
                          <tfoot>
                              <tr>
                                  <td colSpan={ 5 } className={ "text-center" }>
                                      { t('no data') }
                                  </td>
                              </tr>
                          </tfoot>
                        }
                    </table>
                </Sidebar>
            </div>
        </>
    );
}

//     AddJourneyToMultipleOrders.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(AddJourneyToMultipleOrders);
