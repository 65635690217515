import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_OTP, AUTH_REMOVE_OTP } from "../actions/auth";
import * as store from "store";

const LOCALSTORAGE_PREFIX = process.env.REACT_APP_STORAGE_PREFIX || 'AlcolmCALP';

const initialState: any = {
    auth: ( store.get(`${ LOCALSTORAGE_PREFIX }.auth`) || false ),
    otp: ( store.get(`${ LOCALSTORAGE_PREFIX }.otp`) || false ),
};

function authReducer(state = initialState, action: any) {
    switch (action.type) {
        case AUTH_LOGIN:
            store.remove(`${ LOCALSTORAGE_PREFIX }.otp`);
            store.set(`${ LOCALSTORAGE_PREFIX }.auth`, true);

            return {
                ...state,
                auth: true,
                otp: false
            };

        case AUTH_OTP:
            store.set(`${ LOCALSTORAGE_PREFIX }.otp`, true);

            return {
                ...state,
                otp: true,
            };

        case AUTH_LOGOUT:
            store.remove(`${ LOCALSTORAGE_PREFIX }.auth`);
            store.remove(`${ LOCALSTORAGE_PREFIX }.user`);

            return {
                ...state,
                auth: false
            };

        case AUTH_REMOVE_OTP:
            store.remove(`${ LOCALSTORAGE_PREFIX }.otp`);

            return {
                ...state,
                otp: false
            };

        default:
            return state;
    }
}

export default authReducer;
