import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "./Tooltip";
import { Badge } from 'primereact/badge';
import { Button } from "primereact/button";

interface IProps {
    type?: string;
    vinNumber: string;
    priorisation?: number | string;
    builtToOrderCd?: number | string;
}

const PriorityAvatar = (props: any) => {
    const { priorisation, builtToOrderCd } = props;
    const { t } = useTranslation();

    if ( priorisation && priorisation > 0 ) {
        return (
            <span className={ "text-uppercase" }>
                <Tooltip tooltip={ ( builtToOrderCd > 0 ) ? t('superHigh') : t('high') }>
                  <Badge
                      value={ ( builtToOrderCd > 0 ) ? 'S' : 'H' }
                      severity="info" />
                </Tooltip>
            </span>
        );
    }

    if ( builtToOrderCd && builtToOrderCd > 0 ) {
        return (
            <span className={ "text-uppercase" }>
            <Tooltip tooltip={ t('superHigh') }>
                <Badge
                    value={ 'S' }
                    severity="warning" />
            </Tooltip>
          </span>
        );
    }

    return <></>;
};

function VinWithType(props: IProps) {
    const { type, vinNumber } = props;
    const { t } = useTranslation();
    const [tooltip, setToolTip] = useState(t('click: to copy'));

    const handleCopy = (clipboard: string) => {
        navigator.clipboard.writeText(clipboard).then(() => {
            setToolTip(t('copied'));
            setTimeout(() => {
                setToolTip(t('click: to copy'));
            }, 5000);
        }).catch(() => {
            setToolTip(t('failed to copy text'));
        });
    };

    return (
        <span>
              {
                  !type &&
                <>
                    <Button
                      className={ "p-button-x-sm" }
                      size={ "small" }
                      link
                      type={"button"}
                      tooltip={ tooltip }
                      tooltipOptions={{event: "both", position: "top"}}
                      style={{fontSize: '0.975rem'}}
                      onClick={ () => handleCopy(vinNumber) }>
                        { vinNumber }
                    </Button>
                </>
              }
            {
                type === 'renault' &&
              <>
                  <Button
                    className={ "p-button-x-sm" }
                    size={ "small" }
                    link
                    type={"button"}
                    tooltip={ tooltip }
                    tooltipOptions={{event: "both", position: "top"}}
                    style={{fontSize: '0.975rem'}}
                    onClick={ () => handleCopy(vinNumber) }>
                      <PriorityAvatar { ...props } />
                      { vinNumber }
                  </Button>
                  {/*<Tooltip tooltip={ 'Renault' }>*/}
                  {/*    <PriorityAvatar { ...props } />*/}
                  {/*    { vinNumber }*/}
                  {/*</Tooltip>*/}
              </>
            }
            {
                type === 'nissan' &&
              <>
                  <Button
                    className={ "p-button-x-sm" }
                    size={ "small" }
                    link
                    type={"button"}
                    tooltip={ tooltip }
                    tooltipOptions={{event: "both", position: "top"}}
                    style={{fontSize: '0.975rem'}}
                    onClick={ () => handleCopy(vinNumber) }>
                      <PriorityAvatar { ...props } />
                      { vinNumber }
                  </Button>
                  {/*<Tooltip tooltip={ 'Nissan' }>*/}
                  {/*    <PriorityAvatar { ...props } />*/}
                  {/*    { vinNumber }*/}
                  {/*</Tooltip>*/}
              </>
            }
            {
                type === 'alp' &&
              <>
                  <Button
                    className={ "p-button-x-sm" }
                    size={ "small" }
                    link
                    type={"button"}
                    tooltip={ tooltip }
                    tooltipOptions={{event: "both", position: "top"}}
                    style={{fontSize: '0.975rem'}}
                    onClick={ () => handleCopy(vinNumber) }>
                      <PriorityAvatar { ...props } />
                      { vinNumber }
                  </Button>
                  {/*<Tooltip tooltip={ 'Emil Frey' }>*/}
                  {/*    <PriorityAvatar { ...props } />*/}
                  {/*    { vinNumber }*/}
                  {/*</Tooltip>*/}
              </>
            }
            {
                type === 'system' &&
              <>
                  <Button
                    className={ "p-button-x-sm" }
                    size={ "small" }
                    link
                    type={"button"}
                    tooltip={ tooltip }
                    tooltipOptions={{event: "both", position: "top"}}
                    style={{fontSize: '0.975rem'}}
                    onClick={ () => handleCopy(vinNumber) }>
                      <PriorityAvatar { ...props } />
                      { vinNumber }
                  </Button>
              </>
            }
    </span>
    );
};

export default VinWithType;
