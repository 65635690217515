import React, { createContext, useContext, useReducer } from 'react';

// @ts-ignore
const StateContext = createContext();

// @ts-ignore
export const StateProvider = ({ reducer, initialState, children }) => (
    <StateContext.Provider value={ useReducer(reducer, initialState) }>
        { children }
    </StateContext.Provider>
);

export const useStateValue = () => useContext(StateContext);
