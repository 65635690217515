import { withTranslation } from "react-i18next";
import WithRouter from "../../../../../components/Common/WithRouter";
import React, { useRef, useState } from "react";
import { request } from "../../../../../utils/Request";
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import StateLessGrid from "../../../../../components/Shared/StateLessGrid";
import { Button } from "primereact/button";
import { Col, Row } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputField } from "../../../../../components/Shared/InputField";
import SpotLoadFilter from "./SpotLoadFilter";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { get, isEmpty } from "lodash";
import Tooltip from "../../../../../components/Common/Display/Tooltip";
import {
    ColumnLoadNumber, ColumnRenderDeliveredAt,
    ColumnRenderForBrandModel,
    ColumnRenderForClient,
    ColumnRenderForDriver, ColumnRenderForInvoiceNumber, ColumnRenderForLegalEntity,
    ColumnRenderForLocation,
    ColumnRenderForTransportVehicle,
    ColumnRenderForVin, ColumnRenderInTransitAt, ColumnRenderInvoicedAt,
    ColumnRenderStatus
} from "../../../loads/partials/Column";
import { customSplitter } from "../../../../../utils/Helper";
import HasAccess from "../../../../../utils/HasAccess";
import SpotLoadDetail from "../../../spotLoads/partials/SpotLoadDetail";
import { ButtonGroup } from "primereact/buttongroup";

const SpotLoadList = (props: any) => {
    const { t, moduleState, toastify } = props;
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [operation, setOperation] = useState<any>(searchParams.get('operation') ?? null);
    const [operationId, setOperationId] = useState<any>(searchParams.get('operationId') ?? null);
    const [filterPanel, setFilterPanel] = useState<boolean>(false);
    const [initSearchFormState, setInitSearchFormState] = useState<any>({
        search: moduleState.search ?? null
    });
    const tableRef: any = useRef();
    const [total, setTotal] = useState(0);

    const columns = [
        {
            field: 'load_number',
            title: t("load number"),
            sorting: true,
            sortField: "load_number",
            render: (row: any) => <ColumnLoadNumber row={ row } t={ t } />
        },
        {
            field: 'driver_name',
            title: t("driver"),
            sortField: "driver_name",
            sorting: true,
            render: (row: any) => <ColumnRenderForDriver row={ row } t={ t } />
        },
        {
            field: 'cmr',
            title: t("cmr"),
            sortField: "cmr",
            sorting: true,
        },
        {
            field: 'legal_entity',
            title: t("legal entity"),
            render: (row: any) => <ColumnRenderForLegalEntity row={ row } t={ t } dropdowns={ props.dropdowns }/>
        },
        {
            field: 'ldg_nr',
            title: t("ldg nr"),
            sorting: false,
            render: (row: any) => <>
                <div>
                    { customSplitter(( get(row, ['ldg_nr']) || "" )
                    .split("||"))
                    .map((i: any) => i.value).join(', ') }
                </div>
            </>
        },
        {
            field: 'client',
            title: t('client'),
            sorting: false,
            render: (row: any) => <ColumnRenderForClient row={ row } t={ t } dropdowns={ props.dropdowns }/>
        },
        {
            field: 'brand_model',
            title: t('model(s)'),
            sorting: false,
            render: (row: any) => <ColumnRenderForBrandModel row={ row } t={ t } dropdowns={ props.dropdowns } />
        },
        {
            field: 'transport_vehicle',
            title: t('transport vehicle'),
            sorting: false,
            render: (row: any) => <ColumnRenderForTransportVehicle row={ row } t={ t } />
        },
        {
            field: 'locations',
            title: t('location'),
            sorting: false,
            render: (row: any) => <ColumnRenderForLocation row={ row } t={ t } dropdowns={ props.dropdowns } />
        },
        {
            field: 'order_id',
            title: t('vin(s)'),
            sorting: false,
            render: (row: any) => <ColumnRenderForVin row={ row } t={ t } />
        },
        {
            field: 'qty',
            title: t('qty'),
            sorting: false,
        },
        {
            field: 'status',
            title: t('status'),
            sorting: false,
            render: (row: any) => {
                return <>
                    <ColumnRenderStatus
                        row={ row }
                        t={ t } />
                    <div>{ !isEmpty(row.load_invoiced_at) ? t('invoiced') : '' }</div>
                </>;
            }
        },
        {
            field: 'invoiced_at',
            title: t('invoiced at'),
            sorting: false,
            render: (row: any) => <ColumnRenderInvoicedAt row={ row } t={ t } />
        },
        {
            field: 'invoice_number',
            title: t('invoice'),
            sorting: false,
            render: (row: any) => <ColumnRenderForInvoiceNumber row={ row } t={ t } />
        },
        {
            field: 'in_transit_at',
            title: t('loading'),
            sorting: false,
            render: (row: any) => <ColumnRenderInTransitAt row={ row } t={ t } />
        },
        {
            field: 'delivered_at',
            title: t('unloading'),
            sorting: false,
            render: (row: any) => <ColumnRenderDeliveredAt row={ row } t={ t } />
        },
        {
            field: 'action',
            title: t('action'),
            sorting: false,
            render: (_row: any) => {
                return (
                    <>
                        <HasAccess hasAnyAccess={ ['archivedspotloadsupdate'] }>
                            <Tooltip
                                tooltip={ t('switch to delivered') }>
                                <a
                                    title={ t('delete item') }
                                    className={ "cursor-pointer ms-1 text-yellow-500" }
                                    onClick={ (event: any) => confirmPopup({
                                        target: event.currentTarget,
                                        message: t('do you want to delete this record?'),
                                        icon: 'pi pi-info-circle',
                                        // @ts-ignorer
                                        defaultFocus: 'reject',
                                        acceptClassName: 'p-button-danger',
                                        accept: () => onUpdateStatus(_row.id),
                                        reject: () => {
                                        },
                                    }) }>
                                    <i className="fas fa-allergies" />
                                </a>
                            </Tooltip>
                        </HasAccess>
                        <HasAccess hasAnyAccess={ ['archivedspotloadsview'] }>
                            <Tooltip tooltip={ t('detail of item') } position={ "left" }>
                                <a
                                    className={ "cursor-pointer ms-2 btn btn-soft-info waves-effect waves-light btn-xs" }
                                    onClick={ () => onOperationClick('detail', _row.id) }>
                                    <i className="fas fa-eye" />
                                </a>
                            </Tooltip>
                        </HasAccess>
                    </>
                );
            }
        },
    ];

    const onOperationClick = (operationToBePerformed: any = 'add', id: any = null) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('operation', operationToBePerformed);
        searchParams.set('operationId', id);

        if ( isEmpty(operationToBePerformed) ) {
            searchParams.delete('operation');
        }

        if ( isEmpty(id) ) {
            searchParams.delete('operationId');
        }

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        }, { replace: true });
        setOperationId(id);
        setOperation(operationToBePerformed);
    };

    const onUpdateStatus = async (id: string | null | undefined) => {
        try {
            const response = await request({
                method: 'PATCH',
                url: `/suppliers/archived/spot/loads/${ id }`,
            });

            toastify(t("record updated", { item: t('spot load') }), "info");
            tableRef.current.onRefresh();
            focusOnSearch();
        } catch (e: any) {
            toastify(t("server error"), "error");
        }
    };

    const onReset = () => {
        props.setQuery({
            ...moduleState,
            filters: [],
            page: 1,
            search: null
        });
    };

    const onSearch = ({ search }: any) => {
        props.setQuery({
            ...moduleState,
            search: search
        });
    };

    const onFilterClick = () => {
        setFilterPanel(true);
    };

    const onSyncList = async () => {
        try {
            const response = await request({
                url: `/suppliers/alp/spot/loads/sync/list`,
                method: 'POST',
            });

            onReset();
        } catch (e: any) {
            if ( e.status === 422 ) {
                return toastify(e.data.message, "error");
            }

            toastify(t("server error"), "error");
        }
    };

    const onCloseOperation = () => {
        onOperationClick(null, null);
        onReset();
        focusOnSearch();
    };

    const focusOnSearch = () => {
        const searchInput: HTMLInputElement | null = document.querySelector('input[name="search"]');
        if ( searchInput ) {
            searchInput.focus();
        }
    };

    return (
        <div className={ "block-viewer" }>
            <div className="block-section">
                <div className="block-header">
                    <span className="block-title">
                        <h5 className={ "mb-0" }>{ t('spot load') } ({total})</h5>
                    </span>
                    <div className="block-actions">
                    </div>
                </div>
                <div className="block-content">
                    <Row className={ `p-2 ${ operation === 'detail' ? 'position-minus' : '' }` }>
                        <Col
                            sm={ 12 } md={ 6 } className={ "mb-2" }>
                            <Formik
                                enableReinitialize={ true }
                                initialValues={ initSearchFormState }
                                onSubmit={ onSearch }
                            >
                                { (props: FormikProps<any>) => {
                                    return (
                                        <Form onSubmit={ props.handleSubmit }>
                                            <Col sm={ 12 } md={ 8 }>
                                                <Field component={ InputField } withoutLabel={ true }
                                                       autoFocus={ true }
                                                       name="search"
                                                       formGroupClass={ "mb-0" }
                                                       placeholder={ t("search") } />
                                            </Col>
                                        </Form>
                                    );
                                } }
                            </Formik>
                        </Col>

                        <Col
                            sm={ 12 } md={ 6 } className={ "text-right mb-2" }>
                                <ButtonGroup>
                                    <Button icon="pi pi-filter-fill" size={ "small" } outlined tooltip={ t('filter') }
                                            onClick={ onFilterClick }
                                            tooltipOptions={ { position: 'top' } } />
                                    <Button onClick={ onReset } icon="pi pi-refresh" size={ "small" } outlined
                                            tooltip={ t('reset') }
                                            tooltipOptions={ { position: 'top' } } />
                                </ButtonGroup>
                        </Col>

                        <Col sm={ 12 }>
                            <StateLessGrid
                                size={ "normal" }
                                url={ '/suppliers/archived/spot/loads?1=1' }
                                name={ "ArchiveSpotLoadList" }
                                columns={ columns }
                                moduleState={ moduleState }
                                setQuery={ props.setQuery }
                                ref={ tableRef }
                                itemSize={ 45 }
                                setTotal={ setTotal }
                            />
                        </Col>
                    </Row>

                    {
                        (operation === 'detail' && !isEmpty(operationId)) &&
                      <>
                          <SpotLoadDetail
                            setQuery={ props.setQuery }
                            toastify={ props.toastify }
                            dropdowns={ props.dropdowns }
                            params={ { operation, operationId } }
                            onClose={ onCloseOperation }
                          />
                      </>
                    }

                    <SpotLoadFilter
                        moduleState={ moduleState }
                        setQuery={ props.setQuery }
                        toastify={ props.toastify }
                        dropdowns={ props.dropdowns }
                        visibility={ filterPanel }
                        onClose={ () => {
                            setFilterPanel(false);
                            focusOnSearch();
                        } }
                    />

                    <ConfirmPopup />
                </div>
            </div>
        </div>
    );
};

SpotLoadList.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(SpotLoadList));
