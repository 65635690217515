import { Sidebar } from "primereact/sidebar";
import { useContext } from "react";
import { get } from "lodash";
import { LayoutContext } from "../../contexts/LayoutContext";
import { useTranslation } from "react-i18next";
import Auth from "../../utils/Auth";
import { useNavigate } from "react-router-dom";

const AppProfileSidebar = () => {
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const auth: Auth = new Auth();

    const onProfileSidebarHide = () => {
        setLayoutState((prevState) => ( {
            ...prevState,
            profileSidebarVisible: false,
        } ));
    };

    return (
        <Sidebar
            visible={ layoutState.profileSidebarVisible }
            onHide={ onProfileSidebarHide }
            position="right"
            className="layout-profile-sidebar w-full sm:w-25rem"
        >
            <div className="flex flex-column mx-auto md:mx-0">
                <span className="mb-2 font-semibold">{ t('welcome') }</span>
                <span className="text-color-secondary font-medium mb-5">
                    { get(auth.getUser(), ['name']) }
                </span>

                <ul className="list-none m-0 p-0">
                    <li>
                        <a onClick={ () => {
                               navigate(`/profile`);
                           } }
                           className="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                            <span>
                                <i className="pi pi-user text-xl text-primary"></i>
                            </span>
                            <div className="ml-3">
                                <span className="mb-2 font-semibold">
                                    { t('profile') }
                                </span>
                                <p className="text-color-secondary m-0">
                                    { t('update personal information') }
                                </p>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href={ '/logout' }
                           className="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                            <span>
                                <i className="pi pi-sign-out text-xl text-primary"></i>
                            </span>
                            <div className="ml-3">
                                <span className="mb-2 font-semibold">
                                    { t('logout') }
                                </span>

                                <p className="text-color-secondary m-0">
                                    { t('to end session') }
                                </p>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </Sidebar>
    );
};

export default AppProfileSidebar;
