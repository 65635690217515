import React from 'react';
import { ErrorMessage } from "formik";
import { get, has } from "lodash";
import { InputTextarea } from "primereact/inputtextarea";

interface IInputTextAreaFieldProps {
    label?: string,
    withoutLabel?: boolean,
    field: any,
    form?: any,
    icon?: any,
    isRequired?: boolean
    formGroupClass?: any,
    inputClass?: any,
    placeholder?: any,
    disabled?: boolean,
    invalid?: boolean,
    autoFocus?: boolean,
    onBlur?: any,
    formText?: any,
}

export class InputTextAreaField extends React.Component<IInputTextAreaFieldProps, {}> {
    static defaultProps = {
        isRequired: false,
        inputClass: "",
    };

    render() {
        const {
            field,
            form,
            isRequired,
            formGroupClass,
            invalid,
            withoutLabel,
            inputClass,
            formText,
            ...props
        } = this.props;
        const { errors } = form;
        return (
            <div className="p-fluid">
                <div className={ `field ${ formGroupClass }` }>
                    { !withoutLabel
                        && <label htmlFor={ field.id ?? field.name }>
                            { props.label }
                            { isRequired && <strong style={ { color: 'var(--red-500)' } }> *</strong> }
                        </label>
                    }

                    <InputTextarea
                        { ...props }
                        name={ field.name }
                        id={ field.id ?? field.name }
                        value={ get(field, ['value']) === null ? "" : get(field, ['value']) }
                        onChange={ field.onChange }
                        className={ `${ inputClass } ${ has(errors, field.name) ? 'p-invalid' : '' } p-inputtext-sm` }
                        onFocus={ ($event: any) => $event.target.select() }
                        disabled={props.disabled ?? false}
                        autoFocus={props.autoFocus ?? false}
                    />
                    { formText && <small>{ formText }</small> }
                    <ErrorMessage name={ field.name } component="small"
                                  className="p-error" />
                </div>
            </div>
        );
    }
}
