import { Tooltip } from "primereact/tooltip";
import React, { useContext, useEffect, useRef } from "react";
import { LayoutContext } from "../../contexts/LayoutContext";
import { MenuProvider } from "../../contexts/MenuProvider";
import AppMenuitem from "./AppMenuItem";
import { Breadcrumb, BreadcrumbItem, MenuModel, MenuProps } from "../../types";
import { random } from "../../utils/Helper";
import { isEmpty } from "lodash";

const AppSubMenu = (props: MenuProps) => {
    const { layoutState, setBreadcrumbs } = useContext(LayoutContext);
    const tooltipRef = useRef<Tooltip | null>(null);

    useEffect(() => {
        if ( tooltipRef.current ) {
            tooltipRef.current.hide();
            ( tooltipRef.current as any ).updateTargetEvents();
        }
    }, [layoutState.overlaySubmenuActive]);

    useEffect(() => {
        generateBreadcrumbs(props.model);
    }, []);

    const generateBreadcrumbs = (model: MenuModel[]) => {
        let breadcrumbs: Breadcrumb[] = [];

        const getBreadcrumb = (item: BreadcrumbItem, labels: string[] = []) => {
            const { label, to, items } = item;

            label && labels.push(label);
            items &&
            items.forEach((_item: any) => {
                getBreadcrumb(_item, labels.slice());
            });
            to && breadcrumbs.push({ labels, to });
        };

        model.forEach((item) => {
            getBreadcrumb(item);
        });
        setBreadcrumbs(breadcrumbs);
    };

    return (
        <MenuProvider>
            <ul className="layout-menu">
                { props.model.map((item: any, i: any) => {
                    return !item.seperator ? (
                        <AppMenuitem
                            key={ item.key }
                            item={ item }
                            root={ true }
                            index={ i }
                        />
                    ) : (
                        <li className="menu-separator"></li>
                    );
                }) }
            </ul>
            <Tooltip
                ref={ tooltipRef }
                target="li:not(.active-menuitem)>.tooltip-target"
            />
        </MenuProvider>
    );
};

export default AppSubMenu;
