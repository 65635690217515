import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FormikProps, Formik, Form, Field } from "formik";
import { Col, Row } from "react-bootstrap";
import { Dialog } from "primereact/dialog";
import { isEmpty } from "lodash";
import { request } from "../../../../utils/Request";
import { InputSingleSelectField } from "../../../../components/Shared/InputSingleSelectField";
import * as yup from "yup";
import { Button } from "primereact/button";

interface IFormProps {
    t?: any | undefined;
    onClose: any;
    id: any;
    toastify: any;
}

type TInputForm = {
    update_invoice_number: null | any
};

const DEFAULT_FORM_STATE = {
    update_invoice_number: null,
};

const DEFAULT_DROPDOWNS = {
    invoiceNumber: []
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        update_invoice_number: yup.string().required(t('the field is required.', { field: t('invoice number') })).nullable(),
    });
};


// @ts-ignore
function InvoiceUpdateNumber(props: IFormProps) {
    const { t, toastify, id } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [open, setOpen] = useState(false);
    const [dropdowns, setDropdowns] = useState<any>(DEFAULT_DROPDOWNS);

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => ( document.body.style.overflow = 'unset' ), 500);
        if ( props.onClose ) {
            props.onClose();
        }
    };

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: 'POST',
                url: `/suppliers/invoices/update/number/${ props.id }`,
                data: {
                    ...payloads,
                },
            });

            toastify(t("record updated", { item: t('invoice') }), "success");

            resetForm();
            handleClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");

            }
        }
    };


    const getDropdowns = async () => {
        try {
            const response = await request({
                url: '/suppliers/invoices/create',
                params: {
                    type: 'suggest-number',
                    sub_type: 'suppliers',
                    id: props.id
                }
            });

            const { data } = response.data;

            setDropdowns({
                invoiceNumber: data.invoice_number
            });

            setOpen(true);

        } catch (e) {
            setDropdowns(DEFAULT_DROPDOWNS);
        }
    };


    useEffect(() => {
        if ( !isEmpty(props.id) ) {
            setInitFormState(DEFAULT_FORM_STATE);
            getDropdowns();
        }
    }, [props.id]);

    return (
        <>
            <div>
                <Dialog
                    header={ <>{ t('update invoice number') }</> }
                    visible={ open }
                    onHide={ handleClose }
                    style={ { width: '50vw' } }
                >
                    <div>
                        <Formik
                            innerRef={ formRef }
                            enableReinitialize={ true }
                            initialValues={ initFormState }
                            onSubmit={ onSubmit }
                            validationSchema={ validationSchema(t) }
                        >
                            { (props: FormikProps<TInputForm>) => {
                                const { values, errors, setFieldValue }: any = props;

                                return (
                                    <Form onSubmit={ props.handleSubmit }>
                                        <Row>
                                            <Field component={ InputSingleSelectField }
                                                   name="update_invoice_number"
                                                   panelClassName={ "hide-p-toggler" }
                                                   options={ dropdowns.invoiceNumber }
                                                   label={ t('invoice number') }
                                                   placeholder={ t("pick") + ' ' + t("invoice number").toLowerCase() }
                                            />
                                        </Row>
                                        <Row>
                                            <Col sm={ 2 } className={ "mb-2 m-auto text-center" }>
                                                <Button type="submit" outlined size={ "small" }
                                                        loading={ props.isSubmitting } disabled={ props.isSubmitting }
                                                        title={ t("to data save into database") }
                                                        className={ "w-full d-block" }>
                                                    <i className="fas fa-save me-1" /> { t("let's save") }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form> );
                            }
                            }
                        </Formik>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

InvoiceUpdateNumber.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(InvoiceUpdateNumber);
