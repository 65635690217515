import axios from 'axios';
import * as moment from "moment-timezone";
import Auth from "./Auth";
import { get } from "lodash";


/**
 * Create an Axios Client with defaults
 */
let axiosInstance = axios.create();

axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.common['Content-Timezone'] = moment.tz.guess();
axiosInstance.defaults.baseURL = process.env.REACT_APP_API_URL + '/api';

export const client = axiosInstance;

/**
 * Request Wrapper with default success/error actions
 */
export const request = function (options: any) {
    const auth = new Auth();

    if ( !options.withoutAuth ) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${ auth.getToken() }`;
        axiosInstance.defaults.headers.common['Content-Lang'] = get(auth.getUser(), ['app_settings', 'lang']);
    }

    const onSuccess = function (response: any): any {
        return response;
    };

    const onError = function (error: any): any {
        if ( error.response ) {
            if ( error.response.status === 401 ) {
                auth.logout();
                // store.dispatch({ type: AUTH_LOGOUT });
                setTimeout(() => window.location.reload(), 2000);
                return false;
            }

            if ( error.response.status === 500 ) {

            }
        } else {
            // Something else happened while setting up the request
            // triggered the error
            // console.error('Error Message:', error.message);
        }

        return Promise.reject(error.response || error.message);
    };

    return axiosInstance(options)
    .then(onSuccess)
    .catch(onError);
};
