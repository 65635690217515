import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useEffect, useState } from "react";
import { CardBody, CardHeader, Col, Row } from "react-bootstrap";
import { capitalize, find, get, includes, isEmpty, sumBy } from "lodash";
import { request } from "../../../../utils/Request";
import { Card } from "primereact/card";
import Auth from "../../../../utils/Auth";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { confirmPopup } from "primereact/confirmpopup";
import { numberFormat, POINT_DECIMAL, utcDateToLocalDate } from "../../../../utils/Helper";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment/moment";
import Tooltip from "../../../../components/Common/Display/Tooltip";
import HasAccess from "../../../../utils/HasAccess";
import InvoicePayment from "./InvoicePayment";

const InvoiceDetail = (props: any) => {
    const { t, toastify, params } = props;
    const auth = new Auth();
    const [entity, setEntity] = useState<any>(null);
    const [isInvoicePaymentPanelActive, setIsInvoicePaymentPanelActive] = useState<any>(null);

    const getDetail = async (id: string) => {
        try {
            const response = await request({
                url: `/suppliers/invoices/${ id }`
            });

            const { data } = response.data;
            setEntity(data);
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    const onDeleteDocument = async (id: string) => {
        try {
            await request({
                url: `suppliers/invoices/delete/document/${ id }`,
                method: 'DELETE',
                data: { _method: 'DELETE' }
            });

            toastify(t("file deleted", { item: t('file') }), "success");
        } catch (e: any) {
            toastify(t("server error"), "error");
        }
    };

    const onPantheonTransfer = async (payloads: any): Promise<void> => {
        try {
            await request({
                url: `suppliers/invoices/pantheon/transfer/${ payloads }`,
                method: 'post',
                data: { _method: 'post' }
            });

            toastify(t("transfer started", { item: t('invoice') }), "success");
        } catch (e: any) {
            toastify(t("server error"), "error");
        }
    };

    const onFinalInvoice = async (): Promise<void> => {
        try {
            await request({
                url: `suppliers/invoices/finalize/${ params.operationId }`,
                method: 'POST',
            });

            toastify(t("record updated", { item: t('invoice') }), "info");
            await getDetail(params.operationId);
        } catch (e: any) {
            if ( e.status === 422 ) {
                return toastify(e.data.message, "error");
            }

            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        if ( includes(['detail'], get(params, ['operation'])) ) {
            getDetail(params.operationId);
        }
    }, [params]);

    return (
        <div>
            {
                entity &&
              <Row>
                  <Col md={ 7 } lg={ 8 } sm={ 12 } className={ "mb-4" }>
                      <Card>
                          <CardHeader>
                              <Row>
                                  <Col sm={ 12 } md={ 6 }>
                                      <h5 className={ "mb-0" }>
                                          { t('invoice') } { t('detail') }
                                          <br />
                                          <small className={ "text-gray-500" }>
                                              { entity.status === 'draft' && `#${ entity.draft_invoice_number }` }
                                              { entity.status !== 'draft' && `#${ entity.final_invoice_number }` }
                                          </small>
                                          <small className={ "ms-2 text-gray-500" }>
                                              { t('for') }
                                          </small>
                                          <small className={ "ms-2 text-gray-500" }>
                                              ${ entity.grand_total }
                                          </small>
                                          <br />
                                      </h5>
                                      <div className={"d-flex align-items-center"}>
                                          <small className={ "text-capitalize" }>
                                              <Tag value={ t(entity.status) }
                                                   style={ { background: 'linear-gradient(-225deg,#AC32E4 0%,#7918F2 48%,#4801FF 100%)' } }></Tag>
                                          </small>
                                          <HasAccess hasAnyAccess={ ['invoicepaymentsview'] }>
                                              { entity.status == "draft" &&
                                                <a
                                                  onClick={ (event: any) => confirmPopup({
                                                      target: event.currentTarget,
                                                      message: t('do you want to mark this invoice as final?'),
                                                      icon: 'pi pi-info-circle',
                                                      // @ts-ignorer
                                                      defaultFocus: 'reject',
                                                      acceptClassName: 'p-button-danger',
                                                      accept: () => onFinalInvoice(),
                                                      reject: () => {
                                                      },
                                                  }) }
                                                   className={ "ms-2 pt-1 text-primary-500 cursor-pointer" }>
                                                    { t("mark as final") }
                                                </a>
                                              }
                                          </HasAccess>
                                      </div>
                                  </Col>
                                  <Col sm={ 12 } md={ 6 } className={ "text-end" }>
                                      <Button type={ "button" } link
                                              tooltip={ t("operation cancel") } className={ "ms-2" }
                                              size={ "small" } outlined
                                              tooltipOptions={ { position: 'top' } } onClick={ props.onClose }>
                                          <i className="pi pi-arrow-left me-2" /> { t("back") }
                                      </Button>
                                  </Col>
                                  <Col sm={ 12 }>
                                      <div className={ "mt-3" }>
                                          <HasAccess hasAnyAccess={ ['invoicesview'] }>
                                              <Button onClick={
                                                  (event: any) => confirmPopup({
                                                      target: event.currentTarget,
                                                      message: t('are you sure to transfer the invoice to pantheon?'),
                                                      icon: 'pi pi-info-circle',
                                                      // @ts-ignorer
                                                      defaultFocus: 'reject',
                                                      acceptClassName: 'p-button-danger',
                                                      accept: () => onPantheonTransfer(entity.id),
                                                      reject: () => {
                                                      },
                                                  })
                                              } className={ "me-2" } size={"small"} disabled={ ( entity.status !== "final" ) }>
                                                  <i className="fas fa-fighter-jet me-1" /> { t("send to pantheon") }
                                              </Button>
                                          </HasAccess>

                                          <HasAccess hasAnyAccess={ ['invoicesview'] }>
                                              <Button severity={ "success" }  size={"small"} className={ "me-2" }
                                                      onClick={ () => setIsInvoicePaymentPanelActive(entity.id) }
                                                      outlined>
                                                  { t("make payment") }
                                              </Button>
                                          </HasAccess>

                                          <HasAccess hasAnyAccess={ ['invoicesview'] }>
                                              <Button severity={ "danger" }  size={"small"} className={ "me-2" }
                                                      onClick={ () => onDeleteDocument(entity.id) }
                                                      outlined>
                                                  { t("regenerate document") }
                                              </Button>
                                          </HasAccess>

                                          {
                                              entity.status === 'draft'
                                              &&
                                            <Tooltip tooltip={ t("draft pdf") }>
                                                <a
                                                  className={ "me-2 p-button p-component p-button-outlined p-button-primary p-button-sm" }
                                                    /*@ts-ignore*/
                                                  href={ `${ process.env.REACT_APP_API_URL }/w/invoice/document/${ entity.id }?status=draft&types=pdf&uToken=` + auth.getUser().id }
                                                  target={ "_blank" }
                                                  rel="noopener noreferrer"
                                                >
                                                    <i className="far fa-file-pdf me-1" /> { t('download pdf') }
                                                </a>
                                            </Tooltip>
                                          }

                                          {
                                              entity.status !== 'draft'
                                              &&
                                            <>
                                                <Tooltip tooltip={ t("final pdf") }>
                                                    <a
                                                      className={ "me-2 p-button p-component p-button-outlined p-button-danger p-button-sm" }
                                                        /*@ts-ignore*/
                                                      href={ `${ process.env.REACT_APP_API_URL }/w/invoice/document/${ entity.id }?status=final&types=pdf&uToken=` + auth.getUser().id }
                                                      target={ "_blank" }
                                                      rel="noopener noreferrer"
                                                    >
                                                        <i className="far fa-file-pdf me-1" /> { t('download pdf') }
                                                    </a>
                                                </Tooltip>
                                                {
                                                    ( entity.status === 'final' && entity.paid_total > 0 )
                                                    &&
                                                  <Tooltip tooltip={ t("payment report pdf") }>
                                                      <a
                                                        className={ "me-2 p-button p-component p-button-outlined p-button-danger p-button-sm" }
                                                          /*@ts-ignore*/
                                                        href={ `${ process.env.REACT_APP_API_URL }/w/invoice/document/${ entity.id }?status=final&types=pdf&payment=1&uToken=` + auth.getUser().id }
                                                        target={ "_blank" }
                                                        rel="noopener noreferrer"
                                                      >
                                                          <i className="far fa-file-pdf me-1" /> { t('payment report pdf') }
                                                      </a>
                                                  </Tooltip>
                                                }
                                            </>
                                          }


                                      </div>
                                  </Col>
                              </Row>
                          </CardHeader>
                      </Card>
                  </Col>

                  <Col md={ 5 } lg={ 4 } sm={ 12 } className={ "mb-4" }>
                      <Card className={"content-p-0"}>
                          <div className={"p-3"}>
                              <CardHeader className={"pt-3"}>
                                  <h5 className={ "mb-0" }>{ t('client') } { t('details') }</h5>
                              </CardHeader>
                              <CardBody className={ "mt-4  p-1" }>
                                  <div className={ "d-flex justify-content-between flex-wrap mb-1" }>
                                      <div><h6>{ t('recipient') }</h6></div>
                                      <div>{ get(entity, ['client', 'name']) }</div>
                                  </div>
                                  <div className={ "d-flex justify-content-between flex-wrap mb-1" }>
                                      <div><h6>{ t('recipient type') }</h6></div>
                                      <div>{ get(entity, ['recipient_type']) }</div>
                                  </div>
                                  <div className={ "d-flex justify-content-between flex-wrap mb-0 pb-3" }>
                                      <div><h6 className={"mb-0"}>{ t('email') }</h6></div>
                                      <div>{ get(entity, ['email']) }</div>
                                  </div>
                              </CardBody>
                          </div>
                      </Card>
                  </Col>

                  <Col md={ 7 } lg={ 8 } sm={ 12 } className={ "mb-4" }>
                      <Card>
                          <CardHeader>
                              <h5 className={ "mb-0" }>{ t('invoice items') }</h5>
                          </CardHeader>
                          <CardBody className={ "mt-3" }>
                              <DataTable value={ entity.invoice_items } editMode="row" dataKey="id"
                                         footer={ <>
                                             <Row>
                                                 <Col sm={ 12 }>
                                                     <Row>
                                                         <Col sm={ 9 }
                                                              className={ "text-end" }>{ t("item total") }</Col>
                                                         <Col sm={ 3 }
                                                              className={ "text-end" }>{ numberFormat(sumBy(entity.invoice_items, (r: any) => ( ( r.price > 0 ? r.price : 0 ) ) * Number(( r.qty > 0 ? r.qty : 0 ))), POINT_DECIMAL, ',', '.') }</Col>
                                                     </Row>
                                                 </Col>
                                                 <Col sm={ 12 }>
                                                     <Row>
                                                         <Col sm={ 9 }
                                                              className={ "text-end" }>{ t("discount total") }</Col>
                                                         <Col sm={ 3 }
                                                              className={ "text-end" }>{ numberFormat(sumBy(entity.invoice_items, (r: any) => parseFloat(r.discount)), POINT_DECIMAL, ',', '.') }</Col>
                                                     </Row>
                                                 </Col>
                                                 <Col sm={ 12 }>
                                                     <Row>
                                                         <Col sm={ 9 }
                                                              className={ "text-end" }>{ t("service fee total") }</Col>
                                                         <Col sm={ 3 }
                                                              className={ "text-end" }>{ numberFormat(sumBy(entity.invoice_items, (r: any) => ( !isNaN(r.service_fee_amount) && r.service_fee_amount > 0 ) ? Number(r.service_fee_amount) : 0), POINT_DECIMAL, ',', '.') }</Col>
                                                     </Row>
                                                 </Col>
                                                 <Col sm={ 12 }>
                                                     <Row>
                                                         <Col sm={ 9 }
                                                              className={ "text-end" }>{ t("vat total") }</Col>
                                                         <Col sm={ 3 }
                                                              className={ "text-end" }>{ numberFormat(sumBy(entity.invoice_items, (r: any) => ( !isNaN(r.vat_total) && r.vat_total > 0 ) ? Number(r.vat_total) : 0), POINT_DECIMAL, ',', '.') }</Col>
                                                     </Row>
                                                 </Col>
                                                 <Col sm={ 12 }>
                                                     <Row>
                                                         <Col sm={ 9 }
                                                              className={ "text-end" }>{ t("grand total") }</Col>
                                                         <Col sm={ 3 }
                                                              className={ "text-end" }>{ numberFormat(sumBy(entity.invoice_items, (r: any) => ( !isNaN(r.total) && r.total > 0 ) ? Number(r.total) : 0), POINT_DECIMAL, ',', '.') }</Col>
                                                     </Row>
                                                 </Col>
                                                 <Col sm={ 12 }>
                                                     <Row>
                                                         <Col sm={ 9 }
                                                              className={ "text-end" }>{ t("pre payment(transferable)") + ' ' + t("total") }</Col>
                                                         <Col sm={ 3 }
                                                              className={ "text-end" }>{ numberFormat(entity.t_pre_payment_total, POINT_DECIMAL, ',', '.') }</Col>
                                                     </Row>
                                                 </Col>
                                                 <Col sm={ 12 }>
                                                     <Row>
                                                         <Col sm={ 9 }
                                                              className={ "text-end" }>{ t("pre payment(not transferable)") + ' ' + t("total") }</Col>
                                                         <Col sm={ 3 }
                                                              className={ "text-end" }>{ numberFormat(entity.nt_pre_payment_total, POINT_DECIMAL, ',', '.') }</Col>
                                                     </Row>
                                                 </Col>
                                                 <Col sm={ 12 }>
                                                     <Row>
                                                         <Col sm={ 9 }
                                                              className={ "text-end" }>{ t("final") + ' ' + t("total") }</Col>
                                                         <Col sm={ 3 }
                                                              className={ "text-end" }>{ numberFormat(sumBy(entity.invoice_items, (r: any) => ( !isNaN(r.total) && r.total > 0 ) ? Number(r.total) : 0) - entity.nt_pre_payment_total, POINT_DECIMAL, ',', '.') }</Col>
                                                     </Row>
                                                 </Col>
                                             </Row>
                                         </> }>
                                  <Column field="qty" header={ t("qty") } />
                                  <Column field="name" header={ t("name") } body={ (data: any) => {
                                      return (
                                          <>
                                              <div>{ data.title }</div>
                                          </>
                                      );
                                  } } />
                                  <Column field="price" header={ t("price") } body={ (data: any) => {
                                      return (
                                          <>
                                              <div>{ numberFormat(data.price, POINT_DECIMAL, ',', '.') }</div>
                                          </>
                                      );
                                  } } />
                                  <Column field="discount" header={ t("discount") } body={ (data: any) => {
                                      return (
                                          <>
                                              <div>{ numberFormat(data.discount, POINT_DECIMAL, ',', '.') }</div>
                                              <div>{ numberFormat(data.discount_percentage, POINT_DECIMAL, ',', '.') }</div>
                                          </>
                                      );
                                  } } />
                                  <Column field="vat" header={ t("vat") } body={ (data: any) => {
                                      return (
                                          <>
                                              <div>{ numberFormat(data.vat, POINT_DECIMAL, ',', '.') }</div>
                                          </>
                                      );
                                  } } />
                                  <Column field="vat_total" header={ t("vat total") } body={ (data: any) => {
                                      return (
                                          <>
                                              <div>{ numberFormat(data.vat_total, POINT_DECIMAL, ',', '.') }</div>
                                          </>
                                      );
                                  } } />
                                  <Column field="service_fee_amount" header={ t("service amount") }
                                          body={ (data: any) => {
                                              return (
                                                  <>
                                                      <div>{ numberFormat(data.service_fee_amount, POINT_DECIMAL, ',', '.') }</div>
                                                  </>
                                              );
                                          } } />
                                  <Column field="total" header={ t("total") }
                                          bodyClassName={ "text-end" } body={ (data: any) => {
                                      return (
                                          <>
                                              <div>{ numberFormat(data.total, POINT_DECIMAL, ',', '.') }</div>
                                          </>
                                      );
                                  } } />
                              </DataTable>
                          </CardBody>
                      </Card>
                  </Col>

                  <Col md={ 5 } lg={ 4 } sm={ 12 } className={ "mb-4" }>
                      <Card>
                          <CardHeader>
                              <h5 className={ "mb-0" }>{ t('invoice') } { t('details') }</h5>
                          </CardHeader>
                          <CardBody className={ "mt-4" }>
                              <div className={ "d-flex justify-content-between flex-wrap mb-1" }>
                                  <div><h6>{ t('legal entity') }</h6></div>
                                  <div>{ get(entity, ['legal_entity', 'company_name']) }</div>
                              </div>
                              <div className={ "d-flex justify-content-between flex-wrap mb-1" }>
                                  <div><h6>{ t('draft number') }</h6></div>
                                  <div>{ get(entity, ['draft_invoice_number']) }</div>
                              </div>
                              <div className={ "d-flex justify-content-between flex-wrap mb-1" }>
                                  <div><h6>{ t('final number') }</h6></div>
                                  <div>{ get(entity, ['final_invoice_number']) }</div>
                              </div>
                              <div className={ "d-flex justify-content-between flex-wrap mb-1" }>
                                  <div><h6>{ t('bank account number') }</h6></div>
                                  <div>{ get(entity, ['bank_account_number']) }</div>
                              </div>
                              <div className={ "d-flex justify-content-between flex-wrap mb-1" }>
                                  <div><h6>{ t('service fee') }</h6></div>
                                  <div>{ get(entity, ['service_fee']) }</div>
                              </div>
                              <div className={ "d-flex justify-content-between flex-wrap mb-1" }>
                                  <div><h6>{ t('discount code') }</h6></div>
                                  <div>{ get(entity, ['discount_code', 'code']) }</div>
                              </div>
                              <div className={ "d-flex justify-content-between flex-wrap mb-1" }>
                                  <div><h6>{ t('created at') }</h6></div>
                                  <div>{ utcDateToLocalDate(entity.created_at) }</div>
                              </div>
                              <div className={ "d-flex justify-content-between flex-wrap mb-1" }>
                                  <div><h6>{ t('modified at') }</h6></div>
                                  <div>{ utcDateToLocalDate(entity.updated_at) }</div>
                              </div>
                          </CardBody>
                      </Card>
                  </Col>

                  <Col md={ 7 } lg={ 8 } sm={ 12 } className={ "mb-4" }>
                      <Card>
                          <CardHeader>
                              <h5 className={ "mb-0" }>{ t('history') }</h5>
                          </CardHeader>
                          <CardBody className={ "mt-2" }>
                              {
                                  entity.history
                                  && entity.history.map((item: any) => {
                                      return ( <div className={ "m-timeline" }>
                                          <div>
                                              <section className="year">
                                                  <h3>
                                                      { moment(utcDateToLocalDate(item.created_at, "YYYY-MM-DD HH:mm:ss")).format("MMM") }
                                                  </h3>
                                                  <section>
                                                      <ul>
                                                          <li>
                                                              <strong>{ get(item, ['author', 'name']) }</strong> { t("updated invoice with the status") }
                                                              <strong
                                                                  className={ "text-gray-800 ms-1" }>{ t(item.status) }</strong>
                                                              <div
                                                                  className={ "text-gray-800" }>
                                                                  <small>{ item.system_note }</small>
                                                              </div>
                                                              <div
                                                                  className={ "text-gray-800" }>{ utcDateToLocalDate(item.created_at) }</div>
                                                          </li>
                                                      </ul>
                                                  </section>
                                              </section>
                                          </div>
                                      </div> );
                                  })
                              }
                          </CardBody>
                      </Card>
                  </Col>

                  <Col md={ 7 } lg={ 8 } sm={ 12 } className={ "mb-4" }>
                      <Card>
                          <CardHeader>
                              <h5 className={ "mb-0" }>{ t('payment histories') }</h5>
                          </CardHeader>
                          <CardBody className={ "mt-3" }>
                              <DataTable value={ entity.payments }>
                                  <Column field="payment_mode" header={ t('payment mode') }
                                          body={ (row: any) => {
                                              return <>
                                                  {
                                                      !isEmpty(
                                                          find(props.dropdowns.paymentMethods, {
                                                              id: get(row, ['payment_mode'])
                                                          })
                                                      )
                                                          ? get(find(props.dropdowns.paymentMethods, {
                                                              id: row.payment_mode
                                                            }), ['label'])
                                                          : row.payment_mode
                                                  }
                                              </>
                                          } } />
                                  <Column field="payment_date" header={ t('payment date') }
                                          body={ (row: any) => utcDateToLocalDate(row.payment_date) } />
                                  <Column field="is_prepayment" header={ t('prepayment connection') }
                                          body={ (row: any) => row.is_prepayment ? t("deduct from pre payment") : t("dont deduct from prepayment") } />
                                  <Column field="status" header={ t('status') }
                                          body={ (row: any) => ( row.status ? t(row.status) : "-" ) } />
                                  <Column field="total" header={ t('total') } />
                              </DataTable>
                          </CardBody>
                      </Card>
                  </Col>

                  <InvoicePayment
                    id={ isInvoicePaymentPanelActive }
                    toastify={ props.toastify }
                    onClose={ () => {
                        setIsInvoicePaymentPanelActive(null);
                        getDetail(params.operationId)
                    } }
                  />
              </Row>
            }
            {
                !entity &&
              <>Loading...</>
            }
        </div>

    );
};

InvoiceDetail.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(InvoiceDetail));
