import React from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "primereact/badge";

function SpotLoadStatus(props: any) {
    const { status, label, textable } = props;
    const { t } = useTranslation();
    if ( status === 'pending' ) {
        return (
            <>
                { textable &&
                <span className={ "text-red-500" }>
                    { label ? label : t('pending') }
                </span>
                }
                {
                    !textable &&
                    <Badge severity={ "danger" } value={ label ? label : t('pending') } />
                }
            </>
        );
    }

    if ( status === 'announced' ) {
        return (
            <>
                { textable &&
                <span className={ "text-primary-500" }>
                    { label ? label : t('announced') }
                </span>
                }
                {
                    !textable &&
                    <Badge severity={ "info" } value={ label ? label : t('announced') } />
                }
            </>
        );
    }

    if ( status === 'in-transit' ) {
        return (
            <>
                { textable &&
                <span className={ "text-yellow-500" }>
                    { label ? label : t('in transit') }
                </span>
                }
                {
                    !textable &&
                    <Badge severity={ "warning" } value={ label ? label : t('in transit') } />
                }
            </>
        );
    }

    if ( status === 'delivered' ) {
        return (
            <>
                { textable &&
                <span className={ "text-green-500" }>
                    { label ? label : t('delivered') }
                </span>
                }
                {
                    !textable &&
                    <Badge severity={ "success" } value={ label ? label : t('delivered') } />
                }
            </>
        );
    }

    if ( status === 'archived' ) {
        return (
            <>
                { textable &&
                <span className={ "text-green-500" }>
                    { label ? label : t('archived') }
                </span>
                }
                {
                    !textable &&
                    <Badge severity={ "success" } value={ label ? label : t('archived') } />
                }
            </>
        );
    }

    return (
        <span>-</span>
    );
}

export default SpotLoadStatus;
