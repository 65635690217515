import { Button } from "primereact/button";
import { forwardRef, useContext, useImperativeHandle, useRef, useState } from "react";
import { AppTopbarRef } from "../../types";
import { LayoutContext } from "../../contexts/LayoutContext";
import Auth from "../../utils/Auth";
import { useLocation, useNavigate } from "react-router-dom";
import { isArray, isEmpty, split } from "lodash";
import { useTranslation } from "react-i18next";
import { random } from "../../utils/Helper";

const AppTopbar = forwardRef<AppTopbarRef>((props, ref) => {
    const { onMenuToggle: onMenuToggle, showProfileSidebar: showProfileSidebar, showConfigSidebar: showConfigSidebar } =
        useContext(LayoutContext);
    const [vinNumbers, setVinNumbers] = useState<string[]>([]);
    const [search, setSearch] = useState<string[]>([]);
    const menubuttonRef = useRef(null);
    const auth = new Auth();
    const navigate = useNavigate();
    const location = useLocation();
    const user: any = auth.getUser() || { 'name': "" };
    const inputSearchRef: any = useRef();
    const { t } = useTranslation();

    const onConfigButtonClick = () => {
        showConfigSidebar();
    };

    useImperativeHandle(ref, () => ( {
        menubutton: menubuttonRef.current,
    } ));

    const onSearchVin = () => {
        if ( location.pathname == '/orders' ) {
            navigate('/temporary', { replace: true });
            setTimeout(() => navigate('/orders', {
                state: { vin_numbers: vinNumbers.join(" ") },
                replace: true
            }), 300);
        } else {
            navigate('/orders', { state: { vin_numbers: vinNumbers.join(" ") } });
        }
    };

    const removeSearchTag = (indexToRemove:any) => {
        const updatedVinNumbers = vinNumbers.filter((_, index) => index !== indexToRemove);
        setVinNumbers(updatedVinNumbers);
    };

    const setSearchTag = (event:any) => setSearch(event.target.value);

    const setSearchByPasteTag = (event:any) => {
        const vins: any = [];
        const pastedText = event.clipboardData.getData('text');
        const searchData = split(pastedText, /\s+/);

        if ( isArray(searchData) ) {
            [...searchData, ...vinNumbers].filter((item: any) => !isEmpty(item)).map((i: any) => vins.push(i));
            setVinNumbers(vins);
        }

        setTimeout(() => {
            // @ts-ignore
            setSearch('');
            if ( inputSearchRef && inputSearchRef.current ) {
                inputSearchRef.current.value = '';
            }
        }, 500);
    }

    const removeAllSearchTag = () => {
        setVinNumbers([]);
        if ( location.pathname == '/orders' ) {
            const event = new CustomEvent('searchEvent', {
                detail: { external_vin_numbers: [] }
            });

            window.dispatchEvent(event);
        }
    }

    return (
        <div className="layout-topbar">
            <div className="topbar-start">
                <button
                    ref={ menubuttonRef }
                    type="button"
                    className="topbar-menubutton p-link p-trigger"
                    onClick={ onMenuToggle }
                >
                    <i className="pi pi-bars"></i>
                </button>
            </div>

            <div className="topbar-end">
                <ul className="topbar-menu">
                    {
                        auth.hasAnyAccess(['ordersview']) &&
                          <li className="topbar-search">
                              <div className="app-search">
                                  <div className="d-inline-block" id={ 'header-search-vin' }>
                                      <div className="position-relative custom-tag-search-container">
                                          <div className="custom-tag-container">
                                              <div className="position-relative">
                                                  <ul
                                                    className="form-control custom-tag-multi-value-container surface-border border-2 border-round">
                                                      { vinNumbers.map((vinNumber: string, index: number) => (
                                                          <li key={ index } className="custom-tag-value-item">
                                                              { vinNumber }
                                                              <button className="close-indicator ms-1"
                                                                      onClick={ () => removeSearchTag(index) }
                                                                      type="button">
                                                                  <i className="fas fa-times" />
                                                              </button>
                                                          </li>
                                                      )) }
                                                      <input
                                                          // @ts-ignore
                                                        ref={ inputSearchRef }
                                                        className="custom-tag-search-input"
                                                        placeholder={ t('enter vin(s)') }
                                                        defaultValue={ search }
                                                        onKeyUp={ setSearchTag }
                                                        onPaste={ setSearchByPasteTag }
                                                      />
                                                      { vinNumbers.length > 0 && (
                                                          <button
                                                              className="close-indicator-main"
                                                              onClick={ removeAllSearchTag }
                                                              title="Clear"
                                                              type="button"
                                                          >
                                                              <i className={ "fas fa-times" } />
                                                          </button>
                                                      ) }
                                                  </ul>
                                                  <button className="btn-vin-search" onClick={ onSearchVin }
                                                          title="Search">
                                                      <i className="fas fa-search" />
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </li>
                    }
                    <li className="ml-3">
                        <Button
                            type="button"
                            icon="pi pi-cog"
                            text
                            rounded
                            severity="secondary"
                            className="flex-shrink-0"
                            onClick={ onConfigButtonClick }
                        ></Button>
                    </li>
                    <li className="topbar-profile mr-2">
                        <button
                            type="button"
                            className="p-link"
                            onClick={ showProfileSidebar }
                        >
                            {/*<img*/ }
                            {/*    src="/layout/images/avatar/avatar.png"*/ }
                            {/*    alt="Profile"*/ }
                            {/*/>*/ }

                            {
                                ( user && user.profile && user.profile.id ) &&
                              <img
                                className="border-circle"
                                src={ `${ process.env.REACT_APP_DOCUMENT_URL }/${ user.profile.id }` }
                                alt={ user.name }
                              />
                            }

                            {
                                ( user && !user.profile ) &&
                              <img
                                className="border-circle"
                                src={ `https://ui-avatars.com/api/?name=${ user.name }&background=E06469&color=fff` }
                                alt={ user.name }
                              />
                            }
                        </button>
                    </li>
                    <li className="ml-4 mr-4">
                        <div className="loader" style={ { visibility: "hidden" } } id={ "preloader" }></div>
                    </li>
                </ul>
            </div>
        </div>
    );
});

AppTopbar.displayName = "AppTopbar";

export default AppTopbar;
