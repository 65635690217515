import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { numberFormat, POINT_DECIMAL } from "../../../../utils/Helper";
import Tooltip from "../../../../components/Common/Display/Tooltip";

const InvoiceAmountDisplay = (props: any) => {
    const { total, paid, status, t } = props;
    if ( status == 'final' ) {
        if ( Number(paid) > Number(total) ) {
            return <>
                <Tooltip tooltip={ t('paid amount') }>
                    <span className={ 'text-success' }>{ numberFormat(total ?? 0, POINT_DECIMAL, ',', '.') }</span>
                </Tooltip>
            </>;
        } else {
            return <>
                <span>{ numberFormat(total ?? 0, POINT_DECIMAL, ',', '.') }</span><span
                className={ "ps-1 pe-1" }>/</span>
                <Tooltip tooltip={ t('unpaid amount') }>
                    <span className={ 'text-danger' }>
                        { numberFormat(( Number(paid) > Number(total) ? 0 : Number(total - paid) ) ?? 0, POINT_DECIMAL, ',', '.') }
                    </span>
                </Tooltip>
            </>;
        }
    }
    return <span>{ numberFormat(total ?? 0, POINT_DECIMAL, ',', '.') }</span>;
};

InvoiceAmountDisplay.propTypes =
    {
        t: PropTypes.any,
        total: PropTypes.any,
        paid: PropTypes.any,
        status: PropTypes.any,
    }
;
export default withTranslation()(InvoiceAmountDisplay);
