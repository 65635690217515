import { withTranslation } from "react-i18next";
import WithRouter from "../../../../components/Common/WithRouter";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { get, includes } from "lodash";
import { request } from "../../../../utils/Request";
import { Badge } from "primereact/badge";

const RoleDetail = (props: any) => {
    const { t, toastify, params } = props;
    const [entity, setEntity] = useState<any>(null);

    const getDetail = async (id: string) => {
        try {
            const response = await request({
                url: `/suppliers/roles/${ id }`
            });

            const { data } = response.data;
            setEntity(data);
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        if ( includes(['detail'], get(props.params, ['operation'])) ) {
            getDetail(params.operationId);
        }
    }, [props.params]);

    return (
        <div className={ "p-2 bg-white" }>
            {
                entity &&
                <>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('name') }</span>
                            </h6>
                        </Col>
                        <Col md="6" lg="6" sm="12">
                            <div className="text-end">{ entity.name }</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" lg="6" sm="12">
                            <h6>
                                <span className="text-capitalize">{ t('permissions') }</span>
                            </h6>
                        </Col>
                        <Col md="12" lg="12" sm="12">
                            {
                                entity.permissions.map((item: any, index: number) => {
                                    return ( <span key={ index } className={ 'me-2' }>
                                                      <Badge severity="info" value={ item.name } />
                                                    </span> );
                                })
                            }
                        </Col>
                    </Row>
                </>
            }
        </div>

    );
};

RoleDetail.propTypes = {
    // t: PropTypes.any,
};

export default withTranslation()(WithRouter(RoleDetail));
