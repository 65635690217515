import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { FormikProps, Formik, Form, Field } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Col, Row } from "react-bootstrap";
import { Sidebar } from "primereact/sidebar";
import { find, findIndex, get, isEmpty, sumBy } from "lodash";
import { Button } from "primereact/button";
import { dateToUtcDate, formatDate, random, STORE_POINT_DECIMAL, utcDateToLocalDate } from "../../../../utils/Helper";
import { InputSingleSelectField } from "../../../../components/Shared/InputSingleSelectField";
import InputDateTime from "../../../../components/Shared/InputDateTime";
import { InputField } from "../../../../components/Shared/InputField";
import HasAccess from "../../../../utils/HasAccess";
import { InputTextAreaField } from "../../../../components/Shared/InputTextAreaField";
import { InputSwitchField } from "../../../../components/Shared/InputSwitchField";

interface IFormProps {
    t?: any | undefined;
    onClose: any;
    id: any;
    toastify: any;
}

type TInputForm = {
    id?: any,
    payment_date?: any,
    payment_mode?: any,
    is_prepayment?: any,
    status?: any,
    total?: any,
    token?: any
    comment?: any
};

const DEFAULT_DROPDOWNS = {
    paymentMethods: [],
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        payment_mode: yup.string().required(t('the field is required.', { field: t('payment mode') })).nullable(),
        is_prepayment: yup.string().required(t('the field is required.', { field: t('pre payment') })).nullable(),
        status: yup.string().required(t('the field is required.', { field: t('status') })).nullable(),
        payment_date: yup.date(t('the field value is not valid', { field: t("payment date") })).required(t('the field is required.', { field: t('payment date') })).nullable(),
        total: yup.number().min(0).required(t('the field is required.', { field: t('total') })).nullable(),
    });
};

const DEFAULT_FORM_STATE = {
    id: null,
    payment_date: null,
    payment_mode: null,
    is_prepayment: false,
    status: 'paid',
    total: 0,
    comment: null,
    token: random(10),
};

function InvoicePayment(props: IFormProps) {
    const { t, toastify } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);
    const [entity, setEntity] = useState<any>({});
    const [invoice, setInvoice] = useState<any>(null);
    const [dropdowns, setDropdowns] = useState<any>(DEFAULT_DROPDOWNS);
    const [payments, setPayments] = useState<any>([]);
    const [total, setTotal] = useState<number>(0);

    const onClose = () => {
        setPanelState(false);

        if ( props.onClose ) {
            props.onClose();
        }
    };

    const onSubmit = async () => {
        try {
            const response = await request({
                method: 'PUT',
                url: `/suppliers/invoices/receivable/payments/${ entity.id }`,
                data: {
                    payments: payments.map((item: any) => ({...item, payment_date: dateToUtcDate(item.payment_date)})),
                }
            });

            toastify(t("record updated", { item: t('payment') }), "success");
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                const ref: any = formRef.current;
                ref.setErrors(e.data.errors);
                if ( !isEmpty(e.data.errors.invalid_total) ) {
                    toastify(t("invalid payment total"), "error");
                }
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    const onSave = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        const _payments: any = [...payments];
        let existing = find(payments, { token: payloads.token });

        if ( !isEmpty(existing) ) {
            const index = findIndex(_payments, { token: payloads.token });
            existing = { ...payloads };
            _payments[index] = existing;
        } else {
            _payments.push({
                ...payloads,
                token: random(10),
            });
        }

        setPayments(_payments);
        resetForm();

        const _DEFAULT_FORM_STATE = { ...DEFAULT_FORM_STATE };
        _DEFAULT_FORM_STATE.total = ( total - sumBy(_payments, 'total') > 0 )
            ? Number(( total - sumBy(_payments, 'total') ).toFixed(STORE_POINT_DECIMAL))
            : 0;

        setInitFormState({
            ..._DEFAULT_FORM_STATE,
            is_prepayment: (entity && entity.status === "draft"),
            payment_mode:  (entity && entity.status === "draft" ? "cash" : "bank transfer"),
        });
    };

    const onEdit = (token: any) => {
        const payment: any = find(payments, { token: token });

        if ( payment ) {
            setInitFormState(payment);
        } else {
            toastify(t("no data"), "error");
        }
    };

    const onDelete = (id: string) => {
        const shallowPayments = payments.filter((item: any) => item.token !== id);
        setPayments(shallowPayments);
    };

    const getDropdowns = async () => {
        try {
            const response = await request({
                url: '/suppliers/invoices/receivable/payments/create',
                params: {
                    type: 'list',
                    sub_type: 'suppliers'
                }
            });

            const { data } = response.data;
            setDropdowns({
                paymentMethods: data.paymentMethods.map((item: any) => ({...item, key: item.id})),
                paymentStatus: [
                    { id: "paid", label: t("paid") },
                    { id: "open", label: t("open") },
                ]
            });

            await getDetail(props.id);
        } catch (e) {
            setDropdowns(DEFAULT_DROPDOWNS);
        }
    };

    const getDetail = async (id: string) => {
        try {
            const response = await request({
                url: `/suppliers/invoices/${ id }`
            });

            const { data } = response.data;
            setInvoice(data);
            await getList(data.id);
        } catch (error) {
            toastify(t("server error"), "error");
        }
    };

    const getList = async (id: string): Promise<void> => {
        try {
            const response = await request({
                url: `/suppliers/invoices/receivable/payments`,
                params: {
                    invoice_id: id, // to get a specific invoice detail
                    filters: {
                        'invoices': [id]
                    }
                }
            });

            const { data, extra } = response.data;

            setEntity({
                id: id,
                status: get(extra, ['status']),
                title: get(extra, ['title']),
                pre_paid: get(extra, ['pre_payment_positive']),
                available: (get(extra, ['pre_payment_positive']) - get(extra, ['pre_payment_negative'])),
                used: get(extra, ['pre_payment_negative']),
            });

            setPayments(data.map((item: any) => ( { ...item, payment_date: utcDateToLocalDate(item.payment_date, 'YYYY-MM-DD HH:mm:ss'), token: item.id } )));
            setPanelState(true);

            const _DEFAULT_FORM_STATE = DEFAULT_FORM_STATE;

            if ( extra.total && extra.paid && ( extra.total - extra.paid ) > 0 ) {
                setTotal(extra.total);
                _DEFAULT_FORM_STATE.total = Number((extra.total - extra.paid).toFixed(STORE_POINT_DECIMAL));
            }

            setTimeout(() => {
                setInitFormState({
                    ..._DEFAULT_FORM_STATE,
                    is_prepayment: (extra.status === "draft"),
                    payment_mode:  ((extra.status === "draft") ? "cash" : "bank transfer"),
                });
            }, 500);

        } catch (error: any) {
            // console.log(error)
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        if ( !isEmpty(props.id) ) {
            setInitFormState(DEFAULT_FORM_STATE);

            getDropdowns();
        }
    }, [props.id]);

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('make payment') }#{ entity?.title }</h5>
                            <p>{ t("using this form you can paid invoices amount") }</p>
                        </div>
                    }
                    visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSave }
                        validationSchema={ validationSchema(t) }
                    >
                        { (props: FormikProps<TInputForm>) => {
                            return (
                                <Form onSubmit={ props.handleSubmit }>
                                    <Row className={ "mt-4 md:mb-7 sm:mb-7" }>
                                        <Col sm={ 12 } md={ 4 }>
                                            <Field
                                                component={ InputSingleSelectField }
                                                name="payment_mode"
                                                options={ dropdowns.paymentMethods }
                                                label={ t('payment mode') }
                                                placeholder={ t('pick') + ' ' + t('payment mode') }
                                                isRequired
                                                autoFocus
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 4 }>
                                            <Field
                                                placeholder={ t("payment date") }
                                                component={ InputDateTime }
                                                name="payment_date"
                                                type={ "datetime" }
                                                label={ t("payment date") }
                                                // maxDate={ moment().tz(moment.tz.guess()).toDate() }
                                                isRequired
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 4 }>
                                            <Field component={ InputField }
                                                   type={ "number" }
                                                   name="total"
                                                   label={ t("total") }
                                                   placeholder={ t("enter") + ' ' + t("total").toLowerCase() }
                                                   isRequired />
                                        </Col>
                                        <Col sm={ 12 } md={ 4 }>
                                            <Field
                                                withoutLabel={true}
                                                component={ InputSingleSelectField }
                                                name="status"
                                                options={ [
                                                    { id: "paid", key: "paid", label: t('paid') },
                                                    { id: "open", key: "open", label: t("open") }
                                                ] }
                                                placeholder={ t('pick') + ' ' + t('status') }
                                                isRequired
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 8 }>
                                            <Field component={ InputSwitchField }
                                                   withoutLabel={ true }
                                                   disabled={ entity && entity.status === "draft" }
                                                   name="is_prepayment"
                                                   trueLabel={ t("deduct from pre payment") }
                                                   falseLabel={ t("dont deduct from prepayment") }
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 12 }>
                                            <Field component={ InputTextAreaField }
                                                   name="comment"
                                                   label={ t("comment") }
                                                   placeholder={ t("enter") + ' ' + t("comment").toLowerCase() } />
                                        </Col>
                                        <Col sm={12} md={{ span: 4, offset: 8 }} className={"text-end"}>
                                            <Row>
                                                <Col sm={ 12 } md={6}>
                                                    {
                                                        ( initFormState && initFormState.id ) ?
                                                            <Button type="submit" severity={ undefined } size={ "small" } outlined
                                                                    title={ t("save") } className={ "d-block w-full" }>
                                                                <i className="fas fa-plus" />
                                                            </Button>
                                                            :
                                                            <HasAccess hasAllAccess={ ['invoicepaymentsstore'] }>
                                                                <Button type="submit" severity={ undefined } size={ "small" } outlined
                                                                        title={ t("save") } className={ "d-block w-full" }>
                                                                    <i className="fas fa-plus" />
                                                                </Button>
                                                            </HasAccess>
                                                    }
                                                </Col>
                                                <Col sm={ 12 } md={6}>
                                                    <HasAccess hasAnyAccess={ ['invoicepaymentsstore','invoicepaymentsupdate'] }>
                                                        <Button type="button" severity={ "danger" } size={ "small" } outlined
                                                                className={ "d-block w-full" } tooltip={t("reset")}
                                                                tooltipOptions={ { position: "top" } }
                                                                onClick={ () => props.resetForm() }>
                                                            <i className="fas fa-redo-alt" />
                                                        </Button>
                                                    </HasAccess>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            );
                        } }
                    </Formik>
                    {
                        <>
                            <HasAccess hasAnyAccess={ ['invoicepaymentsview'] }>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <strong>{ t('available') }: { entity.available }</strong>
                                        <strong className={ "ms-1 text-green-500" }>{ t('prepaid') }: { entity.pre_paid }</strong>
                                        <strong className={ "ms-1 text-red-500" }>{ t('used') }: { entity.used }</strong>
                                    </div>
                                </div>
                                <table className={ "fl-table mb-7" }>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{ t('payment mode') }</th>
                                            <th>{ t('payment date') }</th>
                                            <th>{ t('prepayment connection') }</th>
                                            <th>{ t('status') }</th>
                                            <th>{ t('total') }</th>
                                            <th className={ "text-end" }>{ t('action') }</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { payments && payments.map((item: any, index: any) => {
                                            return (
                                                <tr key={ index }>
                                                    <td>{ index + 1 }</td>
                                                    <td className={ "text-capitalize" }>
                                                        {
                                                            !isEmpty(
                                                                find(dropdowns.paymentMethods, {
                                                                    id: item.payment_mode
                                                                })
                                                            )
                                                            ? get(find(dropdowns.paymentMethods, {
                                                                id: item.payment_mode
                                                            }), ['label'])
                                                            : item.payment_mode
                                                        }
                                                    </td>
                                                    <td>{ formatDate(item.payment_date, 'DD-MM-YYYY hh:mm A') }</td>
                                                    <td>{ item.is_prepayment ? t("deduct from pre payment") : t("dont deduct from prepayment") }</td>
                                                    <td>{ item.status ? t(item.status) : "-" }</td>
                                                    <td>{ item.total }</td>
                                                    <td className={ "text-end" }>
                                                        <HasAccess hasAnyAccess={ ['invoicepaymentsupdate'] }>
                                                            <a
                                                                onClick={ () => onEdit(item.token) }
                                                                className={ `cursor-pointer text-info me-1` }
                                                                target={ "_blank" }
                                                                rel="noopener noreferrer">
                                                                <i className="fas fa-edit" />
                                                            </a>
                                                        </HasAccess>
                                                        <HasAccess hasAnyAccess={ ['invoicepaymentsdestroy'] }>
                                                            <a
                                                                onClick={ () => onDelete(item.token) }
                                                                className={ `cursor-pointer text-danger me-1` }
                                                                target={ "_blank" }
                                                                rel="noopener noreferrer">
                                                                <i className="fas fa-trash-alt" />
                                                            </a>
                                                        </HasAccess>
                                                    </td>
                                                </tr>
                                            );
                                        }) }
                                        {
                                            ( get(invoice, ['discount_code', 'is_internal_discount']) === true ) &&
                                          <tr key={ 9999 }>
                                              <td>{ payments.length > 0 ? payments.length : 1 }</td>
                                              <td>{ t("internal discount") }</td>
                                              <td>{ formatDate(invoice.created_at, 'DD-MM-YYYY hh:mm A') }</td>
                                              <td>-</td>
                                              <td>-</td>
                                              <td>{ invoice.discount }</td>
                                              <td className={ "text-end" }>-</td>
                                          </tr>
                                        }
                                    </tbody>
                                </table>
                            </HasAccess>
                        </>
                    }

                    <div className="filter-card-footer"
                         style={ { width: '785px' } }>
                        <Button type={ "button" }
                                onClick={ onSubmit }
                                tooltip={ t("to data save into database") }
                                size={ "small" } outlined
                                tooltipOptions={ { position: 'top' } }
                        >
                            <i className="pi pi-save me-2" />
                            { t("let's save") }
                        </Button>
                        <Button type={ "button" }
                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                size={ "small" } severity={ "danger" } outlined
                                tooltipOptions={ { position: 'top' } }
                                onClick={ onClose }
                        >
                            <i className="pi pi-arrow-left me-2" />
                            { t("cancel") }
                        </Button>
                    </div>
                </Sidebar>
            </div>
        </>
    );
}

//     InvoicePayment.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(InvoicePayment);
