import React, { useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { FormikProps, Formik, Field } from "formik";
import config from "../../../config";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import RevenuePerDriver from "./partials/RevenuePerDriver";
import RevenuePerTransportVehicle from "./partials/RevenuePerTransportVehicle";
import RevenuePerClient from "./partials/RevenuePerClient";
import PaymentPerClient from "./partials/PaymentPerClient";
import KilometerPerDriver from "./partials/KilometerPerDriver";
import KilometerPerTransportVehicle from "./partials/KilometerPerTransportVehicle";
import InputDateTime from "../../../components/Shared/InputDateTime";
import moment from "moment";
import { Button } from "primereact/button";

type TInputFilterForm = {
    from: Date | string | null;
    to: Date | string | null;
};

const DEFAULT_FORM_STATE = {
    from: moment().startOf("year").startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    to: moment().endOf("year").endOf("day").format("YYYY-MM-DD HH:mm:ss"),
};

const ManagementDashboard = (props: any) => {
    const { t } = props;
    const formRef: any = useRef();
    document.title = `${ t("dashboard") } | ${ config.appNameForTitle }`;
    // @ts-ignore
    const [initFormState, setInitFormState] = useState<TInputFilterForm>(DEFAULT_FORM_STATE);
    // @ts-ignore
    const [filterPayloads, setFilterPayloads] = useState<TInputFilterForm>(DEFAULT_FORM_STATE);
    const onSubmit = async (payloads: any) => setFilterPayloads(payloads);

    const onClickQuickFilter = (type: string = "7 days") => {
        let fromDate = moment().subtract(7, "day").startOf("day").format("YYYY-MM-DD HH:mm:ss");
        let toDate = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");

        if ( type === "this year" ) {
            fromDate = moment().startOf("year").startOf("day").format("YYYY-MM-DD HH:mm:ss");
            toDate = moment().endOf("year").endOf("day").format("YYYY-MM-DD HH:mm:ss");
        }

        if ( type === "this month" ) {
            fromDate = moment().startOf("month").startOf("day").format("YYYY-MM-DD HH:mm:ss");
            toDate = moment().endOf("month").endOf("day").format("YYYY-MM-DD HH:mm:ss");
        }

        formRef.current.setValues({ from: fromDate, to: toDate });
    };

    return (
        <div className="page-content">
            <Container fluid>
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-0 font-size-18">{ t("management dashboard") }</h4>
                    </div>
                </div>
                <Row>
                    <Col sm={ 12 }>
                        <Formik
                            innerRef={ formRef }
                            enableReinitialize={ true }
                            initialValues={ initFormState }
                            onSubmit={ onSubmit }
                        >
                            { (props: FormikProps<TInputFilterForm>) => {
                                return (
                                    <Form onSubmit={ props.handleSubmit }>
                                        <Row>
                                            <Col sm={ 12 } md={ 3 }>
                                                <Field component={ InputDateTime }
                                                       name="from"
                                                       type={ "date" }
                                                       label={ t("from date") }
                                                       placeholder={ t("pick a date") }
                                                       isRequired
                                                       autofocus={true}
                                                />
                                            </Col>
                                            <Col sm={ 12 } md={ 3 }>
                                                <Field component={ InputDateTime }
                                                       name="to"
                                                       type={ "date" }
                                                       label={ t("to date") }
                                                       placeholder={ t("pick a date") }
                                                       isRequired
                                                />
                                            </Col>
                                            <Col sm={ 12 } md={ 5 } className={"mt-4"}>
                                                <Button type="submit" size={ "small" } outlined>
                                                    <i className="fas fa-filter me-1" />
                                                    { t("filter") }
                                                </Button>
                                                <Button type="button" size={ "small" } outlined onClick={ () => onClickQuickFilter("7 days") } className={"ms-1"}>
                                                    <i className="fas fa-filter me-1" />
                                                    { t("7 days") }
                                                </Button>
                                                <Button type="button" size={ "small" } outlined
                                                        onClick={ () => onClickQuickFilter("this month") }
                                                        className={ "ms-1" }>
                                                    <i className="fas fa-filter me-1" />
                                                    { t("this month") }
                                                </Button>

                                                <Button type="button" size={ "small" } outlined
                                                        onClick={ () => onClickQuickFilter("this year") } className={"ms-1"}>
                                                    <i className="fas fa-filter me-1" />
                                                    { t("this year") }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                );
                            } }
                        </Formik>
                    </Col>


                    <Col sm={ 12 } md={ 6 } xl={ 6 } className={"mt-4"}>
                        <div className="card">
                            <div
                                className="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
                                <div className="text-900 text-xl font-semibold mb-3 md:mb-0">
                                    { t('revenue per driver') }
                                </div>
                                <div className="inline-flex align-items-center">

                                </div>
                            </div>
                            <RevenuePerDriver filters={ filterPayloads } />
                        </div>
                    </Col>
                    <Col sm={ 12 } md={ 6 } xl={ 6 } className={"mt-4"}>
                        <div className="card">
                            <div
                                className="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
                                <div className="text-900 text-xl font-semibold mb-3 md:mb-0">
                                    { t('revenue per truck') }
                                </div>
                                <div className="inline-flex align-items-center">

                                </div>
                            </div>
                            <RevenuePerTransportVehicle filters={ filterPayloads } />
                        </div>
                    </Col>
                    <Col sm={ 12 } md={ 6 } xl={ 6 } className={"mt-4"}>
                        <div className="card">
                            <div
                                className="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
                                <div className="text-900 text-xl font-semibold mb-3 md:mb-0">
                                    { t('revenue per client') }
                                </div>
                                <div className="inline-flex align-items-center">

                                </div>
                            </div>
                            <RevenuePerClient filters={ filterPayloads } />
                        </div>
                    </Col>
                    <Col sm={ 12 } md={ 6 } xl={ 6 } className={"mt-4"}>
                        <div className="card">
                            <div
                                className="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
                                <div className="text-900 text-xl font-semibold mb-3 md:mb-0">
                                    { t('payment per client') }
                                </div>
                                <div className="inline-flex align-items-center">

                                </div>
                            </div>
                            <PaymentPerClient filters={ filterPayloads } />
                        </div>
                    </Col>
                    <Col sm={ 12 } md={ 6 } xl={ 6 } className={"mt-4"}>
                        <div className="card">
                            <div
                                className="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
                                <div className="text-900 text-xl font-semibold mb-3 md:mb-0">
                                    { t('kilometer per driver') }
                                </div>
                                <div className="inline-flex align-items-center">

                                </div>
                            </div>
                            <KilometerPerDriver filters={ filterPayloads } />
                        </div>
                    </Col>
                    <Col sm={ 12 } md={ 6 } xl={ 6 } className={"mt-4"}>
                        <div className="card">
                            <div
                                className="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
                                <div className="text-900 text-xl font-semibold mb-3 md:mb-0">
                                    { t('kilometer per truck') }
                                </div>
                                <div className="inline-flex align-items-center">

                                </div>
                            </div>
                            <KilometerPerTransportVehicle filters={ filterPayloads } />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

ManagementDashboard.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(ManagementDashboard);
